import React from 'react';
import { Field } from 'redux-form/immutable';
import { FormGroup, Input, Label, FormFeedback, Row, Col } from 'reactstrap';

export const renderField = ({ useLabelAsPlaceholder, label, input, type, placeholder, value, min, max, step, meta: { touched, error }, onEnter }) => {
  const hasError = touched && error;
  return <FormGroup>
    <Input 
        onKeyPress={e => { 
            if (e.key === 'Enter') {
                e.preventDefault();
                if(onEnter) onEnter();
            }
        }} 
        className="input"
        autoFocus
        min={min}
        max={max}
        step={step}
        value={value}
        placeholder={useLabelAsPlaceholder ? label.text : placeholder.show ? placeholder.text : null}
        valid={hasError ? false : null}
        type={type} { ...input } 
    />
  </FormGroup>
}

const PostiveNumber = (props) => {
  const { classProperty, type, validations, ...rest } = props;

  return (
      <Field
          name={classProperty}
          component={renderField}
          validate={validations}
          type="number"
          {...rest}
      />
  );
}

export default PostiveNumber;