import React from 'react';
import { Button } from 'reactstrap';
import { connect } from 'react-redux';
import { toJS } from '../../containers/to-js';
import queryString from 'query-string';
import PropTypes from 'prop-types';
import { makePayment } from '../../actions/payment';
import client from '../../client';
import config from '../../config';
import { Redirect } from 'react-router-dom';

const randomString = (length, chars) => {
    var result = '';
    for (var i = length; i > 0; --i) result += chars[Math.floor(Math.random() * chars.length)];
    return result;
}

class Payment extends React.Component {
    constructor(props, context) {
        super(props, context);
        const route = context.router.route;
        const params = queryString.parse(route.location.search);
        this.state = {
            orderId: randomString(16, '0123456789')
        }
    }

    makePayment = (payload) => {
        const { layoutItem: { itemProperties: { paymentConfig } }} = this.props;
        switch(paymentConfig.paymentProvider && paymentConfig.paymentProvider.value) {
            case 'CASHFREE': this.makeCashFreePayment(payload);
            break;
            case 'CCAVENUE': this.makeCCAvenuePayment(payload);
            break;
            default: console.error("Payment provider is not set");
        }
    }
    
    makeCashFreePayment = (payload) => {
        const { layoutItem: { itemProperties: { paymentConfig } }} = this.props;
        const self = this;
        const newPayload = {
            orderId: this.state.orderId,
            redirectUrl: 'http://www.screenermonkey.com/app',
            description: payload.billingName,
            currencyCode: payload.currency,
            totalAmount: payload.amount,
            customerName: payload.billing_name,
            customerEmail: payload.billing_email,
            customerPhone: payload.billing_tel,
            isTestMode: paymentConfig.isTestEnvironment
        }
        client().post(`${config.authAPI}/payment/cashfree`, newPayload).then((res) => {
            console.log(res.data);
        }).catch(error => {
            console.log('Payment Failed: ', error);
        })
    }
    makeCCAvenuePayment = (payload) => {
        const { layoutItem: { itemProperties: { paymentConfig } }, company} = this.props;
        const self = this;
        //TODO: Get the payment URL from server
        const ccavHanlderEndPoint = paymentConfig.isTestEnvironment ? 'ccavTestRequestHandler' : 'ccavRequestHandler';
        client().post(`${config.authAPI}/payment/${ccavHanlderEndPoint}`, payload).then((res) => {
            let paymentURL = config.paymentAppUrl;
            if(company && company.companySettings && company.companySettings.ccavenuesettings) {
                const c =  JSON.parse(company.companySettings.ccavenuesettings);
                paymentURL = c.paymentAppUrl ? c.paymentAppUrl : config.paymentAppUrl;
            }
            console.log(paymentURL);
            var windowHandle = window.open(`${paymentURL}?pay=${encodeURIComponent(res.data)}`);
            window.callbacks = [];
            window.callbacks['paymentCallback' + payload.order_id] = function() {
                windowHandle.close();
            };
            var closeInterval;
            var closeFunc = function() {
                if(windowHandle && windowHandle.closed) {
                    clearInterval(closeInterval);
                    client().post(`${config.authAPI}/payment/orderDetails`, {
                        orderId: payload.order_id
                    }).then((orderDetailsResponse) => {
                        orderDetailsResponse = orderDetailsResponse.data;
                        const actualSubmit = self.props.onHandleSubmit(values => {
                            let payload = values;
                            payload = values.set(paymentConfig.paymentStatusField, orderDetailsResponse.orderStatus);
                            payload = payload.set(paymentConfig.paymentReferenceField, orderDetailsResponse.orderId);
                            if(orderDetailsResponse.transactionDate) {
                                payload = payload.set(paymentConfig.paymentDateField, orderDetailsResponse.transactionDate);
                            }
                            self.props.onSubmit(payload);
                        });
                        actualSubmit();
                    }).catch((er) => {
                        console.error(er);
                    });
                }
            }
            closeInterval = setInterval(closeFunc, 500);
        }).catch((error) => {
            console.error(error);
        });
    }

    render() {
        const { 
            layoutItem: { 
                itemProperties: {
                    paymentConfig,
                    label
                }
            },
            amount,
            currency,
            billingName,
            billingAddress,
            billingCity,
            billingState,
            billingZip,
            billingCountry,
            billingTel,
            billingEmail,
            company,
            user
        } = this.props;
        return (
            <div>
                <Button name='payment'
                    onClick={() => {
                        const payload = {
                            order_id: this.state.orderId,
                            currency,
                            amount,
                            billing_name: billingName || company && company.name,
                            billing_address: billingAddress || 'NA',
                            billing_city: billingCity || 'NA',
                            billing_state: billingState || 'NA',
                            billing_zip: billingZip || 'NA',
                            billing_country: billingCountry || 'NA',
                            billing_tel: billingTel || '+917760959143',
                            billing_email: billingEmail || (user && user.email) || `${company.name}@huwats.com`,
                            integration_type: 'iframe_normal'
                        }
                        this.makePayment(payload);
                        this.setState({orderId: null});
                    }}
                    block 
                    color='primary'
                    disabled={!paymentConfig || !currency || !amount || !this.state.orderId}
                >{this.state.orderId ? (label && label.show ? label.text : 'Pay Now') : 'Complete payment to proceed...'}</Button>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    const { layoutItem: { itemProperties: { paymentConfig } }} = ownProps;
    return {
        currency: paymentConfig.currencyField && state.getIn(['form', ownProps.form, 'values', ...getAbsoluteField(paymentConfig.currencyField)]),
        billingName: paymentConfig.billingNameField && state.getIn(['form', ownProps.form, 'values', ...getAbsoluteField(paymentConfig.billingNameField)]),
        billingAddress: paymentConfig.billingAddressField && state.getIn(['form', ownProps.form, 'values', ...getAbsoluteField(paymentConfig.billingAddressField)]),
        billingCity: paymentConfig.billingCityField && state.getIn(['form', ownProps.form, 'values', ...getAbsoluteField(paymentConfig.billingCityField)]),
        billingState: paymentConfig.billingStateField && state.getIn(['form', ownProps.form, 'values', ...getAbsoluteField(paymentConfig.billingStateField)]),
        billingZip: paymentConfig.billingZipField && state.getIn(['form', ownProps.form, 'values', ...getAbsoluteField(paymentConfig.billingZipField)]),
        billingCountry: paymentConfig.billingCountryField && state.getIn(['form', ownProps.form, 'values', ...getAbsoluteField(paymentConfig.billingCountryField)]),
        billingTel: paymentConfig.billingTel && state.getIn(['form', ownProps.form, 'values', ...getAbsoluteField(paymentConfig.billingTel)]),
        billingEmail: paymentConfig.descriptionField && state.getIn(['form', ownProps.form, 'values', ...getAbsoluteField(paymentConfig.descriptionField)]),
        amount: paymentConfig.amountField && state.getIn(['form', ownProps.form, 'values', ...getAbsoluteField(paymentConfig.amountField)]),
        company: state.getIn(['company', 'details', 'data']),
        user: state.getIn(['user', 'user'])
    }
}

const getAbsoluteField = (fieldKey) => {
    
    return fieldKey ? fieldKey.split(".") : fieldKey;
}

const mapDispatchToProps = (dispatch) => {
    return {}
}

Payment.contextTypes = {
    router: PropTypes.object
}

export default connect(mapStateToProps, mapDispatchToProps)(toJS(Payment));
