import React, { Component } from 'react';
import { Badge, Label, Button, ButtonGroup } from 'reactstrap';
import ActionContainer from '../../../containers/workflow/ActionContainer';
import { ModelPropertiesParser } from '../../commons/modelPropertiesParser';
import * as charts from '../../dashboard/charts';
import COLORS from '../../../dto/chart-colors';

class TypeModelChart extends Component {

    getChartData = () => {
        const { chartConfig, workflowData } = this.props;
        let graphData = [];
        workflowData.map((data, index) => {
            const yAxisKeys = chartConfig.yAxis.split(',');
            let entry = {
                xProperties: ModelPropertiesParser(chartConfig.xAxis, data),
                //yProperties: parseInt(ModelPropertiesParser(chartConfig.yaxis, data))
            };
            let counter = 0;
            yAxisKeys.forEach(element => {
                entry['yAxisProperty' + counter++] = parseInt(ModelPropertiesParser(element, data));
            });
            graphData.push(entry);
        });
        return graphData;
    }
    getSeriesData = () => {
        const { chartConfig, workflowData } = this.props;
        let series = [];
        const legends = chartConfig.yLegend.split(',');
        const yAxisKeys = chartConfig.yAxis.split(',');
        yAxisKeys.map((data, index) => {
            let ySeries = {
                yPropertyName: 'yAxisProperty' + index,
                legendName: legends[index]
            }
            series.push(ySeries);
        });
        return series;
    }
    render() {
        const { workflow, chartConfig, workflowData, modelSelector, label, viewMode, typeModelViewMode, onChangeTypeModelViewMode, layoutItem: { itemProperties } } = this.props;
        if (!chartConfig || !workflowData) return null;
        if (workflowData && Array.isArray(workflowData)) {
            const ChartComponent = chartConfig.chartType && charts[chartConfig.chartType.code];
            return (
                <div>
                    <div className='d-flex justify-content-between mb-2'>
                        <div>
                            {
                            label.show && <Label className='text-capitalize mb-1'>{label.text}</Label>
                            }
                        </div>
                        <div>
                            {
                                itemProperties && itemProperties.allowAdditionalTypeModelViewMode && <ButtonGroup className="mr-4">
                                <Button onClick={onChangeTypeModelViewMode.bind(this, typeModelViewMode.value)} color='primary' outline={viewMode !== typeModelViewMode.value} size="sm">
                                    <i className="fa fa-th-list" aria-hidden="true"></i>
                                </Button>
                                <Button onClick={onChangeTypeModelViewMode.bind(this, itemProperties.additionalTypeModelViewMode.value)} color='primary' outline={viewMode !== itemProperties.additionalTypeModelViewMode.value} size="sm">
                                    <i className="fa fa-th-large" aria-hidden="true"></i>
                                </Button>
                            </ButtonGroup>
                            }
                            <TypeModelCreateAction workflow={workflow} itemProperties={itemProperties} actions={workflow.currentItem.inlineAction} />
                        </div>
                    </div>
                    <div className='d-flex'>
                        <ChartComponent 
                            graphData={this.getChartData()}
                            colors={COLORS}
                            xLabel={chartConfig.xLabel}
                            yLabel={chartConfig.yLabel}
                            graphHeight={parseInt(chartConfig.height) || 500}
                            legendName={chartConfig.yLegend}
                            series = {this.getSeriesData()}
                        />
                    </div>
                </div>
            )
        }
        return (
            <div className='d-flex'>

            </div>
        );
    }
}

const findActionByCode = (actions, code) => {
    let matchedAction = null;
    for (let i = 0; i < actions.length; i += 1) {
        if (actions[i].actionProperties.generatedActionCode && actions[i].actionProperties.generatedActionCode === code) {
            matchedAction = actions[i]
        }
    }
    return matchedAction;
}

const TypeModelCreateAction = (props) => {
    const { actions, itemProperties, workflow } = props;
    const { allowAddition, generatedActionCodeForAllowAddition } = itemProperties;
    if (!allowAddition) return null;
    const action = findActionByCode(actions, generatedActionCodeForAllowAddition);
    if (!action) return null;
    return (
        <ActionContainer workflow={workflow} action={action} />
    )
}

export default TypeModelChart;