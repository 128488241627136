import React, { Component } from 'react';
import KanbanBoard from './KeyedViews/KanbanBoard';
import ChronoView from './KeyedViews/ChronoView';
import MapView from './KeyedViews/MapView';
import MapViewWithPath from './KeyedViews/MapViewWithPath';

const VIEW_TYPES = {
    KANBAN: 'KANBAN',
    CHRONO: 'CHRONOLOGICAL',
    MAP: 'MAP'
}

class KeyedLayout extends Component {
    getViewType(TYPE) {
        switch (TYPE) {
            case 'DATETIME':
            case 'DATE': return VIEW_TYPES.CHRONO;
            case 'DROPDOWN': return VIEW_TYPES.KANBAN;
            case 'LOCATION': return VIEW_TYPES.MAP;
            default: return VIEW_TYPES.KANBAN;
        }
    }
    render() {
        const { config } = this.props;
        if(!config) return null;
        const { lanes, card, laneEnumerator, locationEnumeratorConfig } = this.props.config;
        let viewType = VIEW_TYPES.KANBAN;
        let laneKey = 'status';

        const  { workflowData, ...rest } = this.props;
        if (laneEnumerator && laneEnumerator.type) {
            laneKey = laneEnumerator.classProperty;
            viewType = this.getViewType(laneEnumerator.type);
        }
        if (viewType === VIEW_TYPES.KANBAN) {
            return (
                <KanbanBoard laneKey={laneKey} workflowData={this.props.data} {...rest} />
            );
        }
        if (viewType === VIEW_TYPES.CHRONO) {
            return (
                <ChronoView laneKey={laneKey} workflowData={this.props.data} {...rest} />
            )
        }
        if(viewType === VIEW_TYPES.MAP) {
            if (locationEnumeratorConfig && locationEnumeratorConfig.useDiretionAPI) return (
                <MapViewWithPath laneKey={laneKey} locationConfig={locationEnumeratorConfig} workflowData={this.props.data} {...rest}/>
            )
            return (
                <MapView laneKey={laneKey} locationConfig={locationEnumeratorConfig} workflowData={this.props.data} {...rest} />
            )
        }

    }
}

export default KeyedLayout;