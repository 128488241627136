import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Input, Row, Col } from 'reactstrap';
import { withScriptjs } from "react-google-maps";
import StandaloneSearchBox from 'react-google-maps/lib/components/places/StandaloneSearchBox';
import Select from 'react-select';

class LocationFilter extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      filterValue: null,
      refs: {},
      selectedOption: '10'
    }
  }
  onSearchBoxMounted = ref => {
    const refs = this.state.refs;
    refs.searchBox = ref;
    this.setState({
      refs
    });
  }
  onPlacesChanged = () => {
    const places = this.state.refs.searchBox.getPlaces();
    const filterValue = [];
    filterValue.push(`${places[0].geometry.location.lat()}`);
    filterValue.push(`${places[0].geometry.location.lng()}`);
    filterValue.push(this.state.selectedOption);
    this.setState({
      filterValue
    }, () => {
      this.triggerFilterChange();
    });
  }

  triggerFilterChange() {
    const { onFilterChange, filter, filterIndex } = this.props;
    const filterConfig = {
        filter,
        filterIndex
    }
    onFilterChange(filterConfig, this.state.filterValue);
  }

  handleChangeDistance = (value) => {
    let filterValue = this.state.filterValue;
    if(filterValue) {
      filterValue[2] = value.value
    }
    this.setState({ selectedOption: value.value, filterValue }, ()=> {
      this.triggerFilterChange();
    });
  }
  render() {
    const options = [{
      value: '5',
      label: '< 5 miles'
    }, {
      value: '10',
      label: '< 10 miles'
    }]

    return (
      <Row>
        <Col xs="8" className="pr-0">
          <LocationSearch 
            googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyBfhuMCKv2c1jH_lJcxJ1iNjg0mmHXY_zU&v=3.exp&libraries=geometry,drawing,places"
            loadingElement={<div style={{ height: `100%` }} />}
            containerElement={<div style={{ height: `400px` }} />}
            onSearchBoxMounted={this.onSearchBoxMounted}
            onPlacesChanged={this.onPlacesChanged}
          />
        </Col>
        <Col xs="4" className="pl-0">
          <Select
              name={'distance'}
              value={this.state.selectedOption}
              onChange={this.handleChangeDistance.bind(this)}
              labelKey='label'
              valueKey='value'
              options={options}
              clearable={false}
          />
        </Col>
      </Row>
    )
  }
}

const LocationSearch = withScriptjs((props) => {
  return (
    <StandaloneSearchBox
      ref={props.onSearchBoxMounted}
      bounds={props.bounds}
      onPlacesChanged={props.onPlacesChanged}
    >
      <Input
        type="text"
        placeholder="Enter location"
      />
    </StandaloneSearchBox>
  )
})

export default LocationFilter;