import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReCAPTCHA from 'react-google-recaptcha';

class ReCaptcha extends React.Component {
  render() {
    const {
      layoutItem: {
        itemProperties: { reCaptchaConfig },
      },
      change,
    } = this.props;
    return (
      <div>
        <ReCAPTCHA
          ref='recaptcha'
          sitekey={
            reCaptchaConfig && reCaptchaConfig.siteKey
              ? reCaptchaConfig.siteKey
              : '6LdA1rYUAAAAADPkB4lFQbyas7GQ_1LQalVgiu4-'
          }
          onChange={token => {
            reCaptchaConfig &&
              reCaptchaConfig.reCaptchaField &&
              change(reCaptchaConfig.reCaptchaField, token);
          }}
        />
      </div>
    );
  }
}

export default ReCaptcha;
