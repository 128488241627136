import React, { Component } from "react";
import { Marker, DirectionsRenderer } from "react-google-maps";
import { ModelPropertiesParser } from '../commons/modelPropertiesParser';

class DirectionRenderComponent extends Component {
  constructor(props) {
        super(props);
        this.state = {
			directions: null
        }
   }

  componentWillReceiveProps(nextProps) {
	const { googleMapConfig, workflowData } = this.props;
	const workflowDataNext = nextProps.workflowData;
	let source = ModelPropertiesParser(`{{${googleMapConfig.source}}}`, workflowData);
	let destination = ModelPropertiesParser(`{{${googleMapConfig.destination}}}`, workflowData);
	let sourceNew = ModelPropertiesParser(`{{${googleMapConfig.source}}}`, workflowDataNext);
	let destinationNew = ModelPropertiesParser(`{{${googleMapConfig.destination}}}`, workflowDataNext);
	if(!this.state.directions) {
		this.getDirections(nextProps);
	}
	else if(source != sourceNew || destination != destinationNew) {
		this.getDirections(nextProps);
	}
  }

  getDirections = (props) => {
		let directions = null;
		const { googleMapConfig, workflowData } = props;
		const directionService = new window.google.maps.DirectionsService();
		let wayPoints = [];
		let source = ModelPropertiesParser(`{{${googleMapConfig.source}}}`, workflowData);
		let destination = ModelPropertiesParser(`{{${googleMapConfig.destination}}}`, workflowData);
		
		if(source && destination) {
			source = source.split(',');
			destination = destination.split(',');
			let stop1 = googleMapConfig.stop1 ? ModelPropertiesParser(`{{${googleMapConfig.stop1}}}`, workflowData): '';
			let stop2 = googleMapConfig.stop2 ? ModelPropertiesParser(`{{${googleMapConfig.stop2}}}`, workflowData): '';
			let stop3 = googleMapConfig.stop3 ? ModelPropertiesParser(`{{${googleMapConfig.stop3}}}`, workflowData): '';
			let stop4 = googleMapConfig.stop4 ? ModelPropertiesParser(`{{${googleMapConfig.stop4}}}`, workflowData): '';
			let stop5 = googleMapConfig.stop5 ? ModelPropertiesParser(`{{${googleMapConfig.stop5}}}`, workflowData): '';
			if(stop1)
			{
				stop1 = stop1.split(',');
				wayPoints.push({
					location: new window.google.maps.LatLng(Number(stop1[0]), Number(stop1[1])),
					stopover: true
				});
			}
			if(stop2)
			{
				stop2 = stop2.split(',');
				wayPoints.push({
					location: new window.google.maps.LatLng(Number(stop2[0]), Number(stop2[1])),
					stopover: true
				});
			}
			if(stop3)
			{
				stop3 = stop3.split(',');
				wayPoints.push({
					location: new window.google.maps.LatLng(Number(stop3[0]), Number(stop3[1])),
					stopover: true
				});
			}
			if(stop4)
			{
				stop4 = stop4.split(',');
				wayPoints.push({
					location: new window.google.maps.LatLng(Number(stop4[0]), Number(stop4[1])),
					stopover: true
				});
			}
			if(stop5)
			{
				stop5 = stop5.split(',');
				wayPoints.push({
					location: new window.google.maps.LatLng(Number(stop5), Number(stop5[1])),
					stopover: true
				});
			}
			directionService.route({
				origin: new window.google.maps.LatLng(Number(source[0]), Number(source[1])),
				destination: new window.google.maps.LatLng(Number(destination[0]), Number(destination[1])),
				waypoints: wayPoints,
				travelMode: window.google.maps.TravelMode.DRIVING,
				}, (result, status) => {
					console.log(result)
				if (status === window.google.maps.DirectionsStatus.OK) {
					this.setState({
						directions: result
					})
				} else {
				  console.error(`error fetching directions ${result}`);
				  return null;
				}
			});
		}
		
	}

  
  render() {
    return (
      <div>
		
        {this.state.directions && (
          <DirectionsRenderer
            directions={this.state.directions}
            
          />
        )}
      </div>
    );
  }
}

export default DirectionRenderComponent;