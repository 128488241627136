import { 
    NEXT_ITEM_REQUESTED,
    NEXT_ITEM_SAVED_SUCCESS,
    NEXT_ITEM_REQUESTED_FAILURE,
    NEXT_ITEM_REQUESTED_SUCCESS,
    CLEAR_NEXT_STATE
} from '../actions/workflow-item';
import { Map } from 'immutable';

const intitalState = Map({});

const next = (state = intitalState, action = {}) => {
    switch (action.type) {
        case NEXT_ITEM_REQUESTED: return state.merge({ isLoading: true, hasError: false, error: null, pristine: true });
        case NEXT_ITEM_SAVED_SUCCESS: return state.merge({ isLoading: false, hasError: false, error: null, pristine: action.payload.pristine });
        case NEXT_ITEM_REQUESTED_SUCCESS: return state.merge({ isLoading: false, hasError: false, error: null, data: action.payload });
        case NEXT_ITEM_REQUESTED_FAILURE: return state.merge({ isLoading: false, hasError: true, error: action.payload.error, pristine: true });
        case CLEAR_NEXT_STATE: return intitalState;
        default: return state;
    }
};

export default next;