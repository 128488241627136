import React, { Component } from 'react';
import { Button } from 'reactstrap';


class WorkflowSubmenuEndButton extends Component {
   
    render() {
        const { workflow: { currentItem: { layoutProperties: { workflowSubmenuConfig, pageHeaderConfig } } }, submenuActiveIndex, onSubmenuClicked } = this.props;
        const { items } = workflowSubmenuConfig;
        const headerText = pageHeaderConfig &&
        pageHeaderConfig.isConfigured ? pageHeaderConfig.modelSelector : null;
        if (!items || (items && !items.length)) return null;
        const buttons = [];
        // {
        //   items.map((item, index) => {
        //       const component = () => <Text style={{fontSize:22, textTransform: 'uppercase'}}>{item.label}</Text>
        //       buttons.push(item.label)
        //   })
        // }
        return (<div>
            { !(items.length - 1 === submenuActiveIndex) &&  
            <Button 
            color={'primary'}
            className='endButtonSubmenu'
            onClick={this.props.onHandleSubmit(()=>{
                onSubmenuClicked(submenuActiveIndex + 1)
                window.scrollTo(0, 0);
               })} >Next
            </Button>}
            </div>
        );
    }
}



export default WorkflowSubmenuEndButton;