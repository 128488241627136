import isNumeric from 'validator/lib/isNumeric';

const wholeNumber = value => {
    if (typeof value === 'undefined') return undefined;
    const isNum = isNumeric(value + '');
    const _numVal = Number(value);
    if (!isNum) {
        return 'Please enter a valid number';
    }
    return undefined;
} 
export default wholeNumber;
