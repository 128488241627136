import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody, Button } from 'reactstrap';
import PropTypes from 'prop-types';

class SidebarRight extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: props.open
        }
		
		this.toggle = this.toggle.bind(this);
    }
	componentDidMount() { 
		const self = this;
		document.onkeydown = function(evt) {
			evt = evt || window.event;
			if (evt.keyCode == 27) {
				evt.preventDefault();
				evt.stopPropagation();
				self.toggle();
			}
		};
	}

    componentWillReceiveProps(nextProps) {
        this.setState({ open: nextProps.open });
    }
    toggle() {
        this.setState({ open: false });
    }
    render() {
        const { width, onClosed, onOpened, className, heading, children } = this.props;
        const isOpen = this.state.open;
		const styles = {
            width: `${width}%`,
            animationDuration: '.35s'
        }
        return (
			<Modal onOpened={onOpened} onClosed={onClosed} fade={true} style={styles} isOpen={isOpen} modalClassName='sidebar-right-modal'
			className={`sidebar-right-player ${className} animated ${isOpen ? 'slideInRight' : 'slideOutRight'}`} backdropClassName='sidebar-right-backdrop'>
                <Button color='light' className='close' onClick={this.toggle} > 
                    <i className="fa fa-times" aria-hidden="true"></i>
                </Button>
                <ModalBody>
                    {
                        children
                    }
                </ModalBody>
            </Modal>
        );
    }
}

SidebarRight.defaultProps = {
    width: 40
};

SidebarRight.propTypes = {
    width: PropTypes.number
};

export default SidebarRight;