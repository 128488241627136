import React, { Component } from 'react';
import { connect } from 'react-redux';
import { toJS } from '../../containers/to-js';
import { getUsers } from '../../actions/user';
import User from '../commons/user/index';
import { Field } from 'redux-form/immutable';
import { renderSelect, genericNormalizer } from './_FORM_FIELDS';
import PropTypes from 'prop-types';
import PrintText from './PrintText';

const normalize = (multiple, selected) => {
    if (!selected) return selected
    return selected;
}

class UsersList extends Component {
    constructor(props) {
        super(props);
        this.optionRenderer = this.optionRenderer.bind(this);
        this.valueRenderer = this.valueRenderer.bind(this);
    }
    componentDidMount() {
        const { getUsers, users } = this.props;
        if (users && !users.data) {
            getUsers();
        }
    }
    optionRenderer(user) {
        return <User user={user} />
    }
    valueRenderer(user) {
        return <User avatarSize={25} user={user} displayMode='SingleLine' />
    }
    render() {
        const { users, classProperty, type, isMulti, validations, isReadOne, ...rest } = this.props;
        const { layoutItem: { itemProperties: { field: { properties, collection } } } } = this.props;
        if (users.isLoading) return <p className='text-muted'>Loading Users...</p>
        if (!users.data) return null;
        const usersList = users.data.data;
        return (
            <div>
                {
                    isReadOne ?
                        <PrintText {...this.props} /> :
                        <Field
                            name={classProperty}
                            component={renderSelect}
                            type="select"
                            validate={validations}
                            fieldType={type}
                            valueKey='id'
                            labelKey='email'
                            options={usersList}
                            properties={properties}
                            normalize={normalize.bind(this, collection)}
                            multiple={collection}
                            optionRenderer={this.optionRenderer}
                            valueRenderer={this.valueRenderer}
                            {...rest}
                        />
                }
            </div>
        );
    }
}

UsersList.defaultProps = {
    isMulti: false
}

UsersList.propTypes = {
    isMulti: PropTypes.bool
}

const mapStateToProps = (state, ownProps) => {
    return {
        users: state.getIn(['user', 'list'])
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        getUsers: () => {
            dispatch(getUsers());
        }
    }
};
export default connect(mapStateToProps, mapDispatchToProps)(toJS(UsersList));