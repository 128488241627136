import { Map, List, fromJS } from 'immutable';
import {
   ACTION_TRIGGERED,
   ACTION_TRIGGERED_SUCCESS,
   ACTION_TRIGGERED_FAILURE,
   CLEAR_ACTION_TRIGGERTED_STATE
} from '../actions/workflow-actions';

const intitalState = Map({});

const createActionKey = (state, action) => {
    const actionId = action.payload.action.id; 
    return state.set(actionId, fromJS({ isLoading: true, hasError: false }));
}

const clearActionKey = (state, action) => {
    const actionId = action.payload.id; 
    return state.delete(actionId);
}

const onSuccess = (state, action) => {
    const actionId = action.payload.action.id; 
    return state.set(actionId, fromJS({ isLoading: false, hasError: false }));
}

const onError = (state, action) => {
    const actionId = action.payload.action.id; 
    return state.set(actionId, fromJS({ isLoading: false, hasError: true }));
}

const workflowActions = (state = intitalState, action = {}) => {
    switch (action.type) {
        case ACTION_TRIGGERED: return createActionKey(state, action);
        case ACTION_TRIGGERED_SUCCESS: return onSuccess(state, action);
        case ACTION_TRIGGERED_FAILURE: return onError(state, action);
        case CLEAR_ACTION_TRIGGERTED_STATE: return clearActionKey(state, action);
        default: return state;
    }
};

export default workflowActions;
