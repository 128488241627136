import React from 'react';
import { FormGroup, Label, FormFeedback } from 'reactstrap';
import { Field } from 'redux-form/immutable';
import ReactPhoneInput from 'react-phone-input-2';

const Phone = (props) => {
    const { classProperty, type, validations, value, isReadOne, ...rest } = props;
    return (
        <div>
            <Field
                name={classProperty}
                component={renderPhone}
                type="number"
                validate={validations}
                value={value}
                fieldType={type}
                {...rest}
            />
        </div>
    );
}

const renderPhone = ({ input, onEnter, label, type, placeholder, value, min, max, step, layoutItem, meta: { touched, error } }) => {
  const hasError = touched && error;
  const { properties } = layoutItem.itemProperties.field;
  return (
    <FormGroup>
      <ReactPhoneInput
          defaultCountry={'in'}
          onChange={input.onChange}
          inputStyle={{
            width: '100%'
          }}
          onKeyDown={e => {
            if (e.key === 'Enter') {
                e.preventDefault();
                if(onEnter) onEnter();
            }
          }}
          value={input.value}
      />
      {
          hasError ? <FormFeedback className='animated fadeIn'>
              <i className="fa fa-exclamation-triangle" aria-hidden="true"></i> {error}
          </FormFeedback> : ''
      }
    </FormGroup>
  )
}

export default Phone;