import { SET_UI_FILTER } from '../actions/uiFilters';
import { Map } from 'immutable';

const intitalState = Map({});

const uiFilters = (state = intitalState, action = {}) => {
  switch (action.type) {
    case SET_UI_FILTER:
      return state.merge({
        [action.payload.key]: action.payload.value
      });

    default:
      return state;
  }
};

export default uiFilters;
