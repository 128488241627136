import React, { Component } from 'react';
import * as charts from './';
import { Alert, Button, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import CHART_TYPES from '../../../dto/chart-types';
import COLORS from '../../../dto/chart-colors';

class Chart extends Component {
  constructor(props) {
    super(props);
    const reportTypeConfig = this.resolveReportType(props);
    this.state = {
      chart: {
        isLoading: true,
        data: null,
        hasError: false,
        reportType: reportTypeConfig.reportType,
        hasSavedReportType: reportTypeConfig.hasSavedReportType,
        originalReportType: null
      }
    };
  }
  componentDidMount() {
    const { getReportData, reportId } = this.props;
    getReportData({ reportId }).then((res) => {
      const chart = Object.assign({}, this.state.chart);
      chart.isLoading = false;
      chart.data = res && res.data && res.data.data ? res.data.data : {};
      chart.reportType = chart.hasSavedReportType ? chart.reportType : CHART_TYPES[chart.data.reportType];
      chart.originalReportType = chart.data.reportType;
      chart.hasError = false;
      this.setState({ chart });
    }).catch((ex) => {
      const chart = Object.assign({}, this.state.chart);
      chart.isLoading = false;
      chart.data = null;
      chart.reportType = CHART_TYPES.LINECHART;
      chart.hasError = true;
      this.setState({ chart });
    });
  }
  resolveReportType(props) {
    const savedReport = this.getSavedReport(props.savedDashboard, props.reportId);
    let reportType = CHART_TYPES.LINECHART;
    let hasSavedReportType = false;
    if (savedReport.report && savedReport.report.preferences !== '') {
      const preferences = JSON.parse(savedReport.report.preferences);
      if (preferences && preferences.reportType) {
        reportType = CHART_TYPES[preferences.reportType];
        hasSavedReportType = true;
      }
    }
    return {
      reportType,
      hasSavedReportType
    };
  }
  getSavedReport(savedDashboard, reportId) {
    let matched = { report: null, index: -1 };
    for (let index = 0; index < savedDashboard.savedReports.length; index++) {
      const savedReport = savedDashboard.savedReports[index];
      if (savedReport.reportId === reportId) {
        matched.report = savedReport;
        matched.index = index;
        break;
      }
    }
    return matched;
  }
  getLegendName(data) {
    if (data.columns && data.columns[1]) {
      let str = data.columns[1];
      return str;
    }
    return null
  }
  getXLabel(data) {
    if (data.columns && data.columns.length === 2) {
      return data.columns[0];
    }
    if (data.columns && data.columns.length === 3) {
      return data.columns[1];
    }
    return null
  }
  getYLabel(data) {
    if (data.columns && data.columns.length === 2) {
      return data.columns[1];
    }
    if (data.columns && data.columns.length === 3) {
      return data.columns[2];
    }
    return null
  }
  updateReportType = (reportType) => {
    const chart = Object.assign({}, this.state.chart);
    chart.reportType = CHART_TYPES[reportType];
    this.setState({ chart }, () => {
      this.persistReportType(reportType);
    });
  }
  getChartDisplayOptions() {
    const options = [];
    for (let key in CHART_TYPES) {
      const CHART = CHART_TYPES[key];
      const isActive = this.state.chart.reportType.code === CHART.code;
      options.push(
        <DropdownItem className={`${isActive ? 'text-success' : ''}`} key={key} onClick={() => { this.updateReportType(CHART.code) }}>
          <i className={`${CHART.icon} mr-2`} aria-hidden="true"></i>
          {CHART.label}
          {isActive ? <i className={`fa fa-check ml-2`} aria-hidden="true"></i> : ''}
        </DropdownItem>
      )
    }
    return options;
  }
  persistReportType(reportType) {
    const { reportId, saveDashboard, savedDashboard } = this.props;
    const savedReport = this.getSavedReport(savedDashboard, reportId);
    const payload = Object.assign({}, savedDashboard);
    if (savedReport.report) {
      if (savedReport.report.preferences === '') {
        savedReport.report.preferences = { reportType };
      }
      else {
        const _preferences = JSON.parse(savedReport.report.preferences);
        _preferences.reportType = reportType;
        savedReport.report.preferences = _preferences;
      }
      savedReport.report.preferences = JSON.stringify(savedReport.report.preferences);
      payload.savedReports[savedReport.index] = savedReport.report;
      saveDashboard(payload);
    }

  }
  removeReport() {
    const { reportId, saveDashboard, savedDashboard } = this.props;
    const savedReport = this.getSavedReport(savedDashboard, reportId);
    const payload = Object.assign({}, savedDashboard);
    if (savedReport.index !== -1) {
      let _layoutInformationIndex = -1;
      const toMatch = `reportid__${reportId}`;
      const _layoutInformation = JSON.parse(payload.layoutInformation);
      payload.savedReports.splice(savedReport.index, 1);
      for (let index = 0; index < _layoutInformation.length; index++) {
        const layout = _layoutInformation[index];
        if (layout.i === toMatch) {
          _layoutInformationIndex = index;
        }
      }
      if (_layoutInformationIndex !== -1) {
        _layoutInformation.splice(savedReport.index, 1);
        payload.layoutInformation = _layoutInformation;
        if (payload.layoutInformation.length === 0) {
          payload.layoutInformation = null;
        }
        else {
          payload.layoutInformation = JSON.stringify(payload.layoutInformation);
        }
      }

      saveDashboard(payload);
    }

  }
  render() {
    const { chart } = this.state;
    const { isLoading, reportType, data, hasError, originalReportType } = chart;
    if (isLoading) {
      return <p className='text-muted'>Loading...</p>;
    }
    let Component = reportType ? charts[reportType.code] : null;
    if (hasError) {
      return (
        <div>
          <div className='d-flex justify-content-end mb-3' style={{ borderBottom: '2px solid #fafafa' }}>
            <div className='d-flex'>
              <UncontrolledDropdown>
                <DropdownToggle className='text-muted' color='link' size='sm'>
                  <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
                </DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem className='text-danger' onClick={this.removeReport.bind(this)}>
                    <i className='fa fa-times mr-2' aria-hidden="true"></i>
                    Remove
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </div>
          </div>
          <Alert color='danger'><i className="fa fa-exclamation-triangle mr-1" aria-hidden="true"></i>Something went wrong while trying to get the report data!</Alert>
        </div>
      )
    }
    return (
      <div className='chart-container'>
        <div className='d-flex justify-content-between mb-3' style={{ borderBottom: '2px solid #fafafa' }}>
          <div className='chart-heading-wrapper'>
            <p className='text-capitalize mb-0'>{data.name.toLowerCase()}</p>
          </div>
          <div className='d-flex'>
            <UncontrolledDropdown>
              <DropdownToggle className='text-muted' color='link' size='sm'>
                <i className="fa fa-eye" aria-hidden="true"></i>
              </DropdownToggle>
              <DropdownMenu right>
                {
                  this.getChartDisplayOptions()
                }
              </DropdownMenu>
            </UncontrolledDropdown>
            <UncontrolledDropdown>
              <DropdownToggle className='text-muted' color='link' size='sm'>
                <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
              </DropdownToggle>
              <DropdownMenu right>
                <DropdownItem className='text-danger' onClick={this.removeReport.bind(this)}>
                  <i className='fa fa-times mr-2' aria-hidden="true"></i>
                  Remove
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </div>
        </div>

        {
          Component ? <Component
            graphData={data.graphData}
            legendName={this.getLegendName(data)}
            colors={COLORS}
            xLabel={this.getXLabel(data)}
            yLabel={this.getYLabel(data)}
            graphHeight={this.props.graphHeight}
            rawData={data}
            originalReportType={originalReportType}

          /> : <Alert color='warning'><i className="fa fa-exclamation-triangle mr-1" aria-hidden="true"></i>Report Type is not available.</Alert>
        }

      </div>
    )
  }
}

export default Chart;
