import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Alert } from 'reactstrap';

class RenderMessage extends Component {
    getIconClass(context) {
        switch(context) {
            case 'warning': return 'fa fa-exclamation-triangle';
            case 'danger': return 'fa fa-exclamation-triangle'; 
            case 'success': return 'fa fa-check-circle';
            case 'info':  return 'fa fa-info-circle';
            default: return 'fa fa-info-circle';
        }
    }
    render() {
        const { workflowMessage } = this.props;
        if (!workflowMessage) return null;
        let { message, messageType } = workflowMessage;
        messageType = typeof messageType === 'string' ? messageType.toLowerCase() : 'info';
        if (messageType === 'error') messageType = 'danger';
        return (
            <Alert className="workflow-message" title={message} color={messageType}>
                <i className={`${this.getIconClass(messageType)} mr-1`}></i>{message}
            </Alert>
        );
    }
}

RenderMessage.defaultProps = {
    message: null
}

RenderMessage.propTypes = {
    message: PropTypes.object
};

export default RenderMessage;