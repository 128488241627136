const date = value => {
    if (typeof value === 'undefined' || value === null) return undefined;
    const _date = new Date(value.replace(/-/g, "/"));
    if (_date != "Invalid Date") {
        return undefined;
    }
    else {
        return 'Please enter a valid date';
    }
} 
export default date;