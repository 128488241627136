import React from 'react';
import { Field } from 'redux-form/immutable';
import PrintText from './PrintText';
import { connect } from 'react-redux';
import { toJS } from '../../containers/to-js';
import { getCities } from '../../actions/static-data';
import { renderSelect, renderField } from './_FORM_FIELDS';
import _ from 'lodash'

class Cities extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            cities: []
        }
    }

    componentDidMount() {
        const { getCities, isReadOne, layoutItem: { itemProperties: { othersAsOption } } } = this.props;
        if(!isReadOne && !othersAsOption) {
            //getCities();
        }
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.selectedState && nextProps.cities) {
            if(this.props.selectedState) {
                this.setState({cities: (this.props.selectedState.id !== nextProps.selectedState.id || 
                    this.state.cities.length == 0) ? this.getCities(nextProps.selectedState,nextProps.cities) : this.state.cities});
            } else {
                this.setState({cities: this.getCities(nextProps.selectedState, nextProps.cities)});
            }
        }
    }

    getCities(state, cities) {
        return state && cities && cities.filter(city => city.state_id === state.id).map((city, index) => {
            return {
                value: city.name,
                label: city.name,
                cityId: city.id,
                stateId: state.stateId
            }
        });
      }

    render() {
        const { classProperty, type, validations, isReadOne, isMulti, selectedState, ...rest } = this.props;
        const { layoutItem: { itemProperties: { field: { properties }, othersAsOption } } } = this.props;
        let { possibleValues } = properties;
        const options = possibleValues.length ? [] : this.state.cities;
        return (
            <div>
            {
                isReadOne ?
                    <PrintText {...this.props} /> :
                    <div>
                        {
                            (othersAsOption) ? (
                                <Field
                                    name={classProperty}
                                    component={renderField}
                                    type={"text"}
                                    validate={validations}
                                    fieldType={type}
                                    {...rest}
                                />
                            ) : (
                                <Field
                                    name={classProperty}
                                    component={renderSelect}
                                    type="select"
                                    validate={validations}
                                    fieldType={type}
                                    valueKey='value'
                                    labelKey='label'
                                    options={options}
                                    properties={properties}
                                    onDataChange={(v)=> {
                                        const cEventKeys = window.cEvents && Object.keys(window.cEvents);
                                        const fValues = this.props.formValues;
                                        _.set(fValues,classProperty,v)
                                        Array.isArray(cEventKeys) && cEventKeys.map(k => {
                                        k.startsWith(classProperty) &&
                                            window.cEvents[k](fValues);
                                        });
                                    }}
                                    {...rest}
                                    disabled={options && options.length > 0 ? false : true}
                                    simpleValue={true}
                                />
                            )
                        }
                        
                    </div>
            }
        </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getCities: () => {
            return dispatch(getCities());
        }
    }
  };
  
  const mapStateToProps = (state, ownProps) => {
    const { layoutItem: { itemProperties: { citiesConfig } } } = ownProps;
    let selectedState;
    if(citiesConfig && citiesConfig.defaultState) {
        selectedState = citiesConfig.defaultState;
    } else if (citiesConfig && citiesConfig.stateField) {
        const stateFieldSplit = citiesConfig.stateField.split(".");
        let selectedStateName = !ownProps.isReadOne && state.getIn(['form', 'create-update-form-base', 'values', ...stateFieldSplit]);
        selectedStateName =  selectedStateName ? selectedStateName : state.getIn(['form', 'create-update-form-modal', 'values', ...stateFieldSplit]);
        selectedStateName =  selectedStateName ? selectedStateName : state.getIn(['form', 'create-update-form-sidebar', 'values', ...stateFieldSplit]);
        const states = state.getIn(['staticData', 'states']);
        selectedState = states && selectedStateName && states.filter((state) => state.get('name') === selectedStateName).get(0);
    }
    return {
        cities: state.getIn(['staticData', 'cities']),
        selectedState
    };
  };


export default connect(mapStateToProps, mapDispatchToProps)(toJS(Cities));