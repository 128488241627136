import React, { Component } from 'react';
import { Col, Label, Input } from 'reactstrap';

class SmallTextFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filterValue: '',
    };
  }

  render() {
    const {
      filter,
      elem_id,
      filter: { type },
      onFilterChange,
    } = this.props;
    const { filterValue } = this.state;
    return (
      <Col xs='12' className='w-100 p-0'>
        <Label>
          {filter.paramLabel ? filter.paramLabel : 'Enter filter key'}
        </Label>
        <Input
          value={filterValue}
          onChange={event => {
            console.log(event.target.value);
            this.setState({ filterValue: event.target.value }, () => {
              onFilterChange(filter.paramName, this.state.filterValue);
            });
          }}
          placeholder={
            filter.paramLabel ? filter.paramLabel : 'Enter filter key'
          }
          type={'text'}
        />
      </Col>
    );
  }
}

SmallTextFilter.propTypes = {};

export default SmallTextFilter;
