import React, { Component } from 'react';
import PropTypes from 'prop-types';
import IcoMoon from "react-icomoon";

const Icon = ({ ...props }) => {
    let customIconsSet = props.customicons && props.customicons.data 
    return <IcoMoon iconSet={customIconsSet} {...props} />;
  };

class RenderIcon extends Component {
    render() {        
        const { config, color ,customIcons,iconBackgroundcolor} = this.props;
        const  customIconsSet = this.props.customIcons && this.props.customIcons.data ? this.props.customIcons.data : null
        if (!config) return null;
        const { cssClass,cssClass2,cssCustomWeb,isCustomIcon,customIconClass,webIconSize,webIconSizeMenu,cssCustomIconMenu} = config;
        const cssClassGenral = cssClass2 ? cssClass2 : cssClass ? cssClass : null
        if (!cssClassGenral) return null;
        const onlyIcon = config.onlyIcon;
        const isCustomIconTrue = isCustomIcon ?isCustomIcon : null 
        const customIconSize = config && config.iconSize ?config.iconSize : webIconSizeMenu? webIconSizeMenu:null 
        const cssCustomSupport  = cssCustomWeb? cssCustomWeb:cssCustomIconMenu?cssCustomIconMenu:null
        if(cssCustomSupport || customIconClass || cssCustomIconMenu && customIconsSet !== null && customIconsSet !== undefined  )
        return(
           <Icon customicons={customIcons} className={`fa ${cssClass} ${cssCustomSupport} ${onlyIcon ? 'm-1' : 'mr-1'} ${color?'text-'+color:''}`} size={customIconSize?customIconSize:"24"} icon={`${cssCustomSupport}`} removeInlineStyle={false} />
        )
        return (
            <i style={iconBackgroundcolor?{backgroundColor:iconBackgroundcolor}:{}} className={`fa ${cssClassGenral} ${onlyIcon ? 'm-1' : 'mr-1'} ${color?'text-'+color:''}`} aria-hidden="true"></i>
        );
    }
}

RenderIcon.defaultProps = {
    config: null
}

RenderIcon.propTypes = {
    config: PropTypes.object
};

export default RenderIcon;