import React from 'react';
import { Button } from 'reactstrap';
import config from '../../config';

const HeroBanner = (props) => {
    const { classProperty, type, validations, value, label, isReadOne, nextState, layoutItem: { itemProperties }, ...rest } = props;
    const isLoading = nextState && nextState.isLoading ? true : false;
    const { heroBannerConfig } = itemProperties;
    let bgImageStyle = {};
    if (!heroBannerConfig) return null;
    const { heading, subtitle, showBannerButton, buttonText, backgroundImagePath, overrideMargins } = heroBannerConfig;
    if (backgroundImagePath && backgroundImagePath.path) {
        bgImageStyle = {
            backgroundImage: `url("${config.assetsBasePath}${backgroundImagePath.path}")`
        }
    }
    return (
        <div id='hero' className={`hero-banner ${overrideMargins ? 'override-margins' : ''}`}>
            <div className='hero-banner-image' style={bgImageStyle}></div>
            <div className='hero-background-color'>
                <div className='banner'>
                    <h1 className='banner-heading' style={{ color: heroBannerConfig.headingColor}}>
                        <span className='overlay-banner'>{heading}</span>
                    </h1>
                    <p className='banner-subtitle'>
                        <span className='overlay-banner' style={{ color: heroBannerConfig.subtitleColor}}>
                            {subtitle}
                        </span>
                    </p>
                    {
                        showBannerButton ? <Button color='link' size='lg' outline className='banner-button'>
                            {buttonText}
                        </Button> : ''
                    }

                </div>
            </div>
        </div>
    );
}

export default HeroBanner;
