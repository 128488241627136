import React, { useState, useEffect } from 'react';

import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
  Polygon,
  DirectionsRenderer,
} from 'react-google-maps';
import { ModelPropertiesParser } from '../../commons/modelPropertiesParser';

const getMarkers = (workflowData, laneKey, cardConfig) => {
  const markerData = (workflowData || []).map((data, index) => {
    let pos = data[laneKey];
    if (pos) {
      pos = pos.split(',');
      return {
        center: { lat: parseFloat(pos[0]), lng: parseFloat(pos[1]) },
        id: data.id,
        title: getCardData(cardConfig.title, data),
        data,
      };
    }
    return {
      center: { lat: 0, lng: 0 },
      id: data.id,
      title: getCardData(cardConfig.title, data),
      data,
    };
  });
  return markerData;
};

const getCardData = (key, data) => {
  let _key = null;
  if (typeof key === 'string') {
    _key = key;
  }
  if (typeof key === 'object' && key.hasOwnProperty('path')) {
    _key = key.path;
  }
  if (_key !== null) {
    return ModelPropertiesParser(_key, data);
  }
  return 'Not Available';
};

const GMap = withScriptjs(
  withGoogleMap(props => {
    const {
      value,
      classProperty,
      workflowData,
      laneKey,
      locationConfig,
      config: { card },
    } = props;
    const markers = getMarkers(workflowData, laneKey, card);
    const c = markers.length
      ? markers[0].center
      : { lat: 19.076, lng: 72.8777 };
    const [center, setCenter] = useState(c);
    const [mapref, setMapRef] = useState(null);
    const [zoom, setZoom] = useState(15);
    const [directions, setDirections] = useState([]);

    useEffect(() => {
      const length = markers.length;
      if (length) {
        const DirectionsService = new window.google.maps.DirectionsService();
        const waypoints = markers.map(m => {
          return { location: m.center };
        });

        const updatedWaypoints = waypoints.filter(w => {
          return w.location.lat !== 0;
        });
        const orig = {
          lat: updatedWaypoints[updatedWaypoints.length - 1].location.lat,
          lng: updatedWaypoints[updatedWaypoints.length - 1].location.lng,
        };
        const dest = {
          lat: updatedWaypoints[0].location.lat,
          lng: updatedWaypoints[0].location.lng,
        };
        DirectionsService.route(
          {
            origin: new window.google.maps.LatLng(orig),
            destination: new window.google.maps.LatLng(dest),
            waypoints: updatedWaypoints,
            travelMode: window.google.maps.TravelMode.DRIVING,
          },
          (result, status) => {
            if (status === window.google.maps.DirectionsStatus.OK) {
              setDirections(result);
            } else {
              console.error(`error fetching directions ${result}`);
            }
          }
        );
        setCenter(markers[0].center);
      }
    }, [workflowData]);
    let iconMarker = new window.google.maps.MarkerImage(
      locationConfig.markerIcon,
      null /* size is determined at runtime */,
      new window.google.maps.Point(0, 0),
      null /* anchor is bottom center of the scaled image */,
      new window.google.maps.Size(zoom * 2, zoom * 2)
    );
    return (
      <div>
        <GoogleMap
          options={{}}
          ref={ref => setMapRef(ref)}
          defaultZoom={zoom}
          center={center}
          mapTypeId='terrain'
          onZoomChanged={() => {
            setZoom(mapref.getZoom());
          }}
        >
          {directions && (
            <DirectionsRenderer
              options={{
                markerOptions: { icon: iconMarker },
                polylineOptions: { strokeColor: '#0e5a9a' },
              }}
              directions={directions}
            />
          )}
        </GoogleMap>
      </div>
    );
  })
);

const MapViewWithPath = props => {
  return (
    <GMap
      googleMapURL='https://maps.googleapis.com/maps/api/js?key=AIzaSyBfhuMCKv2c1jH_lJcxJ1iNjg0mmHXY_zU&v=3.exp&libraries=geometry,drawing,places'
      loadingElement={<div style={{ height: `100%` }} />}
      containerElement={<div style={{ height: `600px` }} />}
      mapElement={<div style={{ height: `100%` }} />}
      {...props}
    />
  );
};

export default MapViewWithPath;
