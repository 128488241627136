import React from 'react';
import { Card, CardBody, CardTitle, CardSubtitle, CardText, CardImg } from 'reactstrap';
import TextTruncate from 'react-text-truncate';
import CommonFooterActions from './CommonFooterActions';
import config from '../../../config';
import TextStyleResolver from '../../elements/TextStyleResolver/index';

const EnchancedImageCard = (props) => {

    const { title, subtitle, description, cardConfig, image, imageCardStyle } = props;
    const cardImage = image ? image.startsWith('http') ? image : config.assetsBasePath + image:'https://assets.business.codeninja.ai/instagram/undraw_No_data_re_kwbl-202307311036.png';
     const backgroundImage_Color = cardConfig.config && cardConfig.config.backgroundImage_Color ;
    const backgroundImage = cardConfig.config && cardConfig.config.backgroundImage && cardConfig.config.backgroundImage.path
    const backgroundImagUrlComplete = `url(${config.assetsBasePath}${backgroundImage})`
    const imageCardLayout= cardConfig.config && cardConfig.config.imageCardLayout ? cardConfig.config.imageCardLayout.value :null;
    const {authTemplateBackgroundColor,cardDescription,cardImagePadding,cardImageHeight,cardImageWidth,cardSubtitle,cardTitle,imageRadius,cardSize,borderRadius,cardTextColor,disableHoverEffect} = cardConfig && cardConfig.config;    
    const borderRadiusCard = borderRadius ? parseInt(borderRadius): 10 

    const cardImageRadius = imageRadius ? parseInt(imageRadius): 10 

    const imagPadding = cardImagePadding ? parseInt(cardImagePadding): 10 ;
    const imageHeight = cardImageHeight ? `${cardImageHeight}%`: '100%' ;
    const imageWidth = cardImageWidth ? `${cardImageWidth}%`: '100%' ;
    let width = 250;
        if( cardSize >= '6'){
            width= 400
        }
    if(imageCardLayout === 'horizontal'){
        return <Card style={{borderRadius:borderRadiusCard,color:cardTextColor?cardTextColor:"#000", backgroundColor:backgroundImage_Color? backgroundImage_Color:'#fff',backgroundImage:backgroundImage? backgroundImagUrlComplete:'#fff',marginLeft:10,marginRight:10,flexDirection:'row'}}>
     <div  style={{flexDirection:'column'}}>
       <div>
        <CardImg  style={{borderRadius: cardImageRadius,padding:imagPadding,width:width}}   src={cardImage} alt="Card image cap" />
        </div>
        <CommonFooterActions isImageCard = {true} {...props} />
        </div>
        <div>
        <CardBody>
            <CardTitle tag='h5'>
                <TextStyleResolver config={cardConfig.config.cardTitle} text={title} {...props} />
            </CardTitle>
            <CardSubtitle>
                <TextStyleResolver config={cardConfig.config.cardSubtitle} text={subtitle} {...props} />
            </CardSubtitle>
            <CardText tag='div' className='mt-2' title={description}>
                {
                    typeof description === 'string' ? <TextTruncate
                        line={3}
                        truncateText="…"
                        text={typeof description === 'string' ? description : ''}
                    /> : <div>{description}</div>
                }
            </CardText>
        </CardBody>
  
        </div>
    </Card>
    }
    return <Card style={{borderRadius:borderRadiusCard,color:cardTextColor?cardTextColor:"#000", backgroundColor:backgroundImage_Color? backgroundImage_Color:'#fff',backgroundImage:backgroundImage_Color? backgroundImage_Color:'#fff',marginLeft:10,marginRight:10}}>
        <CardImg  style={{borderRadius: cardImageRadius,padding:imagPadding}}  width="100%" src={cardImage} alt="Card image cap" />
        <CardBody>
            <CardTitle tag='h5'>
                <TextStyleResolver config={cardConfig.config.cardTitle} text={title} {...props} />
            </CardTitle>
            <CardSubtitle>
                <TextStyleResolver config={cardConfig.config.cardSubtitle} text={subtitle} {...props} />
            </CardSubtitle>
            <CardText tag='div' className='mt-2' title={description}>
                {
                    typeof description === 'string' ? <TextTruncate
                        line={3}
                        truncateText="…"
                        text={typeof description === 'string' ? description : ''}
                    /> : <div>{description}</div>
                }
            </CardText>
        </CardBody>
        <CommonFooterActions isImageCard = {true} {...props} />
    </Card>
}

export default EnchancedImageCard;

