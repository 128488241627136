import React, { Component } from 'react';
import { Cell } from 'fixed-data-table-2';
import NoDataCell from '../NoDataCell';
import TextStyleResolver from '../../../TextStyleResolver/index';
import conf from '../../../../../config';
import { Popover, PopoverBody } from "reactstrap";
import { ModelPropertiesParser } from "../../../../commons/modelPropertiesParser";

class SimpleModelCell extends Component {
	constructor(props) {
		super(props);
		this.state = {
		  popoverOpen: false,
		};
	}

	toggle = () => {
		this.setState({ popoverOpen: !this.state.popoverOpen });
	};
    render() {
        const { rows, column, columnKey, rowIndex, config, width,maskValue,showLastdigits } = this.props;
        const { field: { type }, config: {prependAssetsUrl}} = column;
		let valueToRender = rows.getObjectAt(rowIndex)[columnKey];
		if (maskValue && valueToRender){
		
			valueToRender = valueToRender.toString().substring(0, 3) + '*****' + valueToRender.toString().substring(valueToRender.toString().length-4, valueToRender.toString().length)
		  }
		  if (showLastdigits && valueToRender){
			let num = valueToRender.toString().substring(0, valueToRender.toString().length-4);
			let cross = '*'
			for (let index = 0; index < num.length-1; index++) {
			   cross = cross + '*'
			}
			valueToRender =  cross + valueToRender.toString().substring(valueToRender.toString().length-4, valueToRender.toString().length)
		  }
		const wfData = rows.getObjectAt(rowIndex);
		if(!valueToRender){
            return <NoDataCell {...this.props} /> 
        }
		let flyoutextText = config.flyout && ModelPropertiesParser(
			column.config.flyoutField,
			rows.getObjectAt(rowIndex)
		);
		flyoutextText = flyoutextText && flyoutextText.split(',');
		flyoutextText = flyoutextText && flyoutextText.length && flyoutextText.join("\n");
		let cellAlignment;
        let cellStyle;
        if(config && config.styles && config.styles) {
            const styles = config.styles
            cellAlignment = styles.alignment && styles.alignment.value;
            cellStyle = {
                color: styles.color,
                textDecoration: styles.lineThrough ? 'line-through' : 'inherit'
            }
        }
		if(type === 'IMAGE' || prependAssetsUrl) {
			
			if(Array.isArray(valueToRender) ) {
				if(valueToRender.length > 0)
				{
					let newVal = [];
					valueToRender.forEach((item) => {
						item = item.indexOf(`${conf.assetsBasePath}` == -1 ) ? encodeURI(`${conf.assetsBasePath}${item}`) : item;
						newVal.push(item)
					});
					valueToRender = newVal.join(', ');
				}
			} else {
				valueToRender = !valueToRender ? '' : valueToRender.indexOf(`${conf.assetsBasePath}`) == -1 ? encodeURI(`${conf.assetsBasePath}${valueToRender}`) : valueToRender;
			}
			
		}
        return (
            <Cell width={width} style={cellStyle} align={cellAlignment ? cellAlignment : 'left'}>
				{ config.flyout ? 
                    <div ><a
						className="fa fa-info-circle mr-1"
						id={"Popover-" + rowIndex}
						onClick={this.toggle}
						href="#"
					></a>
				    <Popover
						placement="right"
						isOpen={this.state.popoverOpen}
						target={"Popover-" + rowIndex}
						toggle={this.toggle}
				    >
						<PopoverBody><div style={{whiteSpace: 'break-spaces'}}>{flyoutextText}</div ></PopoverBody>
					</Popover></div> : ''
                } 
                { valueToRender !== 'undefined' && valueToRender !== undefined && valueToRender !== null && valueToRender !== '' ? 
                    <TextStyleResolver wfData={wfData} cellStyle={cellStyle} text={`${valueToRender}`} {...this.props} /> : 
                    <NoDataCell {...this.props} /> 
                }        
            </Cell>
        );
    }null
}

export default SimpleModelCell;