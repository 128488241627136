import React, { Component } from 'react';
import { build } from './helpers/form-layout/common-routines';
import Wrapper from './helpers/form-layout/Wrapper';
import LayoutItemContainer from '../../../containers/workflow/LayoutItemContainer';

class CreateUpdate extends Component {
    render() {
        const { container, workflow, ...rest } = this.props;
        return (
            <div>
                <Wrapper>
                    {
                        // (container && container.containerType) === 'TABBED' ? 
                        // container.items && container.items.map((item, itemIndex) => {
                        //     return <LayoutItemContainer
                        //         layoutItem={item}
                        //         itemIndex={itemIndex}
                        //         workflow={workflow}
                        //         { ...rest }
                        //     />
                        // }) : 
                        build(this.props)
                    }
                </Wrapper>
            </div>
        );
    }
}

export default CreateUpdate;