import React from 'react';
import NavWorkflowsContainer from '../../containers/NavWorkflowsContainer';

class DesktopMenuItems extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: 0
    }
  }
  resolveIsActive = (match, location) => {
    if (!match || !location) {
        return false
    }
    const { path } = match;
    const { pathname } = location;
    if (pathname === path) {
        return true;
    }
    return false;
  }

  setActiveTab = (tabIndex) => {
    this.setState({ activeTab: tabIndex });
  }
  render() {
    const { isActive, isPublic, baseWorkflowRoute, isScreenerMonkeyApp } = this.props;
    return (
      <div className="desktop-menu-items">
        <ul className="desktop-level-one-menu">
          <li className={`desktop-level-one-menu-item ${this.state.activeTab === 1 ? 'active' : ''}`} onMouseOver={() =>this.setActiveTab(1)}>
            <a className={`desktop-level-one-menu-item-link ${isScreenerMonkeyApp ? 'screener-monkey' : ''}`}>
              <span className="left-icon-container">
                <i className='fa fa-university'></i>
              </span>
              Start Here
              <span className="right-icon-container">
                <i className='fa fa-chevron-down'></i>
              </span>
            </a>
            <DesktopLevelTwoMenu>
              <NavWorkflowsContainer baseWorkflowRoute={baseWorkflowRoute} isDesktop={true} isPublic={isPublic} resolveIsActive={this.resolveIsActive}/> 
            </DesktopLevelTwoMenu>
          </li>
          {
            isPublic ? <li className="desktop-level-one-menu-item signin">
              <a className={`desktop-level-one-menu-item-link ${isScreenerMonkeyApp ? 'screener-monkey' : ''}`} href="/app/login">
                <span className="left-icon-container">
                  <i className='fa fa-sign-out'></i>
                </span>
                Sign-In
              </a>
            </li> : <div style={{display: 'inline-block'}}>
              <li className={`desktop-level-one-menu-item ${this.state.activeTab === 2 ? 'active' : ''}`} onMouseOver={() =>this.setActiveTab(2)}>
                <a className={`desktop-level-one-menu-item-link ${isScreenerMonkeyApp ? 'screener-monkey' : ''}`}>
                  <span className="left-icon-container">
                    <i className='fa fa-user-md'></i>
                  </span>
                  Account
                  <span className="right-icon-container">
                    <i className='fa fa-chevron-down'></i>
                  </span>
                </a>
                <DesktopLevelTwoMenu>
                  <ul className="desktop-level-two-menu-items">
                    <li className="desktop-level-two-menu-item">
                      <a className="desktop-level-two-menu-item-link" href="/profile" >
                        Profile
                        <span className="right-icon-container">
                          <i className='fa fa-user'></i>
                        </span>
                      </a>
                    </li>
                    <li className="desktop-level-two-menu-item">
                      <a className="desktop-level-two-menu-item-link" href="#" onClick={() => this.props.logout()} >
                        SignOut
                        <span className="left-icon-container">
                          <i className='fa fa-sign-out'></i>
                        </span>
                      </a>
                    </li>
                  </ul>
                </DesktopLevelTwoMenu>
              </li>
            </div>
          }
        </ul>
      </div>
    )
  }
}

const DesktopLevelTwoMenu = (props) => {
  return <div className="desktop-level-two-menu">
    {props.children}
  </div>
}



export default DesktopMenuItems;