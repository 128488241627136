import React, { Component } from 'react';
import { reduxForm } from 'redux-form/immutable';
import { Form } from 'reactstrap';
import PropTypes from 'prop-types';
import WorkflowContainer from '../../containers/workflow/WorkflowContainer';
import { ModelPropertiesParser } from '../commons/modelPropertiesParser';

class ReduxWrapperHoc extends Component {
    constructor(props) {
        super(props);
        this.onSubmit = this.onSubmit.bind(this);
    }
    componentDidMount() {
        this.updateVersionToLatest(this.props);
    }
    componentWillReceiveProps(nextProps) {
        const { updatedVersion, workflowForContainer: { currentItem: { id } }, workflowData } = nextProps;
        if (this.props.updatedVersion && updatedVersion && this.props.updatedVersion !== updatedVersion) {
            this.updateVersionToLatest(nextProps);
        }
        if (!nextProps.workflowForContainer.workflowCardData && this.props.workflowForContainer.workflowCardData) {
            this.props.initialize(this.props.workflowForContainer.workflowCardData);
        } else if (nextProps.workflowForContainer.workflowCardData && (!this.props.workflowForContainer.workflowCardData ||
            nextProps.workflowForContainer.workflowCardData.id !== this.props.workflowForContainer.workflowCardData.id)) {
            this.props.initialize(nextProps.workflowForContainer.workflowCardData);
        } else if (this.props.workflowForContainer.currentItem.id !== id) {
            //Reinit Redux Form
            this.props.initialize((workflowData || {}));
        }
    }
    updateVersionToLatest(props) {
        const { updatedVersion, change } = props;
        if (updatedVersion) {
            change('version', updatedVersion);
        }
    }
    onSubmit(values) {
        const { onSubmit, reset, pristine, onEndWorkflow, workflowForContainer, modalClose } = this.props;
        const endWorkflow = values && values.get('endWorkflow');
        const { workflowModel, sessionId } = workflowForContainer;
        if (endWorkflow && typeof onEndWorkflow != 'undefind') {
            onEndWorkflow({ workflowId: workflowModel, sessionId });
        } else if (endWorkflow && typeof modalClose != 'undefined') {
            modalClose();
        }
        else {
            onSubmit(values, pristine);
        }
        reset();
    }
    render() {
        const { children, onSubmit, handleSubmit, className, updatedVersion, workflowForContainer, isBase, ...rest } = this.props;
        return (
            <Form className={className}>
                <WorkflowContainer onHandleSubmit={handleSubmit} onSubmit={this.onSubmit} workflow={workflowForContainer} isBase={isBase} {...rest} />
            </Form>
        );
    }
}

ReduxWrapperHoc.propTypes = {
    updatedVersion: PropTypes.number
}

ReduxWrapperHoc.defaultProps = {
    updatedVersion: null
}



ReduxWrapperHoc = reduxForm({
    destroyOnUnmount: true
})(ReduxWrapperHoc);

export default ReduxWrapperHoc;