import React from 'react';
import PrintText from './PrintText';
import { Field } from 'redux-form/immutable';
import DateTimeClass from './FieldClasses/_DATE_TIME_CLASS';
import moment from 'moment';

const DateRange = (props) => {
    const { layoutItem, classProperty, type, validations, value, isReadOne, ...rest } = props;
    const _ranges = Array.isArray(value) && value.length ? value : ['', ''];
    const { itemProperties: { dateTimeConfig } } = layoutItem;
    let dateTimeDefaultValue = null;
    if (dateTimeConfig) {
        const { workflow } = props;
        if(workflow && workflow.selectedStartDateTime && workflow.selectedEndDateTime) {
            if (dateTimeConfig.calendarLoadType && dateTimeConfig.calendarLoadType.name === 'START') {
                const newDateTime = moment(workflow.selectedStartDateTime, "x");
                dateTimeDefaultValue = newDateTime;
            } else if(dateTimeConfig.calendarLoadType && dateTimeConfig.calendarLoadType.name === 'END') {
                const newDateTime = moment(workflow.selectedEndDateTime, "x");
                dateTimeDefaultValue = newDateTime;
            }
        }
    }
    return (
        <div>
            {
                isReadOne ?
                    <PrintText {...props} /> :
                    <Field
                        name={classProperty}
                        component={DateTimeClass}
                        validate={validations}
                        fieldType={type}
                        layoutItem={layoutItem}
                        {...rest}
                        onBlur={() => {
                            const cEventKeys = window.cEvents && Object.keys(window.cEvents);
                            Array.isArray(cEventKeys) &&   cEventKeys.map(k => {
                                k.startsWith(classProperty) &&
                                window.cEvents[k](props.formValues);
                            });
                        }}
                        ranges={_ranges}
                        defaultValue={dateTimeDefaultValue}
                    />
            }
        </div>
    );
}

export default DateRange;
