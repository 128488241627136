import React, { Component } from 'react';
import { Card, CardBody, CardTitle, CardSubtitle, CardText } from 'reactstrap';
import TextTruncate from 'react-text-truncate';
import CommonFooterActions from './CommonFooterActions';
import TextStyleResolver from '../../elements/TextStyleResolver/index';
import RatingComp from 'react-rating';

const SimpleCard = (props) => {
    const { title, subtitle, description, cardConfig, isFullWidth } = props;
    const { hasRating, ratingConfig, showInline, showActionInline } = cardConfig.config || [];
    
    return <Card className="simple-card">
        <CardBody>
            {
                showInline ? isFullWidth ? <CardText tag='div' className='mt-2 card-inline' title={description} >
                    <span className="card-inline-text title">
                        <TextStyleResolver config={cardConfig.config.cardTitle} text={title} {...props} />
                    </span>
                    <span className="card-inline-text subtitle">
                        {
                            hasRating && ratingConfig ? <RatingComp
                                start={0}
                                stop={parseInt(ratingConfig.ratingLength) || 5}
                                initialRating={parseInt(subtitle)}
                                fractions={ratingConfig.ratingType.value === 'star-half' ? 2 : 1}
                                step={parseInt(ratingConfig.ratingStep) || 1}
                                emptySymbol={`rating-symbol fa fa-${ratingConfig.ratingType.value === 'star-half' ? 'star-o' : ratingConfig.ratingType.value === 'thumbs-up' ? 'thumbs-o-up' : ratingConfig.ratingType.value+'-o'} ${ratingConfig.ratingType.value}`}
                                fullSymbol={`rating-symbol fa fa-${ratingConfig.ratingType.value === 'star-half' ? 'star' : ratingConfig.ratingType.value} ${ratingConfig.ratingType.value}`}
                                readonly
                            /> : <TextStyleResolver config={cardConfig.config.cardSubtitle} text={subtitle} {...props} />
                        }
                    </span>
                    <span className="card-inline-text description">
                        {description}
                    </span>
                </CardText> : <div>
                    <CardText tag='div' className="card-inline-text">
                        <span className="card-inline-text title">
                            <TextStyleResolver config={cardConfig.config.cardTitle} text={title} {...props} />
                        </span>
                        <span className="card-inline-text subtitle">
                            {
                                hasRating && ratingConfig ? <RatingComp
                                    start={0}
                                    stop={parseInt(ratingConfig.ratingLength) || 5}
                                    initialRating={parseInt(subtitle)}
                                    fractions={ratingConfig.ratingType.value === 'star-half' ? 2 : 1}
                                    step={parseInt(ratingConfig.ratingStep) || 1}
                                    emptySymbol={`rating-symbol fa fa-${ratingConfig.ratingType.value === 'star-half' ? 'star-o' : ratingConfig.ratingType.value === 'thumbs-up' ? 'thumbs-o-up' : ratingConfig.ratingType.value+'-o'} ${ratingConfig.ratingType.value}`}
                                    fullSymbol={`rating-symbol fa fa-${ratingConfig.ratingType.value === 'star-half' ? 'star' : ratingConfig.ratingType.value} ${ratingConfig.ratingType.value}`}
                                    readonly
                                /> : <TextStyleResolver config={cardConfig.config.cardSubtitle} text={subtitle} {...props} />
                            }
                        </span>
                    </CardText>
                    <CardText tag='div' className='mt-2' title={description}>
                        {
                            typeof description === 'string' ? <TextTruncate
                                line={3}
                                truncateText="…"
                                text={typeof description === 'string' ? description : ''}
                            /> : <div>{ description }</div>
                        }
                    </CardText>
                </div> : <div>
                    <div className='d-flex justify-content-between'>
                        <CardTitle tag='h5'>
                            <TextStyleResolver config={cardConfig.config.cardTitle} text={title} {...props} />
                        </CardTitle>
                        {showActionInline && <CommonFooterActions {...props} />}
                    </div>
                    <CardSubtitle>
                    {
                        hasRating && ratingConfig ? <RatingComp
                            start={0}
                            stop={parseInt(ratingConfig.ratingLength) || 5}
                            initialRating={parseInt(subtitle)}
                            fractions={ratingConfig.ratingType.value === 'star-half' ? 2 : 1}
                            step={parseInt(ratingConfig.ratingStep) || 1}
                            emptySymbol={`rating-symbol fa fa-${ratingConfig.ratingType.value === 'star-half' ? 'star-o' : ratingConfig.ratingType.value === 'thumbs-up' ? 'thumbs-o-up' : ratingConfig.ratingType.value+'-o'} fa-2x ${ratingConfig.ratingType.value}`}
                            fullSymbol={`rating-symbol fa fa-${ratingConfig.ratingType.value === 'star-half' ? 'star' : ratingConfig.ratingType.value} fa-2x ${ratingConfig.ratingType.value}`}
                            readonly
                        /> : <TextStyleResolver config={cardConfig.config.cardSubtitle} text={subtitle} {...props} />
                    }
                    </CardSubtitle>
                    <CardText tag='div' className='mt-2' title={description}>
                        {
                            typeof description === 'string' ? <TextTruncate
                                line={3}
                                truncateText="…"
                                text={typeof description === 'string' ? description : ''}
                            /> : <div>{ description }</div>
                        }
                    </CardText>
                </div>
            }
            
        </CardBody>
        {!showActionInline && <CommonFooterActions {...props} />}
    </Card>
}

export default SimpleCard;
