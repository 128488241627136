import React from 'react';
import PrintText from './PrintText';
import { Field } from 'redux-form/immutable';
import { renderField, convertToFloat } from './_FORM_FIELDS';
import { Button, Row, Input, InputGroup, InputGroupAddon } from 'reactstrap';
import DataStore from '../../utils/DataStore'

const GlobalSerach = (props) => {
    const { classProperty, type, validations, value, isReadOne, ...rest } = props;
    const searchData = DataStore.get("globalSearch");
    return (
        <div>
      <div className='mb-3 mt-3'>
                    <InputGroup>
                        <Input
                            type="text"
                            name="search"
                            //onChange={(event)=>DataStore.add( "globalSearch", event.target.value)}
                            onBlur={(event)=>DataStore.add( "globalSearch", event.target.value)}
                            placeholder="Search"
                            defaultValue={searchData ? searchData : ""}
                        ></Input>
                        <InputGroupAddon addonType='append'><Button  size="sm" onClick={() => {}}>SEARCH</Button></InputGroupAddon>
                    </InputGroup>
                    
                </div>
        </div>
    );
}

export default GlobalSerach;