import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import ReportSelector from './ReportSelector';

class DashboardHeader extends Component {
    render() {
        return (
            <div className='dashboard-header-main'>
                <div className='dashboard-header'>
                    <div className='heading'>
                        <h4 className='text'>Dashboard</h4>
                    </div>
                    <div className='d-flex h-100' style={{ flexGrow: 1, justifyContent: 'flex-end', alignItems: 'center' }}>
                        <div className='h-100'>
                            <ReportSelector />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

DashboardHeader.propTypes = {

};

export default DashboardHeader;