import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Button, Form, FormGroup, Label, Input, InputGroup, InputGroupAddon, Alert } from 'reactstrap';
import { Field, reduxForm, formValueSelector } from 'redux-form/immutable';
import { Redirect, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { getTranslate } from 'react-localize-redux';
import { toJS } from '../../../containers/to-js';
import { resetPassword, resetForgotPasswordState } from '../../../actions/user';
import { getCompanyDetails } from '../../../actions/company';
import isEmail from 'validator/lib/isEmail';

class ForgotPassword extends Component {
    componentDidMount() {
		localStorage.removeItem('user');
		const { companyDetails } = this.props;
		if(companyDetails.data == null) {
			this.props.getCompanyDetails();
		}
    }
    renderField({ input, label, type, placeholder, meta: { touched, error } }) {
        return <FormGroup>
            <Label for={input.name}>{label}</Label>
            <Input placeholder={placeholder} type={type} { ...input } />
            {touched && error && <small className='text-danger form-text mt-2 animated fadeIn'><i className="fa fa-exclamation-triangle" aria-hidden="true"></i> {error}</small>}
        </FormGroup>
    }
    componentWillUnmount() {
        this.props.resetForgotPasswordState();
    }
    render() {
		const { handleSubmit, forgotPasswordState, companyDetails } = this.props;
        const logoutUrl = companyDetails && companyDetails.data && companyDetails.data.logoutURL ? companyDetails.data.logoutURL : '/app/login'
		const companyName = companyDetails && companyDetails.data != null ? companyDetails.data.description : '';
        if (!forgotPasswordState.isLoading && forgotPasswordState.data !== null) {
            return <ShowResponseToUser {...this.props}/>
        }
		return (
            <Row className='h-100 d-flex align-items-center justify-content-center public-user-page'>
                <Col xs="12" sm="10" md="4">
					{
						companyName ? <h4 className='brand-name player'>{companyName}</h4> : <h4 className='brand-name player'>APTO<span className='ats'>.AI</span></h4>
					}
					
                    <Form onSubmit={handleSubmit} className='player-public-user-form p-4'>
                        <h5 className='text-center mb-3'>Reset your password</h5>
                        <Field
                            name="email"
                            component={this.renderField}
                            type="text"
                            label='Enter your email'
                        />
                        <Button disabled={forgotPasswordState.isLoading} className='mt-4' color='primary' block>
                            {forgotPasswordState.isLoading ? 'Please wait...' : 'Reset Password'}
                        </Button>
                        <Link className='mt-4 d-block text-center' to={logoutUrl}>
                            <i className="fa fa-arrow-left mr-1" aria-hidden="true"></i>
                            Back to login
                        </Link>
                    </Form>
                </Col>
            </Row>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        forgotPasswordState: state.getIn(['user', 'resetPassword']),
        translate: getTranslate(state.get('locale')),
		companyDetails: state.getIn(['company', 'details'])
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onSubmit: (values) => {
            dispatch(resetPassword(values));
        },
        resetForgotPasswordState: () => {
            dispatch(resetForgotPasswordState());
        },
		getCompanyDetails: () => {
            dispatch(getCompanyDetails());
        }
    }
};

const ShowResponseToUser = (props) => {
	const { companyDetails } = props;
	const companyName = companyDetails && companyDetails.data != null ? companyDetails.data.description : '';
    const logoutUrl = companyDetails && companyDetails.data && companyDetails.data.logoutURL ? companyDetails.data.logoutURL : '/app/login'
    return (
        <Row className='h-100 d-flex align-items-center justify-content-center public-user-page'>
            <Col xs="12" sm="10" md="4">
                {
					companyName ? <h4 className='brand-name player'>{companyName}</h4> : <h4 className='brand-name player'>HUW<span className='ats'>ATS</span></h4>
				}
                <Alert color='info'>
                <i className='fa fa-check'></i> Thankyou for submitting the request. If the email address is registered with us, you will recieve an email with link to reset the password.
                </Alert>
                <Link className='mt-4 d-block text-center bg-white p-2 rounded' to={logoutUrl}>
                    Continue to login
                </Link>
            </Col>
        </Row>
    )
}

const validate = (values) => {
    const errors = {};
    const email = values.get('email');
    if (!email || !isEmail(email)) {
        errors.email = 'Please provide a valid email address';
    }
    return errors;
}

ForgotPassword = reduxForm({
    form: 'player-resetpassword-form',
    validate
})(ForgotPassword)

export default connect(mapStateToProps, mapDispatchToProps)(toJS(ForgotPassword));