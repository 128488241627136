import React, { Component } from "react";
import {
  TABLE,
  KEYEDLAYOUT,
  CARDLAYOUT,
  ACCORDIANLAYOUT,
  WORKFLOWCARDLAYOUT,
  IMAGECARDLAYOUT
} from "../../elements/index";
import ReadAllHeader from "./headers/ReadAllHeader";
import Filters from "./filters/index";
import { Alert } from "reactstrap";
import sizeMe from "react-sizeme";
import PropTypes from "prop-types";
import DataListStore from "./filters/DataListStore";
import DataListWrapper from "./filters/DataListWrapper";
import queryString from 'query-string';

class ReadAll extends Component {
  constructor(props) {
    super(props);
    this._dataList = new DataListStore(0);
    const params = queryString.parse(window.location.search);
    this.state = {
      filteredDataList: this._dataList,
      sorting: {
        classProperty: null,
        order: ""
      },
      querySearch: params['qs'],
      allData: []
    };
  }
  componentDidMount() {
    this.setState({
      sorting: {
        classProperty: this.props.sortPagination.sortField,
        order: this.props.sortPagination.order
      }
    });
    this.triggerReadAll();
  }
  componentWillReceiveProps(nextProps) {
    const { collection, sortPagination } = nextProps;
    const { sorting } = this.state;
    if (!collection) {
      this.triggerReadAll();
    }
    if (sorting.classProperty != null) {
      this.triggerReadAll();
    }
    if (
      collection &&
      Array.isArray(collection.data) &&
      this.state.filteredDataList.length !== collection.data.length
    ) {
      this._dataList = new DataListStore(collection.data);
      this.setState({ filteredDataList: this._dataList });
    }
  }
  triggerReadAll() {
    const {
      workflowItem,
      workflow,
      isBase,
      pagination,
      paginationStart,
      pageSize,
      filterMap,
      sortPagination,
      containerIndex,
	    container,
      columnIndex,
      targetContainerIndex
    } = this.props;
    const { sorting } = this.state;
    const {
      layoutProperties: { readAllFilterConfig }
    } = workflowItem;
    const fetchAfterFilter =
      readAllFilterConfig &&
      readAllFilterConfig[containerIndex] &&
      readAllFilterConfig[containerIndex].filterConfig &&
      readAllFilterConfig[containerIndex].filterConfig.fetchAfterFilter;
    const allDataDownload = container.properties.allDataDownload;
	  const alternateTypeModel = container.properties.alternateTypeModel;
    const columnLayoutModel = container.properties.colLayoutConfig && container.properties.colLayoutConfig[`columnLayoutModel-${columnIndex}`];
    const wf = Object.assign({}, workflow);
    wf['collections'] = null;
    let payload = {
      typeId: columnLayoutModel ? columnLayoutModel.id : (alternateTypeModel ? alternateTypeModel.id : workflowItem.typeModel.id),
      customerName: workflowItem.typeModel.customerName,
      workflow: {
        id: wf.id,
        displayMode: wf.displayMode
      },
      isBase
    };
    if (pagination) {
      payload.count = pageSize;
      payload.start = paginationStart;
      payload.filters = [];
    }
    if (sorting.classProperty != null) {
      payload.sort = [];
      payload.sort.push({
        sortField: sorting.classProperty,
        descending: sorting.order == "DESCENDING" ? true : false
      });
      if (Object.keys(filterMap).length) {
        for (let filterKey in filterMap) {
          const _f = filterMap[filterKey];
          const { collection, targetClassProperty, filterBy, leafType } = _f;
          if (collection === true) {
            let modelName = targetClassProperty;
            let modelProperty = targetClassProperty;
            if (targetClassProperty.indexOf(".") !== -1) {
              const splits = targetClassProperty.split(".");
              modelName = splits.shift();
              modelProperty = splits.join(".");
            }
            payload.filterInAnotherModel = {
              modelName,
              modelProperty
            };
          } else {
            payload.filters.push({
              filterField: targetClassProperty,
              filterValue:
                filterBy && filterBy.length && filterBy[0] == "NULL"
                  ? null
                  : filterBy,
              valueType: leafType
            });
          }
        }
      }
    }
    if(this.state.querySearch) {
      if(!payload.filters ) payload.filters= [];
      payload.filters.push({
        filterField: "*",
        filterValue: [this.state.querySearch],
        isSearch: true
      });
    }
    if (!fetchAfterFilter || this.state.querySearch) {
      this.props.readAll(payload);
      this.setState({
        sorting: {
          classProperty: null,
          order: ""
        }
      });
      if(allDataDownload) {
        payload.count = '';
        const self = this;
        this.props.readAllData(payload).then((res) => {
          self.setState({
            allData: res.data
          });
        });
      }
    }
  }

  sortByClassProperty = classProperty => {
    const { sortPagination } = this.props;
    let order = sortPagination.order;
    if (sortPagination.sortField === classProperty) {
      if (order === "ASCENDING") {
        order = "DESCENDING";
      } else {
        order = "ASCENDING";
      }
    } else {
      order = "DESCENDING";
    }
    this.setState({
      sorting: {
        classProperty,
        order
      }
    });
    sortPagination.order = order;
    sortPagination.sortField = classProperty;
    this.props.setSortAndPagination(sortPagination);
  };
  
  getMenuTypeInlineAction = actions =>{
	const menuTypeAction = [];
	actions.length && actions.map((action, index) => {
		if(action.actionProperties && action.actionProperties.elementType == 'MENULINK') {
			menuTypeAction.push(action);
		}
	})
	return menuTypeAction;
  }

  render() {
    const {
      getInlineActions,
      collection,
      workflowItem,
      workflowId,
      workflow,
      updatePayload,
      save,
      size,
      saveResponse,
      triggerAction,
      user,
      containerIndex,
      companyDetails,
      targetContainerIndex,
      container
    } = this.props;
    let { viewMode } = this.props;
    console.log(viewMode,"viewModeviewModeviewModeviewMode")
    const loaderUrl = this.props.companyDetails && this.props.companyDetails.data && this.props.companyDetails.data.loaderURL ? this.props.companyDetails.data.loaderURL : null ;
    if (!collection) return null;
    if (collection.isLoading && viewMode !== "KEYED")
    return (<div>
      { !loaderUrl ?<div className='preloader d-flex align-items-center justify-content-center flex-column'>
              <div className='la-square-jelly-box la-2x'>
                <div></div>
                <div></div>
              </div>
             </div>:
         <div className='preloader d-flex align-items-center justify-content-center flex-column'>
          <img width="400px" height="238px" src={loaderUrl}>
          </img>
          </div>}
      </div> 
      );
    let dateformat =
      companyDetails && companyDetails.data && companyDetails.data != null
        ? companyDetails.data.companySettings.dateformat
        : "";
    dateformat = dateformat ? dateformat.toUpperCase() : "";
    const { layoutProperties } = workflowItem;
    let noDataText =
      layoutProperties.readAllFilterConfig &&
      layoutProperties.readAllFilterConfig[containerIndex]
        ? layoutProperties.readAllFilterConfig[containerIndex] &&
          layoutProperties.readAllFilterConfig[containerIndex].filterConfig &&
          layoutProperties.readAllFilterConfig[containerIndex].filterConfig
            .noDataText
        : layoutProperties.readAllFilterConfig &&
          layoutProperties.readAllFilterConfig.filterConfig &&
          layoutProperties.readAllFilterConfig.filterConfig.noDataText;

     let noDataTextHeading =
          layoutProperties.readAllFilterConfig &&
          layoutProperties.readAllFilterConfig[containerIndex]
            ? layoutProperties.readAllFilterConfig[containerIndex] &&
              layoutProperties.readAllFilterConfig[containerIndex].filterConfig &&
              layoutProperties.readAllFilterConfig[containerIndex].filterConfig
                .noDataTextHeading
            : layoutProperties.readAllFilterConfig &&
              layoutProperties.readAllFilterConfig.filterConfig &&
              layoutProperties.readAllFilterConfig.filterConfig.noDataTextHeading;      
    const menuItemIconPosition = layoutProperties.menuItemIconPosition;
    const menuItemIcon = layoutProperties.menuItemIcon;
    const { filteredDataList, sorting, allData } = this.state;
    const { table, card, keyed, accordian, workflowCard ,imageCard} =
      workflowItem.layoutProperties.readAllViews[containerIndex | ""] ||
      workflowItem.layoutProperties.readAllViews;
    const columns = table && table.isConfigured && table.config.columns;
    const selectedTemplate = table && table.isConfigured && table.selectedTemplate;
    const tableStyleConfig = table && table.config && table.config.tableStyleConfig;
 
    const cardConfig = card && card.config;
    const keyedConfig = keyed && keyed.config;
    const accordianConfig = accordian && accordian.config;
    const workflowCardConfig = workflowCard && workflowCard.config;
    const imageCardConfig = imageCard && imageCard.config;
    const canActOnMultipleItems = workflowItem.hasOwnProperty(
      "canActOnMultipleItems"
    )
      ? workflowItem.canActOnMultipleItems
      : false;
    if (
      (!table || !table.isConfigured) &&
      (!card || !card.isConfigured) &&
      (!keyed || !keyed.isConfigured) &&
      (!accordian || !accordian.isConfigured) &&
      (!workflowCard || !workflowCard.isConfigured) &&
      (!imageCard || !imageCard.isConfigured) 
    )
      return (
        <Alert color="warning">
          Looks like no layout has not been configured. Please contact the admin
          and configure atleast one layout for this workflow.
        </Alert>
      );
    const actions = getInlineActions(workflowItem, targetContainerIndex);
	const menuTypeInlineAction = this.getMenuTypeInlineAction(actions.inlines);
  const allDataDownload = container.properties.allDataDownload;
    if (!viewMode) viewMode = "TABLE";
    return (
      <div>
        <div className="wf-item-body">
          {viewMode === "TABLE" ? (
            <TABLE
              size={size}
              workflow={workflow}
              workflowId={workflowId}
              rows={filteredDataList}
              columns={columns}
              inlineActions={actions.inlines}
              canActOnMultipleItems={canActOnMultipleItems}
              noDataText={noDataText}
              noDataTextHeading={noDataTextHeading}
              user={user}
              selectedTemplate={selectedTemplate}
              tableStyleConfig={tableStyleConfig}
              dateformat={dateformat}
              sortByClassProperty={this.sortByClassProperty}
              sorting={sorting}
			        menuTypeInlineAction={menuTypeInlineAction}
              allData= {allData}
              allDataDownload={allDataDownload}
            />
          ) : (
            ""
          )}
          {viewMode === "CARD" ? (
            <CARDLAYOUT
              size={size}
              workflow={workflow}
              workflowId={workflowId}
              cards={filteredDataList}
              inlineActions={actions.inlines}
              cardConfig={cardConfig}
              canActOnMultipleItems={canActOnMultipleItems}
              noDataText={noDataText}
              noDataTextHeading={noDataTextHeading}
              user={user}
              dateformat={dateformat}
			        menuTypeInlineAction={menuTypeInlineAction}
              menuItemIconPosition={menuItemIconPosition}
              menuItemIcon={menuItemIcon}
              allData={allData}
              allDataDownload={allDataDownload}
            />
          ) : (
            ""
          )}

{viewMode === "IMAGECARD" ? (
            <IMAGECARDLAYOUT
              size={size}
              workflow={workflow}
              workflowId={workflowId}
              cards={filteredDataList}
              inlineActions={actions.inlines}
              cardConfig={imageCardConfig}
              canActOnMultipleItems={canActOnMultipleItems}
              noDataText={noDataText}
              noDataTextHeading={noDataTextHeading}
              user={user}
              dateformat={dateformat}
			        menuTypeInlineAction={menuTypeInlineAction}
              menuItemIconPosition={menuItemIconPosition}
              menuItemIcon={menuItemIcon}
              allData={allData}
              allDataDownload={allDataDownload}
            />
          ) : (
            ""
          )}
          {viewMode === "KEYED" ? (
            <KEYEDLAYOUT
              size={size}
              workflow={workflow}
              workflowId={workflowId}
              data={collection.data}
              config={keyedConfig}
              updatePayload={updatePayload}
              save={save}
              saveResponse={saveResponse}
              canActOnMultipleItems={canActOnMultipleItems}
              triggerAction={triggerAction}
              user={user}
              noDataText={noDataText}
              noDataTextHeading={noDataTextHeading}
              dateformat={dateformat}
              {...this.props}
            />
          ) : (
            ""
          )}
          {viewMode === "ACCORDIAN" ? (
            <ACCORDIANLAYOUT
              size={size}
              workflow={workflow}
              workflowId={workflowId}
              accordians={collection.data}
              accordianConfig={accordianConfig}
              updatePayload={updatePayload}
              save={save}
              saveResponse={saveResponse}
              canActOnMultipleItems={canActOnMultipleItems}
              triggerAction={triggerAction}
              noDataText={noDataText}
              noDataTextHeading={noDataTextHeading}
              dateformat={dateformat}
              user={user}
            />
          ) : (
            ""
          )}
          {viewMode === "WORKFLOWCARD" ? (
            <WORKFLOWCARDLAYOUT
              size={size}
              workflow={workflow}
              workflowId={workflowId}
              workflowCards={collection.data}
              workflowCardConfig={workflowCardConfig}
              updatePayload={updatePayload}
              save={save}
              saveResponse={saveResponse}
              canActOnMultipleItems={canActOnMultipleItems}
              triggerAction={triggerAction}
              inlineActions={actions.inlines}
              noDataText={noDataText}
              noDataTextHeading={noDataTextHeading}
              dateformat={dateformat}
              user={user}
              containerIndex={containerIndex}
			        menuTypeInlineAction={menuTypeInlineAction}
              menuItemIconPosition={menuItemIconPosition}
              menuItemIcon={menuItemIcon}
              allData={allData}
              allDataDownload={allDataDownload}
              onHandleSubmit={this.props.onHandleSubmit}
            />
          ) : (
            ""
          )}
        </div>
      </div>
    );
  }
}

ReadAll.defaultProps = {
  viewMode: "TABLE"
};

ReadAll.propTypes = {
  viewMode: PropTypes.string
};

export default sizeMe()(ReadAll);
