import React from 'react';
import PrintText from './PrintText';
import { Button, Label, Modal, ModalHeader, ModalBody, ModalFooter, Row, Col } from 'reactstrap';
import { Field } from 'redux-form/immutable';
import { renderField } from './_FORM_FIELDS';
import { ModelPropertiesParser } from '../commons/modelPropertiesParser';
import QRCode from "react-qr-code";

const UniqueCode = (props) => {
    const { classProperty, label, workflowData, type, validations, value, isReadOne, layoutItem: { itemProperties }, ...rest } = props;
    const { uniqueCodeConfig } = itemProperties;
    const showAsQR = uniqueCodeConfig && uniqueCodeConfig.showAsQRCode ? true : false;
    return (
        <div>
            {showAsQR ? <div style={{ flexDirection: "column" }}>
                <div> <Label>{label && label.show ? label.text : null}</Label></div>
                <div><QRCode value={ModelPropertiesParser('{{' + classProperty + '}}', workflowData)} /></div>
            </div> :
                <PrintText {...props} />
            }
        </div>
    );
}

export default UniqueCode;