import React from 'react';
import PrintText from './PrintText';
import { Field } from 'redux-form/immutable';
import { renderField } from './_FORM_FIELDS';

const Fallback = (props) => {
    const { classProperty, type, validations, value, isReadOne, ...rest } = props;
    return (
        <div>
            {
                isReadOne ?
                    <PrintText {...props} /> :
                    <Field
                        name={classProperty?classProperty:"fallBackComponent"}
                        component={renderField}
                        type="text"
                        validate={validations}
                        value={value}
                        fieldType={type}
                        {...rest}
                    />
            }
        </div>
    );
}

export default Fallback;