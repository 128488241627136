import React from 'react';
import PrintText from './PrintText';
import { Field } from 'redux-form/immutable';
import { renderField, convertToNumber } from './_FORM_FIELDS';

const Percentage = (props) => {
    const { classProperty, type, validations, value, isReadOne, ...rest } = props;
    return (
        <div>
            {
                isReadOne ?
                    <PrintText {...props} /> :
                    <Field
                        name={classProperty}
                        component={renderField}
                        type="number"
                        validate={validations}
                        value={value}
                        fieldType={type}
                        {...rest}
                        min={0}
                        max={100}
                        normalize={convertToNumber}
                    />
            }
        </div>
    );
}

export default Percentage;