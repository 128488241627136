import React, { Component } from 'react';
import { FormGroup, Label, Alert } from 'reactstrap';
import { ModelPropertiesParser, getNewContext } from '../commons/modelPropertiesParser';
import JSONPath from 'jsonpath';
import Table from './Table/Tablev1';
import { TypeModelDisplays } from '../../dto/type-model-displays';
import TypeModelTable from './TypeModelHelpers/Table';
import TypeModelKeyedLayout from './TypeModelHelpers/TypeModelKeyedLayout';
import TypeModelCard from './TypeModelHelpers/TypeModelCard';
import TypeModelDeck from './TypeModelHelpers/TypeModelDeck';
import TypeModelAccordian from './TypeModelHelpers/TypeModelAccordian';
import CollectionIterator from './TypeModelHelpers/CollectionIterator';
import TypeModelBadges from './TypeModelHelpers/TypeModelBadges';
import TypeModelLink from './TypeModelHelpers/TypeModelLink';
import TypeModelCarousel from './TypeModelHelpers/TypeModelCarousel';
import TypeModeCountOnly from './TypeModelHelpers/TypeModeCountOnly';
import TypeModelChart from './TypeModelHelpers/TypeModelChart';
import TypeModelBulletedList from './TypeModelHelpers/TypeModelBulletedList';
import PrintText from './PrintText';
import FilterWrapperHOC from '../workflow/operations/filters/FilterWrapperHOC';
import TextStyleResolver from './TextStyleResolver';

const NotAvailable = (props) => {
return <span className='text-dark'>{props.defaultText ? props.defaultText : 'Not Available'}</span>
}

class TypeModelReadOne extends Component {
    constructor(props) {
        super(props);
        this.state = {
            filterStateMap: {},
            typeModelViewMode: null
        }
        this._onFilterChange = this._onFilterChange.bind(this);
        this.onFiltersApplied = this.onFiltersApplied.bind(this);
        this.onClearAppliedFilters = this.onClearAppliedFilters.bind(this);
    }
    onFiltersApplied() {
        const filterMap = Object.assign({}, this.state.filterStateMap);
        const { workflowItem, workflow, isBase } = this.props;
        const payload = {
            typeId: workflowItem.typeModel.id,
            customerName: workflowItem.typeModel.customerName,
            workflow,
            isBase,
            filters: [],
            filterInAnotherModel: {}
        };
        if (Object.keys(filterMap).length) {
            for (let filterKey in filterMap) {
                const _f = filterMap[filterKey];
                const { collection, targetClassProperty, filterBy, leafType } = _f;
                if (collection === true) {
                    let modelName = targetClassProperty;
                    let modelProperty = targetClassProperty;
                    if (targetClassProperty.indexOf('.') !== -1) {
                        const splits = targetClassProperty.split('.');
                        modelName = splits.shift();
                        modelProperty = splits.join('.');
                    }
                    payload.filterInAnotherModel = {
                        modelName,
                        modelProperty
                    }
                }
                else {
                    payload.filters.push({
                        filterField: targetClassProperty,
                        filterValue: filterBy,
                        valueType: leafType
                    });
                }
            }
        }
        this.props.triggerFilterReadAll(payload);
    }
    onClearAppliedFilters() {
        const { workflowItem, workflow, isBase } = this.props;
        const payload = {
            typeId: workflowItem.typeModel.id,
            customerName: workflowItem.typeModel.customerName,
            workflow,
            isBase,
            filters: []
        };
        this.setState({ filterStateMap: {} });
        this.props.triggerFilterReadAll(payload);
    }
    _onFilterChange(filterConfig, filterBy) {
        const { filter, filterIndex } = filterConfig;
        const { filterStateMap } = this.state;
        filter.filterBy = filterBy
        filterStateMap[`filter_${filterIndex}`] = filter;
        this.setState({ filterStateMap });
    }

    onChangeTypeModelViewMode = (typeModelViewMode) => {
        this.setState({ typeModelViewMode });
    }
    render() {
        const { workflow: { workflowData, currentItem: { inlineAction } }, layoutItem: { itemProperties: { field, iterationWorkflowId, generatedActionCodeForIterable, modelSelector, label, replaceClassProperty, typeModelViewMode, accordianConfig, carouselConfig, countConfig, chartConfig , tableConfig, keyedConfig, badgeType,  badgeCount, typeModelLink, isTextualActionTriggerEnabled, textualActionTriggerCode, textualActionTriggerWorkflowCode } }, classProperty, layoutItem, shouldRefresh, size,  } = this.props;
        const hasModifiedClassProperty = replaceClassProperty && typeof replaceClassProperty === 'string' && replaceClassProperty !== '' ? true : false;
		if (!workflowData) {
			return 	<Alert color="warning">
						No data available
					</Alert>
		}
        const data = ModelPropertiesParser(modelSelector, workflowData[classProperty], { hasModifiedClassProperty, replaceClassProperty, workflowData });
        let viewMode = typeModelViewMode ? typeModelViewMode.value : TypeModelDisplays.COMMASEPERATED.value;
        if(this.state.typeModelViewMode) {
            viewMode = this.state.typeModelViewMode;
        }
        const { filterStateMap } = this.state;
        if (viewMode === TypeModelDisplays.CARD.value) {
            const rows = hasModifiedClassProperty ? getNewContext(workflowData, replaceClassProperty) : workflowData[classProperty];
            if (typeModelViewMode.value === TypeModelDisplays.CARD.value) {
                return <FilterWrapperHOC
                    useLayoutItem={true}
                    layoutItem={this.props.layoutItem}
                    filterStateMap={filterStateMap}
                    onFiltersApplied={this.onFiltersApplied}
                    onFilterChange={this._onFilterChange}
                    onClearAppliedFilters={this.onClearAppliedFilters}
                    workflow={this.props.workflow}
                >
                    <TypeModelCard
                        label={label}
                        workflow={this.props.workflow}
                        cards={rows}
                        layoutItem={layoutItem}
                        inlineActions={inlineAction}
                        viewMode={viewMode}
                        typeModelViewMode={typeModelViewMode}
                        onChangeTypeModelViewMode={this.onChangeTypeModelViewMode}
                    />
                </FilterWrapperHOC>
            }
        }

        if (viewMode === TypeModelDisplays.TABLE.value) {
            const rows = hasModifiedClassProperty ? getNewContext(workflowData, replaceClassProperty) : workflowData[classProperty];
            if (typeModelViewMode.value === TypeModelDisplays.TABLE.value) {
                return <FilterWrapperHOC
                    useLayoutItem={true}
                    layoutItem={this.props.layoutItem}
                    filterStateMap={filterStateMap}
                    onFiltersApplied={this.onFiltersApplied}
                    onFilterChange={this._onFilterChange}
                    onClearAppliedFilters={this.onClearAppliedFilters}
                    workflow={this.props.workflow}
                >
                    <TypeModelTable
                        label={label}
                        workflow={this.props.workflow}
                        rows={rows}
                        columns={tableConfig.config.columns}
                        layoutItem={layoutItem}
                        viewMode={viewMode}
                        typeModelViewMode={typeModelViewMode}
                        onChangeTypeModelViewMode={this.onChangeTypeModelViewMode}
                    />
                </FilterWrapperHOC>
            }
        }
        if (viewMode === TypeModelDisplays.KEYED.value) {
            const laneData = hasModifiedClassProperty ? getNewContext(workflowData, replaceClassProperty) : workflowData[classProperty];
            return <TypeModelKeyedLayout
                label={label}
                workflow={this.props.workflow}
                keyedConfig={keyedConfig.config}
                layoutItem={layoutItem}
                laneData={laneData}
                size={size}
                field={field}
                viewMode={viewMode}
                typeModelViewMode={typeModelViewMode}
                onChangeTypeModelViewMode={this.onChangeTypeModelViewMode}
            />
        }

        if (viewMode === TypeModelDisplays.BADGES.value) {
            const badgeData = hasModifiedClassProperty ? getNewContext(workflowData, replaceClassProperty) : workflowData[classProperty];
            return <TypeModelBadges
                workflowData={badgeData}
                label={label}
                badgeType={badgeType}
                badgeCount={badgeCount}
                modelSelector={modelSelector}
                layoutItem={layoutItem}
                viewMode={viewMode}
                typeModelViewMode={typeModelViewMode}
                onChangeTypeModelViewMode={this.onChangeTypeModelViewMode}
            />
        }

        if (viewMode === TypeModelDisplays.LINK.value) {
            const linkData = hasModifiedClassProperty ? getNewContext(workflowData, replaceClassProperty) : workflowData[classProperty];
            return <TypeModelLink
                workflowData={linkData}
                label={label}
                typeModelLink={typeModelLink}
                layoutItem={layoutItem}
                viewMode={viewMode}
                typeModelViewMode={typeModelViewMode}
                onChangeTypeModelViewMode={this.onChangeTypeModelViewMode}
            />
        }

        if (viewMode === TypeModelDisplays.CAROUSEL.value) {
            const linkData = hasModifiedClassProperty ? getNewContext(workflowData, replaceClassProperty) : workflowData[classProperty];
            return <TypeModelCarousel
                workflowData={linkData}
                label={label}
                carouselConfig={carouselConfig}
                layoutItem={layoutItem}
                viewMode={viewMode}
                typeModelViewMode={typeModelViewMode}
                onChangeTypeModelViewMode={this.onChangeTypeModelViewMode}
            />
        }

        if (viewMode === TypeModelDisplays.COUNT.value) {
            const countData = hasModifiedClassProperty ? getNewContext(workflowData, replaceClassProperty) : workflowData[classProperty];
            return <TypeModeCountOnly
                workflowData={countData}
                label={label}
                countConfig={countConfig}
                modelSelector={modelSelector}
                layoutItem={layoutItem}
                viewMode={viewMode}
                typeModelViewMode={typeModelViewMode}
                onChangeTypeModelViewMode={this.onChangeTypeModelViewMode}
            />
        }

        if (viewMode === TypeModelDisplays.CHART.value) {
            const chartData = hasModifiedClassProperty ? getNewContext(workflowData, replaceClassProperty) : workflowData[classProperty];
            return <TypeModelChart
                workflowData={chartData}
                label={label}
                workflow={this.props.workflow}
                chartConfig={chartConfig}
                modelSelector={modelSelector}
                layoutItem={layoutItem}
                viewMode={viewMode}
                typeModelViewMode={typeModelViewMode}
                onChangeTypeModelViewMode={this.onChangeTypeModelViewMode}
            />
        }

        if (viewMode === TypeModelDisplays.BULLETEDLIST.value) {
            const listData = hasModifiedClassProperty ? getNewContext(workflowData, replaceClassProperty) : workflowData[classProperty];
            return <TypeModelBulletedList
                workflowData={listData}
                label={label}
                modelSelector={modelSelector}
                layoutItem={layoutItem}
                viewMode={viewMode}
                typeModelViewMode={typeModelViewMode}
                onChangeTypeModelViewMode={this.onChangeTypeModelViewMode}
            />
        }

        if (viewMode === TypeModelDisplays.DECK.value) {
            const rows = hasModifiedClassProperty ? getNewContext(workflowData, replaceClassProperty) : workflowData[classProperty];
            if (typeModelViewMode.value === TypeModelDisplays.CARD.value) {
                return <TypeModelDeck
                    label={label}
                    workflow={this.props.workflow}
                    cards={rows}
                    layoutItem={layoutItem}
                    inlineActions={inlineAction}
                    viewMode={viewMode}
                    typeModelViewMode={typeModelViewMode}
                    onChangeTypeModelViewMode={this.onChangeTypeModelViewMode}
                />
            }
        }
        if (viewMode === TypeModelDisplays.ACCORDIAN.value) {
            const rows = hasModifiedClassProperty ? getNewContext(workflowData, replaceClassProperty) : workflowData[classProperty];
            if (typeModelViewMode.value === TypeModelDisplays.ACCORDIAN.value) {
                return <TypeModelAccordian
                    workflowData={rows}
                    label={label}
                    accordianConfig={accordianConfig}
                    layoutItem={layoutItem}
                    viewMode={viewMode}
                    typeModelViewMode={typeModelViewMode}
                    onChangeTypeModelViewMode={this.onChangeTypeModelViewMode}
                />
            }
        }

        if (viewMode === TypeModelDisplays.ITERATOR.value) {
            const collectionToIterate = hasModifiedClassProperty ? getNewContext(workflowData, replaceClassProperty) : workflowData[classProperty];
            if (!collectionToIterate || !Array.isArray(collectionToIterate)) return null;
            return <CollectionIterator
                workflow={this.props.workflow}
                actions={inlineAction}
                generatedActionCodeForIterable={generatedActionCodeForIterable}
                iterationWorkflowId={iterationWorkflowId}
                collectionToIterate={collectionToIterate}
                shouldRefresh={shouldRefresh}
                layoutItem={layoutItem}
                viewMode={viewMode}
                typeModelViewMode={typeModelViewMode}
                onChangeTypeModelViewMode={this.onChangeTypeModelViewMode}
                {...this.props}
            />
        }
        return (
            <FormGroup>
                {label.show?<Label className='text-muted text-capitalize mb-0'>{label.text}</Label>:null}
                {
                    data ? <p><TextStyleResolver 
                        text={`${data}`}
                        data={workflowData[classProperty]}
                        config={{
                            isTextualActionTriggerEnabled,
                            textualActionTriggerCode,
                            textualActionTriggerWorkflowCode
                        }}
                        {...this.props} /></p> : <p><NotAvailable defaultText={this.props.placeholder && this.props.placeholder.text} /></p>
                }
            </FormGroup>
        )
    }
}

export default TypeModelReadOne;