import React, { Component } from 'react';
import { FormGroup, Input, Row, Label, Col, FormFeedback, InputGroup, InputGroupAddon } from 'reactstrap';
import PrintText from '../PrintText';
import { Field } from 'redux-form/immutable';
import isNumeric from 'validator/lib/isNumeric';

class NumberRangeClass extends Component {
    constructor(props) {
        super(props);
        this.state = {
            numbers: {
                from: {
                    value: null
                },
                to: {
                    value: null,
                }
            }
        }
    }
    numEntered(numkey, ev) {
        const numbers = Object.assign([], this.state.numbers);
        const { input: { onChange }, touch, layoutItem: { itemProperties: { field: { classProperty } } } } = this.props;
        const etVal = ev.target.value + '';
        numbers[numkey]['value'] = etVal;
        this.setState({ numbers }, () => {
            touch(classProperty);
            onChange([this.state.numbers.from.value, this.state.numbers.to.value]);
        });
    }
    render() {
        const { label,
            layoutItem
        } = this.props;
        const { properties } = layoutItem.itemProperties.field;
        const { numbers } = this.state;
        const { meta: { touched, error } } = this.props;
        return (
            <FormGroup>
                {
                    label.show ? <Label>{label.text}{properties.required ? <sup>*</sup> : ''}</Label> : ''
                }
                {
                    <div>
                        <Row>
                            <Col sm='12' md='6'>
                                <InputGroup>
                                    <InputGroupAddon>From</InputGroupAddon>
                                    <Input type='text' onChange={this.numEntered.bind(this, 'from')} />
                                </InputGroup>
                            </Col>
                            <Col sm='12' md='6'>
                                <InputGroup>
                                    <InputGroupAddon>To</InputGroupAddon>
                                    <Input type='text' onChange={this.numEntered.bind(this, 'to')} />
                                </InputGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                {
                                    touched && error ? <InvalidNumber message={error} /> : ''
                                }
                            </Col>
                        </Row>
                    </div>
                }
            </FormGroup>
        );
    }
}

const InvalidNumber = (props) => {
    const { message } = props;
    return <p className='animated fadeIn text-danger invalid-feedback d-block'>
        <i className="fa fa-exclamation-triangle" aria-hidden="true"></i> {message}
    </p>
}

export default NumberRangeClass;