import React, { Component } from 'react';
import { Card, CardBody, CardTitle, CardSubtitle, CardText, CardImg } from 'reactstrap';
import ReactPlayer from 'react-player';
import TextTruncate from 'react-text-truncate';
import CommonFooterActions from './CommonFooterActions';
import config from '../../../config';
import TextStyleResolver from '../../elements/TextStyleResolver/index';

const ImageCard = (props) => {
    const { title, subtitle, description, cardConfig, image, imageCardStyle } = props;
    const cardImage = image && image.startsWith('http') ? image : config.assetsBasePath + image;
    let isVideo = false;
    if(cardImage) {
        if(cardImage.endsWith(".mp4") || cardImage.endsWith(".MP4") || cardImage.endsWith(".avi") || cardImage.endsWith(".mkv")) isVideo = true;
    }
    return <Card style={imageCardStyle ? imageCardStyle : {}}>
        {
            isVideo ? (
                <ReactPlayer controls width={'100%'} height={"160px"}
                    url={cardImage} />
            ) : (
                <CardImg top width="100%" src={cardImage} alt="Card image cap" />
            )
        }
        <CardBody>
            <CardTitle tag='h5'>
                <TextStyleResolver config={cardConfig.config.cardTitle} text={title} {...props} />
            </CardTitle>
            <CardSubtitle>
                <TextStyleResolver config={cardConfig.config.cardSubtitle} text={subtitle} {...props} />
            </CardSubtitle>
            <CardText tag='div' className='mt-2' title={description}>
                {
                    typeof description === 'string' ? <TextTruncate
                        line={3}
                        truncateText="…"
                        text={typeof description === 'string' ? description : ''}
                    /> : <div>{description}</div>
                }
            </CardText>
        </CardBody>
        <CommonFooterActions {...props} />
    </Card>
}

export default ImageCard;

