import React, { Component } from 'react';
import { connect } from 'react-redux';
import { toJS } from '../../containers/to-js';
import config from '../../config';

class RenderCompanyLogo extends Component {
    render() {
        const { companyDetails: { isLoading, hasError, data }, userProfile } = this.props;
        if (isLoading || hasError || !data) return null;
        const { logoUrl } = data;

        let logoURL = `${config.assetsBasePath}${logoUrl}`;

        if(userProfile && userProfile.data && userProfile.data.customLogoURL) logoURL = `${config.assetsBasePath}${userProfile.data.customLogoURL}`;
        return (
            <div className='company-logo'>
                <img src={logoURL} />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        companyDetails: state.getIn(['company', 'details']),
        userProfile: state.getIn(['user', 'profile'])
    }
}

export default connect(mapStateToProps, null)(toJS(RenderCompanyLogo));