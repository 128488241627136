import React from 'react';
import PrintText from './PrintText';
import { Field } from 'redux-form/immutable';
import { Button, Label } from 'reactstrap';
import { renderField } from './_FORM_FIELDS';
import { ModelPropertiesParser } from '../commons/modelPropertiesParser';
import { getFormValues } from 'redux-form/immutable';
import { connect } from 'react-redux';
import { toJS } from '../../containers/to-js';
import RenderIcon from '../commons/RenderIcon';

class LinkToContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    render() {
        const { layoutItem, workflow: { currentItem: { layoutProperties: { workflowSubmenuConfig }, layout:{containers} }, workflowData }, __form__values,customIcons } = this.props;
        const { itemProperties } = layoutItem;
        const { linkToContainerConfig, label,placeholder } = itemProperties;
        const { container, linkText } = linkToContainerConfig ? linkToContainerConfig : { container: null,linkText: null };
        let data  = workflowData;
        let textToRender = data? ModelPropertiesParser(linkText, data): null;
        let noText = !textToRender || textToRender.replace(/,/gi,'').replace(/-/gi,'').trim().length == 0;
        if(noText) {
            data = __form__values;
            textToRender = data? ModelPropertiesParser(linkText, data): null;
        }
        const items = workflowSubmenuConfig && workflowSubmenuConfig.isActive && workflowSubmenuConfig.items;
        const onClickLinkToContainer = () => {
            if(containers && containers.length) {
                containers.some((c, index) => {
                    if(index + 1 === container.value) {
                        if(this.props.toggleContainer) {
                            this.props.toggleContainer(index);
                        }
                    }
                });
            }
            let selectedSubMenuIndex;
            if(items && items.length) {
                items.some((item, index) => {
                    item.containerIndexes.some((containerIndex) => {
                        if ((containerIndex + 1) === container.value) {
                            selectedSubMenuIndex = index;
                            this.props.onSubmenuClicked(index);
                            const elementClassName = `.unique-container-${container.value}`;
                            setTimeout(() => {
                                const element = document ? document.querySelector(elementClassName) : null;
                                element && element.scrollIntoView({ 
                                    behavior: 'smooth' 
                                });
                                return true;
                            }, 500);
                        }
                    });
                });
            } else {
                const elementClassName = `.unique-container-${container.value}`;
                const element = document ? document.querySelector(elementClassName) : null;
                element && element.scrollIntoView({ 
                behavior: 'smooth' 
            });
            }
        };
        noText = !textToRender || textToRender.replace(/,/gi,'').replace(/-/gi,'').trim().length == 0;
        const labelClassName = `text-capitalize ${!noText?'text-muted':''}`;
        const textClassName = `text-capitalize ${!noText?'':'text-muted'}`;
        if(noText)
            textToRender=placeholder && placeholder.show?placeholder.text:label.show ?label.text:'Click to Configure';
        return (
            <div className={`link-to-container-${linkToContainerConfig.display && linkToContainerConfig.display.key === 'LABEL'?'text':'button'}`} onClick={onClickLinkToContainer} >
                <div>
                    {
                        itemProperties.beforeIcon?<RenderIcon config={itemProperties.beforeIcon} customIcons={customIcons} color={itemProperties.textColor?itemProperties.textColor.value:''} />:null
                    }
                {
                    linkToContainerConfig ? 
                        (!linkToContainerConfig.display || linkToContainerConfig.display.key==='BUTTON')?
                        <Button outline color="primary" onClick={onClickLinkToContainer}>{textToRender}</Button>:
                        linkToContainerConfig.display.key==='LABEL'?
                        <Label className={textClassName}>{textToRender}</Label>
                        :null
                    : null
                }
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        __form__values: getFormValues(ownProps.form)(state)
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(toJS(LinkToContainer));