import { connect } from 'react-redux';
import WorkflowsNav from '../components/menu/WorkflowsNav';
import { getTranslate, getActiveLanguage } from 'react-localize-redux';
import { fetchNavWorkflows, endCurrentBaseWorkflow } from '../actions/workflows';
import { toJS } from './to-js';

const mapStateToProps = (state) => {
    const locale = state.get('locale');
    return { 
        translate: getTranslate(locale),
        currentLanguage: getActiveLanguage(locale).code,
        user: state.get('user'),
        items: state.getIn(['navigation', 'data'])
      };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        fetchWorkflows: (user) => {
            dispatch(fetchNavWorkflows(user));
        },
        endCurrentBaseWorkflow: () => {
            dispatch(endCurrentBaseWorkflow());
        }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(toJS(WorkflowsNav));