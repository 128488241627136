import { connect } from 'react-redux';
import Workflow from '../../components/workflow/Workflow';
import { getTranslate } from 'react-localize-redux';
import { startWorkflow, loadAndSetBinaryBg } from '../../actions/workflows';
import { toJS } from '../to-js';
import { fetchNavWorkflows } from '../../actions/workflows';
import { getUserProfile } from '../../actions/user';


const mapStateToProps = (state, ownProps) => {
    return {
        translate: getTranslate(state.get('locale')),
        user: state.get('user'),
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        fetchNavWorkflows: (user) => {
            if(user) {
                dispatch(fetchNavWorkflows(user));
            }
        },
        getUserProfile:()=>{
            dispatch(getUserProfile());
        }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(toJS(Workflow));