import { connect } from 'react-redux';
import Login from '../components/pages/login/Login';
import { getTranslate } from 'react-localize-redux';
import { toJS } from './to-js';
import { login } from '../actions/user';
import { getCompanyDetails } from '../actions/company';

const mapStateToProps = (state) => {
    return { 
        translate: getTranslate(state.get('locale')),
        user: state.get('user'),
        companyDetails: state.getIn(['company', 'details'])
      };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onSubmit: (user) => {
            dispatch(login(user));
        },
        getCompanyDetails: () => {
            dispatch(getCompanyDetails());
        }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(toJS(Login));