import { Map } from 'immutable';
import {
    FETCH_ALL_REPORTS,
    FETCH_ALL_REPORTS_SUCCESS,
    FETCH_ALL_REPORTS_FAILURE
} from '../actions/reports';

const intitalState = Map({
    allReports: {
        isLoading: false,
        data: null,
        error: false
    }
});

const allReportsLoading = (state, action) => {
    const allReports = {
        isLoading: true,
        data: null,
        error: null
    }
    return state.merge({ allReports });
}

const allReportsSuccess = (state, action) => {
    const allReports = {
        isLoading: false,
        data: action.payload.data,
        error: null
    }
    return state.merge({ allReports });
}

const allReportsFailure = (state, action) => {
    const allReports = {
        isLoading: false,
        data: null,
        error: true
    }
    return state.merge({ allReports });
}

const reports = (state = intitalState, action = {}) => {
    switch (action.type) {
        case FETCH_ALL_REPORTS: return allReportsLoading(state, action);
        case FETCH_ALL_REPORTS_SUCCESS: return allReportsSuccess(state, action);
        case FETCH_ALL_REPORTS_FAILURE: return allReportsFailure(state, action);
        default: return state;
    }
};

export default reports;