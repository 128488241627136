import React, { Component } from 'react';
import SubWorkflowTrigger from './SubWorkflowTrigger';
import RenderIcon from '../../commons/RenderIcon'
class ContextualTextColor extends Component {
    render() {
        const { text, config,customIcons, ...rest } = this.props;
        const { contextual_text_color, isTextualActionTriggerEnabled } = config;

        let textToRender = contextual_text_color.hasOwnProperty(text + '_text')?contextual_text_color[text + '_text']:text;

        if(!textToRender || !textToRender.length) textToRender = text;
        
        const renderText = !contextual_text_color.hasOwnProperty(text) ? 
        !contextual_text_color.hasOwnProperty(text + '_icon')?<div>{textToRender}</div> : 
        <div className='contextual-text-with-icon'><RenderIcon config={contextual_text_color[text + '_icon']} customIcons={customIcons} /> {textToRender}</div>
        : (
            <span className={`text-${contextual_text_color[text].code}`}>
                {
                    isTextualActionTriggerEnabled ? <span>
                        <i className='fa fa-external-link'></i>
                        {'   '}
                    </span> : null
                }
                {textToRender}
            </span>
        )
        const renderContextualTextColor = isTextualActionTriggerEnabled ? <SubWorkflowTrigger text={renderText} config={config} {...rest} /> : renderText;
        return renderContextualTextColor;
    }
}

export default ContextualTextColor;