import React from 'react';
import { Field} from 'redux-form/immutable';
import { FormGroup, Label, Input } from 'reactstrap';
import ReactPlayer from 'react-player'
import config from '../../config';
import PrintText from './PrintText';
import Uploader from './File';
import { renderField } from './_FORM_FIELDS';

const Video = (props) => {
    const { layoutItem, workflowData, classProperty, value, isReadOne } = props;
	const { itemProperties } = layoutItem;
    const { label, field, placeholder, videoUrl } = itemProperties;
	console.log(isReadOne)
	return (
		<div className='video'>
		{ 
			isReadOne ? (
				<PrintText {...props} />
			) :
			(
				<div className='create-video'>
					<Field
						name={classProperty}
						component={renderField}
						type="text"
						value={value}
						{...props}
					/>
					<p className='text-center mb-1 mt-0'>or</p>
					<Uploader {...props} />
				</div>
			)
		}
		</div>
	)
}

export default Video;