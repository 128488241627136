import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Col } from 'reactstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Field } from 'redux-form/immutable';
import Select from 'react-select';

class DatepickerComponent extends Component {
    constructor(props) {
        super(props);
        const { filterStateMap, filterIndex } = props;
        const savedFilterState = filterStateMap ? filterStateMap[`filter_${filterIndex}`] : '';
        this.state = {
            date: null,
            fromDate: null,
            toDate: null
        }
    }
    componentWillReceiveProps(nextProps) {
        const { filterStateMap, filterIndex } = nextProps;
        const savedFilterState = filterStateMap[`filter_${filterIndex}`];
        if (!savedFilterState) {
            this.setState({
                date: null,
                fromDate: null,
                toDate: null
            })
        }
    }
    triggerFilterChange(value, fromValue, toValue) {
        const { onFilterChange, filter, filterIndex } = this.props;
        const { fromDate, toDate } = this.state;
        const { dateFilterType, leafType } = filter;
        this.setState({ value });
        let filterValue = [];
        const filterConfig = {
            filter,
            filterIndex
        }
        if (dateFilterType == 'DATERANGE') {
            let from = fromValue ? leafType == 'DATETIME' ? fromValue.format('YYYY-MM-DD hh:mm a') : fromValue.format('YYYY-MM-DD') : 
                fromDate ? leafType == 'DATETIME' ? fromDate.format('YYYY-MM-DD hh:mm a') : fromDate.format('YYYY-MM-DD') : '';
            from = !from ? '' : from;
            let to = toValue ? leafType == 'DATETIME' ? toValue.format('YYYY-MM-DD hh:mm a') : toValue.format('YYYY-MM-DD') : 
                toDate ? leafType == 'DATETIME' ? toDate.format('YYYY-MM-DD hh:mm a') : toDate.format('YYYY-MM-DD') : '';
            to = !to ? '' : to;
            filterValue = [from, to];
        } else {
            filterValue = [value];
        }
        onFilterChange(filterConfig, filterValue);
    }
    handleChange(date) {
        const { filter : { leafType } } = this.props;
        this.setState({ date });
        let dateValue = date ? leafType == 'DATETIME' ? date.format('YYYY-MM-DD hh:mm a') : date.format('YYYY-MM-DD') : '';
        dateValue = !dateValue ? '' : dateValue;
        this.triggerFilterChange(dateValue);
    }
    handleFromChange(date) {
        this.setState({ fromDate: date });
        this.triggerFilterChange(null, date);
    }
    handleToChange(date) {
        this.setState({ toDate: date });
        this.triggerFilterChange(null, null, date);
    }

    render() {
        const { filter, elem_id } = this.props;
        const { showClickableHorizontalView, dateFilterType, leafType } = filter;
        const { date, fromDate, toDate, selectedOption } = this.state;
        if (dateFilterType == 'DATERANGE') {
            return (
                <Col xs="12" className='w-100 p-0'>
                    {
                        leafType == 'DATETIME' ? <div>
                            <DatePicker
                                selected={fromDate}
                                onChange={this.handleFromChange.bind(this)}
                                placeholderText='Select From Date...'
                                className='form-control w-100'
                                popperPlacement='auto-right'
                                showTimeSelect
                                timeFormat="HH:mm"
                                timeIntervals={15}
                                timeCaption="time"
                                dateFormat="MM/DD/YYYY hh:mm a"
                            />
                            <DatePicker
                                selected={toDate}
                                onChange={this.handleToChange.bind(this)}
                                placeholderText='Select To Date...'
                                className='form-control w-100'
                                popperPlacement='auto-right'
                                showTimeSelect
                                timeFormat="HH:mm"
                                timeIntervals={15}
                                timeCaption="time"
                                dateFormat="MM/DD/YYYY hh:mm a"
                                
                            />
                        </div> : <div>
                            <DatePicker
                                selected={fromDate}
                                onChange={this.handleFromChange.bind(this)}
                                placeholderText='Select From Date...'
                                className='form-control w-100'
                                popperPlacement='auto-right'
                            />
                            <DatePicker
                                selected={toDate}
                                onChange={this.handleToChange.bind(this)}
                                placeholderText='Select To Date...'
                                className='form-control w-100'
                                popperPlacement='auto-right'
                            />
                        </div>
                    }
                </Col>
            );
        }
        return (
            <Col xs="12" className='w-100 p-0'>
                {
                    leafType == 'DATETIME' ? <DatePicker
                        selected={date}
                        onChange={this.handleChange.bind(this)}
                        placeholderText='Select Date...'
                        className='form-control w-100'
                        popperPlacement='auto-right'
                        showTimeSelect
                        timeFormat="HH:mm"
                        timeIntervals={15}
                        timeCaption="time"
                        dateFormat="MM/DD/YYYY hh:mm a"
                    /> : <DatePicker
                        selected={date}
                        onChange={this.handleChange.bind(this)}
                        placeholderText='Select Date...'
                        className='form-control w-100'
                        popperPlacement='auto-right'
                    />
                }
            </Col>
        );
    }
}

const DateFilter = (props) => {
    const { ...rest } = props;
    return (
        <div>
            <Field
                name="dateFilter"
                component={DatepickerComponent}
                type="date"
                {...rest}
            />
        </div>
    );
}


export default DateFilter;