export const ADD_BREADCRUMB = 'ADD_BREADCRUMB';
export const REMOVE_BREADCRUMB = 'REMOVE_BREADCRUMB';
export const POP_BREADCRUMB = 'POP_BREADCRUMB';
export const CLEAR_BREADCRUMS = 'CLEAR_BREADCRUMS';

export const addBreadcrumb = (payload) => {
    return (dispatch) => {
        dispatch({
            type: ADD_BREADCRUMB,
            payload
        })
    }
}

export const removeBreadcrumb = (payload) => {
    return (dispatch) => {
        dispatch({
            type: REMOVE_BREADCRUMB,
            payload
        })
    }
}

export const popBreadcrumb = () => {
    return (dispatch) => {
        dispatch({
            type: POP_BREADCRUMB,
            payload: {}
        })
    }
}

export const clearBreadcrumbs = (payload) => {
    return (dispatch) => {
        dispatch({
            type: CLEAR_BREADCRUMS,
            payload
        })
    }
}