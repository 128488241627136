import React, { Component } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import BaseWorkflow from '../workflow/BaseWorkflow';
import ModalWorkflow from '../workflow/ModalWorkflow';
import SidebarWorkflow from '../workflow/SidebarWorkflow';
import Dashboard from '../dashboard/index';
import { connect } from 'react-redux';
import { toJS } from '../../containers/to-js';

class Home extends Component {
    flattenNav(navigation) {
        const flat = [];
        if (!navigation) return flat;
        navigation.map((parent) => {
            const { childMenus } = parent;
            const parentTitle = parent.title;
            const parentWorkflowId = parent.workflowId;
            const parentFriendlyUrl = parent.friendlyUrl;
            const parentWorkflowCode = parent.workflowCode;
            let weirdParent = !parentWorkflowId && !childMenus.length;
            if (!weirdParent) {
                flat.push({
                    title: parentTitle,
                    workflowId: parentWorkflowId,
                    friendlyUrl: parentFriendlyUrl,
                    workflowCode: parentWorkflowCode
                });
            }
            if (childMenus && childMenus.length) {
                childMenus.map((child) => {
                    const { title, workflowId, friendlyUrl, workflowCode } = child;
                    flat.push({
                        title: `${parentTitle}/${title}`,
                        workflowId,
                        friendlyUrl,
                        workflowCode
                    });
                });
            }
        });
        return flat;
    }
    render() {
        const { navigation } = this.props;
        if (!navigation) return null;
        const flatNav = this.flattenNav(navigation);
        return <div className='h-100'>
            {
                navigation && navigation.length ? <Switch>
                    <Route path={`${this.props.match.url}/:_URL+`} render={props => (
                        <div className='h-100'>
                            <BaseWorkflow {...props} navigation={flatNav} />
                            <ModalWorkflow />
                            <SidebarWorkflow />
                        </div>
                    )} />
                    <Route exact path={`${this.props.match.url}`} render={props => (
                        <div className='h-100'>
                            <BaseWorkflow isDefaultWorkflow={true} navigation={flatNav} />
                            <ModalWorkflow />
                            <SidebarWorkflow />
                        </div>
                    )} />
                </Switch> : ''
            }

        </div>
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.get('user'),
        navigation: state.getIn(['navigation', 'data'])
    }
}

export default connect(mapStateToProps)(toJS(Home));