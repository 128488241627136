import React from 'react';
import RichhTextRenderer from '../elements/RichhTextRenderer'

class RichText extends React.Component {

  componentDidMount() {
    setTimeout(() => {
      this.props.onEnter();
    }, 5000);
  }

  render() {
    return <RichhTextRenderer {...this.props} />
  }
}

export default RichText;