import React, { Component } from 'react';
import { connect } from 'react-redux';
import { toJS } from '../../../containers/to-js';
import { triggerAction } from '../../../actions/workflow-actions';
import ActionContainer from '../../../containers/workflow/ActionContainer';
import { Dropdown, ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

class CardInlineActions extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
			actionsDDOpen: false
        }
    }
    toggle() {
        this.setState({ isOpen: !this.state.isOpen, actionsDDOpen: !this.state.actionsDDOpen });
    }
    render() {
        const { isOpen } = this.state;
        const { actions, data, triggerAction, workflow, isFullWidth, menuTypeInlineAction, editActionId} = this.props;
        return (
            <div className='ml-3 mt-2 mb-2'>
                {
                    actions.map((action, index) => {
                        const { actionProperties } = action;
                        if(actionProperties && actionProperties.actionDisabled || actionProperties.elementType == 'MENULINK' || 
							editActionId && actionProperties && actionProperties.generatedActionCode !=  editActionId ) {
                            return null;
                        }
                        return <div key={index} className='d-inline-block mr-1'>
                            <ActionContainer key={index} workflow={workflow} action={action} tid={data?data.id:null} />
                        </div>
                    })
                }
				{
					menuTypeInlineAction && menuTypeInlineAction.length > 0  && <div className='ml-1 d-inline-block'>
						<Dropdown size="sm" isOpen={this.state.actionsDDOpen} toggle={this.toggle.bind(this, 'actionsDDOpen')}>
							<DropdownToggle color='link'>
								<i className="fa fa-ellipsis-h fa-lg" aria-hidden="true"></i>
							</DropdownToggle>
							<DropdownMenu right>
								{
									menuTypeInlineAction.map((action, index) => {
										if (action.actionProperties.hasOwnProperty('systemGenerated') &&  action.actionProperties.systemGenerated === true) return null;
										const { actionProperties } = action;
										if(actionProperties && actionProperties.actionDisabled || 
											editActionId && actionProperties && actionProperties.generatedActionCode !=  editActionId ) {
											return null;
										}
										return <DropdownItem key={index}>
											<ActionContainer key={index} workflow={workflow} action={action} tid={data?data.id:null} />
										</DropdownItem>
									})
								}
							</DropdownMenu>
						</Dropdown>
					</div>
				}
            </div>
        )

        // return (
        //         <ButtonDropdown isOpen={isOpen} toggle={this.toggle.bind(this)}>
        //             <DropdownToggle color="link">
        //                 <i className="fa fa-ellipsis-h" aria-hidden="true"></i>
        //             </DropdownToggle>
        //             <DropdownMenu>
        //                 {
        //                     actions.map((action, index) => {
        //                         return <DropdownItem onClick={triggerAction.bind(this, action, data.id, workflow)} key={index}>
        //                             { action.actionProperties.elementText }
        //                         </DropdownItem>
        //                     })
        //                 }
        //             </DropdownMenu>
        //         </ButtonDropdown>
        // );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        triggerAction: (action, tid, workflow) => {
            dispatch(triggerAction(action, tid, workflow));
        }
    }
}

export default connect(null, mapDispatchToProps)(CardInlineActions);