import React from 'react';
import { Row, Col, Alert, Jumbotron, Button } from 'reactstrap';
import { getBreakpoint } from '../../workflow/operations/helpers/form-layout/common-routines';
import * as InteractiveElements from '../../inteteractiveElements';
import * as validations from '../../workflow/validations/index';

class InteractiveContainer extends React.Component{
  constructor(props) {
    super(props);
    this.state = {
      currentContainerItem: 0,
      isHiding: false
    };
  }

  getItemToDisplay = () => {
    const { container } = this.props;
    const column = container && container.properties && container.properties.columns[0];
    let bkPoint = getBreakpoint(this.props);
    let grid = column && column.layouts[bkPoint];
    const { currentContainerItem } = this.state;
    let item = "";
    if(grid) {
      const currentItem = grid.findIndex((row, index) => {
        return row.y === currentContainerItem
      });
      item = container.items[currentItem];
    }
    return item;
  }

  getItemValidations = (layoutItem) => {
    if (layoutItem.itemType === 'MODELPROPERTIES') {
        let itemValidations = [];
        const { field } = layoutItem.itemProperties;
        const { type } = field;
        const { required } = field.properties;
        
        //TYPE PROPERTIES VALIDATIONS
        required && itemValidations.push(validations.required);

        //ITEM TYPE BASED VALIDATIONS
        const _validations = validations.typeValidations[type];
        if (typeof validations !== 'undefined' && Array.isArray(_validations)) {
            itemValidations = itemValidations.concat(_validations);
        }
        return itemValidations;
    }
}

  handleNextContainerItem = () => {
    const { currentContainerItem } = this.state;
    const { container } = this.props;
    this.setState({isHiding: true});
    setTimeout(() => {
      if(currentContainerItem < container.items.length - 1) {
        this.setState({ currentContainerItem: currentContainerItem + 1, isHiding: false });
      } else {
        this.props.onHandleSubmit();
      }
    }, 500);
  }

  render() {
    const itemToDisplay = this.getItemToDisplay();
    const Element = itemToDisplay && itemToDisplay.itemProperties.field && InteractiveElements[itemToDisplay.itemProperties.field.type]
    if(!Element) return <Alert>No Such Element</Alert>
    const title = itemToDisplay.itemProperties.interactiveContainerConfig.text || itemToDisplay.itemProperties.label.text;
    return <div className="interactive-wrapper">
      <Row className="interactive">
        <Col>
          <Jumbotron className="h-100 interactive-querry">
            <h2 className={`animated ${this.state.isHiding ? 'slideOutLeft' : 'typeWriter anim-typewriter'}`}>{title}</h2>
            <Row className="justify-content-center">
              <Col xs="12" className={`my-4 interactive-input animated ${!this.state.isHiding ? 'fadeIn delay-animation' : 'fadeOut'}`}>
                <Element 
                  placeholder={{show: true, text:"Please enter your response here"}}
                  itemProperties={itemToDisplay.itemProperties}
                  layoutItem={itemToDisplay}
                  classProperty={itemToDisplay.itemProperties.field.classProperty}
                  onEnter={this.handleNextContainerItem}
                  validations={this.getItemValidations(itemToDisplay)}
                  {...this.props}
                />  
              </Col>
            </Row>
          </Jumbotron>
        </Col>
      </Row>
    </div>
  }
}

export default InteractiveContainer;