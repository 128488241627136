import React, { Component } from 'react';
import { FormGroup, Input, Label, FormFeedback, Row, Col, Button } from 'reactstrap';
import client from '../../../client';
import config from '../../../config';
import { LinkedIn } from 'react-linkedin-login-oauth2';

class LinkedInLoginComp extends Component {
  
	constructor(props) {
		super(props);
    }
	response = (response) => {
		console.log(response);
		let token = response.accessToken;
		if(token) {
			const payload = {
				token,
				requiredRole: this.props.requiredRole
			};
			client().post(`${config.publicAPI}/linkedInLogin`, payload).then((res) => {
			  const {authenticationCallback} = this.props;
			  authenticationCallback();
			}).catch((error) => {
			  
			});
		}
		
	}
	render() {
		return (
			<LinkedIn
			  clientId="81lx5we2omq9xh"
			  onFailure={this.response}
			  onSuccess={this.response}
			  className='linkedin-button-class w-100'
			  redirectUri="https://web.mentonect.com/"
			>
			  <span style={{ maxWidth: '180px' }}><i className="fa fa-linkedin" aria-hidden="true"></i> Login with LinkedIn</span>
			</LinkedIn>
		)
	}
  
}

export default LinkedInLoginComp;