export const SET_UI_FILTER = 'SET_UI_FILTER';

export const setUIFilter = (payload) => {
  return (dispatch) => {
    dispatch({
      type: SET_UI_FILTER,
      payload
    })
  }
}

