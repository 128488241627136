import React, { useState, useEffect, useRef } from 'react';
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
  Polygon,
} from 'react-google-maps';
import { Button, Input, ButtonGroup } from 'reactstrap';
import { Field } from 'redux-form/immutable';
import { Modal } from 'reactstrap';
import { toast } from 'react-toastify';
import RenderIcon from '../commons/RenderIcon';
import StandaloneSearchBox from "react-google-maps/lib/components/places/StandaloneSearchBox";

const GeoJSONPolygon = withScriptjs(
  withGoogleMap(props => {
    let existingPoints = props.input.value;
    const { layoutItem: { itemProperties: { polygonConfig } } } = props;
    const defaultLat = polygonConfig && polygonConfig.lat;
    const defaultLong = polygonConfig && polygonConfig.long;

    let path = props.input.value;
    if (typeof existingPoints === 'string' && existingPoints.length) {
      let cords = `[${existingPoints}]`;
      cords = JSON.parse(cords);
      existingPoints = [];
      path = [];
      cords.map((c, i) => {
        if (i !== cords.length - 1) {
          existingPoints.push({
            lat: c[0],
            lng: c[1],
          });
        }
        path.push({
          lat: c[0],
          lng: c[1],
        });
      });
    } else {
      existingPoints = [];
    }

    const [searchRef, setSearchRef] = useState(null);
    const defaultSetPoint = existingPoints && existingPoints[0]
    const [mapref, setMapRef] = useState(null);
    const [center, setCenter] = useState(defaultSetPoint ? defaultSetPoint : { lat: defaultLat ? parseFloat(defaultLat) : 12.9716, lng: defaultLong ? parseFloat(defaultLong) : 77.5946 });
    const [modal, setModal] = useState(false);
    const [pinMode, setPinMode] = useState(false);
    const [inputField, setInputField] = useState('');
    const [coordFormat, setCoordFormat] = useState('LngLat');
    const updatePolygon = points => {
      let updatedPoints = '';
      if (Array.isArray(points) && points.length) {
        points.map(p => {
          updatedPoints = `${updatedPoints}[${p.lat},${p.lng}], `;
        });
        updatedPoints = `${updatedPoints}[${points[0].lat},${points[0].lng}]`;
      }
      setCenter(points[0]);
      props.change(props.classProperty, updatedPoints);
    };

    return (
      <div className='geojsonpolygon'>
        <Modal size='md' isOpen={modal} toggle={() => setModal(!modal)}>
          <div className='add-points'>
            <ButtonGroup className={'m-2'}>
              <Button
                outline
                active={coordFormat === 'LngLat'}
                onClick={() => setCoordFormat('LngLat')}
              >
                Longitude,Latitude
              </Button>
              <Button
                outline
                active={coordFormat === 'LatLng'}
                onClick={() => setCoordFormat('LatLng')}
              >
                Latitude,Longitude
              </Button>
            </ButtonGroup>
            <div className='text-input'>
              <Input
                value={inputField}
                type='textarea'
                onChange={ev => setInputField(ev.target.value)}
                placeholder='GeoJson coordinates'
                rows={4}
              ></Input>
              <Button
                onClick={e => {
                  e.preventDefault();
                  const polygonPoints = existingPoints.map(p => p);
                  try {
                    const data = JSON.parse(inputField);
                    console.log();
                    data.map((coord, i) => {
                      if (coordFormat === 'LngLat') {
                        polygonPoints.push({ lat: coord[1], lng: coord[0] });
                      } else {
                        polygonPoints.push({ lat: coord[0], lng: coord[1] });
                      }
                    });
                    updatePolygon(polygonPoints);
                    setModal(!modal);
                  } catch (e) {
                    toast.error(
                      'Please enter valid GEOJSON Array. example: [[73.00,19.22],[73.01,19.23]]'
                    );
                  }
                }}
                color={'success'}
              >
                Add
              </Button>
            </div>
          </div>
        </Modal>
        <GoogleMap
          onClick={ev => {
            if (pinMode) {
              const polygonPoints = existingPoints.map(p => p);
              polygonPoints.push({
                lat: ev.latLng.lat(),
                lng: ev.latLng.lng(),
              });
              updatePolygon(polygonPoints);
            }
          }}
          options={{ draggableCursor: pinMode ? 'crosshair' : 'auto' }}
          ref={ref => setMapRef(ref)}
          onDragEnd={() => {
            const c = mapref.getCenter();
            setCenter({ lat: parseFloat(c.lat()), lng: parseFloat(c.lng()) });
          }}
          defaultZoom={14}
          center={center}
        >
          <div className='searchContainer'>
              <StandaloneSearchBox
                ref={ref => setSearchRef(ref)}
                bounds={props.bounds}
                onPlacesChanged={() => {
                  const selectedPlace = searchRef.getPlaces()[0];
                  const coord = {lat: selectedPlace.geometry.location.lat(), lng: selectedPlace.geometry.location.lng()}
                  const polygonPoints = existingPoints.map(p => p);
                  polygonPoints.push(coord);
                  updatePolygon(polygonPoints);
                }}
              >
                <input
                  type="text"
                  placeholder={props.placeholder.show ? props.placeholder.text : "Search location"}
                  style={{
                    width: '100%',
                    height: 40,
                    paddingLeft: 15,
                    paddingRight: 15
                  }}
                />
              </StandaloneSearchBox>
            </div>
          <div className='buttonContainer'>
            <Button
              title='Add GeoJSON'
              size='sm'
              onClick={() => setModal(!modal)}
              color={'light'}
            >
              <RenderIcon config={{ cssClass: 'fa-plus', onlyIcon: true }} />
            </Button>
            <Button
              onClick={() => {
                setPinMode(!pinMode);
              }}
              title='Mark polygons'
              size='sm'
              color={pinMode ? 'danger' : 'light'}
            >
              <RenderIcon
                config={{ cssClass: 'fa-map-marker', onlyIcon: true }}
              />
            </Button>
          </div>
          {existingPoints.length ? (
            <div className='selected-locations'>
              <div className='header'>
                <div className='col'>Latitude</div>
                <div className='col'>Longitude</div>
                <div className='empty'></div>
              </div>
              {existingPoints.map((p, i) => {
                return (
                  <div className='row' key={i}>
                    <div className='col'>{p.lat}</div>
                    <div className='col'>{p.lng}</div>
                    <div className='remove'>
                      <Button
                        title='Remove'
                        size='sm'
                        onClick={e => {
                          e.preventDefault();
                          const polygonPoints = existingPoints.map(p => p);
                          polygonPoints.splice(i, 1);
                          updatePolygon(polygonPoints);
                        }}
                        color={'danger'}
                      >
                        X
                      </Button>
                    </div>
                  </div>
                );
              })}
            </div>
          ) : null}
          {Array.isArray(existingPoints)
            ? existingPoints.map((marker, index) => {
              return (
                <Marker
                  draggable={true}
                  onDragEnd={m => {
                    const polygonPoints = existingPoints.map(p => p);
                    const latlng = {
                      lat: m.latLng.lat(),
                      lng: m.latLng.lng(),
                    };
                    polygonPoints[index] = latlng;
                    updatePolygon(polygonPoints);
                  }}
                  label={`${index + 1}`}
                  key={`${marker.lat}_${index}`}
                  defaultPosition={marker}
                ></Marker>
              );
            })
            : null}
          {Array.isArray(path) ? <Polygon path={path} /> : null}
        </GoogleMap>
      </div>
    );
  })
);

const GMap = props => {
  const existingValues = props.values;
  return (
    <Field
      name={props.classProperty}
      component={GeoJSONPolygon}
      googleMapURL='https://maps.googleapis.com/maps/api/js?key=AIzaSyBfhuMCKv2c1jH_lJcxJ1iNjg0mmHXY_zU&v=3.exp&libraries=geometry,drawing,places'
      loadingElement={<div style={{ height: `100%` }} />}
      containerElement={<div style={{ height: `600px` }} />}
      mapElement={<div style={{ height: `100%` }} />}
      {...props}
    />
  );
};

export default GMap;
