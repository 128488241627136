import React from 'react';
import PrintText from './PrintText';
import { Field } from 'redux-form/immutable';
import DateRangeClass from './FieldClasses/_DATE_RANGE_CLASS';

const normalize = (dates) => {
    if (!dates) return dates;
    const withTimes = dates.map((date) => {
        return date !== '' ? `${date} 00:00` : date;    
    });
    return withTimes;
}

const DateRange = (props) => {
    const { classProperty, type, validations, value, isReadOne, ...rest } = props;
    const _ranges = Array.isArray(value) && value.length ? value : ['', ''];
    return (
        <div>
            {
                isReadOne ?
                    <PrintText {...props} /> :
                    <Field
                        name={classProperty}
                        component={DateRangeClass}
                        validate={validations}
                        fieldType={type}
                        {...rest}
                        ranges={_ranges}
                        normalize={normalize}
                    />
            }
        </div>
    );
}

export default DateRange;
