import React from 'react';
import { Input } from 'reactstrap';
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from "react-google-maps";
import { SearchBox } from "react-google-maps/lib/components/places/SearchBox";
import { Field, change as changeFieldValue } from 'redux-form';
import PrintText from './PrintText';

class Location extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      bounds: null,
      center: {
        lat: 41.9, lng: -87.624
      },
      markers: [],
      refs: {},
      places: null
    }
  }

  onMapMounted = ref => {
    const refs = this.state.refs;
    refs.map = ref;
    this.setState({
      refs
    });
  }

  onBoundsChanged = () => {
    this.setState({
      bounds: this.state.refs.map.getBounds(),
      center: this.state.refs.map.getCenter(),
    })
  }

  onSearchBoxMounted = ref => {
    const refs = this.state.refs;
    refs.searchBox = ref;
    this.setState({
      refs
    });
  }
  onPlacesChanged = () => {
	const { registeredFields } = this.props;
	const fieldsKey = Object.keys(registeredFields);
    const places = this.state.refs.searchBox.getPlaces();
    const center = {
      lat: places[0].geometry.location.lat(),
      lng: places[0].geometry.location.lng()
    }
    this.setState({
      center,
      places
    });
    const value = `${places[0].geometry.location.lat()},${places[0].geometry.location.lng()}`
    this.props.dispatch(changeFieldValue(this.props.form, this.props.classProperty, value));
	fieldsKey.map((item) => {
		if(item.indexOf('address') > -1 ) {
			this.props.dispatch(changeFieldValue(this.props.form, item, places[0].formatted_address));
		}
	})
  }
  
  componentWillReceiveProps(nextProps) {
	//const { formValues } = this.props;
  }

  render() {
    const { isReadOne, value, classProperty, layoutItem: { itemProperties: { placeholder,locationConfig } } } = this.props;
    const customMapStyle = locationConfig &&  locationConfig.customMapStyle
    return <div className="location">
      {
        isReadOne ? <PrintText {...this.props} /> : <LocationMap 
        isMarkerShown
        googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyBfhuMCKv2c1jH_lJcxJ1iNjg0mmHXY_zU&v=3.exp&libraries=geometry,drawing,places"
        loadingElement={<div style={{ height: `100%` }} />}
        containerElement={<div style={{ height: `400px` }} />}
        mapElement={<div style={{ height: `100%` }} />}
        onMapMounted={this.onMapMounted}
        onBoundsChanged={this.onBoundsChanged}
        onSearchBoxMounted={this.onSearchBoxMounted}
        onPlacesChanged={this.onPlacesChanged.bind(this)}
        bounds={this.state.bounds}
        customMapStyle={customMapStyle}
        center={this.state.center}
        panToBounds={() => {
          this.state.refs.map && this.state.refs.map.panToBounds(this.state.bounds);
        }}
        placeholder={placeholder}
        classProperty={classProperty}
        value={value}
      />
      }
      </div>
  }
}

const LocationMap = withScriptjs(withGoogleMap((props) => {
  const customMapStyle = props &&  props.customMapStyle
  const silverMapStyles = customMapStyle ? [{"elementType":"geometry","stylers":[{"color":"#f5f5f5"}]},{"elementType":"labels.icon","stylers":[{"visibility":"off"}]},{"elementType":"labels.text.fill","stylers":[{"color":"#616161"}]},{"elementType":"labels.text.stroke","stylers":[{"color":"#f5f5f5"}]},{"featureType":"administrative.land_parcel","elementType":"labels.text.fill","stylers":[{"color":"#bdbdbd"}]},{"featureType":"poi","elementType":"geometry","stylers":[{"color":"#eeeeee"}]},{"featureType":"poi","elementType":"labels.text.fill","stylers":[{"color":"#757575"}]},{"featureType":"poi.park","elementType":"geometry","stylers":[{"color":"#e5e5e5"}]},{"featureType":"poi.park","elementType":"labels.text.fill","stylers":[{"color":"#9e9e9e"}]},{"featureType":"road","elementType":"geometry","stylers":[{"color":"#ffffff"}]},{"featureType":"road.arterial","elementType":"labels.text.fill","stylers":[{"color":"#757575"}]},{"featureType":"road.highway","elementType":"geometry","stylers":[{"color":"#dadada"}]},{"featureType":"road.highway","elementType":"labels.text.fill","stylers":[{"color":"#616161"}]},{"featureType":"road.local","elementType":"labels.text.fill","stylers":[{"color":"#9e9e9e"}]},{"featureType":"transit.line","elementType":"geometry","stylers":[{"color":"#e5e5e5"}]},{"featureType":"transit.station","elementType":"geometry","stylers":[{"color":"#eeeeee"}]},{"featureType":"water","elementType":"geometry","stylers":[{"color":"#c9c9c9"}]},{"featureType":"water","elementType":"labels.text.fill","stylers":[{"color":"#9e9e9e"}]}]:[]
  return (
      <GoogleMap
        options={{
          mapTypeControl: false,
          scrollwheel: false
        }}
        ref={props.onMapMounted}
        defaultZoom={15}
        center={props.center}
        onBoundsChanged={props.onBoundsChanged}
        panToBounds={props.fitBounds}
      options={{styles:silverMapStyles}}
      >
        <SearchBox
          ref={props.onSearchBoxMounted}
          bounds={props.bounds}
          controlPosition={1}
          onPlacesChanged={props.onPlacesChanged}
          options={{
            enableEventPropagation: false
          }}
        >
          <Field
              name={props.classProperty}
              component={renderInput}
              type="text"
              value={props.value}
              placeholder={props.placeholder}
          />
        </SearchBox>
      {props.isMarkerShown && <Marker position={props.center} />}
    </GoogleMap>
    )
  }
))

const renderInput = (props) => <Input
  type="text"
  style={{
    width: '90%',
    marginLeft: '5%',
    marginTop: '40px'
  }}
  placeholder={props.placeholder.show ? props.placeholder.text : 'Please enter the place name'}
/>;


export default Location;