import wholeNumber from './wholeNumber';

const numberRange = values => {
    if (!values) return values;
    const numFrom = values[0];
    const numTo = values[1];
    const numFromRes = wholeNumber(numFrom);
    const numToRes = wholeNumber(numTo);
    if (!numFromRes && !numToRes) {
        return undefined;
    }
    if (numFromRes) {
        return 'Please enter a valid number for the From field';
    }
    if (numToRes) {
        return 'Please enter a valid number for the To field';
    }
    return undefined;
} 
export default numberRange;
