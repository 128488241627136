import React, { Component } from 'react';
import { Card, CardBody, CardHeader, Collapse, Label } from 'reactstrap';
import { ModelPropertiesParser } from '../../commons/modelPropertiesParser';

class TypeModelAccordian extends Component {
    constructor(props) {
      super(props);
      this.state = {
        toggler: 'type-model-accordian-0'
      }
    }

    handleToggler = (id) => {
        if ( this.state.toggler === id ) {
          this.setState({ toggler: null });
        } else {
            this.setState({ toggler: id });
        }
    }
    render() {
        const { accordianConfig, workflowData, modelSelector, label } = this.props;
        if (!accordianConfig || !workflowData) return null;
        if (workflowData && Array.isArray(workflowData)) {
            return (
                <div>
                    <Label className='text-capitalize mb-1'>{label.text}</Label>
                    {
                        workflowData.map((data, index) => {
                            return ( 
                              <Card key={index} className="accordian">
                                <CardHeader
                                  onClick={() => this.handleToggler(`type-model-accordian-${index}`)}>{ModelPropertiesParser(accordianConfig.titleField, data)}
                                  <i className={`icon fa ${ this.state.toggler === `type-model-accordian-${index}` ? 'fa-chevron-up' : 'fa-chevron-down'}`}></i>
                                </CardHeader>
                                <Collapse className="collapse-body" isOpen={this.state.toggler === `type-model-accordian-${index}`}>
                                  <CardBody>
                                      {ModelPropertiesParser(accordianConfig.bodyField, data)}
                                  </CardBody>
                                </Collapse>
                              </Card>
                            )
                        })
                    }
                </div>
            )
        }
        return (
            <div className='d-flex'>

            </div>
        );
    }
}

export default TypeModelAccordian;