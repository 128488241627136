import React from 'react';
import { Button } from 'reactstrap';
import RenderIcon from '../commons/RenderIcon';

const LinkButton = (props) => {
    const {
        action: {
            actionProperties: {
                elementText,
                iconConfig,
                buttonConfig,
                displayMode
            }
        },
        triggerAction,
        actionState,
        customIcons
    } = props;
    const _color = buttonConfig && buttonConfig.buttonStyle ? buttonConfig.buttonStyle : 'primary';
    const outline = buttonConfig && buttonConfig.isOutline ? buttonConfig.isOutline : false;
    return (
        <Button disabled={actionState ? actionState.isLoading : false} onClick={() => {
                if(displayMode != 'MODAL') {
                    window && window.scrollTo({ top: 0, behavior: 'smooth' })
                }
                triggerAction();
            }
        } color="link" size="sm">
            {
                iconConfig ? <RenderIcon customIcons={customIcons} config={iconConfig} /> : null
            }
            {elementText}
        </Button>
    );
}

export default LinkButton;