import { fromJS } from 'immutable';
import React from 'react';
import { Badge } from 'reactstrap';
import { setInterval, clearInterval } from 'timers';

class Timer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            timerConfig: {minutes:0, seconds:0},
            interval: null
        }
    }
    componentDidMount() {
		let { timerIndex } = this.props;
        const { itemProperties } = this.props.layoutItem;
        const { timerConfig } = itemProperties;
        const { forceRefresh } = timerConfig;
        this.setState({timerConfig: timerConfig});
        const state = this.state;
        const props = this.props;
        let self = this;
        const interval = setInterval(function() {
            const { timerConfig } = self.state;
            let { minutes, seconds } = timerConfig;
            if(seconds) {
                seconds--;
            } else if(minutes) {
                minutes--;
                seconds = 59;
            } else {
                if(forceRefresh) {
                    props.refreshWF();
                    seconds = parseInt(this.props.layoutItem.itemProperties.timerConfig.seconds);
                    minutes = this.props.layoutItem.itemProperties.timerConfig.minutes ? parseInt(this.props.layoutItem.itemProperties.timerConfig.minutes) : 0;
					props.changeTimerIndex(++timerIndex)
                } else {
                    clearInterval(interval);
                    props.onSubmit(fromJS(props.workflow.workflowData));
                }
            }
            timerConfig.minutes = minutes;
            timerConfig.seconds = seconds;
            self.setState({timerConfig: timerConfig});
        }.bind(this), 1000);
        self.setState({interval});
    }

    componentWillUnmount() {
        this.state.interval && clearInterval(this.state.interval);
    }

    render() {
        const { timerConfig } = this.state;
        if (!timerConfig) {
            return (<div>Not configured</div>)
        }

        const badgeStyle = {
        minWidth: '40px',
        margin: '0 3px'
        }
        const { minutes, seconds, hideTimer } = timerConfig;
        if(hideTimer) return '';
        return (
        <div>
            <div>
                {minutes?
            <Badge style={badgeStyle} color='primary'>Min</Badge>:null
                }
            <Badge style={badgeStyle} color='primary'>Sec</Badge>
            </div>
            <div>
                {
                    minutes?
            <Badge style={badgeStyle} color='primary'>{minutes}</Badge>:null
                }
            <Badge style={badgeStyle} color='primary'>{seconds ? seconds : '00'}</Badge>  
            </div>
        </div>
        )
    }
}

export default Timer;