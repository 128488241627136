import React from 'react';
import { Row, Col } from 'reactstrap';

const Wrapper = (props) => {
    return <Row className={`h-100 wf-item-body`}>
        <Col>
            {props.children}
        </Col>
    </Row>
};

export default Wrapper;