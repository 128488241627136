import React from 'react';
import { Row } from 'reactstrap';
import GenericColumn from './GenericColumn';

const TwoColContainer = (props) => {
    const Layout = props.operationLayout;
    const { index, rowClassName, contStyle } = props;
    return <Row className={rowClassName} style={contStyle}>
        <GenericColumn twoColumn={true} {...props} containerIndex={index} columnIndex={0} isFirst={true}  />
        <GenericColumn twoColumn={true} {...props} containerIndex={index} columnIndex={1} isLast={true} />
    </Row>
}

export default TwoColContainer;