import React from 'react';
import { Jumbotron } from 'reactstrap';
import { Link } from 'react-router-dom';

export default class NUMBER extends React.Component {

  render() {
    const { graphData, textColor, reportCTA,numberConfig,beforeIcon,reportNameFinal,reportPrimaryColor } = this.props;
    
    const numberReportTemplate = numberConfig && numberConfig.numberReportTemplate.value
    const reportBackgroundColor = numberConfig && numberConfig.reportBackgroundColor
    console.log(numberReportTemplate,"numberReportTemplate")

    if(numberReportTemplate == 'template4'){
      return(
        <div >
        <div className='mb-0 pt-2 pb-2 pl-1 pr-1 jumbotron-template3' style={{backgroundColor:"transparent"}}>
        {beforeIcon && 
        <div className='numberReportIconT3Div' >
         <i className={`fa ${beforeIcon.cssClass} numberReportIconT3`} aria-hidden="true"></i>
        </div>
        }
         <div  style={{display:"flex",justifyContent:"center",alignItems:'center',flexDirection:'column'}}>
         <span className='cn-template3-name' >{reportNameFinal}</span>
         <p  style={reportPrimaryColor?{color:reportPrimaryColor}:{}} className={`chart-number cn-template3`}>{graphData && graphData[0]?graphData[0].yProperties:'N/A'}</p>
         </div>
       </div>
      </div>
      )
    }

    if(numberReportTemplate == 'template3'){
      return(
        <div style={{width: '100%' }} >
        {
          reportCTA ? (
            <Link className='report-link' to={`/app${reportCTA}`}>
              <Jumbotron className='mb-0 jumbotron-template2' style={{backgroundColor:"transparent"}}>
                <h1 className={`chart-number ${ textColor && textColor.value? 'text-' + textColor.value:''} cn-template2`}>{graphData && graphData[0]?graphData[0].yProperties:'N/A'}</h1>
              </Jumbotron>
            </Link>
          ) : (
            <Jumbotron className='mb-0 pt-2 pb-2 pl-1 pr-1 jumbotron-template3'  style={{backgroundColor:"transparent"}}>
             {beforeIcon && 
             <div className='numberReportIconT3Div' >
              <i className={`fa ${beforeIcon.cssClass} numberReportIconT3`} aria-hidden="true"></i>
             </div>
             }
              <div>
              <p  style={reportPrimaryColor?{color:reportPrimaryColor}:{}} className={`chart-number cn-template3`}>{graphData && graphData[0]?graphData[0].yProperties:'N/A'}</p>
              <span className='cn-template3-name' >{reportNameFinal}</span>
              </div>
            </Jumbotron>
          )
        }
      </div>
      )
    }

    if(numberReportTemplate == 'template2'){
      return(
        <div style={{width: '100%' }} >
            <Jumbotron className='mb-0 pt-2 pb-2 pl-1 pr-1 jumbotron-template2'  style={{backgroundColor:"transparent"}}>
            <div className='report-circle circle1'></div>
            <div className='report-circle circle2'></div>
           <div className='report-circle circle3'></div>
           <div className='report-circle circle4'></div>
             {beforeIcon && 
             <div>
              <i className={`fa ${beforeIcon.cssClass} cn-template2-icon`} aria-hidden="true"></i>
             </div>
             }
              <div className='template2-content'>
              <h6 className={` ${ textColor && textColor.value? 'text-' + textColor.value:''} cn-template2`}>{graphData && graphData[0]?graphData[0].yProperties:'N/A'}</h6>
               <span className='cn-template2-name' >{reportNameFinal}</span>
              </div>
            </Jumbotron>
       
      </div>
      )
    }
    
    return (
      <div style={{width: '100%'}}>
        {
          reportCTA ? (
            <Link className='report-link' to={`/app${reportCTA}`}>
              <Jumbotron className='mb-0'>
                <h1 className={`chart-number ${ textColor && textColor.value? 'text-' + textColor.value:''}`}>{graphData && graphData[0]?graphData[0].yProperties:'N/A'}</h1>
              </Jumbotron>
            </Link>
          ) : (
            <Jumbotron className='mb-0 pt-2 pb-2 pl-1 pr-1'>
              <h1 className={`chart-number ${ textColor && textColor.value? 'text-' + textColor.value:''}`}>{graphData && graphData[0]?graphData[0].yProperties:'N/A'}</h1>
            </Jumbotron>
          )
        }
      </div>
    );
  }
}
