const CHART_TYPES = {
    LINECHART: {
        label: 'Line Chart',
        code: 'LINECHART',
        icon: 'fa fa-line-chart'
    },
    AREACHART: {
        label: 'Area Chart',
        code: 'AREACHART',
        icon: 'fa fa-area-chart'
    },
    BARCHART: {
        label: 'Bar Chart',
        code: 'BARCHART',
        icon: 'fa fa-bar-chart'
    },
    PIECHART: {
        label: 'Pie Chart',
        code: 'PIECHART',
        icon: 'fa fa-pie-chart'
    },
    TABLE: {
        label: 'Table',
        code: 'TABLE',
        icon: 'fa fa-table'
    },
    NUMBER: {
        label: 'Number',
        code: 'NUMBER',
        icon: 'fa fa-dashboard'
    },
    NUMBERDECIMAL: {
        label: 'Number Decimal',
        code: 'NUMBERDECIMAL',
        icon: 'fa fa-dashboard'
    },
    NUMBERTEXT: {
        label: 'Number Text',
        code: 'NUMBERTEXT',
        icon: 'fa fa-dashboard'
    },
    
}

export default CHART_TYPES;