import React, { Component } from 'react';
import PropTypes from 'prop-types';
import qs from 'query-string';

class ProcessPayment extends Component {

  constructor(props, context) {
    super(props, context);
    this.state = {
      ccavUrl: null,
      orderId: null
    }
  }

  componentDidMount() {
    const { router: { history: { location: { search } } } } = this.context;
    qs.parse(search);
    this.setState({ ccavUrl: qs.parse(search)['pay'], orderId: qs.parse(search)['orderId']})
  }

  notifyParent(orderId) {
    if(window.opener && window.opener.callbacks) {
      window.opener.callbacks['paymentCallback' + orderId]();
    }
  }

  render() {
    const { router: { history } } = this.context;
    if(this.state.ccavUrl) return (
      <center>
        {
          this.state.ccavUrl && <iframe  width="80%" height="1200" scrolling="No" frameBorder="0"  id="paymentFrame" src={this.state.ccavUrl}></iframe>
        }
        <script src="https://ajax.googleapis.com/ajax/libs/jquery/1.10.2/jquery.min.js"></script>
      </center>
    );
    if(this.state.orderId) return (
      <div>
      {
        this.notifyParent(this.state.orderId)
      }
      </div>
    )
    return <div></div>
  }
}

ProcessPayment.contextTypes = {
  router: PropTypes.object
}


export default ProcessPayment;
