import isNumeric from 'validator/lib/isNumeric';

const positiveNumber = value => {
    if (typeof value === 'undefined') return undefined;
    const isNum = isNumeric(value + '');
    const _numVal = Number(value);
    if (!isNum && value && value.length == 0 ) {
        return 'Please enter a valid number';
    }
    if (isNum && _numVal <= 0) {
        return 'Please enter a positive value greater than 0';
    }
    return undefined;
} 
export default positiveNumber;