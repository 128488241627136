export const operation = {
    READALL: 'READALL',
    READONE: 'READONE',
    CREATEUPDATE: 'CREATEUPDATE',
    REPORT: 'REPORT'
}

export const reduxFormKeys = {
    base: 'create-update-form-base',
    modal: 'create-update-form-modal',
    sidebar: 'create-update-form-sidebar',
    inlinePrepend: 'create-update-inline-form-'
}