import isFloat from 'validator/lib/isFloat';

const Decimal = value => {
    if (typeof value === 'undefined') return undefined;
    const isNum = isFloat(value + '');
    const _numVal = Number(value);
    if (!isNum) {
        return 'Please enter a valid number';
    }
    return undefined;
} 
export default Decimal;
