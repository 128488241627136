import React, { Component } from 'react';
import { FormGroup, Label } from 'reactstrap';
import { Field } from 'redux-form/immutable';
import { renderSelect, genericNormalizer, renderMultipleCheckBox } from './_FORM_FIELDS';
import { connect } from 'react-redux';
import { toJS } from '../../containers/to-js';
import { readAll } from '../../actions/workflow-item';
import PrintText from './PrintText';
import { ModelPropertiesParser, getNewContext } from '../commons/modelPropertiesParser';
import { TypeModelDisplays } from '../../dto/type-model-displays';
import Table from './Table/Tablev1';
import { getModelByNamePromise } from '../../actions/workflows';
import TypeModelCreateUpdateTable from './TypeModelHelpers/TypeModelCreateUpdateTable';
import TypeModelBadges from './TypeModelHelpers/TypeModelBadges';
import RenderIcon from '../commons/RenderIcon';
import { Iterable } from 'immutable';
import resolvePath from '../../utils/resolvePath';
import TypeModelCreateUpdateWorkflowCard from './TypeModelHelpers/TypeModelCreateUpdateWorkflowCard';

class TypeModelCreateUpdate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            model: null,
            isInitialValueSet: false,
            callCounter: 0
        }
    }
    componentDidMount() {
        const { getModelByNamePromise,isLayoutItemEditable, customerName, workflow, containerIndex, isBase, layoutItem: { itemProperties: {  parentModelSelector,field: { classProperty, customModelName } } } } = this.props;
        this.setState({callCounter: 1}, () => {
          if(!parentModelSelector ||isLayoutItemEditable ) {
            getModelByNamePromise({ name: customModelName }).then((res) => {
                const model = res.data;
                const typeId = model.id;
                    this.props.readAll({
                        typeId,
                        customerName,
                        workflow: {
                          id: workflow.id,
                          displayMode: workflow.displayMode
                        },
                        isBase,
                        "count":10000,
                        "start":0
                    }, containerIndex);
                this.setState({ model, callCounter: 0 });
            })
          }
        })
        
    }

    componentWillReceiveProps(nextProps) {
      const { getModelByNamePromise, customerName, workflow, containerIndex, isBase, layoutItem: { itemProperties: {dontAllowAdditionDeletion, parentModelSelector, field: { classProperty, customModelName } } } } = nextProps;
      if(nextProps.workflow && this.props.workflow && nextProps.workflow.currentStep != this.props.workflow.currentStep || !nextProps.typeModelCollection) {
        if(!parentModelSelector && !dontAllowAdditionDeletion && this.state.callCounter === 0) {
          this.setState({callCounter: 1}, () => {
            getModelByNamePromise({ name: customModelName }).then((res) => {
              const model = res.data;
              const typeId = model.id;
              this.props.readAll({
                  typeId,
                  customerName,
                  workflow: {
                    id: workflow.id,
                    displayMode: workflow.displayMode
                  },
                  isBase,
                  count: 10000,
                  start: 0,
              }, containerIndex);
              this.setState({ model, callCounter: 0 });
              });
          })
            
        }
      }
    }

    setInitialValue = (initialValues, classProperty, modelSelector, change,hasModifiedClassProperty, replaceClassProperty) => {
      if(!this.state.isInitialValueSet) {
        const actualValue = resolvePath(hasModifiedClassProperty ? replaceClassProperty : classProperty, initialValues);
        if(Array.isArray(actualValue) && actualValue) {
          const values = actualValue;
          const initValues = initialValues[hasModifiedClassProperty ? replaceClassProperty : classProperty];
          initValues  && initValues.map((item, itemIndex) => {
            if(!item.label) {
              values[itemIndex] = {
                label: ModelPropertiesParser(modelSelector, item),
                value: item.id,
                ...item
              }
            } else {
              values[itemIndex] = item;
            }
          })
          this.setState({
            isInitialValueSet: true
          }, () => {
            change(hasModifiedClassProperty ? replaceClassProperty : classProperty, values);
          })
        } else if(actualValue) {
          
          const value = {
            value: actualValue.id,
            label: ModelPropertiesParser(modelSelector, actualValue),
            ...actualValue
          }
          this.setState({
            isInitialValueSet: true
          }, () => {
            try {
              change(hasModifiedClassProperty ? replaceClassProperty : classProperty, value);
            }
            catch(ex) {
              console.log('ex..',ex);
            }
          })

        }
      }
      
    }

    render() {
        const { layoutItem, initialValues,isLayoutItemEditable, validations, readAll, isEditing, customerName, change, value, isReadOne, workflowData,customIcons, ...rest } = this.props;
        let { typeModelCollection, itemClassPropertyFromTable } = this.props;
        const { itemProperties } = layoutItem;
        const showAsRadioButton = itemProperties && itemProperties.showAsRadioButton
        const optionDisplayType = itemProperties && itemProperties.optionDisplayType ? itemProperties.optionDisplayType.value : null;
        let { modelSelector, typeModelViewMode, badgeType, placeholder, label, tableConfig, field: { collection, classProperty, properties }, replaceClassProperty } = itemProperties
        let TypeCollectionData = null;
        if(itemProperties.parentModelSelector && !isLayoutItemEditable) {
            let data = this.props.formValues?this.props.formValues:workflowData;
            if(!data) {
                data = workflowData?workflowData:this.props.formValues;
                if(data == null)
                    return null;
            }
            const wData = ModelPropertiesParser(itemProperties.parentModelSelector,data);

            if(wData)
              TypeCollectionData = { data: JSON.parse(wData)};
        }  else {
            const { model } = this.state;
            if(!typeModelCollection && model && workflowData && workflowData[classProperty]) {
              const typeId = model.id;
              typeModelCollection = {};
              typeModelCollection[typeId] = workflowData[classProperty]
            }
            if (!layoutItem || !model || !typeModelCollection) return null;
            
            const typeId = model.id;
            TypeCollectionData = typeModelCollection;
        }
        if(!TypeCollectionData) return null;
        if (TypeCollectionData && TypeCollectionData.isLoading === true) return <p className='text-muted'>Loading...</p>;
        const placeholderText = placeholder.show ? placeholder.text : '';
        if (!typeModelViewMode || isEditing) {
            typeModelViewMode = TypeModelDisplays.DROPDOWN;
        }
        const hasModifiedClassProperty = replaceClassProperty && typeof replaceClassProperty === 'string' && replaceClassProperty !== '' ? true : false;
        typeModelCollection = TypeCollectionData.data;
        if(itemProperties.filterField && itemProperties.filterValueField) {
            let data = this.props.formValues?this.props.formValues:workflowData;
            const filterData = ModelPropertiesParser(itemProperties.filterValueField,data);
            let filteredCollection = [];
            typeModelCollection && typeModelCollection.forEach(x => {
                const currentFilterData = ModelPropertiesParser(itemProperties.filterField,x);
                if(currentFilterData == filterData)
                    filteredCollection.push(x);
            });
            typeModelCollection = filteredCollection;
        } 
        if (typeModelViewMode.value === TypeModelDisplays.DROPDOWN.value || typeModelViewMode.value === TypeModelDisplays.CHECKBOX.value) {
            const selectOptions = typeModelCollection && typeModelCollection.map((item) => {
                return {
                    label: ModelPropertiesParser(modelSelector, item),
                    value: item.id,
                    ...item
                }
            });
            this.setInitialValue(initialValues, classProperty, modelSelector, change, hasModifiedClassProperty, replaceClassProperty);
            return (
                    typeModelViewMode.value === TypeModelDisplays.DROPDOWN.value?
                <Field
                    name={hasModifiedClassProperty ? replaceClassProperty : classProperty}
                    component={renderSelect}
                    type="select"
                    validate={validations}
                    fieldType='TYPE'
                    valueKey='value'
                    labelKey='label'
                    options={selectOptions}
                    showAsRadioButton={showAsRadioButton}
                    optionDisplayType={optionDisplayType}
                    properties={properties}
                    initialValues={initialValues}
                    change={change}
                    {...rest}
                    multiple={collection}
                    customModelSelector={modelSelector}
                />:<div className="type-model-collection-checkbox-container d-flex">
                {
                    layoutItem && layoutItem.itemProperties && layoutItem.itemProperties.beforeIcon?
                    <span className="mr-2"><RenderIcon config={layoutItem.itemProperties.beforeIcon}  customIcons={customIcons} /></span>:null
                }
                <Field
                name={classProperty}
                component={renderMultipleCheckBox}
                type="checkbox"
                validate={validations}
                fieldType='checkbox'
                valueKey='id'
                labelKey='label'
                options={selectOptions}
                customIcons={customIcons}
                properties={properties}
                initialValues={initialValues}
                layoutItem={layoutItem}
                {...rest}
                multiple={collection}
                customModelSelector={modelSelector}
            /></div>
            )
        }
        if (typeModelViewMode.value === TypeModelDisplays.TABLE.value) {
          const rows = hasModifiedClassProperty ? getNewContext(workflowData, replaceClassProperty) : workflowData ? workflowData[classProperty] : [];
            return <TypeModelCreateUpdateTable 
                label={label}
                workflow={this.props.workflow}
                rows={rows? rows : []}
                columns={tableConfig.config.columns}
                layoutItem={layoutItem}
                validations={validations}
                {...this.props}
            />
        }

        if (typeModelViewMode.value === TypeModelDisplays.BADGES.value) {
            return <TypeModelBadges 
                label={label}
                workflow={this.props.workflow}
                workflowData={workflowData}
                badgeType={badgeType}
                layoutItem={layoutItem}
                modelSelector={modelSelector}
            />
        }
        if(typeModelViewMode.value === TypeModelDisplays.WORKFLOWCARD.value) {
          const rows = hasModifiedClassProperty ? getNewContext(workflowData, replaceClassProperty) : workflowData ? workflowData[classProperty] : [];
          return (
            <TypeModelCreateUpdateWorkflowCard 
              rows={rows}
              classProperty={this.props.classProperty}
              readOneWorkflowUniqueCode={itemProperties.readOneWorkflowUniqueCode}
            />
          )
        }
    }
}

const mapStateToProps = (state, ownProps) => {
    const { workflow, containerIndex, layoutItem: { itemProperties: { field: { classProperty, customModelName, customTypeId } } } } = ownProps;
    const newState = state.toJS();
    const typeModelId = customTypeId
    return {
        typeModelCollection: typeof workflow.collections !== 'undefined' && workflow.collections !== null ? workflow.collections[typeModelId + `_c${containerIndex}`] : null,//[`${typeId}`] : null,
        customerName: workflow.currentItem.typeModel.customerName
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        readAll: (payload, containerId) => {
            dispatch(readAll(payload, 'c' + containerId));
        },
        getModelByNamePromise: (payload) => {
            return dispatch(getModelByNamePromise(payload));
        }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(toJS(TypeModelCreateUpdate));

