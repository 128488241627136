import React from 'react';
import NavWorkflowsContainer from '../../containers/NavWorkflowsContainer';

class MobileMenuItems extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      activeLevelOneNav: 0
    }
  }

  resolveIsActive = (match, location) => {
    if (!match || !location) {
        return false
    }
    const { path } = match;
    const { pathname } = location;
    if (pathname === path) {
        return true;
    }
    return false;
  }

  setActiveLevelOneNav = (navIndex) => {
    this.setState({ activeLevelOneNav: navIndex });
  }

  render() {
    const { isActive, isPublic, baseWorkflowRoute } = this.props;
    return (
      <div className="mobile-menu-items">
          <div className={`menu-overlay ${isActive ? 'active' : ''}`}>
            {
              isActive ? <LevelOneMenu 
                isPublic={isPublic}
                resolveIsActive={this.resolveIsActive}
                activeLevelOneNav={this.state.activeLevelOneNav}
                setActiveLevelOneNav={this.setActiveLevelOneNav}
                logout={this.props.logout}
                baseWorkflowRoute={baseWorkflowRoute}
              /> : ''
            }
          </div>
      </div>
    )
  }
}

const LevelOneMenu = (props) => {
  return (
      <ul className={`level-one-menu ${props.activeLevelOneNav > 0 ? 'level-two-active': ''}`}>
        <li className="level-one-menu-item">
          <a className="level-one-menu-item-link" onClick={() => props.setActiveLevelOneNav(1)}>
            Start Here
            <span className="icon-container">
              <i className='fa fa-chevron-right'></i>
            </span>
          </a>
          <LevelTwoMenu activeLevelOneNav={props.activeLevelOneNav} navIndex={1} setActiveLevelOneNav={props.setActiveLevelOneNav}>
            <NavWorkflowsContainer baseWorkflowRoute={props.baseWorkflowRoute} isPublic={props.isPublic} resolveIsActive={props.resolveIsActive} />
          </LevelTwoMenu>
        </li>
        {
          props.isPublic ? <li className="level-one-menu-item">
              <a href="/app/login" className="level-one-menu-item-link">
                Already registered? Login here.
              </a>
            </li> : <div>
            <li className="level-one-menu-item">
              <a className="level-one-menu-item-link" onClick={() => props.setActiveLevelOneNav(2)}>
                Account
                <span className="icon-container">
                  <i className='fa fa-chevron-right'></i>
                </span>
              </a>
              <LevelTwoMenu activeLevelOneNav={props.activeLevelOneNav} navIndex={2} setActiveLevelOneNav={props.setActiveLevelOneNav}>
                <ul>
                  <li className="level-one-menu-item">
                    <a className="level-one-menu-item-link" href="/profile" >
                      <span className="left-icon-container">
                        <i className='fa fa-user'></i>
                      </span>
                      Profile
                    </a>
                  </li>
                  <li className="level-one-menu-item">
                    <a className="level-one-menu-item-link" onClick={() => props.logout()} >
                      <span className="left-icon-container">
                        <i className='fa fa-sign-out'></i>
                      </span>
                      SignOut
                    </a>
                  </li>
                </ul>
              </LevelTwoMenu>
            </li>
          </div>
        }
      </ul>
  )
}

const LevelTwoMenu = (props) => {
  return (
    <div className={`level-two-menu ${props.activeLevelOneNav === props.navIndex ? 'active': ''}`}>
      <ul>
        <li className="level-one-menu-item">
          <a className="level-one-menu-item-link" onClick={() => props.setActiveLevelOneNav(0)} >
            <span className="left-icon-container">
              <i className='fa fa-chevron-left'></i>
            </span>
            Back
          </a>
        </li>
        {props.children}
      </ul>
    </div>
  );
}

export default MobileMenuItems;