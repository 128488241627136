import React, { Component } from 'react';
import { Button, ButtonGroup, UncontrolledTooltip } from 'reactstrap';
import ActionContainer from '../../../../containers/workflow/ActionContainer';
import EndWorkflowButton from '../../../commons/EndWorkflowButton';
import { ModelPropertiesParser } from '../../../commons/modelPropertiesParser';

class ReadAllHeader extends Component {
    componentDidMount() {
        const { changeView, containerIndex, workflow: { currentItem: { layoutProperties: { readAllViews } } } } = this.props;
        const { table, card, keyed, accordian, workflowCard } = readAllViews[containerIndex] || readAllViews;
        if(changeView) {
            if (table && table.isConfigured) {
                changeView('TABLE');
            }
            else if (card && card.isConfigured) {
                changeView('CARD');
            }
            else if (keyed && keyed.isConfigured) {
                changeView('KEYED');
            }
            else if (accordian && accordian.isConfigured) {
                changeView('ACCORDIAN');
            }
            else if (workflowCard && workflowCard.isConfigured) {
                changeView('WORKFLOWCARD');
            }
        }
    }
    getShowSelectorViewFlag(table, card, keyed, accordian, workflowCard) {
        let shouldShow = false;
        if (table && table.isConfigured && card && card.isConfigured) {
            shouldShow = true;
        }
        if (table && table.isConfigured && workflowCard && workflowCard.isConfigured) {
            shouldShow = true;
        }
        if (table && table.isConfigured && keyed && keyed.isConfigured) {
            shouldShow = true;
        }
        if (card && card.isConfigured && keyed && keyed.isConfigured) {
            shouldShow = true;
        }
        if (card && card.isConfigured && keyed && keyed.isConfigured) {
            shouldShow = true;
        }
        if (accordian && accordian.isConfigured && workflowCard && workflowCard.isConfigured) {
            shouldShow = true;
        }
        return shouldShow;
    }
    render() {
        const { showOnlyActions, showOnlySwitch, workflow, saveAsPDF, workflow: { currentItem, parentSession, displayMode }, getInlineActions, changeView, viewMode, workflowData, containerIndex, showTitle } = this.props;
        const { name, layoutProperties: { pageHeaderConfig, readAllViews }, allowDownloadAsPdf } = currentItem;
        const { table, card, keyed, accordian, workflowCard } = readAllViews[containerIndex + ''] || readAllViews;
        const actions = getInlineActions? getInlineActions(currentItem, containerIndex): null;
        const delay = {
            show: 500
        }
        const shouldShowViewSelector = !showOnlyActions && this.getShowSelectorViewFlag(table, card, keyed, accordian, workflowCard);
        let keyedIcon = 'fa fa-th-large';
        if (keyed && keyed.isConfigured && (keyed.config.laneEnumerator.type === 'DATE' || keyed.config.laneEnumerator.type === 'DATETIME')) {
            keyedIcon = 'fa fa-calendar';
        }
        const headerText = pageHeaderConfig && pageHeaderConfig.isConfigured ? pageHeaderConfig.modelSelector : name;
        return (
            <div className='wf-item-header d-flex' >
                {
                showTitle?<div className='heading' >
                    <div className='d-flex align-items-center'>
                        {
                            parentSession && displayMode === 'DEFAULT' ? <div className='mb-2'>
                                <EndWorkflowButton iconClass='fa fa-long-arrow-left' className='header-endworkflow-button text-muted' />
                            </div> : ''
                        }
                        {
                            showTitle && (
                                <h5 className='text'>{
                                    pageHeaderConfig && pageHeaderConfig.headerIcon? <i className={`fa ${pageHeaderConfig.headerIcon.cssClass} mr-1`} aria-hidden="true"></i>:''
                                }{headerText}</h5>
                            )
                        }
                    </div>
                </div>:null
                }
                <div className='d-flex' style={{ flexGrow: 1, justifyContent: 'flex-end' }}>
                    <div className='actions mr-1'>
                        {
                            !showOnlySwitch && actions && actions.globals.length > 0  ? <RenderActions workflow={workflow} actions={actions.globals} workflowData={workflowData} /> : ''
                        }
                    </div>
                    {
                        shouldShowViewSelector ? <div className='view-mode-selector d-flex'>
                            <ButtonGroup>
                                {
                                    keyed && keyed.isConfigured ? <Button id='switch-to-keyed' onClick={changeView.bind(this, 'KEYED')} color='primary' outline={viewMode !== 'KEYED'} size="sm">
                                        <i className={keyedIcon} aria-hidden="true"></i>

                                    </Button> : ''
                                }
                                {
                                    table && table.isConfigured ? <Button id='switch-to-table' onClick={changeView.bind(this, 'TABLE')} color='primary' outline={viewMode !== 'TABLE'} size="sm">
                                        <i className="fa fa-th-list" aria-hidden="true"></i>

                                    </Button> : ''
                                }
                                {
                                    card && card.isConfigured ? <Button id='switch-to-card' onClick={changeView.bind(this, 'CARD')} color='primary' outline={viewMode !== 'CARD'} size="sm">
                                        <i className="fa fa-th-large" aria-hidden="true"></i>

                                    </Button> : ''
                                }
                                {
                                    accordian && accordian.isConfigured ? <Button id='switch-to-card' onClick={changeView.bind(this, 'ACCORDIAN')} color='primary' outline={viewMode !== 'ACCORDIAN'} size="sm">
                                        <i className="fa fa-th-large" aria-hidden="true"></i>

                                    </Button> : ''
                                }
                                {
                                    workflowCard && workflowCard.isConfigured ? <Button id='switch-to-card' onClick={changeView.bind(this, 'WORKFLOWCARD')} color='primary' outline={viewMode !== 'WORKFLOWCARD'} size="sm">
                                        <i className="fa fa-th-large" aria-hidden="true"></i>

                                    </Button> : ''
                                }
                            </ButtonGroup>
                        </div> : ''
                    }
                    {
                        /*allowDownloadAsPdf ? <div className='view-mode-selector d-flex'>
                            <Button onClick={saveAsPDF} className="ml-1" title="Download As PDF" color='success' size="sm">
                                <i className="fa fa-file-pdf-o" aria-hidden="true"></i>
                            </Button>
                            <Button className="ml-1" title="Download As XLS" color='danger' size="sm">
                                <i className="fa fa-download" aria-hidden="true"></i>
                            </Button>
                    </div> : ''*/
                    }
                </div>
            </div>
        );
    }
}

const RenderActions = (props) => {
    const { actions, workflow } = props;
    return <div className='d-flex'>
        {
            actions.map((action, index) => {
                const { actionProperties } = action;
                if(actionProperties && actionProperties.actionDisabled) {
                    return null;
                }
                return <div key={index} className='mr-1'>
                    <ActionContainer {...props} action={action} />
                </div>
            })
        }
    </div>
}

export default ReadAllHeader;