import React from 'react';
import { ResponsiveContainer,reportPrimaryColor, LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip, Legend, Label } from 'recharts';

export default class LINECHART extends React.Component {
  render() {
    const { graphData, colors, legendName,reportPrimaryColor, xLabel, yLabel, graphHeight, series } = this.props;
    return (
      <div style={{width: '100%'}} className='reportMainContainer'>
        <ResponsiveContainer height={graphHeight}>
          <LineChart data={graphData}  margin={{ top: 0, right: 10, left: 10, bottom: 20 }}>
            <XAxis dataKey="xProperties">
              <Label value={xLabel} offset={0} position="bottom" />
            </XAxis>
            <YAxis  label={{ value: yLabel, angle: -90, position:'inside', offset: -15,dx:40, }} />
            <CartesianGrid strokeDasharray="3 3" />
            <Tooltip />
            {
              series?
              series.map((y, index) => {
                return <Line name={y.legendName} type='monotone' dataKey={y.yPropertyName} stroke={colors[index]} fill={colors[index]} />
              }):
              <Line name={legendName} type='monotone' dataKey='yProperties' stroke={reportPrimaryColor?reportPrimaryColor:'#8884d8'} fill={reportPrimaryColor?reportPrimaryColor:'#8884d8'} />
            }
            <Legend verticalAlign="top" height={36} />
          </LineChart>
        </ResponsiveContainer>
      </div>
    );
  }
}
