import React from 'react';
import moment from 'moment';

const Timeline = props => {
  const { graphData, timelineconfig } = props;
  if(!timelineconfig) return null;
  const dateField = timelineconfig && timelineconfig.date && timelineconfig.date.value;
  const titleField = timelineconfig && timelineconfig.title && timelineconfig.title.value;
  const descriptionTextField = timelineconfig && timelineconfig.descriptionText && timelineconfig.descriptionText.value;
  const descriptionImageField = timelineconfig && timelineconfig.descriptionImage && timelineconfig.descriptionImage.value;

  return (
    <div className='timeline'>
      {
        Array.isArray(graphData) && graphData.map((item, index) => {
          const date = dateField && moment(item.tableData[dateField]);
          return (
            <div key={index} className='timeline-item'>
              <div className='date'>{date && date.format("MMM DD YYYY")}</div>
              <div className='circle'><div className='dot'></div></div>
              <div className='details'>
                <div className='title'>{titleField && item.tableData[titleField]}</div>
                <div className='description'>{descriptionTextField && item.tableData[descriptionTextField]}</div>
                {
                  item.tableData[descriptionImageField] && <img height={200} width={200} src={item.tableData[descriptionImageField]} />
                }
                
              </div>
            </div>
          )
        })
      }
    </div>
  )
}

export default Timeline;