import { connect } from 'react-redux';
import ReadAll from '../../../components/workflow/operations/ReadAll';
import { getTranslate } from 'react-localize-redux';
import { toJS } from '../../to-js';
import { readAll, updatePayload, save, readAllData } from '../../../actions/workflow-item';
import { triggerAction } from '../../../actions/workflow-actions';
import { setSortAndPagination, getModelByNamePromise } from '../../../actions/workflows';

const mapStateToProps = (state, ownProps) => {
    const { workflow, containerIndex, container, columnIndex } = ownProps;
    const workflowId = workflow.workflowModel;
    const workflowItem = workflow.currentItem;
	const alternateTypeModel = container.properties.alternateTypeModel;
	const columnLayoutModel = container.properties.colLayoutConfig && container.properties.colLayoutConfig[`columnLayoutModel-${columnIndex}`];
    const typeModelId = columnLayoutModel ? columnLayoutModel.id : 
                        (alternateTypeModel ? alternateTypeModel.id : workflowItem.typeModel.id);
    const containerId = `_c${containerIndex}`;
    const collection = typeof workflow.collections !== 'undefined' && workflow.collections !== null ? workflow.collections[typeModelId + containerId] : null;
	const sortPagination = state.getIn(['workflow', 'sortPagination']);
	
    return {
        translate: getTranslate(state.get('locale')),
        collection,
        workflowItem,
        workflowId: ownProps.workflowId,
        workflow,
        saveResponse: state.get('save'),
        user: state.getIn(['user', 'user']),
		companyDetails: state.getIn(['company', 'details']),
		sortPagination: sortPagination,
        columnLayoutModel
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        readAll: (payload) => {
            const { containerIndex } = ownProps;
            dispatch(readAll(payload, 'c' + containerIndex));
        },
        triggerFilterReadAll: (payload) => {
            const { containerIndex } = ownProps;
            dispatch(readAll(payload, 'c' + containerIndex));
        },
        updatePayload: (payload) => {
            dispatch(updatePayload(payload));
        },
        triggerAction(action, tid, workflow) {
            dispatch(triggerAction(action, tid, workflow));
        },
        save: (values) => {
            const { workflow: { currentItem: { typeModel: { safeName, customerName } } } } = ownProps;
            const info = {
                typeModelName: safeName,
                customerName
            };
            dispatch(save(values ? values : `${ownProps.workflowId}`, info));
        },
		setSortAndPagination: (payload) => {
            dispatch(setSortAndPagination(payload));
        },
		getModelByNamePromise: (payload) => {
            return dispatch(getModelByNamePromise(payload));
        },
        readAllData: (payload) => {
            const { containerIndex } = ownProps;
            return dispatch(readAllData(payload, 'c' + containerIndex));
        }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(toJS(ReadAll));