import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Input, Col } from 'reactstrap';
import Autocomplete from 'react-autocomplete';
import FilterMethods from '../../../../../dto/api-filters';

const sortItems = (a, b, value) => {
    const aLower = a.filterValue.toLowerCase()
    const bLower = b.filterValue.toLowerCase()
    const valueLower = value.toLowerCase()
    const queryPosA = aLower.indexOf(valueLower)
    const queryPosB = bLower.indexOf(valueLower)
    if (queryPosA !== queryPosB) {
        return queryPosA - queryPosB
    }
    return aLower < bLower ? -1 : 1
}

const shouldItemRender = (state, value) => {
    return (
        state.filterValue.toLowerCase().indexOf(value.toLowerCase()) !== -1
    )
}

class TextFilter extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: props.value || '',
            allValues: []
        }
    }
	
	componentWillReceiveProps(nextProps) {
		const { filterStateMap, filterIndex } = nextProps;
        const savedFilterState = filterStateMap[`filter_${filterIndex}`];
		if(!savedFilterState) {
			this.setState({
				value: ''
			})
		}
		
	}
	
    triggerFilterChange(filterVal) {
        const { onFilterChange, filter, filterIndex } = this.props;
		const value = filterVal.trim();
		this.setState({ value });
		if(value !== "") {
			const filterConfig = {
				filter,
				filterIndex
			}
			onFilterChange(filterConfig, [value]);
		}
        
    }
    onChange(e) {
        const { getSuggestions } = this.props;
        const filterString = e.target.value;
        this.triggerFilterChange(filterString);
        if(!this.props.filter.isSearch) {
            getSuggestions().then((res) => {
                this.setState({ allValues: res.data });
            }).catch((ex) => {
                this.setState({ allValues: [] });
            })
        }
    }
    onSelected(value) {
        this.triggerFilterChange(value);
    }
    render() {
        const { elem_id, filter: { targetClassProperty } } = this.props;
        const { allValues, value } = this.state;
        return (
            <Col xs="12" className="p-0">
                <Autocomplete
                    name={elem_id}
                    id={elem_id}
                    getItemValue={(item) => item.filterValue}
                    items={allValues}
                    renderItem={(item, isHighlighted) =>
                        <div key={item.filterValue} className={`autocomplete-list-item ${isHighlighted ? 'is-highlighted' : ''}`}>
                            {item.filterValue}
                        </div>
                    }
                    sortItems={sortItems}
                    shouldItemRender={shouldItemRender}
                    value={value}
                    onChange={this.onChange.bind(this)}
                    onSelect={this.onSelected.bind(this)}
                    renderInput={(props) => <input className='form-control' placeholder='Type to see suggestions' {...props} />}
                    renderMenu={(items, value, style) => <div className='autocomplete-menu-wrapper' style={{ ...style, ...this.menuStyle }} children={items} />}
                    wrapperStyle={{
                        width: '100%'
                    }}
                />
            </Col>
        );
    }
}

TextFilter.propTypes = {
    field: PropTypes.object,
    elem_id: PropTypes.string
};

export default TextFilter;