import React, { Component } from 'react';
import Sidebar from 'react-sidebar';
import PropTypes from 'prop-types';
import Items from './items/side-menu-items';
import { Button } from 'reactstrap';
import SidebarWorkflowMenuItemsContainer from '../../containers/SidebarWorkflowMenuItemsContainer';
import RenderCompanyLogo from '../commons/RenderCompanyLogo';
import Avatar from 'react-avatar';
import SingleLine from '../commons/user/SingleLine';
import moment from 'moment';

const week = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

class SidebarMenuCollapsed extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSideMenuActive: true,
    }
  }
  toggleSideMenu = () => {
    this.setState({ isSideMenuActive: !this.state.isSideMenuActive });
  }
  getSidebarContent() {
    const { user, isPublic, baseWorkflowRoute, companyDetails: { data } } = this.props;
    const LogoLink = user && user.user && user.user.email ? '/app/' : '/';
    const userInfo = user && user.user;
    let fullName = null;
    if (userInfo && !userInfo.lastName){
      fullName = `${userInfo.firstName}`
    }
    if(userInfo && userInfo.lastName) {
      fullName = `${userInfo.firstName} ${userInfo.lastName}`;
    }

    const date = moment()
	const dateformat = data && data != null ? data.companySettings.dateformat : '';
    return <div> 
      <div>
       <a onClick={this.toggleSideMenu}>   <i style={{color:"#000",margin:10,cursor:"pointer"}} className={` ${this.state.isSideMenuActive ? 'fa fa-bars' : 'fa fa-times'}`}></i>  </a>
      </div>
  { !this.state.isSideMenuActive  &&   <div>
      
      <div className='main-sidebar-header'>
       
        <a href={LogoLink} className="company-logo-container" >
          <RenderCompanyLogo />
        </a>
      </div>
      {
        !isPublic && userInfo && (
          <div className="user-info">
            <SingleLine avatarSize={50} fullName={fullName} email={userInfo.role} {...this.props}>
              <Avatar
                round={true}
                size={50}
                name={fullName}
                email={userInfo.email}
              />
            </SingleLine>
          </div>
        )
      }
      <div className="drawerDateTime"><span id="clockbox">{week[date.day()]}, &ensp; {dateformat ? date.format(dateformat) : date.format("DD-MM-YYYY hh:mm")}</span></div>
      <div className='main-sidebar-navigation'>
        <SidebarWorkflowMenuItemsContainer isPublic={isPublic} data={data} logout={this.props.logout} baseWorkflowRoute={baseWorkflowRoute}/>
      </div>
    </div>}
    </div>
  }
  render() {
    const sidebarContent = this.getSidebarContent();
    const styles = {
      root: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        overflow: 'hidden'
      },
      sidebar: {
        width: 220,
        zIndex: 2,
        position: 'absolute',
        top: 0,
        bottom: 0,
        transition: 'transform .3s ease-out',
        WebkitTransition: '-webkit-transform .3s ease-out',
        willChange: 'transform',
        overflowY: 'auto',
      },
      content: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        overflowY: 'scroll',
        WebkitOverflowScrolling: 'touch',
        transition: 'left .3s ease-out, right .3s ease-out'
      },
      overlay: {
        zIndex: 1,
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        opacity: 0,
        visibility: 'hidden',
        transition: 'opacity .3s ease-out, visibility .3s ease-out',
        backgroundColor: 'rgba(0,0,0,.3)'
      },
      dragHandle: {
        zIndex: 1,
        position: 'fixed',
        top: 0,
        bottom: 0
      },
    };
    return <Sidebar rootClassName='sidebar-root' sidebarClassName={` ${this.state.isSideMenuActive ? 'sidebar-0' : 'sidebar-main'}`}contentClassName='sidebar-content' style={styles} sidebar={sidebarContent} docked={true}>
      {
        <div>{this.props.children}</div>
      }
    </Sidebar>
  }
}

SidebarMenuCollapsed.propTypes = {
  translate: PropTypes.func,
  setMenuItem: PropTypes.func
}

export default SidebarMenuCollapsed;
