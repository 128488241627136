import React from 'react';
import PrintText from './PrintText';
import { Field } from 'redux-form/immutable';
import { renderField } from './_FORM_FIELDS';
import RichhText from './RichhText';
import { convertFromRaw } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import { ModelPropertiesParser } from '../commons/modelPropertiesParser';
import JSONPath from 'jsonpath';
import { FormText, FormFeedback } from 'reactstrap';
import { 
    colorStyleMap,
    entityStyleFn 
} from '../commons/RichTextResolverUtils';
import EmailTemplate from './EmailTemplate';

const setStaticContent = (classProperty, change, input , workflowData, validationFun, error, rawContent ) => {
    const contentState = convertFromRaw(rawContent);
    const plainText = contentState.getPlainText();
    let options = {
        inlineStyles: { ...colorStyleMap },
        entityStyleFn: entityStyleFn.bind(this, workflowData)
    };
    const __HTML = stateToHTML(contentState, options);
    const largeTextContent = JSON.stringify(rawContent) + "***|||***" + __HTML + "***|||***" + plainText;
	if(validationFun){
		input.onChange(plainText);
	} 
	if(!error && plainText && plainText.length >= 0 ){
		change(classProperty, largeTextContent);
	} 
}

const renderRichTextEditor = (field)  => {
	const { meta: { touched, error }} = field;
	const _saved_value = field.workflowData ? JSONPath.value(field.workflowData, `$.${field.classProperty}`) : null;
    let savedData = null;
    let isPlainText = false;
    if (_saved_value && typeof _saved_value === 'string') {
        try {
            const rawContent = _saved_value.split("***|||***");
            savedData = JSON.parse(rawContent[0]);
        }
        catch(ex) {
            savedData = _saved_value;
            isPlainText = true;
        }
    }
    
   return (
	<div>
      <RichhText 
                        setStaticContent={setStaticContent.bind(this, field.classProperty, field.change, field.input, field.workflowData, field.validationFun, error)} 
                        savedData={savedData}
                        collapsableUIStyle={field && field.collapsableUIStyle}
                        isPlainText={isPlainText}
                        placeholder={field.useLabelAsPlaceholder ? field.label.text : field.placeholder.text}
						{...field.input}
                    />
					{
            touched && error ? <FormFeedback className='animated fadeIn d-block'>
                <i className="fa fa-exclamation-triangle" aria-hidden="true"></i> {error}
            </FormFeedback> : ''
        }
		</div>
   );
}

const LargeText = (props) => {
    const { 
        collection, 
        workflow: { workflowData }, 
        classProperty, 
        type, 
        validations, 
        value, 
        isReadOne, 
        ...rest 
    } = props;
	const { layoutItem: { itemProperties: { field: { properties }, showAsEmailTemplate, valueHolders } } } = props;    
    const collapsableUIStyle =  props.layoutItem.itemProperties.richtextConfig && props.layoutItem.itemProperties.richtextConfig.collapsableStyleUI
    if(showAsEmailTemplate) {
        return <EmailTemplate {...props} />
    }
    return (
        <div>
            {
                isReadOne ?
                    <PrintText {...props} /> :
					<div>
                            <Field
                                name={classProperty}
                                component={renderRichTextEditor}
                                collapsableUIStyle={collapsableUIStyle}
                                validate={validations}
                                value={value}
                                fieldType={type}
								classProperty={classProperty}
								workflowData={workflowData}
								validationFun={validations}
                                {...rest}
                            />
                            <FormText>{properties.validationMessage}</FormText>
                        </div>
            }
        </div>
    );
}

export default LargeText;