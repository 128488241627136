import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { connect } from 'react-redux';
import { toJS } from '../../../containers/to-js';
import { toggleActionModal, addSourceId, removeSourceId, processAssignValueActionFinal } from '../../../actions/workflow-actions';
import AssignValueTable from './AssignValueTable';

class AssignValueModal extends Component {
    render() {
        const { title, body, actionButtonText, children, show, toggleActionModal, action, data, addSourceId, removeSourceId, processAssignValueActionFinal, workflow } = this.props;
        const workflowId = workflow.workflowModel;
        if (!action) return null;
        return (
            <Modal size="lg" isOpen={show} toggle={toggleActionModal.bind(this, { show: false })} className={`${this.props.className} player-action-assign-value-modal`}>
                <ModalHeader tag="h5" toggle={this.toggle}>{title}</ModalHeader>
                <ModalBody className='p-0'>
                    <AssignValueTable 
                        rows={data} 
                        columns={action.actionProperties.columns} 
                        workflowId={workflowId}
                        addSourceId={addSourceId} 
                        removeSourceId={removeSourceId}
                        workflow={workflow}
                    />
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={processAssignValueActionFinal}>{actionButtonText || 'Save and Close'}</Button>
                    <Button color="link" onClick={toggleActionModal.bind(this, { show: false })}>Cancel</Button>
                </ModalFooter>
            </Modal>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        show: state.getIn(['assignValueModal', 'show']),
        title: state.getIn(['assignValueModal', 'title']),
        body: state.getIn(['assignValueModal', 'body']),
        actionButtonText: state.getIn(['assignValueModal', 'actionButtonText']),
        action: state.getIn(['assignValueModal', 'action']),
        data: state.getIn(['assignValueModal', 'data'])
    }
}

const mapDistachToProps = (dispatch, ownProps) => {
    return {
        toggleActionModal: (payload) => {
            dispatch(toggleActionModal(payload));
        },
        addSourceId: (payload) => {
            dispatch(addSourceId(payload));
        },
        removeSourceId: (payload) => {
            dispatch(removeSourceId(payload));
        },
        processAssignValueActionFinal: () => {
            dispatch(processAssignValueActionFinal(ownProps.workflowId));
        }
    }
}

export default connect(mapStateToProps, mapDistachToProps)(toJS(AssignValueModal));