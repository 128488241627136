import JSONPath from 'jsonpath';
import { analyseEvaluatedText } from './evaluatedTextVerfier';
import moment from 'moment';
import config from '../../config'
const delimiter = ', ';

const print = (context, path, strings) => {
  if (!context) return strings;
  const val = JSONPath.value(context, `$.${path}`);
  if (val !== null && typeof val === 'string') {
    strings = strings.push(val);
  }
  if (typeof val === 'object') {
    strings = strings.push(JSON.stringify(val));
  }
  if (val !== null && typeof val === 'number') {
    strings = strings.push(val);
  }
  if (val !== null && typeof val === 'boolean') {
    strings = strings.push(JSON.stringify(val));
  }

  return strings;
};

export const readPath = (context, matched) => {
  let strings = [];
  if (matched.indexOf('.') !== -1) {
    let keys = matched.split('.');
    if (!context) return strings;
    const childContext = context[keys[0].replace(' ', '_')];
    keys = keys.slice(1);
    if (Array.isArray(childContext)) {
      for (let i = 0; i < childContext.length; i += 1) {
        let _matched = null;
        if (keys.length > 1) {
          _matched = keys.join('.');
        } else {
          _matched = keys[0];
        }
        const val = readPath(childContext[i], _matched);
        if (val !== null && typeof val !== 'undefined') {
          strings = strings.concat(val);
        }
      }
    } else {
      const val = readPath(childContext, keys.join('.'));
      if (val !== null && typeof val !== 'undefined') {
        strings = strings.concat(val);
      }
    }
  } else {
    if (Array.isArray(context)) {
      for (
        let contextLength = 0;
        contextLength < context.length;
        contextLength += 1
      ) {
        print(context[contextLength], matched, strings);
      }
    } else {
      print(context, matched, strings);
    }
  }
  if(strings[0] == 'null' || strings == null ){
   return ''
  }
  return strings;
};

export const getNewContext = (data, path) => {
  if (path.indexOf('.') === -1) return data[path];
  const newContext = JSONPath.value(data, `$.${path}`);
  return newContext;
};

export const ModelPropertiesParser = (propString, context, options = {}) => {
  if (options.hasModifiedClassProperty) {
    context = getNewContext(options.workflowData, options.replaceClassProperty);
  }
  let backwardCompString = propString;
  if (Array.isArray(propString)) {
    backwardCompString = '';
    propString.map(str => {
      backwardCompString += `{{${str.classProperty}}} `;
    });
  }
  const propExpression = new RegExp(/\{\{(.*?)\}\}/g);
  let evaluated = [];
  if (
    !context ||
    typeof context === 'undefined' ||
    (Array.isArray(context) && !context.length)
  )
    return null;
  if (
    backwardCompString === null ||
    typeof backwardCompString !== 'string' ||
    backwardCompString === ''
  )
    return null;
  evaluated = backwardCompString.replace(propExpression, (pattr, matched) => {
    let newMatched = matched;
    let dateFormate = null;
    if (matched.indexOf('[')) {
      const dateExpression = new RegExp(/\[(.*?)\]/g);
      newMatched = matched.replace(dateExpression, (patttern, dateMatched) => {
        dateFormate = dateMatched;
        return '';
      });
    }
    
    const values = readPath(context, newMatched);

   if(dateFormate === 'location' && values && values[0] && values[0] != 'null'){
      let splicedLocation = values[0] && values[0].split(",");
      splicedLocation && splicedLocation.splice(0, 2);
      return `${splicedLocation}`
       
  }

  if(dateFormate === 'NumFormat' && values && values[0] && values[0] != 'null') {
    let actualValue = values[0];
    const numFormatter = options.numFormatter ? options.numFormatter : 'en-IN';
    if(typeof actualValue === 'number') {
      return actualValue.toLocaleString(numFormatter);
    } else if(typeof actualValue === 'string' && parseInt(actualValue) !== NaN) {
      return parseInt(actualValue).toLocaleString(numFormatter);
    }
  }

  if(dateFormate === 'inwords' && values && values[0] && values[0] != 'null'){
    var converter = require('number-to-words');
    let numberInWords = converter.toWords(values[0])
    const numbeInWordsarr = numberInWords.split(" ");
    for (var i = 0; i < numbeInWordsarr.length; i++) {
      numbeInWordsarr[i] = numbeInWordsarr[i].charAt(0).toUpperCase() + numbeInWordsarr[i].slice(1);
     }
    let numberInWordsCapitalize = numbeInWordsarr.join(" ");
    return numberInWordsCapitalize
}


if(dateFormate === 'maskValue' && values && values[0] && values[0] != 'null'){
  let valueToRender = values[0];
    valueToRender = valueToRender.toString().substring(0, 3) + '*****' + valueToRender.toString().substring(valueToRender.toString().length-4, valueToRender.toString().length)

  return valueToRender
}

if(dateFormate === 'toINT' && values && values[0] && values[0] != 'null'){
  let valueComing = values[0];
  let changeNumber = valueComing.toFixed(0);
  return changeNumber
}

if(dateFormate === 'commaSep' && values && values[0] && values[0] != 'null'){
  const commaNumber = require('comma-number')
  let valueComing = parseInt(values[0])
   const ValueAfterCommaSeprate = commaNumber(valueComing)
  return ValueAfterCommaSeprate
}

if(dateFormate === 'inwordsIN' && values && values[0] && values[0] != 'null'){
  const { ToWords } = require('to-words');
  const toWords = new ToWords({
    localeCode: 'en-IN',
    converterOptions: {
      currency: false,
      ignoreDecimal: false,
      ignoreZeroCurrency: false,
      doNotAddOnly: true,
    }
  });
  let numberInWords = toWords.convert(values[0])
  const numbeInWordsarr = numberInWords.split(" ");
  for (var i = 0; i < numbeInWordsarr.length; i++) {
    numbeInWordsarr[i] = numbeInWordsarr[i].charAt(0).toUpperCase() + numbeInWordsarr[i].slice(1);
   }
  let numberInWordsCapitalize = numbeInWordsarr.join(" ");
  return numberInWordsCapitalize
}

  if(dateFormate === 'newLine' && values && values[0] && values[0] != 'null'){
      return `${values[0]}\n`
       
  }
    if(dateFormate === 'url' && values && values[0] && values[0] != 'null'){
      return `${config.assetsBasePath}${values[0]}`
       }else if (dateFormate === 'commabefore' && values && values[0] && values[0] != 'null'){
        return `, ${values[0]}`
       }
    else if (  dateFormate &&  !(dateFormate === 'commabefore' || dateFormate === 'url' || dateFormate === 'toINT') && values && values[0] && values[0] !== "INVALID DATE") {
      const date = moment(values[0]+ ' GMT');
      const newValue = date.format(dateFormate);
      values[0] = newValue.toUpperCase();
    }
  
    return values && values.length > 1 ? values.join(delimiter) : values;
  });
  if (!evaluated || !evaluated.length) return null;
  evaluated = analyseEvaluatedText(evaluated);
  return evaluated;
};
