import React from 'react';
import { UncontrolledTooltip } from 'reactstrap';

const SingleLine = (props) => {
    const { avatarRound, avatarSize, fullName, email, children } = props;
    
    return (
        <div className='d-flex align-items-center'>
            <div className='avatar-wrapper'>
                {children}
            </div>
            <div
                className='ml-2'
                style={{
                    width: `calc(100% - ${avatarSize + 8}px)`
                }}
            >
                <div className='text-capitalize'>
                    <div className='text-overflow-ellipsis' title={fullName}>{fullName}</div>
                    {
                        email && <div className='text-muted text-overflow-ellipsis' title={email}>{email}</div>
                    }
                </div>
            </div>
        </div>
    )
}

export default SingleLine;