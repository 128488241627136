import React, { Component } from 'react';
import { Badge, Label } from 'reactstrap';
import { ModelPropertiesParser } from '../../commons/modelPropertiesParser';
import Carousel from '../Carousel';
import config from '../../../config';

class TypeModelCarousel extends Component {
    getCarouselItems = (workflowData, carouselConfig) => {
      return workflowData.map((item, index) => {
        const imageData = ModelPropertiesParser(carouselConfig.imageField, item);
        const imageUrl = imageData.startsWith('http') ? imageData : config.assetsBasePath + imageData;
        return {
          src: imageUrl,
          title: ModelPropertiesParser(carouselConfig.titleField, item),
          subtitle: ModelPropertiesParser(carouselConfig.subtitleField, item)
        }
      })
    }
    render() {
        const { carouselConfig, workflowData, modelSelector, label } = this.props;
        if (!carouselConfig || !workflowData) return null;

        if (workflowData && Array.isArray(workflowData)) {
            const items = this.getCarouselItems(workflowData, carouselConfig);
            return (
                <div>
                    <div className='d-flex justify-flex-end'>   
                        <Carousel items={items} height={carouselConfig.height || 420}/>
                    </div>
                </div>
            )
        }
        return (
            <div className='d-flex'>

            </div>
        );
    }
}

export default TypeModelCarousel;