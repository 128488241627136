import { UPLOAD_FILES, UPLOAD_FILES_FAILURE, UPLOAD_FILES_SUCCESS, CLEAR_UPLOADER } from '../actions/uploader';
import { Map } from 'immutable';

const intitalState = Map({});

const uploader = (state = intitalState, action = {}) => {
    switch (action.type) {
        case UPLOAD_FILES: return state.merge({
            [`uploader_for_${action.payload.namespace}`]: {
                isLoading: true
            }
        });
        
        case UPLOAD_FILES_SUCCESS: return state.merge({
            [`uploader_for_${action.payload.namespace}`]: {
                data: action.payload,
                isLoading: false
            }
        });

        case UPLOAD_FILES_FAILURE: return state.merge({
            [`uploader_for_${action.payload.namespace}`]: {
                hasError: true,
                error: action.payload,
                isLoading: false
            }
        });

        case CLEAR_UPLOADER: return state.merge({
          [`uploader_for_${action.payload}`]: {
            hasError: false,
            error: null,
            isLoading: false,
            data: null
        }
        })

        default: return state;
    }
};

export default uploader;