import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { Button, Alert } from 'reactstrap';
import { toJS } from '../../../containers/to-js';
import { getCalendarEvents, addSubworkflowEvent, clearSubworkflowEvent } from '../../../actions/calendar-event';
import date from '../../workflow/validations/date';

class PillsView extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      startDate: moment()
    }
  }

  componentDidMount() {
    !this.props.calendarEvents && this.props.getCalendarEvents({email: this.props.email});
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.email !== nextProps.email) {
      this.props.getCalendarEvents({email: nextProps.email})
    }
  }
  onClickNextDate = () => {
    const startDate = this.state.startDate;
    this.setState({ startDate: startDate.clone().add(3, 'days') })
  }
  onClickPreviousDate = () => {
    const startDate = this.state.startDate;
    this.setState({ startDate: startDate.clone().subtract(3, 'days') })
  }

  getSlots = (date) => {
    const { config: { dateEnumeratorConfig, laneEnumerator }, workflowData, availabilities } = this.props;
    const duration = (dateEnumeratorConfig && dateEnumeratorConfig.duration) ? parseInt(dateEnumeratorConfig.duration) : 60;
    const numberOfIterations = Math.floor(1440/duration);
    const slots = [];
    availabilities && availabilities.map((value, valueIndex) => {
      const tempStartValue = moment(value.startDateTime);
      const availableStartDateTime = tempStartValue.isValid ? tempStartValue: moment(value.endDateTime);
      const tempEndValue = moment(value.endDateTime);
      const availableEndDateTime = tempEndValue.isValid ? tempEndValue: moment(value.endDateTime);
      if(availableStartDateTime.isSame(date, 'day')) {
        let newTime = moment(availableStartDateTime, "HH:mm");
        const endTime = moment(availableEndDateTime, "HH:mm");
        while(newTime.isBefore(endTime)) {
          let isBusy = false;
          let newDateTime = date.clone();
          newDateTime.set({ hour: newTime.get('hour'), minute: newTime.get('minute'), seconds: 0, millisecond: 0});
          workflowData && workflowData.map((item, itemIndex) => {
            const tempDate = moment(item[laneEnumerator.classProperty] + ' GMT');
            const busySlotDate = tempDate.isValid? tempDate: moment(item[laneEnumerator.classProperty]);
            if(busySlotDate.isSame(newDateTime)) {
              isBusy = true;
            };
          })
          const slot = {
            startDate: newDateTime.toDate(),
            endDate: newDateTime.clone().add(duration, 'minutes').toDate(),
            start: newTime.format("HH:mm"),
            duration,
            isBusy
          };
          slots.push(slot);
          newTime = newTime.add(duration, 'minutes');
        }
      }
    });
    return slots;
  }

  onClickNext = (slot) => {
    const newEvent = {
      name: " ",
      id: " ",
      start: slot.startDate,
      end: slot.endDate,
      bgColor: 'green',
      isTemp: true
    }
    this.props.addSubworkflowEvent(newEvent);
  }

  componentWillUnmount() {
    this.props.clearSubworkflowEvent();
  }

  render() {
    const { config: { dateEnumeratorConfig } } = this.props;
    const { startDate } = this.state;
    return (
      <div className="pills-view">
        <DateCard
          date={startDate} 
          slots={this.getSlots(startDate)}
          onClickNext={this.onClickNext}
        />
        <DateCard
          date={startDate.clone().add(1, 'days')}
          slots={this.getSlots(startDate.clone().add(1, 'days'))}
          onClickNext={this.onClickNext}
        />
        <DateCard
          date={startDate.clone().add(2, 'days')}
          slots={this.getSlots(startDate.clone().add(2, 'days'))}
          onClickNext={this.onClickNext}
        />
        <div className="d-flex justify-content-end">
          <Button
            color="primary"
            className="m-2"
            disabled={this.state.startDate.isSame(moment(), 'day')}
            onClick={(event) => {
              event.preventDefault();
              this.onClickPreviousDate()
            }}
          >
            Previous
          </Button>
          <Button
            color="primary"
            className="m-2"
            onClick={(event) => {
              event.preventDefault();
              this.onClickNextDate()
            }}
          >
            Next
          </Button>
        </div>
      </div>
    )
  }
}

const DateCard = (props) => {
  const  { date, slots, onClickNext } = props;
  return (
    <div className={`pills-card d-flex mb-2 success`}>
      <div className='date-badge d-flex flex-column justify-content-center align-items-center p-3'
        style={{
          flex: '1'
        }}
      >
        <div className='month text-center'>
          {date.format('dddd')}
        </div>
        <div className='day text-center'>
          
        </div>
        <small>{date.format('DD MMM YYYY')}</small>
      </div>
      <div className='d-flex align-items-center p-2'
        style={{
          flexWrap: 'wrap',
          flex: '6'
        }}
      >
        {
          slots && slots.length ? slots.map((slot, slotIndex) => {
            return (
              <Button
                color="primary"
                disabled={slot.isBusy}
                className="slot m-2"
                outline
                key={slotIndex}
                size="sm"
                onClick={(event) => {
                  event.preventDefault();
                  onClickNext(slot);
                }}
              >
                {slot.start}
              </Button>
            )
          }) : <Alert color="warning">
          No Slots available
        </Alert>
        }
      </div>
    </div>
  )
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getCalendarEvents: (payload) => {
      dispatch(getCalendarEvents(payload));
    },
    addSubworkflowEvent: (event) => {
      dispatch(addSubworkflowEvent(event));
      ownProps.next();
    },
    clearSubworkflowEvent: () => {
      dispatch(clearSubworkflowEvent());
    }
  }
};

const mapStateToProps = (state, ownProps) => {
  return {
    availabilities: state.getIn(['calendarEvents', 'data', 'availabilities'])
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(toJS(PillsView));