import { 
    GET_COUNTRIES,
    GET_COUNTRIES_SUCCESS,
    GET_COUNTRIES_FAILURE,
    GET_STATES,
    GET_STATES_FAILURE,
    GET_STATES_SUCCESS,
    GET_CITIES,
    GET_CITIES_FAILURE,
    GET_CITIES_SUCCESS
} from '../actions/static-data';
import { Map } from 'immutable';

const intitalState = Map({});

const staticData = (state = intitalState, action = {}) => {
    switch (action.type) {

        case GET_COUNTRIES: return state;
        
        case GET_COUNTRIES_SUCCESS: return state.merge({
          countries: action.payload
        });

        case GET_STATES_SUCCESS: return state.merge({
            states: action.payload
        });

        case GET_CITIES_SUCCESS: return state.merge({
            cities: action.payload
        });

        case GET_COUNTRIES_FAILURE: return state;

        default: return state;
    }
};

export default staticData;