import React from 'react';
import PrintText from './PrintText';
import { Button, Label, Modal, ModalHeader, ModalBody, ModalFooter ,Row,Col} from 'reactstrap';
import { Field } from 'redux-form/immutable';
import { renderField } from './_FORM_FIELDS';
import { ModelPropertiesParser } from '../commons/modelPropertiesParser';
import QRCode from "react-qr-code";

const _URL = (props) => {
    const { classProperty,label,workflowData, type, validations, value, isReadOne,layoutItem: { itemProperties }, ...rest } = props;
    const { urlConfig } = itemProperties;
    const showAsQR = urlConfig && urlConfig.displayAsQR ? urlConfig.displayAsQR : null
    return (
        <div>
            {showAsQR ? <div style={{flexDirection:"column"}}>
              <div> <Label>{label && label.show ? label.text:null}</Label></div> 
             <div><QRCode value={ModelPropertiesParser('{{'+classProperty+'}}', workflowData)} /></div> 
            </div> :
                isReadOne ?
                    <PrintText {...props} /> :
                    <Field
                        name={classProperty}
                        component={renderField}
                        type="url"
                        validate={validations}
                        value={value}
                        onBlur={() => {
                            const cEventKeys = window.cEvents && Object.keys(window.cEvents);
                       Array.isArray(cEventKeys) &&     cEventKeys.map(k => {
                              k.startsWith(classProperty) &&
                                window.cEvents[k](this.props.formValues);
                            });
                          }}
                        fieldType={type}
                        {...props}
                    />
            }
        </div>
    );
}

export default _URL;