import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Table, Column, Cell } from 'fixed-data-table-2';
import Measure from 'react-measure';
import { Row, Col, Alert } from 'reactstrap';
import moment from 'moment';
import TextStyleResolver from '../../elements/TextStyleResolver';

class ChartTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            maxHeight: 500,
            dimensions: {
                width: -1,
                height: -1
            }
        }
    }
    render() {
        const { graphData, rawData, colors, legendName, xLabel, yLabel, graphHeight, originalReportType,tableConfig,tableFilterdData } = this.props;
        const { width } = this.state.dimensions;
        const { columns } = rawData;
        if (!Array.isArray(columns) || !columns.length) return null;
        const oneRowTable = graphData[0] && graphData[0].tableData
        const showAsBoxUi = tableConfig && tableConfig.showAsBoxUi
        const roundOffField = tableConfig && tableConfig.roundOffField;
        const customizeTable = tableConfig && tableConfig.customizeTable;
        const rowHeight = tableConfig && tableConfig.rowHeight;
        const columnWidths = tableConfig && tableConfig.columnWidths;
        const noBorderTable = tableConfig && tableConfig.noBorderTable;
        const arrayColumnsWidth = columnWidths &&  columnWidths.split(',')

         if(oneRowTable && graphData.length == 1 && showAsBoxUi ){
            return (
            <div className="iterate-object">        
            {
                <div className={'oneRowBox'}>
                      {Object.keys(oneRowTable).map(function(keyName, keyIndex) {

                       const isValidDate = function(date1) {
                        let date = Date.parse(date1);
                            return (new Date(date) !== "Invalid Date") && !isNaN(new Date(date)) && typeof data == 'string';
                        }

                         var dateData = null;
                         let data = oneRowTable[keyName];
                           if(isValidDate(data)){
                              let  formatedDate = moment(data).format('DD-MM-YYYY')
                               dateData =formatedDate
                           }    

                        return (
                                <Row className={'oneRowBoxRow'} key={keyIndex}>
                                    <Col className={'oneRowBoxRow1'}>
                                    {keyName}
                                    </Col>
                                    <Col className={'oneRowBoxRow2'}>
                                    {dateData ? dateData: oneRowTable[keyName]}
                                    </Col>
                                </Row>
                        )
                    })}
                </div>
            }
            </div>)
         
        }

        if(customizeTable){
            return (
                <Measure
                    bounds
                    onResize={(contentRect) => {
                        this.setState({ dimensions: contentRect.bounds })
                    }}>
                    {({ measureRef }) =>
                        <div ref={measureRef} className={noBorderTable?'noBorderTable':'reportTableCustomize'}>
                            <Table
                                rowHeight={rowHeight?parseInt(rowHeight):50}
                                rowsCount={tableFilterdData && tableFilterdData.length > 0 ? tableFilterdData.length :graphData.length}
                                headerHeight={rowHeight?parseInt(rowHeight):50}
                                width={width}
                                className='neene-table-report-customize'
                                maxHeight={graphHeight}>
                                {
                                    columns.map((column, index) => {
                                        return <Column
                                            key={index}
                                            header={<Cell  className='neene-table-header-report-customize'>{column}</Cell>}
                                            cell={<RenderCellData
                                                graphData={tableFilterdData && tableFilterdData.length > 0 ? tableFilterdData: graphData}
                                                className="neene-table-cell-data-customize"
                                                originalReportType={originalReportType}
                                                columnIndex={index}
                                                _columnKey={column}
                                                roundOffField={roundOffField}
                                            />}
                                            width={arrayColumnsWidth && arrayColumnsWidth[index] && typeof(parseInt(arrayColumnsWidth[index])) === 'number'  ? parseInt(arrayColumnsWidth[index]) :  140}
                                            flexGrow={1}
                                        />
                                    })
                                }
                            </Table>
                        </div>
                    }
                </Measure>
            );
        }


        return (
            <Measure
                bounds
                onResize={(contentRect) => {
                    this.setState({ dimensions: contentRect.bounds })
                }}>
                {({ measureRef }) =>
                    <div ref={measureRef}>
                        <Table
                            rowHeight={50}
                            rowsCount={tableFilterdData && tableFilterdData.length > 0 ? tableFilterdData.length :graphData.length}
                            headerHeight={50}
                            width={width}
                            className='neene-table-report'
                            maxHeight={graphHeight}>
                            {
                                columns.map((column, index) => {
                                    return <Column
                                        key={index}
                                        header={<Cell className='neene-table-header-report'>{column}</Cell>}
                                        cell={<RenderCellData
                                            graphData={tableFilterdData && tableFilterdData.length > 0 ? tableFilterdData: graphData}
                                            className="neene-table-cell-data"
                                            originalReportType={originalReportType}
                                            columnIndex={index}
                                            _columnKey={column}
                                            roundOffField={roundOffField}
                                        />}
                                        width={140}
                                        flexGrow={1}
                                    />
                                })
                            }
                        </Table>
                    </div>
                }
            </Measure>
        );
    }
}

const SimpleTableXYCell = (props) => {
    const { graphData, columnIndex, rowIndex } = props;
    const rowData = graphData[rowIndex];
    if (columnIndex > 1) {
        return <p>N.A.</p>
    }
    if (columnIndex === 0) {
        return <Cell>{rowData.xProperties}</Cell>
    }
    if (columnIndex === 1) {
        return <Cell>{rowData.yProperties}</Cell>
    }
    
}

const TableTypeCell = (props) => {
    const { graphData, rowIndex, _columnKey ,roundOffField} = props;
    const rowData = graphData[rowIndex];
    if(!rowData.tableData[_columnKey]){
        return <Cell className='table-type-cell' >Not Avalaible</Cell>;
    }
    if (!rowData.hasOwnProperty('tableData')) return null;
    if(roundOffField){
        let roundOffData = null ;
        for (let index = 0; index < roundOffField.length; index++) {
            roundOffData = rowData.tableData[_columnKey] ;
            if(roundOffField[index].value ===_columnKey ){
                if(typeof roundOffData === 'number' ){
                    roundOffData = parseInt(roundOffData)
                    return <Cell className='table-type-cell' >{roundOffData}</Cell>;
                }
              }
            
        }
      }
       
      return <Cell className='table-type-cell' >      <TextStyleResolver text={`${rowData.tableData[_columnKey]}`} /></Cell>
    
}

const RenderCellData = (props) => {
    const { originalReportType } = props;
    if (originalReportType && originalReportType !== 'TABLE') {
        return <SimpleTableXYCell {...props} />
    }
    
    return <TableTypeCell  {...props} />
}

ChartTable.propTypes = {

};

export default ChartTable;