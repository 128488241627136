import React from 'react';
import { Row } from 'reactstrap';
import GenericColumn from './GenericColumn';

const ThreeColContainer = (props) => {
    const Layout = props.operationLayout;
    const { index, rowClassName, contStyle } = props;
    return <Row className={rowClassName} style={contStyle}>
        <GenericColumn threeColumn={true} {...props} containerIndex={index} columnIndex={0} isFirst={true} />
        <GenericColumn threeColumn={true} {...props} containerIndex={index} columnIndex={1} />
        <GenericColumn threeColumn={true} {...props} containerIndex={index} columnIndex={2} isLast={true} />
    </Row>
}

export default ThreeColContainer;