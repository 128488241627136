import React from 'react';
import { ModelPropertiesParser } from '../commons/modelPropertiesParser';

const getDisplayText = (givenText, workflowData) => {
  const keys = Object.keys(workflowData);
  let convertedText = ModelPropertiesParser(givenText, workflowData);
  if (keys.includes('__ADDITIONAL_MODEL_DATA')) {
    convertedText = ModelPropertiesParser(
      givenText,
      workflowData['__ADDITIONAL_MODEL_DATA']
    );
  }
  return convertedText;
};
const StaticTextCard = props => {
  const {
    layoutItem: {
      itemProperties: { staticTextCardConfig, mobileElement },
    },
    workflowData,
  } = props;
  if (!staticTextCardConfig) return null;
  const { actionableLine, secondaryLine, primaryLine } = staticTextCardConfig;
  const primaryLineText =
    primaryLine &&
    primaryLine.text &&
    getDisplayText(primaryLine.text, workflowData);

  let secondaryLineText =
    secondaryLine &&
    secondaryLine.text &&
    getDisplayText(secondaryLine.text, workflowData);

  let actionableLineText =
    actionableLine &&
    actionableLine.text &&
    getDisplayText(actionableLine.text, workflowData);

  return (
    <div className='static-text-card'>
      {primaryLine && (
        <div className='primaryLine'>
          <span className='primaryLineText'>{primaryLineText}</span>
        </div>
      )}
      {secondaryLine && (
        <div className='secondaryLine'>
          <span className='secondaryLineText'>{secondaryLineText}</span>
        </div>
      )}

      {actionableLine && (
        <div className='actionableLine'>
          <span className='actionableLineText'>{actionableLineText}</span>
          <i className={`fa fa-angle-right mr-4 float-right`}></i>
        </div>
      )}
    </div>
  );
};

export default StaticTextCard;
