import React from 'react';
import Calendar from '../commons/calendar';
import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import { toJS } from '../../containers/to-js';

class CalendarEvent extends React.Component {
  render() {
    return (
      <Calendar 
        view="week"
        events={[]}
        email={this.props.userEmail}
      />
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const { layoutItem: { itemProperties: { calendarEventConfig } } } = ownProps;
  let userEmail = null;
  if (calendarEventConfig) {
    const userFieldSplit = calendarEventConfig.userField.split(".");
    const user = !ownProps.isReadOne && state.getIn(['form', ownProps.form, 'values', ...userFieldSplit]);
    userEmail = user && user.email;
  }
  return {
    userEmail
  }
}

export default connect(mapStateToProps)(toJS(CalendarEvent));