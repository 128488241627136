import React from 'react';
import RowBuilder from './RowBuilder';

export const getBreakpoint = (props) => {
    const { size } = props;
    const { width } = size;
    let bp = 'lg';
    if (width < 480) {
        bp = 'xs';
    }
    if (width >= 480 && width < 768) {
        bp = 'sm';
    }
    if (width >= 768 && width < 1440) {
        bp = 'md'
    }
    return bp;
}

const getLowerBreakPoint = (currentBreakPoint) => {
    if(currentBreakPoint == 'lg') return 'md';
    if(currentBreakPoint == 'md') return 'sm';
    if(currentBreakPoint == 'sm') return 'xs';
    return 'xs';
}

const getMatchedItems = (props) => {
    const { containerIndex, columnIndex, container: { items } } = props;
    const matched = [];
    items.map((item, index) => {
        const { itemProperties: { position } } = item;
        if (position.containerIndex === containerIndex && position.columnIndex === columnIndex) {
            matched.push(item);
        }
    });
    return matched;
}

export const build = (props) => {
    const { containerIndex, columnIndex, workflow } = props;
    const workflowItem = workflow.currentItem;
    const container = workflowItem.layout.containers[containerIndex];
    const column = container.properties.columns[columnIndex];
    let bkPoint = getBreakpoint(props);
    let grid = column.bootstrap[bkPoint];
    if(!grid) {
        bkPoint = getLowerBreakPoint(bkPoint);
        grid = column.bootstrap[bkPoint];
    }
    let rows = [];
    if (grid)
        rows = grid.rows;
    let allWidthOne = true;
    for(var i = 0; i < rows.length; i++) {
        var columns = rows[i].columns;
        for(var j = 0; j < columns.length; j++) {
            if(columns[j].w > 1) {
                allWidthOne = false;
                break;
            }
        }
        if(!allWidthOne) break;
    }
    if(allWidthOne) {
        bkPoint = getLowerBreakPoint(bkPoint);
        grid = column.bootstrap[bkPoint];
        if(!grid) {
            bkPoint = getLowerBreakPoint(bkPoint);
            grid = column.bootstrap[bkPoint];
        }
    }

    if(!grid) {
        //DUDE WTF!!
        for(var x in column.bootstrap) {
            grid = column.bootstrap[x];
        }
    }
    const t = [];
    if(grid) {
        for (let rowKey in grid.rows) {
            t.push(<RowBuilder {...props} key={rowKey} columns={grid.rows[rowKey].columns} layoutItems={getMatchedItems(props)} workflow={workflow} />);
        }
    }
    return t;
}