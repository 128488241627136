import React, { Component } from 'react';
import { CSVLink, CSVDownload } from "react-csv";
import { ModelPropertiesParser, getNewContext, readPath } from '../commons/modelPropertiesParser';
import moment from 'moment';
import conf from '../../config';

class FileDownload extends React.Component {

	constructor(props) {
        super(props);
        this.state = {
            fileName: null,
			fileHeaders: [],
			fileData: [],
			fileType: '',
			fileName: ''
        }
    }
	
	componentDidMount() {
        this.prepareFileData(this.props);
    }
	
	componentWillReceiveProps(nextProps) {
		this.prepareFileData(nextProps);
	}
	
	prepareFileData(props) {
		const { data, type, columns, rows, name, dateformat, duplicateRowsForOneToMany } = props;
		let tableData = [], headers, fileType, fileName;
		if(type == 'report') {
			fileName = data.name;
			fileType = data.reportType !== 'NUMBER' ? 'csv' : '';
			headers = data.columns && data.columns.map((val) => {
				return {
					label: val,
					key: val
				};
			});
			
			data.graphData && data.graphData.map((rowData) => {
				if(data.reportType !== 'NUMBER') {
					if (rowData.xProperties == null && rowData.yProperties == null && rowData.hasOwnProperty('tableData')) {
							tableData.push(rowData.tableData)
					} else {
						let rowObj = {};
						rowObj[data.columns[0]] = rowData.xProperties;
						rowObj[data.columns[1]] = rowData.yProperties;
						tableData.push(rowObj);
					}
				}
			});
		} else {
			let headerData = [], tableRows = [];
			tableData = rows._rows;
			columns.map((column, index) => {
				const key = column.field.classProperty + index;
				headerData.push({label: column.config.downloadheader?column.config.downloadheader: column.config.header, key: key});
			});
			
			tableData.map((row) => {
				let rowObj = {};
				let oneToManys = [];
				columns.map((column, index) => {
					let colKey = column.config.modelSelector;
					let classProperty = column.field.classProperty;
					const isCollection = column.field.collection;
					const type = column.field.type;
					const prependAssetsUrl = column.config.prependAssetsUrl
					rowObj[classProperty+index] = '';
					let text = '';
					let isOneToMany = false;
					if(typeof colKey != 'undefined') {
						if(isCollection) {
							oneToManys.push({col:column,i: index});
							isOneToMany = true;
						}
						if(classProperty.startsWith('$$SELF$$')) {
							if(colKey.indexOf('.') != -1) {
								const tempItems = row[colKey.substring(2, colKey.indexOf('.'))];
								if(Array.isArray(tempItems)) {
									oneToManys.push({col:column,i: index});
									isOneToMany = true;
								}
							}
							text = ModelPropertiesParser(colKey, row);
						} else {
							let tempItems = row[classProperty];
							if(Array.isArray(tempItems)) {
								oneToManys.push({col:column,i: index});
								isOneToMany = true;
							}
							text = ModelPropertiesParser(colKey, row[classProperty]);
						}
						
					} else {
						text = row[classProperty];
						
					}
					const tempDate = type === 'TIME'?moment(text):moment(text + ' GMT');
					const DATE = tempDate.isValid? tempDate: moment(text);
					if (type === 'DATE') {
						
						text = dateformat ? DATE.format(dateformat) : DATE.format('DD-MM-YYYY');
					} else if (type === 'DATETIME') {
						text = dateformat ? DATE.format(dateformat + ' hh:mm') : DATE.format('DD-MM-YYYY hh:mm');
					}
					if (type === 'TIME') {
						text = DATE.format('h:mm a');
					}
					if(type === 'IMAGE' || prependAssetsUrl) {
						if(Array.isArray(text)) {
							if(text.length > 0) {
								let newVal = [];
								text.forEach((item) => {
									item = item.indexOf(`${conf.assetsBasePath}` == -1 ) ? encodeURI(`${conf.assetsBasePath}${item}`) : item;
									newVal.push(item)
								});
								text = newVal.join(', ');
							}
							
						} else {
							text = !text ? '' : text.indexOf(`${conf.assetsBasePath}`) == -1 ? encodeURI(`${conf.assetsBasePath}${text}`) : text;
						}
						
					}	
					if(  typeof text === "string" && text.startsWith('\"')){
						text = text.replace(/\"/g, '')
					}
					if(!isCollection)
					{
						rowObj[classProperty+index] = text;
					}
				});
				if(oneToManys.length) {
					let childRowObjs = [];
					oneToManys.map((item) => {
						const column = item.col;
						const index= item.i;
						let colKey = column.config.modelSelector;
						let classProperty = column.field.classProperty;
						const tokens = colKey.split('.');
						let childRows = (classProperty.startsWith('$$SELF$$')?row[tokens[0].substring(2)]:row[classProperty]) || [];
						if(childRows.length === 0) childRows = [{}]
					//	console.log('Duplicate rows - ', duplicateRowsForOneToMany);
						for(let i = 0; i < childRows.length; i++) {
							let text = '';
							let childRowObj = childRowObjs.length > i?childRowObjs[i]: null;
							if(!childRowObj) childRowObj = (i == 0 || duplicateRowsForOneToMany)?Object.assign({}, rowObj):{};
					//		console.log('Child row - ', childRowObj)
							childRowObjs[i] = childRowObj;
							if(classProperty.startsWith('$$SELF$$')) {
								text = ModelPropertiesParser('{{' + tokens[1], childRows[i]);
							} else 
								text = ModelPropertiesParser(colKey, childRows[i]);

							if(  typeof text === "string" && text.startsWith('\"')){
									text = text.replace(/\"/g, '')
							   }
							let tmp = childRowObj[classProperty+index];
							if(!tmp){
								childRowObj[classProperty+index] = text;
							} else if(tmp !== text){
								//childRowObj[classProperty+index] = tmp+','+text;
							}
							
						
						}
						
					});
					//childRowObjs.map((childRowObj) => tableRows.push(childRowObj));
				    tableRows.push(childRowObjs[0])
				} else 
					tableRows.push(rowObj);
			})
			
			fileType = 'csv';
			fileName = name;
			headers = headerData;
			tableData = tableRows;
		}
		let csv = null;

		try {
			csv = this.jsons2csv(tableData, headers);
		} catch(error) { console.log(error)}

		this.setState({
			fileHeaders : headers,
			fileType: fileType,
			fileData: tableData,
			fileName: fileName,
			csv: csv
		})
		
	}

	jsons2arrays = (jsons, headers) => {
		 // allow headers to have custom labels, defaulting to having the header data key be the label
		 let headerLabels = headers;
		 let headerKeys = headers;
		headerLabels = headers && headers.map((header) => header.label);
		headerKeys = headers && headers.map((header) => header.key);
	   
		 const data = jsons.map((object) => headerKeys.map((header) => this.getHeaderValue(header, object)));
		 return [headerLabels, ...data];
	};
	   
	getHeaderValue = (property, obj) => {
		const foundValue = property
		.replace(/\[([^\]]+)]/g, ".$1")
		.split(".")
		.reduce(function(o, p, i, arr) {
			// if at any point the nested keys passed do not exist, splice the array so it doesnt keep reducing
			if (o[p] === undefined) {
			arr.splice(1);
			} else {
			return o[p];
			}
		}, obj);
		// if at any point the nested keys passed do not exist then looks for key `property` in object obj
		return (foundValue === undefined) ? ((property in obj) ? obj[property] : '') : foundValue;
	}
	   

	elementOrEmpty = (element) => element || element === 0 ? element : '';
	
	joiner = ((data, separator = ',', enclosingCharacter = '"') => {
	  return data
		.filter(e => e)
		.map(
		  row => row
			.map((element) => this.elementOrEmpty(element))
			.map(column => `${(column + '').indexOf(separator) > -1?enclosingCharacter:''}${column}${(column+'').indexOf(separator) > -1?enclosingCharacter:''}`)
			.join(separator)
		)
		.join(`\n`);
	});
	
	jsons2csv = ((data, headers, separator, enclosingCharacter) =>
		this.joiner(this.jsons2arrays(data, headers), separator, enclosingCharacter)
   )
	render() {
		const { fileName, fileType } = this.state;
		const { hideInPDFDownload } = this.props;
		return (
			<div className={hideInPDFDownload?'d-none':''}>
			{
				fileType == 'csv' ? <CSVLink data-html2canvas-ignore="true" data={this.state.csv?this.state.csv:this.state.fileData} headers={this.state.csv?null:this.state.fileHeaders} 
				className="btn btn-sm btn-success" filename={`${fileName}.${fileType}`} uFEFF={false} >
					<i className="fa fa-file-excel-o"></i>
				</CSVLink> : ''
			}
			</div>
        );
    }
}

export default FileDownload;

