import React from 'react';
import { Button, ButtonGroup } from 'reactstrap';

const ChronoButtonGroup = (props) => {
  const { chronoViewMode, onChangeChronoViewMode, lagendName } = props;
  return (
    <div className='chrono-button-group'>
      <ButtonGroup>
        <Button className="button" outline={chronoViewMode !== 'MONTH'} onClick={() => onChangeChronoViewMode('MONTH')}>Month</Button>
        <Button className="button" outline={chronoViewMode !== 'WEEK'} onClick={() => onChangeChronoViewMode('WEEK')}>Week</Button>
        <Button className="button" outline={chronoViewMode !== 'DAY'} onClick={() => onChangeChronoViewMode('DAY')}>Day</Button>
        <Button className="button" outline={chronoViewMode !== 'SUMMARY'} onClick={() => onChangeChronoViewMode('SUMMARY')}>Summary</Button>
      </ButtonGroup>
      <div>
        <div className="rbc-event" style={{ display: 'inline-block', margin: '12px 0 0 6px'}}>{lagendName}</div>
        <div className="rbc-event" style={{ display: 'inline-block', backgroundColor: '#808080', margin: '12px 0 0 6px'}}>Busy slots</div>
      </div>
    </div>
  )
}

export default ChronoButtonGroup;