import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Col } from 'reactstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Field } from 'redux-form/immutable';
import moment from 'moment';

class DatepickerComponent extends Component {
    constructor(props) {
        super(props);
        const { filterStateMap, filterIndex, filter} = props;
        const savedFilterState = filterStateMap ? filterStateMap[`${filter.paramName}`] : '';
        this.state = {
            date: null
        }
    }
    
    handleChange(date) {
		const { onFilterChange, filter, filterIndex} = this.props;
		this.setState({ date });
		date = filter.paramName.toLowerCase() == 'enddate' && date ? date.set({hour:23,minute:59,second:59}) : date;
		let dateValue = date? date.format('YYYY-MM-DD HH:mm:ss'):'';
        dateValue = !dateValue ? '' : dateValue;
        onFilterChange(filter.paramName, dateValue);
    }
	componentWillReceiveProps(nextProps) {
		const { filterStateMap, filterIndex, filter } = nextProps;
        const savedFilterState = filterStateMap[`${filter.paramName}`];
		if(!savedFilterState) {
			this.setState({
				date: null
			})
		}
	}
    
    render() {
		//console.log(this.props)
        const { filter, elem_id, companyDetails, filterName} = this.props;
        const { showClickableHorizontalView } = filter;
		const { date } = this.state;
		const dateformat = companyDetails && companyDetails.data && companyDetails.data != null ? companyDetails.data.companySettings.dateformat : '';
        return (
            <Col xs="12" className='w-100 p-0'>
                <DatePicker
                    selected={date}
                    onChange={this.handleChange.bind(this)}
                    placeholderText={filterName}
                    className='form-control w-100'
					dateFormat= {dateformat ? dateformat : 'MM/DD/YYYY'}
                />
            </Col>
        );
    }
}

const DateFilter = (props) => {
	const { ...rest } = props;
    return (
        <div>
            <Field
				name="dateFilter"
				component={DatepickerComponent}
				type="date"
				{...rest}
			/>
        </div>
    );
}

export default DateFilter;