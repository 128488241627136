import React from 'react';
import { connect } from 'react-redux';
import { CardBody, CardHeader, Alert, Row, Col, Jumbotron, Table } from 'reactstrap';
import { toJS } from '../../containers/to-js';
import { getUserProfile } from '../../actions/user';
import Avatar from 'react-avatar';


class Profile extends React.Component {
  componentDidMount() {
    this.props.getUserProfile();
  }
  render() {
    const { profile } = this.props;
    const { isLoading, hasError, data } = profile;
    if (!data) return null;
    if (isLoading) return <ProfileLoading />;
    if (hasError) return <ProfileError />;
    const { email, firstName, lastName, jobTitle, phoneNumber, role } = data;
    const fullName = `${firstName} ${lastName}`;
    return <div className='common-container-wrapper profile-main'>
      <Row>
        <Col sm="12" md={{ size: 8, offset: 2 }} lg={{ size: 6, offset: 3 }}>
          <Jumbotron>
            <CardHeader className="text-center" tag="h3">{fullName}</CardHeader>
            <CardBody>
              <div className='profile-avatar'>
                <Avatar
                  round={true}
                  size={100}
                  name={fullName}
                  email={email}
                />
              </div>
            </CardBody>
            <Table>
              <tbody>
                <tr>
                  <td>First Name</td>
                  <td>:</td>
                  <td>{firstName}</td>
                </tr>
                <tr>
                  <td>Last Name</td>
                  <td>:</td>
                  <td>{lastName}</td>
                </tr>
                <tr>
                  <td>Email</td>
                  <td>:</td>
                  <td>{email}</td>
                </tr>
                <tr>
                  <td>Phone</td>
                  <td>:</td>
                  <td>{phoneNumber}</td>
                </tr>
                <tr>
                  <td>Job Title</td>
                  <td>:</td>
                  <td>{jobTitle}</td>
                </tr>
                <tr>
                  <td>Role</td>
                  <td>:</td>
                  <td>{role}</td>
                </tr>
              </tbody>
            </Table>
          </Jumbotron>
        </Col>
      </Row>
    </div>
  }
}

const ProfileLoading = (props) => {
  return <div className='common-container-wrapper'>
      <Alert color='light'>
          <i className="fa fa-spinner fa-pulse fa-1x mr-1 fa-fw"></i>Loading your profile...
      </Alert>
  </div>
}

const ProfileError = (props) => {
  return <div className='common-container-wrapper'>
      <Alert color='danger'>
          <i className="fa fa-exclamation-triangle mr-1"></i>Something went wrong while loading the profile
      </Alert>
  </div>
}

const mapDispatchToProps = (dispatch) => {
  return {
    getUserProfile: () => {
        return dispatch(getUserProfile());
    }
  }
};

const mapStateToProps = (state, ownProps) => {
  return {
      profile: state.getIn(['user', 'profile'])
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(toJS(Profile));