import client from '../client';
import config from '../config';
import { showLoading, hideLoading } from 'react-redux-loading-bar';

export const GET_COUNTRIES = 'GET_COUNTRIES';
export const GET_COUNTRIES_SUCCESS = 'GET_COUNTRIES_SUCCESS';
export const GET_COUNTRIES_FAILURE = 'GET_COUNTRIES_FAILURE';

export const GET_STATES = 'GET_STATES';
export const GET_STATES_SUCCESS = 'GET_STATES_SUCCESS';
export const GET_STATES_FAILURE = 'GET_STATES_FAILURE';

export const GET_CITIES = 'GET_CITIES';
export const GET_CITIES_SUCCESS = 'GET_CITIES_SUCCESS';
export const GET_CITIES_FAILURE = 'GET_CITIES_FAILURE';

export const getCountries = () => {
    return (dispatch) => {
        dispatch({
            type: GET_COUNTRIES,
            payload: []
        });
        dispatch(showLoading());
        client().post(`${config.publicAPI}/staticdata/countries`).then((res) => {
            dispatch({
                type: GET_COUNTRIES_SUCCESS,
                payload: res.data
            });
            dispatch(hideLoading());
        }).catch((error) => {
            dispatch({
                type: GET_COUNTRIES_FAILURE,
                payload: error
            });
            dispatch(hideLoading());
        });
    };
};

export const getStates = (payload) => {
    return (dispatch) => {
        dispatch({
            type: GET_STATES,
            payload: []
        });
        dispatch(showLoading());
        client().post(`${config.publicAPI}/staticdata/states`, payload).then((res) => {
            dispatch({
                type: GET_STATES_SUCCESS,
                payload: res.data
            });
            dispatch(hideLoading());
        }).catch((error) => {
            dispatch({
                type: GET_STATES_FAILURE,
                payload: error
            });
            dispatch(hideLoading());
        });
    };
};

export const getCities = (payload) => {
    return (dispatch) => {
        dispatch({
            type: GET_CITIES,
            payload: []
        });
        dispatch(showLoading());
        client().post(`${config.publicAPI}/staticdata/cities`, payload).then((res) => {
            dispatch({
                type: GET_CITIES_SUCCESS,
                payload: res.data
            });
            dispatch(hideLoading());
        }).catch((error) => {
            dispatch({
                type: GET_CITIES_FAILURE,
                payload: error
            });
            dispatch(hideLoading());
        });
    };
};

export const getCitiesData = (payload) => {
    return (dispatch) => {
        return client().post(`${config.publicAPI}/staticdata/cities`, payload);
    }
}


export const getStatesData = (payload) => {
    return (dispatch) => {
        return client().post(`${config.publicAPI}/staticdata/states`, payload);
    }
}