import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Cell } from 'fixed-data-table-2';
import { FormGroup, Label, Input } from 'reactstrap';
import { connect } from 'react-redux';
import { toJS } from '../../../../../containers/to-js';
import { pushTid, popTid } from '../../../../../actions/workflow-actions'; 

class RowSelectorCell extends Component {
    onChange(tid, ev) {
        const checked = ev.target.checked;
        const { pushTid, popTid } = this.props;
        checked ? pushTid(tid) : popTid(tid);
    }
    render() {
        const { rows, columnKey, rowIndex } = this.props;
        if (!rows || !rows.getSize()) return null;
        const tid = rows.getObjectAt(rowIndex)[columnKey];
        return (
            <Label check className='w-100 h-100 d-flex justify-content-center align-items-center'>
                <Input onChange={this.onChange.bind(this, tid)} style={{ position: 'relative', width: 35, height: 35, marginLeft: -7, marginTop: 0 }} type="checkbox" />
            </Label>
        );
    }
}

RowSelectorCell.defaultProps = {
    rows: []
}

RowSelectorCell.propTypes = {
    rows: PropTypes.array,
    columnKey: PropTypes.string,
    rowIndex: PropTypes.number
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        pushTid: (tid) => {
            dispatch(pushTid(tid));
        },
        popTid: (tid) => {
            dispatch(popTid(tid));
        }
    }
};

export default connect(null, mapDispatchToProps)(toJS(RowSelectorCell));