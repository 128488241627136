import React from 'react';
import { Button } from 'reactstrap';

const Logout = props => {
  const {
    layoutItem: {
      itemProperties: {
        label: { text },
      },
    },
    logout,
  } = props;
  return (
    <div>
      {
        <Button
          className='logout-btn'
          onClick={() => {
            logout();
          }}
        >
          {text}
        </Button>
      }
    </div>
  );
};

export default Logout;
