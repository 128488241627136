import React, { useState, useEffect } from 'react';
import { Button, Alert } from 'reactstrap';
import { ModelPropertiesParser, readPath } from '../commons/modelPropertiesParser';
import html2canvas from 'html2canvas';
import config from '../../config';
import jsPDF from 'jspdf';
import RenderIcon from '../commons/RenderIcon';
import html2pdf from 'html2pdf.js';
import { WindowScroller } from 'react-virtualized';

const hideHTMLElement = (propString, context) => {
  let backwardCompString = propString;
  const propExpression = new RegExp(/\{\{if (.*?)\}\}/g);
  const midExpression = new RegExp(/\{\{ifnot (.*?)\}\}/g)
  const postExpression = new RegExp(/\{\{endif\}\}/g);
  if (
    backwardCompString === null ||
    typeof backwardCompString !== 'string' ||
    backwardCompString === ''
  )
    return null;
    backwardCompString =  backwardCompString.replace(postExpression, (pattr, matched) => {
      return "</span>";
    })
    backwardCompString =  backwardCompString.replace(midExpression, (pattr, matched) => {
      const value = readPath(context, matched);
      if(Array.isArray(value)) {
        return value.length ? "<span style='display:none'>" : "<span>";
      }
      return value ? "<span style='display:none'>" : "<span>";
    })
    return backwardCompString.replace(propExpression, (pattr, matched) => {
      const value = readPath(context, matched);
      if(Array.isArray(value)) {
        return value.length ? "<span>" : "<span style='display:none'>";
      }
      return value ? "<span>" : "<span style='display:none'>";
    })

}

const replacePages = (propString, fieldName) => {
  let backwardCompString = propString;
  const propExpression = new RegExp(/\{\{pgstart\}\}/g);
  const postExpression = new RegExp(/\{\{pgend\}\}/g);
  if (
    backwardCompString === null ||
    typeof backwardCompString !== 'string' ||
    backwardCompString === ''
  ) return null;
  backwardCompString =  backwardCompString.replace(postExpression, (pattr, matched) => {
    return "</div>";
  })

  let totalPages = 0;

  backwardCompString = backwardCompString.replace(propExpression, (pattr, matched) => {
    totalPages = totalPages + 1;
    return `<div class="codeninjapagebreak">`;
  })

  return {html: backwardCompString, totalPages}

}

const genPages = (doc, currentPage, totalPages, field) => {
  if(currentPage < totalPages) {
    doc.html(document.getElementById(`${field}_${currentPage}`), {
      callback: d => {
        console.log(currentPage, totalPages)
        d.addPage();
        genPages(d, currentPage+1, totalPages, field)
      },
      x: 20,
    });
    
  } else if(currentPage === totalPages) {
    doc.html(document.getElementById(`${field}_${currentPage}`), {
      callback: d => {
        d.save();
      },
      x: 20,
    });
  }
  
}

const GeneratePDF = props => {
  const [html, updateHTML] = useState(null);
  const [pdf, updatePDF] = useState(null);
  const [error, updateError] = useState(null);
  const [pages, setPages] = useState(0);
  const [generating, setGenerating] = useState(false);
 
  useEffect(() => {
    const {
      layoutItem: { itemProperties },
      workflowData,
    } = props;
    if (
      !html &&
      itemProperties &&
      itemProperties.generatePDFConfig &&
      itemProperties.generatePDFConfig.htmlField
    ) {
      const url = ModelPropertiesParser(
        `{{${itemProperties.generatePDFConfig.htmlField}}}`,
        workflowData
      );

      fetch(`${config.htmlTemplatePath}/${url}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'text/html',
        },
      })
        .then(res => {
          res
            .text()
            .then(data => {
              try {
                const {html, totalPages} = replacePages(data, itemProperties.generatePDFConfig.htmlField);
                setPages(totalPages);
                const replacedConditions = hideHTMLElement(html, workflowData);
                const modelPropertyOptions = {};
                if(itemProperties.generatePDFConfig.commaSeperator) {
                  modelPropertyOptions['numFormatter'] = itemProperties.generatePDFConfig.commaSeperator.value;
                }
                let updatedHtml = ModelPropertiesParser(replacedConditions, workflowData, modelPropertyOptions);
                updatedHtml = updatedHtml.replace('pdfDownload',itemProperties.generatePDFConfig.htmlField);
                updateHTML(updatedHtml);
                updateError(null);
              } catch (err) {
                updateError(err);
              }
            })
            .catch(error => {
              updateError(error);
              console.log(error)
            });
        })
        .catch(ex => {
          updateError(ex);
          console.log(ex);
        });
    }
  }, [html]);

  useEffect(() => {
    const {
      layoutItem: { itemProperties },
    } = props;
    if (
      itemProperties &&
      itemProperties.generatePDFConfig &&
      itemProperties.generatePDFConfig.pdfField
    ) {
      props.change(itemProperties.generatePDFConfig.pdfField, pdf);
    }
  }, [pdf]);

  const {
    label,
    layoutItem: {
      itemProperties: { beforeIcon },itemProperties
    },item
  } = props;

  const downloadbtnLabel =  itemProperties && itemProperties.generatePDFConfig && itemProperties.generatePDFConfig.downloadButtonLabel
  const hideDownloadBtnLabel =  itemProperties && itemProperties.generatePDFConfig && itemProperties.generatePDFConfig.hideDownloadBtnLabel
  return (
    <div className='generate-pdf'>
      {error && (
        <Alert color='warning' className='w-100'>
          {
            error.message === "Network Error" ? <div><b><i className='fa fa-exclamation-triangle' aria-hidden='true'></i>{' '}Network Error</b></div> : <p><b><i className='fa fa-exclamation-triangle' aria-hidden='true'></i>{' '}Invalid Template</b></p>
          }
          {error.message === "Network Error" ? "" : <span>{error.toString()}</span>}
        </Alert>
      )}
      {html && (
        <div className='html-wrapper'>
          <div className='action-control'>
            {true && (
              <Button
                onClick={ev => {
                  
                  try {
                    setGenerating(true);
                    var element = document.getElementById(itemProperties.generatePDFConfig.htmlField);
                    var opt = {
                      margin: 5,
                      filename:     'myfile.pdf',
                      pagebreak: {mode: 'avoid-all', after: [".codeninjapagebreak"]},
                      html2canvas:  { scale: 2 },
                      jsPDF:        { unit: 'pt', format: 'a4' }
                    };
                    html2pdf().set(opt).from(element,'element').outputPdf('datauristring').then(doc => {
                      const dataSplit = doc.split(',');
                      props
                          .base64Upload({
                            fileName: `${new Date().getTime()}.pdf`,
                            base64: dataSplit[1],
                            
                          })
                          .then(res => {
                            setGenerating(false);
                            updatePDF(res.data.data);
                          })
                          .catch(ex => {
                            setGenerating(false);
                            updateError(ex);
                            console.log(ex);
                          });
                    });                    
                  } catch (err) {
                    console.log(err);
                    setGenerating(false);
                    updateError(err);
                  }
                }}
                color='secondary'
                outline
                disabled={generating}
                className="genratePDF mr-4"
              >
                {beforeIcon ? <RenderIcon config={beforeIcon} /> : null}
                {generating ? 'Generating...': label ? label.text : 'Generate PDF'}
              </Button>
            )}
            <a
              className={`btn btn-outline-secondary ${pdf ? '' : 'disabled'} downloadbtn`}
              target='_blank'
              href={`${config.htmlTemplatePath}/${pdf}`}
            >
              <i className= {hideDownloadBtnLabel ? "fa fa-download mr-0":"fa fa-download mr-2"} aria-hidden="true"></i>
              {hideDownloadBtnLabel ? "":downloadbtnLabel ? downloadbtnLabel:"Download"}
            </a>
          </div>
          <div
            className='html-content'
            dangerouslySetInnerHTML={{ __html: html }}
          ></div>
        </div>
      )}
    </div>
  );
};

export default GeneratePDF;
