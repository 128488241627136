import {
    GET_COMPANY_DETAILS,
    GET_COMPANY_DETAILS_SUCCESS,
    GET_COMPANY_DETAILS_FAILURE,
    GET_ICONS,
    GET_ICONS_SUCCESS,
    GET_ICONS_FAILURE,
    GET_STYLES,
    GET_STYLES_SUCCESS,
    GET_STYLES_FAILURE
} from '../actions/company';
import { Map } from 'immutable';

const intitalState = Map({
    details: {
        isLoading: false,
        hasError: false,
        data: null
    },
    icons:{
        data:null
    },
    styleDetails: {
        isLoading: false,
        hasError: false,
        data: null
    },
});

const company = (state = intitalState, action = {}) => {
    switch (action.type) {
        case GET_COMPANY_DETAILS: return state.merge({
            details: {
                isLoading: true,
                hasError: false,
                data: null
            }
        });
        case GET_COMPANY_DETAILS_SUCCESS: return state.merge({
            details: {
                isLoading: false,
                hasError: false,
                data: action.payload.data
            }
        });
        case GET_COMPANY_DETAILS_FAILURE: return state.merge({
            details: {
                isLoading: false,
                hasError: true,
                data: null
            }
        });
        case GET_ICONS: return state.merge({
            icons: {
                data: null
            }
        });
        case GET_ICONS_SUCCESS: return state.merge({
            icons: {
                data: action.payload
            }
        });
        case GET_ICONS_FAILURE: return state.merge({
            icons: {
                data: null
            }
        });
        case GET_STYLES: return state.merge({
            styleDetails: {
                isLoading: true,
                hasError: false,
                data: null
            }
        });
        case GET_STYLES_SUCCESS: return state.merge({
            styleDetails: {
                isLoading: false,
                hasError: false,
                data: action.payload
            }
        });
        case GET_STYLES_FAILURE: return state.merge({
            styleDetails: {
                isLoading: false,
                hasError: true,
                data: null
            }
        });
        

        default: return state;
    }
};

export default company;