import React from 'react';
import { Button, Label, Modal, ModalHeader, ModalBody, ModalFooter ,Row,Col} from 'reactstrap';
import PrintText from './PrintText';
import config from '../../config';
import Uploader from './File';

class _Image extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isModalOpen: false
        }
        this.toggleModal = this.toggleModal.bind(this)
    }
    toggleModal() {
        this.setState({ isModalOpen: !this.state.isModalOpen });
    }
    render() {
        const { layoutItem, isReadOne,initialValues,classProperty } = this.props;
        const PreviousUploadedImage = initialValues && initialValues[classProperty]
        const { itemProperties } = layoutItem;
        const { label, field: { properties }, media } = itemProperties;
        const hasImage = media && media.path;
        const { isModalOpen } = this.state;
        const showPreviousUploadedImage = this.props.layoutItem.itemProperties && this.props.layoutItem.itemProperties.imageConfig && this.props.layoutItem.itemProperties.imageConfig.showPreviousUploadedImage ? this.props.layoutItem.itemProperties.imageConfig.showPreviousUploadedImage : null
        return <div>
            {
                isReadOne ?
                    <PrintText {...this.props} /> :
                    <Row>
                        <Col>
                        <Uploader {...this.props} />
                        </Col>
                        
                        {
                showPreviousUploadedImage && PreviousUploadedImage ? <Col><img className='img-fluid' src={`${config.assetsBasePath}${PreviousUploadedImage}`} /></Col> : ''
                         }
                    </Row>
                    
            }
             
            {
                hasImage ? <img className='img-fluid' src={`${config.assetsBasePath}${media.path}`} /> : ''
            }
        </div>
    }
}

export default _Image;