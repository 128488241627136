import React from 'react';
import PrintText from './PrintText';
import { Field } from 'redux-form/immutable';
import { renderField } from './_FORM_FIELDS';

const MediumText = (props) => {
    const { classProperty, type, validations, value, isReadOne, ...rest } = props;
    const {layoutItem: { itemProperties: { mediumTextConfig } }} = props;
    return (
        <div>
            {
                isReadOne ?
                    <PrintText {...props} /> :
                    <Field
                        name={classProperty}
                        component={renderField}
                        type={mediumTextConfig && mediumTextConfig.textArea ? "textarea" : "text"}
                        validate={validations}
                        value={value}
                        fieldType={type}
                        {...rest}
                    />
            }
        </div>
    );
}

export default MediumText;