import React from 'react';
import { Field } from 'redux-form/immutable';
import { Button } from 'reactstrap';
import { renderSelect } from '../elements/_FORM_FIELDS';

class YesNo extends React.Component {
  onSelectOption = (value) => {
    const { change, classProperty, onEnter } = this.props;
    change(classProperty, value);
    setTimeout(() => {
      onEnter();
    }, 1)
  }
  render() {
    return (
      <div style={{
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
      }}>
        <Button
            style={{
                margin: '12px'
            }}
            color="success"
            onClick={() => this.onSelectOption(true)}
        >Yes</Button>
        <Button
            style={{
                margin: '12px'
            }}
            color="success"
            onClick={() => this.onSelectOption(false)}
        >No</Button>
      </div>
    );
  }
}

export default YesNo;