import SmallText from './SmallText';
import Email from './Email';
import PositiveNumber from './PositiveNumber';
import Dropdown from './Dropdown';
import YesNo from './YesNo';
import _File from './File';
import RichText from './RichText';
import Phone from './Phone';

export const SMALLTEXT = SmallText;
export const EMAIL = Email;
export const POSITIVE_NUMBER = PositiveNumber;
export const NUMBER = PositiveNumber;
export const DROPDOWN = Dropdown;
export const YESNO = YesNo;
export const FILE = _File;
export const RICHHTEXT = RichText;
export const URL = SmallText;
export const PHONE = Phone;