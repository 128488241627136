const DataStore = (function(){
  var _data = {};
  var _handlers = [];

  function add(key, item){
    _data[key] = item;
    _handlers.forEach(element => {
      try {
        element.call(this, key, item);
      } catch(error) {
        console.log(error);
      }
    });
  }

  function get(key){
    return _data[key];
  }

  function registerHandler(handler) {
    let found = false;
    _handlers.forEach(element => {
      if(element == handler) {
        found = true;
      }
    });
    if(!found) _handlers.push(handler);
  }
  function deRegisterHandler(handler) {
    let found = false;
    _handlers.forEach(element => {
      if(element == handler) {
        found = true;
      }
    });
    if(found) delete _handlers[handler];
  }

  return {
    add: add,
    get: get,
    registerHandler: registerHandler,
    deRegisterHandler: deRegisterHandler
  };
}());

export default DataStore;