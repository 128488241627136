import React, { Component } from 'react';
import PropTypes from 'prop-types';

class FallbackForFilter extends Component {
    render() {
        return (
            <div>
                Filter element not created
            </div>
        );
    }
}

FallbackForFilter.propTypes = {

};

export default FallbackForFilter;