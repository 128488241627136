import React from 'react';
import PrintText from './PrintText';
import { Field } from 'redux-form/immutable';
import { renderField, convertToNumber, renderSelect } from './_FORM_FIELDS';
import { ModelPropertiesParser } from '../commons/modelPropertiesParser';

class _Number extends React.Component {
    componentDidMount(){
        const { workflow, isBase, layoutItem: { itemProperties: { numberConfig } } } = this.props;
        if(numberConfig && numberConfig.displayAsOneToOne) {
            const oneToOneModel = numberConfig.oneToOneModel;
            const name = oneToOneModel.name;
            
            this.props.getModelByNamePromise({ name: name }).then((res) => {
                const model = res.data;
                const typeId = model.id;
                const customerName = model.customerName;
                this.props.readAll({
                    typeId,
                    customerName,
                    workflow: {
                        id: workflow.id
                    },
                    isBase,
                    count: 10000,
                    start: 0,
                });
            });
        }
    }
    normalize(value) {
        if(!value) return value;
        return convertToNumber(value.value);
     }
    render(){
        const { typeModelCollection, classProperty, type, validations, value, isReadOne, ...rest } = this.props;
        const { layoutItem: { itemProperties: {numberConfig, field: { properties } }, displayAsOneToOne } } = this.props;
        const fieldToDisplay = numberConfig && numberConfig.fieldToDisplay;
        const fieldToSave = numberConfig && numberConfig.fieldToSave;
        const oneToOneModel = numberConfig && numberConfig.oneToOneModel;
        const modelData = typeModelCollection && oneToOneModel && typeModelCollection[`${oneToOneModel.id}`]
        const selectOptions = modelData && modelData.data && modelData.data.map((item) => {
            return {
                label: ModelPropertiesParser(fieldToDisplay, item),
                value: ModelPropertiesParser(`{{${fieldToSave}}}`, item),
                ...item
            }
        });
        return (
            <div>
                {
                    isReadOne ?
                        <PrintText {...this.props} /> :
                        <div>{
                            numberConfig && numberConfig.displayAsOneToOne ? <Field
                                name={classProperty}
                                component={renderSelect}
                                type="select"
                                validate={validations}
                                fieldType='TYPE'
                                valueKey='value'
                                labelKey='label'
                                options={selectOptions}
                                properties={properties}
                                normalize={this.normalize.bind(this)}
                                onBlur={() => {
                                    const cEventKeys = window.cEvents && Object.keys(window.cEvents);
                                    Array.isArray(cEventKeys) &&       cEventKeys.map(k => {
                                        k.startsWith(classProperty) &&
                                        window.cEvents[k](this.props.formValues);
                                    });
                                }}
                                {...rest}
                            /> : <Field
                                    name={classProperty}
                                    component={renderField}
                                    type="number"
                                    validate={validations}
                                    value={value}
                                    fieldType={type}
                                    onBlur={() => {
                                        const cEventKeys = window.cEvents && Object.keys(window.cEvents);
                                        Array.isArray(cEventKeys) &&     cEventKeys.map(k => {
                                            k.startsWith(classProperty) &&
                                            window.cEvents[k](this.props.formValues);
                                        });
                                    }}
                                    {...rest}
                                    normalize={convertToNumber}
                                />
                        }</div>
                }
            </div>
        );
    }
}

export default _Number;