/* NEENE PLAYER CONFIG
// 1. publicAPI: NODE SERVER Public APIs
// 2. authAPI: NODE SERVER APIs that needs X-AUTH-TOKEN
// 3. uploaderAPI: API-PLAYER URL for Uploading Files
// 4. pathResolverAPI: API-PLAYER URL for getting file via path
*/

const basePath = `${window.location.protocol}//${window.location.hostname}`;
const basePathHttps = `https://${window.location.hostname}`;
const basePathHttp = `http://${window.location.hostname}`;

const config = {
  development: {
    // publicAPI: `${basePathHttp}:8080/proxy/player/public`,
    publicAPI: `https://appointments.meradoctoronline.com/proxy/player/public`,
    // publicAPI: `https://hiimat.business.codeninja.ai/proxy/player/public`,
    // authAPI: `${basePathHttp}:8080/proxy/player`,
    authAPI: `https://appointments.meradoctoronline.com/proxy/player`,
    pathResolverAPI: `${basePathHttps}:8484/`,
    uploaderAPI: `${basePathHttp}:8484/api/player/upload/multi`,
    adminBase: `${basePathHttp}:9090`,
    // assetsBasePath: `${basePathHttp}:8484/api/player/l/`,
    assetsBasePath: `https://assets.business.codeninja.ai/`,
    conferenceAPI: `${basePathHttp}:8080/proxy/player/webrtc/`,
    socketAPI: `wss://${window.location.hostname}:8484/api/player/signaling`,
    merchantId: 184999,
    paymentAppUrl: 'https://appointments.meradoctoronline.com/processpayment',
    playerApi: `${basePathHttp}:8484/api/player`,
    htmlTemplatePath: `${basePath}/api/player/l`,
    //playerApi: `https://hiimat.business.codeninja.ai/api/player`
  },
  production: {
    publicAPI: `${basePath}/proxy/player/public`,
    authAPI: `${basePath}/proxy/player`,
    pathResolverAPI: `${basePath}/`,
    uploaderAPI: `${basePath}/api/player/upload/multi`,
    adminBase: `${basePath}`,
    assetsBasePath: `${window.location.protocol}//assets.business.codeninja.ai/`,
    merchantId: 184999,
    conferenceAPI: `${basePath}/proxy/player/webrtc/`,
    socketAPI: `wss://${window.location.hostname}/api/player/signaling`,
    paymentAppUrl: 'https://app.huwats.com/processpayment',
    htmlTemplatePath: `${basePath}/api/player/l`,
    playerApi: `${basePath}/api/player`,
  },
  mumbaiproduction: {
    publicAPI: `${basePath}/proxy/player/public`,
    authAPI: `${basePath}/proxy/player`,
    pathResolverAPI: `${basePath}/`,
    uploaderAPI: `${basePath}/api/player/upload/multi`,
    adminBase: `${basePath}`,
    assetsBasePath: `${window.location.protocol}//assets.business.codeninja.ai/`,
    merchantId: 184999,
    conferenceAPI: `${basePath}/proxy/player/webrtc/`,
    socketAPI: `wss://${window.location.hostname}/api/player/signaling`,
    paymentAppUrl: 'https://app.huwats.com/processpayment',
    htmlTemplatePath: `${basePath}/api/player/l`,
    playerApi: `${basePath}/api/player`,
  },
  thirdparty: {
    publicAPI: `${basePath}/proxy/player/public`,
    authAPI: `${basePath}/proxy/player`,
    pathResolverAPI: `${basePath}/`,
    uploaderAPI: `${basePath}/api/player/upload/multi`,
    adminBase: `${basePath}`,
    assetsBasePath: `${basePath}/api/player/l/`,
    merchantId: 184999,
    conferenceAPI: `${basePath}/proxy/player/webrtc/`,
    socketAPI: `wss://${window.location.hostname}/api/player/signaling`,
    paymentAppUrl: `${basePath}/processpayment`,
    playerApi: `${basePath}/api/player`,
    htmlTemplatePath: `${basePath}/api/player/l`,
  },
  localserver: {
    publicAPI: `${basePath}:8080/proxy/player/public`,
    authAPI: `${basePath}:8080/proxy/player`,
    pathResolverAPI: `${basePath}/`,
    uploaderAPI: `${basePath}:8484/api/player/upload/multi`,
    adminBase: `${basePath}:8484`,
    assetsBasePath: `${basePath}:8484/api/player/l`,
    merchantId: 184999,
    conferenceAPI: `${basePath}:8484/api/player/webrtc/`,
    socketAPI: `wss://${window.location.hostname}/api/player/signaling`,
    paymentAppUrl: 'https://app.huwats.com/processpayment',
    playerApi: `${basePath}:8484/api/player`,
    htmlTemplatePath: `${basePath}/api/player/l`,
  },
  brigade: {
    publicAPI: `${basePath}/proxy/player/public`,
    authAPI: `${basePath}/proxy/player`,
    pathResolverAPI: `${basePath}/`,
    uploaderAPI: `${basePath}/api/player/upload/multi`,
    adminBase: `${basePath}`,
    assetsBasePath: `${basePath}/api/player/l`,
    merchantId: 184999,
    conferenceAPI: `${basePath}/proxy/player/webrtc/`,
    socketAPI: `wss://${window.location.hostname}/api/player/signaling`,
    paymentAppUrl: 'https://app.huwats.com/processpayment',
    playerApi: `${basePath}/api/player`,
    htmlTemplatePath: `${basePath}/api/player/l`,
  },
};

console.log('ENV -> .', process.env.NODE_ENV);
console.log('Using config -> ', config[process.env.NODE_ENV]);

export default config[process.env.NODE_ENV];
