import React from 'react';
import PrintText from './PrintText';
import { Field } from 'redux-form/immutable';
import { renderSingleRadio, renderErrorMessage } from './_FORM_FIELDS';
import { Label, Row, Col, Button } from 'reactstrap';

const normalize = (value) => {
    if (!value) return value;
    if (value === 'YES') return true;
    if (value === 'NO') return false;
    return value;
}

const format = (value) => {
    if (typeof value !== 'boolean') return value;
    return value === true ? 'YES' : 'NO';
}

const answered = (bit, props) => {
    const classProperty = props.input.name;
    const { change, iterationMode, notifyParentToIncrementIndex, isInline, callInlineNext } = props;
    change(classProperty, bit);
    props.onDataChange();
    if (isInline && typeof callInlineNext === 'function') {
        callInlineNext();
    }
}

const renderYesNoButtons = (props) => {
    return (
        <div className='yesno-buttons-wrapper'>
            <Button disabled={props.disabled} onClick={answered.bind(this, 1, props)} className='yesno-buttons yes-btn' color='success' outline>
                <i className="fa fa-check mr-1" aria-hidden="true"></i>Yes
            </Button>
            <Button disabled={props.disabled} onClick={answered.bind(this, 0, props)} color='dark' outline className='yesno-buttons no-btn'>
                <i className="fa fa-times mr-1" aria-hidden="true"></i>No
            </Button>
        </div>
    )
}

const YesNo = (props) => {
    const { classProperty, type, validations, value, isReadOne, label, inlineNextState, ...rest } = props;
    const { layoutItem: { itemProperties: { field: { properties }, modelSelector } } } = props;
    const propExpression = new RegExp(/\{\{(.*?)\}\}/g);
    const _updatableKey = modelSelector ? modelSelector.replace(propExpression, (pattr, matched) => { return matched }) : classProperty;
    return (
        <div>
            <div>
                <Field
                    name={_updatableKey}
                    component={renderYesNoButtons}
                    fieldType={type}
                    {...rest}
                    normalize={normalize}
                    format={format}
                    onDataChange={() => {
                        const cEventKeys = window.cEvents && Object.keys(window.cEvents);
                        Array.isArray(cEventKeys) &&     cEventKeys.map(k => {
                            k.startsWith(classProperty) &&
                            window.cEvents[k](props.formValues);
                        });
                    }}
                    disabled={inlineNextState.isLoading}
                />
            </div>
            <Row>
                <Col>
                    <Field
                        name={_updatableKey}
                        component={renderErrorMessage}
                        validate={validations}
                    />
                </Col>
            </Row>
        </div>
    );
}

export default YesNo;