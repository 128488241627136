import React, { Component } from 'react';
import ReadAllComp from './ReadAllComp';
import CreateUpdate from './CreateUpdate';
import ReadOne from './ReadOne';
import FilterWrapperHOC from './filters/FilterWrapperHOC';

class ReadAll extends Component {
    constructor(props) {
        super(props);
        this.state = {
            filterStateMap: {},
            paginationStart: props.sortPagination.paginationStart,
            pageSize: props.sortPagination.pageSize
        }
		this._onFilterChange = this._onFilterChange.bind(this);
        this.onFiltersApplied = this.onFiltersApplied.bind(this);
        this.onClearAppliedFilters = this.onClearAppliedFilters.bind(this);
    }
    onChangePage = (paginationStart, pageSize) => {
        this.setState({ paginationStart, pageSize }, () => {
            this.onFiltersApplied();
        });
    }
    onChangePageSize = (pageSize) => {
        this.setState({ pageSize, paginationStart: 0 }, () => {
            this.onFiltersApplied();
        });
    }
    onFiltersApplied() {
        const filterMap = Object.assign({}, this.state.filterStateMap);
        const { workflowItem, workflow, isBase, sortPagination, container,columnLayoutModel } = this.props;
		const alternateTypeModel = container.properties.alternateTypeModel;
        const payload = {
            typeId: columnLayoutModel ? columnLayoutModel.id : alternateTypeModel ? alternateTypeModel.id : workflowItem.typeModel.id,
            customerName: workflowItem.typeModel.customerName,
            workflow,
            isBase,
            filters: [],
            filterInAnotherModel: {}
        };
        payload.start = this.state.paginationStart;
        payload.count = this.state.pageSize;
		sortPagination.paginationStart = this.state.paginationStart;
		sortPagination.pageSize = this.state.pageSize;
        if (Object.keys(filterMap).length) {
            for (let filterKey in filterMap) {
                const _f = filterMap[filterKey];
                const { collection, targetClassProperty, isSearch, filterBy, leafType, dateFilterType } = _f;
                if (collection === true) {
                    let modelName = targetClassProperty;
                    let modelProperty = targetClassProperty;
                    if (targetClassProperty.indexOf('.') !== -1) {
                        const splits = targetClassProperty.split('.');
                        modelName = splits.shift();
                        modelProperty = splits.join('.');
                    }
                    payload.filterInAnotherModel = {
                        modelName,
                        modelProperty
                    }
                }
                else {
                  const filterV = filterBy && filterBy.length && (filterBy[0] == 'NULL' || filterBy[0] === "") ? null:filterBy;
                  if(filterV) {
                    payload.filters.push({
                      filterField: targetClassProperty,
                      filterValue: filterV,
                      valueType: leafType,
                      isSearch: isSearch ? true: false,
					  filterOperator: dateFilterType == 'DATERANGE'?'BETWEEN':''
                  });
                  }
                }
            }
        }
		if(sortPagination.sortField != null) {
			payload.sort = [];
			payload.sort.push({
				sortField: sortPagination.sortField,
				descending: sortPagination.order == 'DESCENDING'? true:false
			});
		}
        this.props.triggerFilterReadAll(payload);
		this.props.setSortAndPagination(sortPagination);
    }
    onClearAppliedFilters() {
        const { workflowItem, workflow, isBase, container,columnLayoutModel } = this.props;
		const alternateTypeModel = container.properties.alternateTypeModel;
        const payload = {
            typeId: columnLayoutModel ? columnLayoutModel.id : alternateTypeModel ? alternateTypeModel.id : workflowItem.typeModel.id,
            customerName: workflowItem.typeModel.customerName,
            workflow,
            isBase,
            filters: []
        };
        this.setState({ filterStateMap: {} });
        this.props.triggerFilterReadAll(payload);
    }
    _onFilterChange(filterConfig, filterBy) {
        if (!filterBy) {
            this.setState({
                filteredDataList: this._dataList,
            });
        }
        const { filter, filterIndex } = filterConfig;
        const { filterStateMap } = this.state;
        filter.filterBy = filterBy
		if(filterBy.length == 0 ) {
			delete filterStateMap[`filter_${filterIndex}`];
		} else {
			filterStateMap[`filter_${filterIndex}`] =filter;
		}
        
        this.setState({ filterStateMap });
    }

    componentWillUnmount() {
        this.setState({ paginationStart: 0, pageSize: 25 });
    }

	componentWillReceiveProps(nextProps) {
        const { sortPagination } = nextProps;
		this.setState({ paginationStart: sortPagination.paginationStart, pageSize: sortPagination.pageSize });
    }
	
    render() {
		const { container, columnIndex, workflowItem, containerIndex,viewMode } = this.props;
   
        const { paginationStart, pageSize } = this.state;
        const { properties, properties: {colLayoutConfig, isStatic} } = container;
        const colOperation = colLayoutConfig && colLayoutConfig[`colOperation-${columnIndex}`];
        const { keyed,table } = workflowItem.layoutProperties.readAllViews[containerIndex | ""] || workflowItem.layoutProperties.readAllViews;
          const selectedTemplate = table && table.isConfigured && table.selectedTemplate;
        const keyedConfig = keyed && keyed.config;
        const columns = table && table.isConfigured && table.config.columns;
        const locationEnumeratorConfig = keyedConfig && keyedConfig.locationEnumeratorConfig;
        const keyedPagination = locationEnumeratorConfig != null ? locationEnumeratorConfig.allowPagination : false;
       if(selectedTemplate === 'Template2'){
        return (
            <div className='h-100'>
                {
                    (isStatic === false && colOperation && colOperation != 'READALL') || (!colOperation && isStatic === false) ?
                        <ReadOne {...this.props} /> :
                        <FilterWrapperHOC
                            pagination={viewMode === "KEYED" ? keyedPagination : false}
                            paginationStart={paginationStart}
                            pageSize={pageSize}
                            onChangePageSize={this.onChangePageSize}
                            onChangePage={this.onChangePage}
                            filterStateMap={this.state.filterStateMap}
                            onFiltersApplied={this.onFiltersApplied}
                            onFilterChange={this._onFilterChange}
                            onClearAppliedFilters={this.onClearAppliedFilters}
                            {...this.props}
                        >
                            <ReadAllComp 
                                pagination={viewMode === "KEYED" ? keyedPagination : false}
                                paginationStart={paginationStart}
                                pageSize={100000}
                                onFiltersApplied={this.onFiltersApplied}
                                onFilterChange={this._onFilterChange}
                                    filterMap = {Object.assign({}, this.state.filterStateMap)}
                                {...this.props} />
                        </FilterWrapperHOC>
                }
            </div>
        );  
       }
     
     
       return (
        <div className='h-100'>
            {
                (isStatic === false && colOperation && colOperation != 'READALL') || (!colOperation && isStatic === false) ?
                    <ReadOne {...this.props} /> :
                    <FilterWrapperHOC
                        pagination={viewMode === "KEYED" ? keyedPagination : true}
                        paginationStart={paginationStart}
                        pageSize={pageSize}
                        onChangePageSize={this.onChangePageSize}
                        onChangePage={this.onChangePage}
                        filterStateMap={this.state.filterStateMap}
                        onFiltersApplied={this.onFiltersApplied}
                        onFilterChange={this._onFilterChange}
                        onClearAppliedFilters={this.onClearAppliedFilters}
                        {...this.props}
                    >
                        <ReadAllComp 
                            pagination={viewMode === "KEYED" ? keyedPagination : true}
                            paginationStart={paginationStart}
                            pageSize={pageSize}
                            onFiltersApplied={this.onFiltersApplied}
                            onFilterChange={this._onFilterChange}
                                filterMap = {Object.assign({}, this.state.filterStateMap)}
                            {...this.props} />
                    </FilterWrapperHOC>
            }
        </div>
    );
    }
}

export default ReadAll;