import React from 'react';
import PrintText from './PrintText';
import { Field } from 'redux-form/immutable';
import { renderField, convertToFloat } from './_FORM_FIELDS';

const Decimal = props => {
  const { classProperty, type, validations, value, isReadOne, ...rest } = props;
  return (
    <div>
      {isReadOne ? (
        <PrintText {...props} />
      ) : (
        <Field
          name={classProperty}
          component={renderField}
          type='number'
          step={"any"}
          validate={validations}
          value={value}
          fieldType={type}
          {...rest}
          normalize={convertToFloat}
        />
      )}
    </div>
  );
};

export default Decimal;
