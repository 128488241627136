import { 
  GET_CALENDAR_EVENTS,
  GET_CALENDAR_EVENTS_SUCCESS,
  GET_CALENDAR_EVENTS_FAILURE,
  ADD_SUBWORKFLOW_EVENT,
  CLEAR_SUBWORKFLOW_EVENT
} from '../actions/calendar-event';
import { Map } from 'immutable';

const intitalState = Map({
  data: null,
  hasError: false,
  message: null,
  subworkflowEvent: null
});

const staticData = (state = intitalState, action = {}) => {
  switch (action.type) {

      case GET_CALENDAR_EVENTS: return state;
      
      case GET_CALENDAR_EVENTS_SUCCESS: return state.merge({
        data: action.payload.data,
        hasError: action.payload.hasError,
        message: action.payload.message
      });

      case GET_CALENDAR_EVENTS_FAILURE: return state.merge({
        data: null,
        hasError: action.payload.hasError,
        message: action.payload.message
      });

      case ADD_SUBWORKFLOW_EVENT: return state.merge({
        subworkflowEvent: action.payload
      })

      case CLEAR_SUBWORKFLOW_EVENT: return state.merge({
        subworkflowEvent: null
      })
      default: return state;
  }
};

export default staticData;