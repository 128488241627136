import React, { Component } from 'react';
import PrintText from './PrintText';
import { Field } from 'redux-form/immutable';
import { renderField } from './_FORM_FIELDS';
import InputRange from 'react-input-range';
import { Label } from 'reactstrap';
import 'react-input-range/lib/css/index.css';

class InputRangeWrapper extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: ''
        }
    }
    onSliderChanged(value) {
        const { input: { onChange }, label } = this.props;
        this.setState({ value }, () => {
            onChange(value);
        });
    }
    render() {
        const { minValue, maxValue, value, label } = this.props;
        return (
            <div>
                {
                    label.show ? <Label className='text-capitalize'>{label.text}</Label> : ''
                }
                <div className='p-3'>
                    <InputRange
                        maxValue={maxValue}
                        minValue={minValue}
                        value={this.state.value || minValue}
                        onChange={this.onSliderChanged.bind(this)}
                    />
                </div>
            </div>
        )
    }
}

const Slider = (props) => {
    const { classProperty, type, validations, value, isReadOne, change, ...rest } = props;
    const { layoutItem: { itemProperties: { field: { properties } } } } = props;
    let { possibleValues } = properties;
    let upperLimit = null;
    let lowerLimit = null;
    let numericArr = possibleValues.map((value) => {
        return Number(value);
    });
    numericArr = numericArr.sort();
    lowerLimit = numericArr[0];
    upperLimit = numericArr[numericArr.length - 1];
    return (
        <div>
            {
                isReadOne ?
                    <PrintText {...props} /> : <Field
                        name={classProperty}
                        component={InputRangeWrapper}
                        //validate={validations}
                        //fieldType={type}
                        minValue={lowerLimit}
                        maxValue={upperLimit}
                        value={value}
                        {...rest}
                        onChange={value => change(classProperty, value)}
                        label={props.label}
                    />


            }
        </div>
    );
}

export default Slider;