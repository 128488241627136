import React from 'react';
import { AreaChart, Area, ResponsiveContainer, CartesianGrid, XAxis, YAxis, Tooltip, Legend, Label } from 'recharts';

export default class AREACHART extends React.Component {

  render() {
    const { graphData, colors, legendName, xLabel, yLabel, graphHeight, series } = this.props;
    return (
      <div style={{width: '100%'}} className='reportMainContainer'>
        <ResponsiveContainer height={graphHeight}>
          <AreaChart data={graphData}>
            <defs>
              <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8} />
                <stop offset="95%" stopColor="#8884d8" stopOpacity={0} />
              </linearGradient>
            </defs>
            <XAxis dataKey="xProperties">
              <Label value={xLabel} offset={0} position="bottom" />
            </XAxis>
            <YAxis label={{ value: yLabel, angle: -90, position: 'outside', offset: -10 }} />
            <CartesianGrid strokeDasharray="3 3" />
            <Tooltip />
            <Legend verticalAlign="top" height={36} />
            {
              series?
              series.map((y, index) => {
                return <Area name={y.legendName} type="monotone" dataKey={y.yPropertyName} stroke={colors[index]} fillOpacity={1} fill="url(#colorUv)" />
              }):
              <Area name={legendName} type="monotone" dataKey="yProperties" stroke="#8884d8" fillOpacity={1} fill="url(#colorUv)" />
            }
          </AreaChart>
        </ResponsiveContainer>
      </div>
    );
  }
}
