import React from 'react';
import { Field, FieldArray } from 'redux-form/immutable';
import { renderSingleRadio, renderErrorMessage } from './_FORM_FIELDS';
import { Label, Row, Col, Button, FormGroup, Input } from 'reactstrap';
import RichTextResolver from '../commons/RichTextResolver';
import RichhText from './RichhText';
import JSONPath from 'jsonpath';

const setStaticContent = (classProperty, change, rawContent ) => {
  change(classProperty, JSON.stringify(rawContent));
}

const nextCall = (props, state, key) => {
  const  { selectedOption } = state;
  const { isInline, callInlineNext } = props;
  const fieldKey = `${props.inlineFormKey}.${key}`;
  props.change(fieldKey, selectedOption);
  if (isInline && typeof callInlineNext === 'function') {
      callInlineNext();
  }
}

class DynamicMultiChoice extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      selectedOption: null
    }
  }

  renderField({ input, label, type, meta: { touched, error } }) {
    return <FormGroup>
      <Label for={input.name}>{label}</Label>
      <Input type={type} {...input} />
      {touched && error && <small className='text-danger form-text mt-2 animated fadeIn'><i className="fa fa-exclamation-triangle" aria-hidden="true"></i> {error}</small>}
    </FormGroup>
  }
  renderRadio({ input, optionKey, optionValue, option, onChangeOption }) {
    return <Label>
      <input onClick={() => onChangeOption(optionKey, optionValue)} 
        type="radio"
        value={optionValue}
        name={input.name}
      />{' '}
      {
        `${optionValue}.   `
      }
      <div style={{display: 'inline-block'}}>
      <RichTextResolver rawContent={JSON.parse(option)} />
      </div>
    </Label>
  }
  renderPossibleValues({ isPlainText, savedData, label, fields, meta: { error }, change, input }) {
    return <div>
        <Label>{label.text}</Label>
        {
          fields.map((member, index) => {
            return <div key={index} style={{position: 'relative'}}>
              <Label>{`Option ${index + 1}`}</Label>
              <RichhText 
                setStaticContent={setStaticContent.bind(this, member, change)} 
                savedData={savedData}
                isPlainText={isPlainText}
              />
              <Button style={{
                position: 'absolute',
                right: '10px',
                top: '40px'
              }} onClick={() => fields.remove(index)} size='sm' color='link'>
                <i className="fa fa-trash text-danger" aria-hidden="true"></i>
              </Button>
            </div>
          })
        }
      <div className='d-flex justify-content-left'>
        <Button className='ml-2' size='sm' color='dark' outline onClick={() => fields.push('')}>
          <i className="fa fa-plus-circle" aria-hidden="true"></i> Add options
        </Button>
      </div>
    </div>
  }

  getOptionsData = (data, classProperty) => {
    if (classProperty.indexOf('.') !== -1) {
      const splits = classProperty.split('.');
      const dataToSend = data[splits[0]];
      splits.shift();
      return this.getOptionsData(dataToSend, splits.join('.'));
    }
    return data[classProperty];
  }

  onChangeOption = (key, value) => {
    this.props.change(key, value);
    this.setState({ selectedOption: value });
  }

  render() {
    const { workflow: { workflowData }, classProperty, type, validations, value, isReadOne, label, inlineNextState, inlineFormKey, change, ...rest } = this.props;
    const { layoutItem: { itemProperties: { dynamicMultiChoiceConfig, modelSelector } } } = this.props;
    const _saved_value = workflowData ? JSONPath.value(workflowData, `$.${classProperty}`) : null;
    let savedData = null;
    let isPlainText = false;
    if (_saved_value && typeof _saved_value === 'string') {
        try {
            savedData = JSON.parse(_saved_value);
        }
        catch(ex) {
            savedData = _saved_value;
            isPlainText = true;
        }
    }
    const propExpression = new RegExp(/\{\{(.*?)\}\}/g);
    const _updatableKey = modelSelector ? modelSelector.replace(propExpression, (pattr, matched) => { return matched }) : classProperty;
    const optionTypeCharCode = dynamicMultiChoiceConfig && dynamicMultiChoiceConfig.default.value ? dynamicMultiChoiceConfig.default.value.charCodeAt(0) : "1".charCodeAt(0);
    let data = workflowData && this.getOptionsData(workflowData, classProperty);
    return (
      <div>
        {
          isReadOne ? (
            <div>
              <Label className="text-muted">{label.text}</Label>
              {
                data && data.map((option, index) => {
                  const value = String.fromCharCode(optionTypeCharCode + index);
                  const key = `${inlineFormKey}.${_updatableKey}`;
                  return (
                    <div key={index}>
                      <Field 
                        name={key}
                        component={this.renderRadio}
                        fieldType='radio'
                        {...rest}
                        optionValue={value}
                        optionKey={key}
                        option={option}
                        disabled={inlineNextState.isLoading}
                        onChangeOption={this.onChangeOption}
                      />
                    </div>
                  )
                })
              }
              <Row>
                <Col sm="6">
                  <Button className='ml-2' size='sm' color='primary' block outline onClick={() => nextCall(this.props, this.state, _updatableKey)}>Next</Button>
                </Col>
              </Row>
            </div>
          ) : (
            <div>
              
              <FieldArray savedData={savedData} isPlainText={isPlainText}  change={change} normalize={this.normalizeToString} label={label} name={classProperty} component={this.renderPossibleValues.bind(this)} />
            </div>
          )
        }
      </div>
    )
  }
}

export default DynamicMultiChoice;