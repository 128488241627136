import client from '../client';
import config from '../config';
import { showLoading, hideLoading } from 'react-redux-loading-bar';

export const GET_COMPANY_DETAILS = 'GET_COMPANY_DETAILS';
export const GET_COMPANY_DETAILS_SUCCESS = 'GET_COMPANY_DETAILS_SUCCESS';
export const GET_COMPANY_DETAILS_FAILURE = 'GET_COMPANY_DETAILS_FAILURE';

export const GET_ICONS = 'GET_ICONS';
export const GET_ICONS_SUCCESS = 'GET_ICONS_SUCCESS';
export const GET_ICONS_FAILURE = 'GET_ICONS_FAILURE';

export const GET_STYLES = 'GET_STYLES';
export const GET_STYLES_SUCCESS = 'GET_STYLES_SUCCESS';
export const GET_STYLES_FAILURE = 'GET_STYLES_FAILURE';

export const getCompanyDetails = () => {
    return (dispatch) => {
        if(window.companyDetailsRequestSent)
            return;
        dispatch(showLoading());
        if(window.cachedCompanyData) {
            dispatch({
                type: GET_COMPANY_DETAILS_SUCCESS,
                payload: window.cachedCompanyData
            });
        } else {
            dispatch({
                type: GET_COMPANY_DETAILS,
                payload: {}
            });
            window.companyDetailsRequestSent = true;
            window.companyDetailsDetailsFetched = false;
            client().post(`${config.authAPI}/company/getdetails`).then((res) => {
                window.cachedCompanyData = res.data;
                window.companyDetailsDetailsFetched = true;
                dispatch({
                    type: GET_COMPANY_DETAILS_SUCCESS,
                    payload: res.data
                });
                dispatch(hideLoading());
            }).catch((error) => {
                window.companyDetailsRequestSent = false;
                dispatch({
                    type: GET_COMPANY_DETAILS_FAILURE,
                    payload: error
                });
                dispatch(hideLoading());
            });
        }
    };
}

export const getCustomIcons = () => {
    const hostName = window.location.hostname.substring(0, window.location.hostname.indexOf('.'))
    const iconUrl = window.location.origin + "/api/player/l/public/" + hostName + '.json';
    console.log(iconUrl,"iconUrl")
    
    return (dispatch) => {
        dispatch({
            type: GET_ICONS,
            payload: {}
        });
        if( !hostName || hostName ===  '' ) {

        }else {
            client().get(`${iconUrl}`).then((res) => {
                dispatch({
                    type: GET_ICONS_SUCCESS,
                    payload: res.data
                });
                dispatch(hideLoading());
            }).catch((error) => {
                window.companyDetailsRequestSent = false;
                dispatch({
                    type: GET_ICONS_FAILURE,
                    payload: error
                });
                dispatch(hideLoading());
            });
        }
        }
          
    }

export const getLanguageTranslations = (payload) => {
    return (dispatch) => {
        return client().post(`${config.publicAPI}/language/translation/get`, payload);
    }
}

export const getStyles = () => {
    const styleApi = `${config.authAPI}/getstyles`;
    return (dispatch) => {
        dispatch({
            type: GET_STYLES,
            payload: {}
        });
        client().post(`${styleApi}`).then((res) => {
            window.cachedCompanyData = res.data;
            dispatch({
                type: GET_STYLES_SUCCESS,
                payload: res.data
            });
        }).catch((error) => {
            dispatch({
                type: GET_STYLES_FAILURE,
                payload: error
            });
        });
    };
}