import React from 'react';
import RatingComp from 'react-rating';
import { Cell } from 'fixed-data-table-2';

const RatingCell = (props) => {
  const { rows, column, columnKey, rowIndex, config: { ratingConfig }, width, config } = props;
  const valueToRender = rows.getObjectAt(rowIndex)[columnKey];
  if(!ratingConfig) return <Cell><span >{valueToRender}</span></Cell> 
  const { ratingType, ratingLength, ratingStep } = ratingConfig;
  let cellAlignment;
  if(config && config.styles && config.styles) {
        const styles = config.styles
        cellAlignment = styles.alignment && styles.alignment.value;
    }
  return  <Cell align={cellAlignment ? cellAlignment : 'left'} width={width} className="rating-container">
      <RatingComp
          start={0}
          stop={parseInt(ratingLength) || 5}
          initialRating={valueToRender}
          fractions={ratingType.value === 'star-half' ? 2 : 1}
          step={parseInt(ratingStep) || 1}
          emptySymbol={`rating-symbol fa fa-${ratingType.value === 'star-half' ? 'star-o' : ratingType.value === 'thumbs-up' ? 'thumbs-o-up' : ratingType.value+'-o'} fa-2x ${ratingType.value}`}
          fullSymbol={`rating-symbol fa fa-${ratingType.value === 'star-half' ? 'star' : ratingType.value} fa-2x ${ratingType.value}`}
          readonly
      />
  </Cell>
}

export default RatingCell;