import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Col, Input } from 'reactstrap';

class NumberFilter extends Component {
	constructor(props) {
        super(props);
        super(props);
        this.state = {
            value: props.value || ''
        }
    }
	
	componentWillReceiveProps(nextProps) {
		const { filterStateMap, filterIndex } = nextProps;
        const savedFilterState = filterStateMap[`filter_${filterIndex}`];
		if(!savedFilterState) {
			this.setState({
				value: ''
			})
		}
		
	}
	
	triggerFilterChange(value) {
        const { onFilterChange, filter, filterIndex } = this.props;
        this.setState({ value });
        const filterConfig = {
            filter,
            filterIndex
        }
		value != "" ? onFilterChange(filterConfig, [value]) : onFilterChange(filterConfig, []);
    }
	
	onChange(e) {
        const filterValue = e.target.value;
        this.triggerFilterChange(filterValue);
    }
   
	render() {
        const { filter, elem_id } = this.props;
        return (
            <Col xs="12" className='w-100 p-0'>
                <Input 
					value={this.state.value}
					onChange={this.onChange.bind(this)}
					placeholder={'Enter value'}
					type={'Number'}
				/>
            </Col>
        );
    }
}

export default NumberFilter;