import React from 'react';
import { Button, ButtonGroup } from 'reactstrap';

class ToolbarRender extends React.Component {
  render() {
    const { label, onNavigate } = this.props;
    return (
      <div className="toolbar-renderer">
        <div className="toobar-button-group">
          <ButtonGroup>
            <Button className="button" outline onClick={() => onNavigate('PREV')}><i className='fa fa-chevron-left'></i></Button>
            <Button className="button" outline onClick={() => onNavigate('TODAY')}>Today</Button>
            <Button className="button" outline onClick={() => onNavigate('NEXT')}><i className='fa fa-chevron-right'></i></Button>
          </ButtonGroup>
        </div>
        {
          label
        }
        <div className="EmptyDiv"></div>
      </div>
    )
  }
}

export default ToolbarRender;