import React from 'react';
import { Button } from 'reactstrap';

class Refresh extends React.Component {

  render() {
    const { layoutItem: { itemProperties }, refreshWF } = this.props;
    const { label, beforeIcon, textAlignment } = itemProperties;
    return <div className={`layout-item-refresh d-flex justify-content-${(textAlignment && textAlignment.value) ? (textAlignment.value === 'left' ? 'start' : 'end') : 'center'} align-items-center`}>
      {
        label.show ? <Button onClick={() => { refreshWF() }} title={label.text} size="sm">{
          (beforeIcon && beforeIcon.cssClass) ? <i className={`fa ${beforeIcon.cssClass} mr-1`} aria-hidden="true"></i> : ''
        }{label.text}</Button> : <Button onClick={() => { refreshWF() }} title="Refresh" size="sm"><i className={`fa ${beforeIcon.cssClass} mr-1`} aria-hidden="true"></i></Button>
      }
    </div>
  }
}

export default Refresh;