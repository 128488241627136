import React from 'react';
import { BarChart, Bar, ResponsiveContainer, CartesianGrid, XAxis, YAxis, Tooltip, Legend, Cell, Label } from 'recharts';

class BARCHART extends React.Component {
  render() {
    const { graphData, colors, legendName, xLabel, yLabel, graphHeight, series,barChartConfig } = this.props;
    const barSize = barChartConfig && parseInt(barChartConfig.barSize);
    const barColor = barChartConfig && barChartConfig.barColor;
    const oneColorOnly = barChartConfig && barChartConfig.oneColorOnly;

    return (
      <div style={{width: '100%'}} className='reportMainContainer'>
        <ResponsiveContainer height={graphHeight}>
          <BarChart data={graphData} margin={{ top: 0, right: 10, left: 5, bottom: 20 }}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="xProperties">
              <Label value={xLabel} offset={0} position="bottom" />
            </XAxis>
            <YAxis label={{ value: yLabel, angle: -90, position:"inside", offset: -15,dx:40 }}  />
            <Tooltip />
            <Legend verticalAlign="top" height={36} />
            {
              series?series.map((y, index) => {
                return <Bar name={y.legendName} dataKey={y.yPropertyName} barSize={barSize}>
                {
                  graphData && graphData.map((entry, index) => {
                    return <Cell key={`cell-${index}`} fill={oneColorOnly ? barColor : colors[index]} stroke={oneColorOnly ? barColor : colors[index]} strokeWidth={index === 2 ? 4 : 1} />
                  })
                }
              </Bar>
              }):
              <Bar name={legendName} dataKey="yProperties" maxBarSize={barSize}>
              {
                graphData && graphData.map((entry, index) => {
                  return <Cell key={`cell-${index}`} fill={oneColorOnly ? barColor : colors[index]} stroke={oneColorOnly ? barColor : colors[index]} strokeWidth={index === 2 ? 4 : 1} />
                })
              }
            </Bar>
            }
            
          </BarChart>
        </ResponsiveContainer>
      </div>
    );
  }
}

export default BARCHART;