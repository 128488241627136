import { Map, List } from 'immutable';
import {
    TOGGLE_ASSIGNVALUE_MODAL,
    ADD_SOURCE_ID,
    REMOVE_SOURCE_ID
} from '../actions/workflow-actions';

const intitalState = Map({
    show: false,
    action: null,
    title: 'Default Action Title',
    body: '',
    actionButtonText: null,
    target_ids: List([]),
    source_ids: List([])
});

const addSourceId = (state, id) => {
    let currentIds = state.get('source_ids');
    currentIds = currentIds.push(id);
    return state.merge({ source_ids: currentIds });
}

const removeSourceId = (state, id) => {
    let currentIds = state.get('source_ids');
    const indexToDelete = currentIds.indexOf(id);
    if (indexToDelete !== -1) {
        currentIds = currentIds.delete(indexToDelete);
    }
    return state.merge({ source_ids: currentIds });
}


const assignValueModal = (state = intitalState, action = {}) => {
    switch (action.type) {
        case TOGGLE_ASSIGNVALUE_MODAL: return state.merge({
            show: action.payload.show,
            action: action.payload.action,
            data: action.payload.data,
            target_ids: action.payload.target_ids
        });
        case ADD_SOURCE_ID: return addSourceId(state, action.payload);
        case REMOVE_SOURCE_ID: return removeSourceId(state, action.payload);
        default: return state;
    }
};

export default assignValueModal;