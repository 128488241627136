import React from 'react';
import { Button, ButtonGroup, Tooltip } from 'reactstrap';

class EventRenderer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isTooltipOpen: false
    }
  }

  toggle = () => {
    this.setState({
      isTooltipOpen: !this.state.tooltipOpen
    });
  } 
  render() {
    const { event, title } = this.props;
    return (
      <div className="event-renderer">
        <div id={`tooltip-${event.id}`}>{event.name}</div>
      </div>
    )
  }
}

export default EventRenderer;