import { Map, List } from 'immutable';
import {
    ADD_BREADCRUMB,
    REMOVE_BREADCRUMB,
    POP_BREADCRUMB,
    CLEAR_BREADCRUMS
} from '../actions/breadcrumbs';

const addBreadcrumb = (state, action) => {
    let items = state.get('items');
    items = items.push(action.payload);
    return state.set('items', items);
}

const removeBreadcrumb = (state, action) => {
    let items = state.get('items');
    items = items.take(action.payload.index);
    return state.set('items', items);
}

const popBreadcrumb = (state, action) => {
    let items = state.get('items');
    items = items.pop();
    return state.set('items', items);
}

const intitalState = Map({
    items: List([])
});

const breadcrumbs = (state = intitalState, action = {}) => {
    switch (action.type) {
        case ADD_BREADCRUMB: return addBreadcrumb(state, action);
        case REMOVE_BREADCRUMB: return removeBreadcrumb(state, action);
        case POP_BREADCRUMB: return popBreadcrumb(state, action);
        case CLEAR_BREADCRUMS: return intitalState;
        default: return state;
    }
};

export default breadcrumbs;