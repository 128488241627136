import React, { Component } from 'react';
import { Input, FormGroup, Label, Button } from 'reactstrap';
import Dropzone from 'react-dropzone';
import { connect } from 'react-redux';
import { toJS } from '../../containers/to-js';
import { uploadFiles } from '../../actions/uploader';
import { Field } from 'redux-form/immutable';
import { toast } from 'react-toastify';

let dropzoneRef;

class Uploader extends Component {
    constructor(props) {
        super(props);
        this.state = {
            files: []
        }
        this.uploaderToastId = null;
    }
    onDrop(accepted, rejected) {
        this.uploaderToastId = toast.info('Upload in progress. Please wait...', {autoClose: false, position: toast.POSITION.TOP_CENTER});
        this.setState({ files: [...accepted] });
        setTimeout(() => {
            this.props.uploadFiles({ files: this.state.files, namespace: 'MODEL_UPLOADER' })
        });
    }
    componentWillReceiveProps(nextProps) {
        const { uploader, classProperty, change } = nextProps;
        if (uploader && !uploader.hasError && uploader.data) {
            const url = uploader.data.data[0];
            change(classProperty, url);
            toast.update(this.uploaderToastId, {
                render: "Upload Done!",
                type: toast.TYPE.SUCCESS,
                autoClose: 5000,
                position: toast.POSITION.TOP_CENTER
            });
        }
        if (uploader && uploader.hasError) {
            toast.update(this.uploaderToastId, {
                render: "Something went wrong. Could not upload file at this time.",
                type: toast.TYPE.ERROR,
                autoClose: 5000,
                position: toast.POSITION.TOP_CENTER
            });
        }
    }
    removeFile(index) {
        let files = Object.assign([], this.state.files);
        files.splice(index, 1);
        this.setState({ files });
    }
    render() {
        const { itemProperties } = this.props.layoutItem;
        const { uploader, validations, classProperty, isReadOne, onEnter } = this.props;
        const { label, field, placeholder } = itemProperties;
        const { files } = this.state;
        return (
            <FormGroup className='player-dropzone-uploader-wrapper'>
                <Dropzone className='player-dropzone-uploader' accepts="application/msword, application/pdf" multiple={false} ref={(node) => { dropzoneRef = node; }} onDrop={this.onDrop.bind(this)}>
                    <div className='d-flex flex-column pt-2 pb-2'>
                        <p className='text-center'><i className="fa fa-3x fa-cloud-upload text-info" aria-hidden="true"></i></p>
                        <p className='text-center mb-1 mt-0'>Drag &amp; Drop files here</p>
                        <p className='text-center mb-1 mt-0'>or</p>
                        <p className='text-center mb-0'>
                            <Button onClick={(ev) => { ev.preventDefault() }} color='success' outline>Choose File</Button>
                        </p>
                    </div>
                </Dropzone>

                <FilesList files={files} removeFile={this.removeFile.bind(this)} {...this.props} />
                <div className='mt-2' style={{ textAlign: 'center'}}>
                  <Button disabled={files.length === 0} 
                    onClick={(ev) => { 
                      onEnter();
                    }}
                    color='primary'
                  >{files.length === 0 ? 'Please upload at least one file' : 'Next' }</Button>
                </div>
            </FormGroup>
        );
    }
}

const formatFileSize = (bytes, decimalPoint) => {
    if (bytes == 0) return '0 Bytes';
    var k = 1000,
        dm = decimalPoint || 2,
        sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
        i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

const FilesList = (props) => {
    const { files, removeFile } = props;
    return (
        <div className='mt-2'>
            {
                files.map((file, index) => {
                    return <div key={index} className='file-info d-flex justify-content-between'>
                        <div>
                            <a href={file.preview} target='_blank' className='mb-0'>
                                {file.name} ({formatFileSize(file.size)}){' '}
                                <i className="fa fa-external-link ml-1 text-muted" aria-hidden="true"></i>
                            </a>
                        </div>
                        <div>
                            <Button onClick={removeFile.bind(this, index)} color='link' className='p-0' title='Remove this file'>
                                <i className="fa fa-times text-danger" aria-hidden="true"></i>
                            </Button>
                        </div>
                    </div>
                })
            }
        </div>

    )
}

const mapStateToProps = (state) => {
    return {
        uploader: state.getIn(['uploader', 'uploader_for_MODEL_UPLOADER'])
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        uploadFiles: (payload) => {
            dispatch(uploadFiles(payload));
        }
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(toJS(Uploader));