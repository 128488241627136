import React from 'react';
import { Button } from 'reactstrap';

const ExternalLink = (props) => {
    const { navigateTo, action: { actionProperties: { elementText } } } = props;
    return (
        <Button title='Opens in a new window' target='_blank' href={navigateTo} color="link" size="sm">
            <i className="fa fa-external-link mr-1" aria-hidden="true"></i>
            {elementText}
        </Button>
    )
}

export default ExternalLink;