import React, { Component } from 'react';
import { connect } from 'react-redux';
import { toJS } from '../../containers/to-js';
import { getTranslate } from 'react-localize-redux';
import { getWorkflowFromCode, endWorkflow, startWorkflow } from '../../actions/workflows';
import { inlineNext } from '../../actions/workflow-item';
import PropTypes from 'prop-types';
import WorkflowContainer from '../../containers/workflow/WorkflowContainer';
import { reduxForm, getFormValues } from 'redux-form/immutable';
import { FormSection, submit } from 'redux-form';
import { fromJS } from 'immutable';
import { reduxFormKeys } from '../../dto/workflow';

class InlineWorkflow extends Component {
    constructor(props) {
        super(props);
        this.state = {
            workflowId: null,
            inlineNextState: {
                isLoading: false,
                hasError: false,
                error: null,
            },

        }
        this.callInlineNext = this.callInlineNext.bind(this)
    }
    componentDidMount() {
        const { code, getWorkflowFromCode, isAccordian, startWorkflow, tid, workflow, iterationMode} = this.props;
        getWorkflowFromCode(code).then((res) => {
            const workflowId = res.data.data;
            this.setState({ workflowId }, () => {
                (isAccordian) && startWorkflow({
                    displayMode: 'INLINE',
                    id: workflowId,
                    tid
                })
            });
        });

        // iterationMode && notifyParentToIncrementIndex();
        // (isAccordian) && getWorkflowFromCode(code).then((res) => {
        //     const workflowId = res.data.data;
        //     this.setState({ workflowId }, () => {
        //          startWorkflow({
        //             displayMode: 'INLINE',
        //             id: workflowId,
        //             tid: 1
        //         })
        //     });
        // });
    }
    componentWillReceiveProps(nextProps) {
        const { inlineWorkflows, change } = nextProps;
        const { workflowId } = this.state;
        if (workflowId !== null && Object.keys(inlineWorkflows).length && inlineWorkflows.hasOwnProperty(workflowId)) {
           const thisWorkflow = inlineWorkflows[workflowId];
           const _formKey = `${reduxFormKeys.inlinePrepend}${workflowId}`;
          //  if (thisWorkflow.hasOwnProperty('workflowData') && Object.keys(thisWorkflow.workflowData).length) {
          //       change(_formKey, fromJS(thisWorkflow.workflowData));
          //  }
        }
    }
    callInlineNext() {
        const { workflowId } = this.state;
        const { inlineWorkflows, inlineNext, baseWorkflowFormValues, iterationMode, notifyParentToIncrementIndex, form, shouldCallEnd, endWorkflow, isAccordian } = this.props;
        const workflow = inlineWorkflows[workflowId];
        const inlineNextState = Object.assign({}, this.state.inlineNextState);
        const keys = {
            parent: form,
            inline: `${reduxFormKeys.inlinePrepend}${workflowId}`
        };
        inlineNextState.isLoading = true;
        this.setState({ inlineNextState });
        inlineNext(workflow, keys).then((res) => {
            const responseWorkflow = res.data;
            if (responseWorkflow && iterationMode === true) {
                notifyParentToIncrementIndex();
                if(isAccordian) {
                  endWorkflow({ workflowId: responseWorkflow.workflowModel, sessionId: responseWorkflow.sessionId });
                }
            }
            inlineNextState.isLoading = false;
            this.setState({ inlineNextState });
        });
    }
    componentWillUnmount() {
        const { inlineWorkflows } = this.props;
        const sessionId = inlineWorkflows[this.state.workflowId].sessionId;
        this.props.endWorkflow({ workflowId: this.state.workflowId, sessionId });
    }

    render() {
        const { inlineWorkflows, change, notifyParentToIncrementIndex, isAccordian } = this.props;
        const { workflowId, inlineNextState } = this.state;
        
        if (!inlineWorkflows || !workflowId || !Object.keys(inlineWorkflows).length) return null;
        const inlineWorkflow = inlineWorkflows[workflowId];
        if (!inlineWorkflow) return null;
        // if(!inlineWorkflow.workflowData && !isAccordian) {
        //     this.callInlineNext();
        //     return null;
        // }
        const workflowData = inlineWorkflow.workflowData || {};
        const form = `${reduxFormKeys.inlinePrepend}${workflowId}`;
        return (
            <FormSection name={form}>
                <WorkflowContainer
                    onHandleSubmit={isAccordian ? ()=>{} : null}
                    change={change}
                    displayHeader={false}
                    noPadding={true}
                    workflow={inlineWorkflow}
                    isBase={false}
                    isInline={true}
                    inlineFormKey={form}
                    callInlineNext={this.callInlineNext}
                    notifyParentToIncrementIndex={notifyParentToIncrementIndex}
                    inlineNextState={inlineNextState}
                />
            </FormSection>
        );
    }
}

InlineWorkflow.propTypes = {
    iterationMode: PropTypes.bool,
    noPadding: PropTypes.bool
};

InlineWorkflow.defaultProps = {
    iterationMode: false,
    noPadding: false
};

const mapStateToProps = (state, ownProps) => {
    return {
        translate: getTranslate(state.get('locale')),
        inlineWorkflows: state.getIn(['workflow', 'inlineWorkflows']),
        baseWorkflowFormValues: getFormValues('create-update-form-base')(state)
    };
};

const mapDisptachToProps = (dispatch) => {
    return {
        getWorkflowFromCode: (code) => {
            return dispatch(getWorkflowFromCode(code));
        },
        inlineNext: (workflow, formKey) => {
            return dispatch(inlineNext(workflow, formKey))
        },
        endWorkflow: (payload) => {
            dispatch(endWorkflow(payload));
        },
        startWorkflow: (payload) => {
            dispatch(startWorkflow(payload));
        }
    }
}

InlineWorkflow = reduxForm({
    form: 'create-update-form-base',
    destroyOnUnmount: false
})(InlineWorkflow);

export default connect(mapStateToProps, mapDisptachToProps)(toJS(InlineWorkflow));