import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SubWorkflowTrigger from './SubWorkflowTrigger';
import ContextualTextColor from './ContextualTextColor';
import { analyseEvaluatedText } from '../../commons/evaluatedTextVerfier';

class TextStyleResolver extends Component {
    render() {
        const { config, text, cellStyle,customIcons, ...rest } = this.props;
        if (!config && !text) return null;
        if (!config) return text;
        const { isTextualActionTriggerEnabled, contextual_text_color } = config;
        if (contextual_text_color) {
            return <ContextualTextColor customIcons={customIcons} text={text} config={config} {...rest} />
        }
        if (isTextualActionTriggerEnabled) {
            return <SubWorkflowTrigger text={text} config={config} {...rest} />
        }
        const analysedText = analyseEvaluatedText(text);
        let showAsLink = false;
        try {
            showAsLink = analysedText && (analysedText.indexOf('http:') != -1 || analysedText.indexOf('https:') != -1
                                || analysedText.indexOf('www.') != -1);
        } catch(error) {}
        return (// DEFAULT PRINT
            showAsLink?<a href={(analysedText.indexOf('http:') == -1 && analysedText.indexOf('https') == -1?"//":"") + analysedText} target="_blank">{analysedText}</a>:
            <span title={analysedText} style={cellStyle}>{analysedText}</span>
        )
    }
}

TextStyleResolver.defaultProps = {
    config: null,
    text: null
}

TextStyleResolver.propTypes = {
    config: PropTypes.object
};

export default TextStyleResolver;