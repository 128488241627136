import React from 'react';
import moment from 'moment';
import {
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  CardText,
  CardImg,
  Row,
  Col,
  ListGroup,
  ListGroupItem

} from 'reactstrap';
import config from '../../../config';

const ImageCard = props => {
  const { graphData, imageCardConfig,imageCardData } = props;
  if (!imageCardConfig) return null;
  const typeOneLabel = imageCardConfig && imageCardConfig.typeOneLabel;
  const typeOneImageField =
    imageCardConfig &&
    imageCardConfig.typeOneImage &&
    imageCardConfig.typeOneImage.value;
  const typeTwoLabel = imageCardConfig && imageCardConfig.typeTwoLabel;
  const typeTwoImageField =
    imageCardConfig &&
    imageCardConfig.typeTwoImage &&
    imageCardConfig.typeTwoImage.value;
  const titleField =
    imageCardConfig && imageCardConfig.title && imageCardConfig.title.value;
  const subTitleField =
    imageCardConfig &&
    imageCardConfig.subTitle &&
    imageCardConfig.subTitle.value;
  const descriptionLabel = imageCardConfig && imageCardConfig.descriptionLabel;
  const descriptionField =
    imageCardConfig &&
    imageCardConfig.description &&
    imageCardConfig.description.value;

    const filterDropdown = imageCardConfig && imageCardConfig.filterDropdown
    const filterProperty = imageCardConfig && imageCardConfig.filterProperty && imageCardConfig.filterProperty.value

  const cards = [];
  Array.isArray(graphData) &&
    graphData.map((item, i) => {
      if (item.tableData[titleField]) {
        let subtitle = item.tableData[subTitleField];
        if (subtitle) {
          const date = moment(subtitle + ' GMT');
          if (date.isValid()) {
            subtitle = date.format('MMM DD YYYY hh:mm a');
          }
        }
        if (item.tableData[typeOneImageField]) {
          const card = {
            type: typeOneLabel,
            image: `${config.htmlTemplatePath}/${item.tableData[typeOneImageField]}`,
            title: item.tableData[titleField],
            subTitle: subtitle,
            descriptionLabel: descriptionLabel,
            description: item.tableData[descriptionField],
          };
          cards.push(card);
        }
        if (item.tableData[typeTwoImageField]) {
          const card = {
            type: typeTwoLabel,
            image: `${config.htmlTemplatePath}/${item.tableData[typeTwoImageField]}`,
            title: item.tableData[titleField],
            subTitle: subtitle,
            descriptionLabel: descriptionLabel,
            description: item.tableData[descriptionField],
          };
          cards.push(card);
        }
      }
    });

  if(filterDropdown && filterProperty && imageCardData.length > 0){
      
  return (
    <Row className='imagecards'>
      {imageCardData.map((card, index) => {
        return (
          <Col lg={3} md={4} sm={6} key={index}>
            <Card className="text-center imageCardReport" color='warning' outline>
              <div className='imageContainer'>
              <CardImg top width='100%' src={card.image} alt='Card image cap' />
              </div>
              <CardBody>
              <ListGroup flush>
             {card.type &&  <ListGroupItem className='p-1 text-primary font-weight-bold'>
                  {card.type}
                </ListGroupItem>}
                <ListGroupItem className='p-1 text-primary font-weight-bold'>
                  {card.title}
                </ListGroupItem>
                <ListGroupItem className='p-1 text-muted'>
                  {card.subTitle}
                </ListGroupItem>
                <ListGroupItem className='p-1'>
                <CardText title={card.description}>
                  <span className=''>{card.descriptionLabel}</span>
                  <span className='mr-2 ml-2'>:</span>
                  <span className=''>{card.description}</span>
                </CardText>
                </ListGroupItem>
              </ListGroup>
              </CardBody>
            </Card>
          </Col>
        );
      })}
    </Row>
  );
    }

  return (
    <Row className='imagecards'>
      {cards.map((card, index) => {
        return (
          <Col lg={3} md={4} sm={6} key={index}>
            <Card className="text-center imageCardReport" color='warning' outline>
            <div className='imageContainer'>
              <CardImg top width='100%' src={card.image} alt='Card image cap' />
              </div>
              <CardBody>
              <ListGroup flush>
            {card.type &&   <ListGroupItem className='p-1  font-weight-bold'>
                  {card.type}
                </ListGroupItem>}
                <ListGroupItem className='p-1 text-primary font-weight-bold'>
                  {card.title}
                </ListGroupItem>
                <ListGroupItem className='p-1 text-muted'>
                  {card.subTitle}
                </ListGroupItem>
                <ListGroupItem className='p-1'>
                <CardText title={card.description}>
                  <span className=''>{card.descriptionLabel}</span>
                  <span className='mr-2 ml-2'>:</span>
                  <span className=''>{card.description}</span>
                </CardText>
                </ListGroupItem>
              </ListGroup>
              </CardBody>
            </Card>
          </Col>
        );
      })}
    </Row>
  );
};

export default ImageCard;
