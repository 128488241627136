import React from 'react';
import { Field } from 'redux-form/immutable';
import PrintText from './PrintText';
import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import { toJS } from '../../containers/to-js';
import { getStates, getCities } from '../../actions/static-data';
import { renderSelect } from './_FORM_FIELDS';
import _ from 'lodash'

class States extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            states: []
        }
    }

    componentDidMount() {
        const { getStates, isReadOne } = this.props;
        if(!isReadOne) {
            getStates();
        }
    }

    componentWillReceiveProps(nextProps) {
		if(nextProps.selectedCountry && nextProps.states) {
            if(this.props.selectedCountry) {
				if(this.props.selectedCountry.id !== nextProps.selectedCountry.id || this.props.selectedCountry.countryId !== nextProps.selectedCountry.countryId || 
                (this.state.states.length == 0))
                {
					this.setState({states: this.getStates(nextProps.selectedCountry, nextProps.states)});
				} else {
					this.setState({states: this.state.states});
				}
            } else {
                this.setState({states: this.getStates(nextProps.selectedCountry, nextProps.states)});
            }
        }
    }

    getStates(country, states) {
		let countryId = country.id || country.countryId;
        if(typeof countryId === "string") countryId = parseInt(countryId)
        return country && states && states.filter(state => {
            return state.country_id === countryId
        }).map((state, index) => {
            return {
                value: state.name,
                label: state.name,
                countryId: state.country_id,
                stateId: state.id
            }
        });
    }

    render() {
        const { classProperty, type, validations, isReadOne, isMulti, selectedCountry, ...rest } = this.props;
        const { layoutItem: { itemProperties: { field: { properties } } } } = this.props;
        let { possibleValues } = properties;
        const options = possibleValues.length ? [] : this.state.states;
        return (
            <div>
            {
                isReadOne ?
                    <PrintText {...this.props} /> :
                    <div>
                        <Field
                            name={classProperty}
                            component={renderSelect}
                            type="select"
                            validate={validations}
                            fieldType={type}
                            valueKey='value'
                            labelKey='label'
                            options={options}
                            properties={properties}
                            normalize={(value) => {
                                if(value) {
                                    this.props.getCities(value);
                                    return value.value
                                } else return value
                                
                            }}
                            onDataChange={(v)=> {
                                const cEventKeys = window.cEvents && Object.keys(window.cEvents);
                                const fValues = this.props.formValues;
                                _.set(fValues,classProperty,v)
                                Array.isArray(cEventKeys) && cEventKeys.map(k => {
                                  k.startsWith(classProperty) &&
                                    window.cEvents[k](fValues);
                                });
                              }}
                            {...rest}
                            disabled={options && options.length > 0 ? false : true}
                            simpleValue={false}
                        />
                    </div>
            }
        </div>
        );
    }
}

const selector = formValueSelector('create-update-form-base');

const mapDispatchToProps = (dispatch) => {
    return {
        getStates: () => {
            return dispatch(getStates());
        },
        getCities: (payload) => {
            return dispatch(getCities(payload));
        }
    }
  };
  
  const mapStateToProps = (state, ownProps) => {
    const { layoutItem: { itemProperties: { statesConfig, tableConfig } } } = ownProps;
    let selectedCountry;
    if(statesConfig && statesConfig.defaultCountry) {
        selectedCountry = statesConfig.defaultCountry;
    } else if (statesConfig && statesConfig.countryField) {
        const countryFieldSplit = statesConfig.countryField.split(".");
        let selectedCountryName = !ownProps.isReadOne && state.getIn(['form', 'create-update-form-base', 'values', ...countryFieldSplit]);
        selectedCountryName = selectedCountryName ? selectedCountryName: state.getIn(['form', 'create-update-form-modal', 'values', ...countryFieldSplit]);
        selectedCountryName = selectedCountryName ? selectedCountryName: state.getIn(['form', 'create-update-form-sidebar', 'values', ...countryFieldSplit]);
        const countries = state.getIn(['staticData', 'countries']);
        selectedCountry = countries && selectedCountryName && countries.filter((country) => country.get('name') === selectedCountryName).get(0);
    } else if (tableConfig) {
        const columns = tableConfig.config.columns;
        columns && columns.length && columns.map((item, index) => {
			if(item.field.type == 'STATES') {
				selectedCountry = item.config.statesConfig.defaultCountry;
			}
		})
    }
    return {
        states: state.getIn(['staticData', 'states']),
        selectedCountry
    };
  };

export default connect(mapStateToProps, mapDispatchToProps)(toJS(States));