import React, { useEffect } from 'react'
import Template1 from './EmailAuthTemplate/Template1'
import Template2 from './EmailAuthTemplate/Template2';
import config from '../../../config';
import Template3 from './EmailAuthTemplate/Template3';
import Template4 from './EmailAuthTemplate/Template4';

export default function EmailAuth(props) {
  const { itemProperties,companyDetails } = props;
  const template =
  itemProperties &&
  itemProperties.authTemplate && itemProperties.authTemplate.value;
  const logoUrl = companyDetails && companyDetails.data && companyDetails.data.logoUrl;
  const logoUrlComplete = `${config.assetsBasePath}${logoUrl}`


  const authTemplateSideImage = itemProperties && itemProperties.authTemplateSideImage && itemProperties.authTemplateSideImage.path
  const authTemplateSideImageUrlComplete = `${config.assetsBasePath}${authTemplateSideImage}`
  const authTemplateBackgroundColor = itemProperties && itemProperties.authTemplateBackgroundColor 
  const authTemplateTopFormText = itemProperties && itemProperties.authTemplateTopFormText 
  const authTemplateFontColor = itemProperties && itemProperties.authTemplateFontColor 


 if(template === 'Template2'){
  return <Template2 logoUrlComplete={logoUrlComplete} authTemplateTopFormText={authTemplateTopFormText} authTemplateFontColor={authTemplateFontColor}  authTemplateBackgroundColor={authTemplateBackgroundColor}  authTemplateSideImageUrlComplete={authTemplateSideImageUrlComplete} {...props}/>
 }

 
 if(template === 'Template3'){
  return <Template3 logoUrlComplete={logoUrlComplete} authTemplateTopFormText={authTemplateTopFormText} authTemplateFontColor={authTemplateFontColor}  authTemplateBackgroundColor={authTemplateBackgroundColor}  authTemplateSideImageUrlComplete={authTemplateSideImageUrlComplete} {...props}/>
 }

 if(template === 'Template4'){
  return <Template4 logoUrlComplete={logoUrlComplete} authTemplateFontColor={authTemplateFontColor} authTemplateSideImageUrlComplete={authTemplateSideImageUrlComplete} authTemplateBackgroundColor={authTemplateBackgroundColor} authTemplateTopFormText={authTemplateTopFormText}  {...props}/>
 }


  return (
   <Template1 {...props} />
  )
}
