import React, { Component } from 'react';
import {
  FormGroup,
  Input,
  Label,
  FormFeedback,
  Row,
  Col,
  Button,
} from 'reactstrap';
import client from '../../../client';
import config from '../../../config';
import FacebookLogin from 'react-facebook-login';

class FacebookLoginComp extends Component {
  constructor(props) {
    super(props);
  }
  responseFacebook = response => {
    console.log(response);
    let token = response.accessToken;
    if (token) {
      const payload = {
        token,
        requiredRole: this.props.requiredRole,
      };
      client()
        .post(`${config.publicAPI}/facebookLogin`, payload)
        .then(res => {
          const { authenticationCallback } = this.props;
          authenticationCallback();
        })
        .catch(error => {});
    }
  };
  render() {
    const { appId,label } = this.props;
    return (
      <FacebookLogin
        appId={appId ? appId : '1473118256287965'}
        autoLoad={false}
        fields='name,email,picture'
        callback={this.responseFacebook}
        cssClass='facebook-button-class w-100'
        icon='fa-facebook'
        textButton={label?label:"Login with Facebook"}
      />
    );
  }
}

export default FacebookLoginComp;
