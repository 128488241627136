import React, { Component } from 'react';
import { Cell } from 'fixed-data-table-2';
import NoDataCell from '../NoDataCell';

class UrlCell extends Component {
    render() {
        const { rows, column, columnKey, rowIndex, config, width } = this.props;
        const valueToRender = rows.getObjectAt(rowIndex)[columnKey];
        let cellAlignment;
        let cellStyle;
        if(config && config.styles && config.styles) {
            const styles = config.styles
            cellAlignment = styles.alignment && styles.alignment.value;
            cellStyle = {
                color: styles.color,
                textDecoration: styles.lineThrough ? 'line-through' : 'inherit'
            }
        }
        return (
            <Cell width={width} align={cellAlignment ? cellAlignment : 'left'}>
                <a style={cellStyle} href={(valueToRender && !valueToRender.startsWith('http'))?('//' + valueToRender):valueToRender} target="_blank" >
                    <i className="fa fa-external-link" area-hidden="true"></i>
                    {"  "}
                    {valueToRender}
                </a>
            </Cell>
        );
    }
}

export default UrlCell;