import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormGroup, Label, Input, Row, Col } from 'reactstrap';
import * as FilterElements from './FilterElementsMap';
import { toJS } from '../../../../containers/to-js';
import { connect } from 'react-redux';
import { getFilterData } from '../../../../actions/filters';
import PossibleValuesClickableFilter from './filter-elements/PossibleValuesClickableFilter';
import PossibleValuesButtonGroupFilter from './filter-elements/PossibleValuesButtonGroupFilter';

class FieldFilter extends Component {
    getFilterData() {
        const { getFilterData, filter: { targetClassProperty, type, collection, parentType }, workflow: { currentItem: { typeModel: { safeName, customerName, customerNameWithVersion } } },columnLayoutModel } = this.props;
        let fieldClassProperty = targetClassProperty;
        let deeperClassProperty = null;
        let modelVersion = columnLayoutModel && columnLayoutModel.customClassName.replace('com.uttara.neene.proxyto.' + customerName + '_', '');
        modelVersion = modelVersion && modelVersion.substring(0, modelVersion.indexOf('.'));
        const vLastIndex = columnLayoutModel && columnLayoutModel.customClassName.lastIndexOf(modelVersion)
        const customClassNameLength = columnLayoutModel && columnLayoutModel.customClassName.length
        const colModelSafeName = columnLayoutModel && columnLayoutModel.customClassName.substring(vLastIndex + modelVersion.length,customClassNameLength )
        if (targetClassProperty.indexOf('.')) {
            const parts = targetClassProperty.split('.');
            fieldClassProperty = parts.shift();
            deeperClassProperty = parts.join('.');
        }
		//let fieldModelName = getFieldModelName(typeModel.fields, fieldClassProperty);
        const autocompleteReqConfig = {
            typeModelName: colModelSafeName ? colModelSafeName.toLowerCase(): safeName.toLowerCase(),
            fieldClassProperty,
            customerNameWithVersion,
            type: parentType,
            deeperClassProperty,
            customerName,
            isCollection: collection
        };
        return getFilterData(autocompleteReqConfig);
    }
	
	/*getFieldModelName(fields, fieldClassProperty) {
		let fieldModelName;
		fields && fields.map((item, itemIndex) => {
            if(item.classProperty == fieldClassProperty && item.type == 'TYPE') {
				fieldModelName = item.customModelName;
			}
        })
		return fieldModelName;
	}*/
    render() {
        const { filter, onFilterChange, filterStateMap, filterIndex } = this.props;
        if (!filter) return null;
        const { targetClassProperty, displayName, leafType } = filter;
        const elem_id = `filter-elem-${targetClassProperty}-${leafType}`;
        const ResolvedFilterElem = FilterElements[leafType] || null;
        if (!ResolvedFilterElem || ResolvedFilterElem.name === 'FallbackForFilter') return null;
        let savedValue = filterStateMap[`filter_${filterIndex}`];
        savedValue = savedValue ? savedValue.filterBy : '';
        if (leafType === 'DROPDOWN' && filter.showClickableButtonGroup) {
            return <PossibleValuesButtonGroupFilter 
                elem_id={elem_id} 
                getSuggestions={this.getFilterData.bind(this)} 
                value={savedValue}
                displayName={displayName}
                {...this.props}
            />
        }
        if (leafType === 'DROPDOWN' && filter.showClickableHorizontalView) {
            return <PossibleValuesClickableFilter 
                elem_id={elem_id} 
                getSuggestions={this.getFilterData.bind(this)} 
                value={savedValue}
                displayName={displayName}
                {...this.props}
            />
        }
        return (
            <Col xs="12" md="6" xl="4">
                <FormGroup className='mb-0'>
                    <Label for={elem_id} className='text-capitalize'>{displayName}</Label>
                    <ResolvedFilterElem
                        elem_id={elem_id}
                        getSuggestions={this.getFilterData.bind(this)}
                        value={savedValue}
                        {...this.props}
                    />
                </FormGroup>
            </Col>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getFilterData: (payload) => {
            return dispatch(getFilterData(payload));
        }
    }
};

FieldFilter.propTypes = {
    filter: PropTypes.object
};

export default connect(null, mapDispatchToProps)(toJS(FieldFilter));
