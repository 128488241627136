import React, { useEffect, useRef, useState } from 'react';
import EmailEditor from 'react-email-editor';
import { Button, Col, Row } from 'reactstrap';
import PrintText from './PrintText';
import ModelFieldsSelector from '../commons/ModelFieldsSelector';

const StaticWebContent = props => {
  const {
    isReadOne,
    workflow: {
      currentItem: {
        typeModel,
        additionalModelMap
      }
    },
    workflowData,
    classProperty
  } = props;

  const [variables, setVariables] = useState([]);
  const editor = useRef(null);
  const saveEditorState = () => {
    editor.current.editor.exportHtml(data => {
      const payload = {
        html: data.html,
        json: data.design,
        variables: variables,
      };
      props.change(classProperty, JSON.stringify(payload));
    });
  };

  const onLoadTemplate = () => {
    const initialVal = workflowData[classProperty];
    if(initialVal) {
      const json = JSON.parse(initialVal);
      editor.current.editor.loadDesign(json.json);
    }
  }

  useEffect(() => {
    const initialVal = workflowData[classProperty];
    if(initialVal) {
      const json = JSON.parse(initialVal);
      setVariables(json.variables);
    }
  }, [])
  return (
    <div className='static-web-content'>
      {isReadOne ? (
        <PrintText {...props} />
      ) : (
        <div className='swc-editor'>
          <Col sm={9}>
            <EmailEditor onLoad={onLoadTemplate} ref={editor} />
          </Col>
          <Col sm={3}>
            <div className='variables-container'>
              <h4>Variables</h4>
              {
                variables.map((va, i) => {
                  return (
                    <div key={i} className='field'>
                      <ModelFieldsSelector
                        userEditable={!true}
                        fields={typeModel.fields}
                        savedValue={va.key}
                        additionalModelMap={additionalModelMap}
                        onSave={(value) => {
                          const vAll = Object.assign([], variables);
                          vAll[i] = {
                            key: value,
                            value: `{{${value}}}`
                          }
                          setVariables(vAll);
                        }}
                      />
                      <div className='copy' onClick={() => {
                        navigator.clipboard && navigator.clipboard.writeText(va.value);
                      }}>copy</div>
                    </div>
                  )
                })
              }
              <div className="add-variables">
                <Button onClick={() => {
                  const vAll = Object.assign([], variables);
                  vAll.push({ key: "", value: "" })
                  setVariables(vAll);
                }} color='primary'>
                  Add Variable
                </Button>
              </div>
            </div>
            <Button className='save-template' onClick={saveEditorState} block color='secondary'>
              Save Template
            </Button>
          </Col>
        </div>
      )}
    </div>
  );
};

export default StaticWebContent;