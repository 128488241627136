import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { toJS } from '../../../containers/to-js';
import {
  getWorkflowFromCode,
  getWorkflowById,
} from '../../../actions/workflows';
import { FieldArray } from 'redux-form';
import WorkflowContainer from '../../../containers/workflow/WorkflowContainer';

const TypeModelCreateUpdateWorkflowCard = props => {
  const [currentItem, setCurrentItem] = useState(null);
  const {
    getWorkflowFromCode,
    readOneWorkflowUniqueCode,
    getWorkflowById,
    rows,
    classProperty,
  } = props;
  useEffect(() => {
    getWorkflowFromCode(readOneWorkflowUniqueCode)
      .then(res => {
        res.data &&
          getWorkflowById(res.data.data)
            .then(workflowResponse => {
              if (workflowResponse) {
                setCurrentItem(workflowResponse.data.items[0]);
              }
            })
            .catch(ex => {});
      })
      .catch(error => {});
  }, []);
  return (
    <div>
      {currentItem && (
        <FieldArray
          fields={rows}
          name={classProperty}
          classProperty={classProperty}
          component={renderWorkflowCards}
          currentItem={currentItem}
        />
      )}
    </div>
  );
};

const renderWorkflowCards = ({ fields, currentItem, classProperty }) => {
  return (
    <div>
      {fields.map((member, index) => {
        const workflow = {
          currentItem,
          workflowData: member,
          memberIndex: index,
          memberClassProperty: classProperty,
          hasMember: true,
        };
        return (
          <div key={`member-${index}`}>
            <WorkflowContainer
              onHandleSubmit={()=>{}}
              change={()=>{}}
              displayHeader={false}
              noPadding={true}
              workflow={workflow}
              isBase={false}
              isInline={true}
              inlineFormKey={'workflow-card-' + index}
              callInlineNext={()=>{}}
              workflowData={member}
            />
          </div>
        )
      })}
    </div>
  );
};

const mapDispatchToProps = dispatch => {
  return {
    getWorkflowFromCode: code => {
      return dispatch(getWorkflowFromCode(code));
    },
    getWorkflowById: id => {
      return dispatch(getWorkflowById(id));
    },
  };
};

const mapStateToProps = (state, ownProps) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(toJS(TypeModelCreateUpdateWorkflowCard));
