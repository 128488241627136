export const WIDTHS = {
    SMALLTEXT: { width: 150, flexGrow: 0 },
    MEDIUMTEXT: { width: 150, flexGrow: 0 },
    LARGETEXT: { width: 300, flexGrow: 1 },
    TYPE: { width: 150, flexGrow: 0 },
    EMAIL: { width: 180, flexGrow: 0 },
    PHONE : { width: 150, flexGrow: 0 },
    RICHHTEXT: { width: 300, flexGrow: 1 },
    DROPDOWN: { width: 100, flexGrow: 0 },
    FILE: { width: 100, flexGrow: 0 },
    URL: { width: 250, flexGrow: 1 },
    ACTION: { width: 100, flexGrow: 0 },
    MULTICHOICE: { width: 100, flexGrow: 0 },
    MULTISELECT: { width: 100, flexGrow: 0 },
    DATETIME: { width: 200, flexGrow: 0 },
    SLIDER: { width: 100, flexGrow: 0 },
    DATE_RANGE: { 150: 100, flexGrow: 0 },
    TIME: { width: 100, flexGrow: 0 },
    DATE: { width: 150, flexGrow: 0 },
    NUMBER_RANGE: { width: 100, flexGrow: 0 },
    BIT: { width: 100, flexGrow: 0 },
    NUMBER: { width: 100, flexGrow: 0 },
    POSITIVE_NUMBER: { width: 100, flexGrow: 0 },
    DECIMAL: { width: 100, flexGrow: 0 },
    IMAGE: { width: 100, flexGrow: 0 },
    VIDEO: { width: 100, flexGrow: 0 },
    YESNO: { width: 100, flexGrow: 0 },
    USER: { width: 200, flexGrow: 1 },
    RATING: { width: 100, flexGrow: 0 }
}
