import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import PropTypes from 'prop-types';
import { confirmable } from 'react-confirm';

let defaultOptions = {
    heading: 'Confirm Your Action',
    proceed: {
        text: 'Ok',
        iconClass: 'fa fa-check',
        color: 'primary'
    }
};

const NeeneDialog = ({ show, proceed, dismiss, cancel, confirmation, options }) => {
    const mergedOptions = Object.assign(defaultOptions, options);
    return (<div>
        <Modal onClosed={dismiss} isOpen={show} className='neene-cofirm-box'>
            <ModalHeader>{mergedOptions.heading}</ModalHeader>
            <ModalBody>
                {confirmation}
            </ModalBody>
            <ModalFooter>
                <Button color={mergedOptions.proceed.color} onClick={proceed}>
                    {mergedOptions.proceed.iconClass ? <i className={`${mergedOptions.proceed.iconClass} mr-1`}></i> : ''}
                    {mergedOptions.proceed.text}
                </Button>{' '}
                <Button color="link" onClick={cancel}>Cancel</Button>
            </ModalFooter>
        </Modal>
    </div>)
}

NeeneDialog.propTypes = {
    show: PropTypes.bool,            // from confirmable. indicates if the dialog is shown or not.
    proceed: PropTypes.func,         // from confirmable. call to close the dialog with promise resolved.
    cancel: PropTypes.func,          // from confirmable. call to close the dialog with promise rejected.
    dismiss: PropTypes.func,         // from confirmable. call to only close the dialog.
    confirmation: PropTypes.string,  // arguments of your confirm function
    options: PropTypes.object,        // arguments of your confirm function
    heading: PropTypes.string,
    proceedButtonText: PropTypes.string
}

export default confirmable(NeeneDialog);