import Layout from '../layout';
import { connect } from 'react-redux';
import { getTranslate, getActiveLanguage } from 'react-localize-redux';
import { toJS } from './to-js';
import { logout } from '../actions/user';
import { getCompanyDetails } from '../actions/company';
import { fetchNavWorkflows } from '../actions/workflows';

const mapStateToProps = (state) => {
    const locale = state.get('locale');
    return { 
        translate: getTranslate(locale),
        currentLanguage: getActiveLanguage(locale).code,
        user: state.get('user'),
        isDasboard: true,
        companyDetails: state.getIn(['company', 'details'])
      };
};

const mapDispatchToProps = (dispatch) => {
    return {
        logout: (history) => {
            dispatch(logout(history));
        },
        getCompanyDetails: () => {
            dispatch(getCompanyDetails());
        },
        fetchNavWorkflows: (user) => {
            if(user) {
                dispatch(fetchNavWorkflows(user));
            }
        }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(toJS(Layout));