class DataListStore {
  constructor(rows){
    this.size = rows.length;
    this._cache = [];
    this._rows = rows;
  }

  createRowObjectData(/*number*/ index) /*object*/ {
    // return {
    //   id: index,
    //   _row: this._rows[index]
    // };
    return this._rows[index];
  }

  getObjectAt(/*number*/ index) /*?object*/ {
    if (index < 0 || index > this.size){
      return undefined;
    }
    if (this._cache[index] === undefined) {
      this._cache[index] = this.createRowObjectData(index);
    }
    return this._cache[index];
  }

  /**
  * Populates the entire cache with data.
  * Use with Caution! Behaves slowly for large sizes
  * ex. 100,000 rows
  */
  getAll() {
    if (this._cache.length < this.size) {
      for (var i = 0; i < this.size; i++) {
        this.getObjectAt(i);
      }
    }
    return this._cache.slice();
  }

  getSize() {
    return this.size;
  }
  sortByClassProperty(classProperty, order) {
    if(order === 'ASCENDING') {
      return this._rows.sort((item, nextItem) => {
        if(item[classProperty] < nextItem[classProperty]) return -1;
        if(item[classProperty] > nextItem[classProperty]) return 1;
        return 0;
      })
    } else {
      return this._rows.sort((item, nextItem) => {
        if(item[classProperty] < nextItem[classProperty]) return 1;
        if(item[classProperty] > nextItem[classProperty]) return -1;
        return 0;
    })
    }
  }
  getPage(pageSize, start) {
    return this._rows.slice(start - 1, start + pageSize);
  }
  search(keyword, columns) {
    return this._rows.filter((row, rowIndex) => {
      let found = false;
      return columns.some((column, columnIndex) => {
        const searchable = column.config.searchable;
        const classProperty = column.field.classProperty;
        if(searchable && typeof row[classProperty] === 'string' && !found) {
          found = row[classProperty].toLowerCase().indexOf(keyword.toLowerCase()) > -1;
          return found;
        }
      })
    })
  }
}

export default DataListStore;