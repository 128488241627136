import React from 'react';

const MultiLine = (props) => {
    const { avatarRound, avatarSize, fullName, email, children } = props;
    return (
        <div className='d-flex align-items-center'>
            <div className='avatar-wrapper'>
                {children}
            </div>
            <div className='ml-2'>
                <span className='text-capitalize d-block'>{fullName}</span>
                <span className='text-muted'>{email}</span>
            </div>
        </div>
    )
}

export default MultiLine;