import React, { Component } from 'react';
import { getTranslate } from 'react-localize-redux';
import { toJS } from '../../../containers/to-js';
import { updatePayload, save } from '../../../actions/workflow-item';
import { triggerAction } from '../../../actions/workflow-actions';
import { connect } from 'react-redux';
import { KEYEDLAYOUT } from '../index';
import { ModelPropertiesParser } from '../../commons/modelPropertiesParser';

class TypeModelKeyedLayout extends Component {
    prepareToSave(values) {
        const { save, workflow: { currentItem: { typeModel: { customerName } } }, field } = this.props;
        const info = {
            customerName,
            typeModelName: field.customModelName
        }
        save(values, info);
    }
    render() {
        const { size, workflow, keyedConfig, updatePayload, save, saveResponse, laneData, triggerAction } = this.props;
        const { workflowData } = workflow;
        return (
            <KEYEDLAYOUT
                size={size}
                workflow={workflow}
                workflowId={workflow.workflowModel}
                workflowData={laneData}
                config={keyedConfig}
                updatePayload={updatePayload}
                save={this.prepareToSave.bind(this)}
                saveResponse={saveResponse}
                canActOnMultipleItems={false}
                triggerAction={triggerAction}
            />
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        translate: getTranslate(state.get('locale')),
        saveResponse: state.get('save')
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        updatePayload: (payload) => {
            dispatch(updatePayload(payload));
        },
        save: (payload, info) => {
            dispatch(save(payload, info));
        },
        triggerAction(action, tid, workflow) {
            dispatch(triggerAction(action, tid, workflow));
        },
    }
};


export default connect(mapStateToProps, mapDispatchToProps)(toJS(TypeModelKeyedLayout));