import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { Col } from 'reactstrap';

class TypeModelFilter extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedOptions: '',
            options: []
        }
    }
    handleChange(selectedOptions) {
        const { field, onFilterChange,filter, filterIndex } = this.props;
        const searchTerms = selectedOptions.map((option) => option.value);
        this.setState({ selectedOptions });
        const filterConfig = {
            filter,
            filterIndex
        }
        onFilterChange(filterConfig, searchTerms);
    }
    componentDidMount() {
        const { getSuggestions } = this.props;
        getSuggestions().then((res) => {
            const values = res.data;
            const options = values.map((val) => {
                if(val.hasOwnProperty('filterValue')) {
                    return {
                        value: val.filterValue,
                        label: val.filterValue
                    };
                } else {
                    let nameProperty = 'name';
                    for(var x in val) {
                        if(val.hasOwnProperty(x) && x.toLowerCase().indexOf('name') > -1) {
                            nameProperty = x;
                            break;
                        }
                    }
                    return {
                        value: val.id,
                        label: val[nameProperty]
                    };
                }
            });
            this.setState({ options });
        })
    }
    
    render() {
        const { field, elem_id } = this.props;
        const { selectedOptions, options } = this.state;
        
        return (
            <Col xs="12" className='w-100 p-0'>
                <Select
                    name={elem_id}
                    value={selectedOptions}
                    onChange={this.handleChange.bind(this)}
                    options={options}
                    clearable={false}
                    multi={true}
                    placeholder='Select to filter...'
                />
            </Col>
        );
    }
}

TypeModelFilter.propTypes = {

};

export default TypeModelFilter;