import React, { Component } from 'react';
import moment from 'moment';
import { ModelPropertiesParser } from '../../commons/modelPropertiesParser';
import TextStyleResolver from '../../elements/TextStyleResolver/index';
import ChronoButtonGroup from './chrono/ChronoButtonGroup';
import Calendar from '../../commons/calendar';
import PillsView from './PillsView';

const CHRONO_VIEW_MODES = {
  SUMMARY: 'SUMMARY',
  MONTH: 'MONTH',
  WEEK: 'WEEK',
  DAY: 'DAY',
};

class ChronoView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      past: [],
      upcoming: [],
      today: [],
      chronoViewMode: CHRONO_VIEW_MODES.SUMMARY,
    };
  }

  componentDidMount() {
    const { laneKey, workflowData, getModelByNamePromise, customerName, workflow, isBase, workflowItem } = this.props;
	const { dateEnumeratorConfig, holidayModel } = this.props.config;
    const past = [];
    const today = [];
    const upcoming = [];
    const todayDate = moment();
	let defaultviewMode = CHRONO_VIEW_MODES.SUMMARY;
    if (workflowData && Array.isArray(workflowData)) {
      workflowData.map((data, index) => {
        const date = data[laneKey];
        console.log(data);
        const parsedDate = moment(date);
        if (parsedDate.isValid()) {
          if (parsedDate.isBefore(todayDate, 'day')) {
            past.push(data);
          } else if (parsedDate.isAfter(todayDate, 'day')) {
            upcoming.push(data);
          } else if (parsedDate.isSame(todayDate, 'day')) {
            today.push(data);
          } else {
          }
        } else {
          console.log('not valid');
        }
      });
    }
    // console.log(past, today, upcoming);
	if(dateEnumeratorConfig && dateEnumeratorConfig.viewMode) {
		defaultviewMode = dateEnumeratorConfig.viewMode.value;
    }
	if(holidayModel && holidayModel.id) {
		this.props.readAll({
            typeId: holidayModel.id,
            customerName: workflowItem.typeModel.customerName,
            workflow: {
              id: workflow.id
            },
            isBase,
            "count":10000,
            "start":0
        });
    }
    this.setState({
      past,
      upcoming,
      today,
	  chronoViewMode: defaultviewMode
    });
  }

  onChangeChronoViewMode = mode => {
    this.setState({ chronoViewMode: mode });
  };

  getCalendarEventsData = (workflowData, config, laneKey, endDate) => {
    const calendarEventsData = [];
    workflowData &&
      workflowData.map((dataItem, index) => {
        const start = moment(dataItem[laneKey]).toDate();
        const end = endDate && endDate.classProperty ? moment(dataItem[endDate.classProperty]).toDate() : moment(dataItem[laneKey]).add(1, 'hours').toDate();
        const event = {
          name:
            config.title && ModelPropertiesParser(config.title.path, dataItem),
          description:
            config.description &&
            ModelPropertiesParser(config.description.path, dataItem),
          label:
            config.label && ModelPropertiesParser(config.label.path, dataItem),
          id: dataItem.id,
          start,
          end,
        };
        calendarEventsData.push(event);
      });
    return calendarEventsData;
  };

  render() {
    const { past, today, upcoming, chronoViewMode } = this.state;
    const { card } = this.props.config;
    const { dateEnumeratorConfig, chronoEvent, holidayModel } = this.props.config;
	const holidayModelId = holidayModel && holidayModel.id;
    const { workflow, workflowData, user, containerIndex } = this.props;
	const containerId = `_c${containerIndex}`;
    const holidayCollection = typeof workflow.collections !== 'undefined' && workflow.collections !== null ? workflow.collections[holidayModelId + containerId] : null;
    const typeModel =
      workflow && workflow.currentItem && workflow.currentItem.typeModel;
    if (
      dateEnumeratorConfig &&
      dateEnumeratorConfig.type &&
      dateEnumeratorConfig.type.value === 'PILLS'
    ) {
      return <PillsView email={user && user.email} {...this.props} />;
    }
    const renderChrono = () => {
      switch (chronoViewMode) {
        case CHRONO_VIEW_MODES.SUMMARY:
          return (
            <div>
              <ChronoSection
                rows={workflowData}
                workflow={workflow}
                laneKey={this.props.laneKey}
                config={card}
                data={today}
                title='Today'
                type='TODAY'
              />
              <ChronoSection
                rows={workflowData}
                workflow={workflow}
                laneKey={this.props.laneKey}
                config={card}
                data={upcoming}
                title='Upcoming'
                type='UPCOMING'
              />
              <ChronoSection
                rows={workflowData}
                workflow={workflow}
                laneKey={this.props.laneKey}
                config={card}
                data={past}
                title='Past'
                type='PAST'
              />
            </div>
          );
        case CHRONO_VIEW_MODES.MONTH:
        case CHRONO_VIEW_MODES.WEEK:
        case CHRONO_VIEW_MODES.DAY:
          return (
            <Calendar
              view={chronoViewMode.toLowerCase()}
              events={this.getCalendarEventsData(
                workflowData,
                card,
                this.props.laneKey,
				dateEnumeratorConfig.endDate
              )}
              email={user && user.email}
			  chronoEvent={chronoEvent}
			  dispatch={this.props.dispatch}
			  workflow={workflow}
			  holidayCollection={holidayCollection}
            />
          );
      }
    };

    return (
      <div className='chrono-view'>
        <ChronoButtonGroup
          chronoViewMode={chronoViewMode}
          onChangeChronoViewMode={this.onChangeChronoViewMode}
          lagendName={typeModel.name}
        />
        {renderChrono()}
      </div>
    );
  }
}

const ChronoSection = props => {
  const { data, title, type, config, laneKey, ...rest } = props;
  return (
    <div className='mb-4'>
      <h4>{title}</h4>
      <div>
        {data.length
          ? data.map((row, index) => {
              return (
                <ChronoCard
                  rowIndex={index}
                  key={index}
                  data={row}
                  type={type}
                  config={config}
                  laneKey={laneKey}
                  {...rest}
                />
              );
            })
          : 'No Data'}
      </div>
    </div>
  );
};

const ChronoCard = props => {
  const { data, type, config, laneKey, ...rest } = props;
  let title = null;
  let description = null;
  let label = null;
  if (!config) {
    return null;
  }
  if (config.title) {
    title = ModelPropertiesParser(config.title.path, data);
  }
  if (config.label) {
    label = ModelPropertiesParser(config.label.path, data);
  }
  if (config.description) {
    description = ModelPropertiesParser(config.description.path, data);
  }
  const date = moment(data[laneKey]);

  return (
    <div className={`chrono-card d-flex mb-2 ${type}`}>
      <div className='date-badge d-flex flex-column justify-content-center align-items-center p-3'>
        <div className='day text-center'>{date.format('DD')}</div>
        <div className='month text-center'>{date.format('MMM')}</div>
        <small>{date.format('YYYY')}</small>
      </div>
      <div className='d-flex flex-column p-2'>
        {title ? (
          <div>
            <h5 className='mb-0'>
              <TextStyleResolver
                data={data}
                config={config.title}
                text={title}
                {...rest}
              />
            </h5>
          </div>
        ) : (
          ''
        )}
        {label ? (
          <div>
            <TextStyleResolver
              data={data}
              config={config.label}
              text={label}
              {...rest}
            />
          </div>
        ) : (
          ''
        )}
        {description ? <div className='mt-2'>{description}</div> : ''}
      </div>
    </div>
  );
};

export default ChronoView;
