import React from 'react';
import PrintText from './PrintText';
import { Field } from 'redux-form/immutable';
import { renderField, convertToNumber } from './_FORM_FIELDS';

const PositiveNumber = (props) => {
    const { classProperty, type, validations, value, isReadOne, ...rest } = props;
    return (
        <div>
            {
                isReadOne ?
                    <PrintText {...props} /> :
                    <Field
                        name={classProperty}
                        component={renderField}
                        disabled={props.disableLayoutItem}
                        type="number"
                        validate={props.disableLayoutItem ? [] : validations}
                        value={value}
                        fieldType={type}
                        onBlur={() => {
                            const cEventKeys = window.cEvents && Object.keys(window.cEvents);
                            Array.isArray(cEventKeys) &&       cEventKeys.map(k => {
                                k.startsWith(classProperty) &&
                                window.cEvents[k](props.formValues);
                            });
                        }}
                        {...rest}
                        normalize={convertToNumber}
                    />
            }
        </div>
    );
}

export default PositiveNumber;