import React, { Component } from 'react';
import PrintText from './PrintText';
import { Field } from 'redux-form/immutable';
import { renderField } from './_FORM_FIELDS';
import { FormGroup, Label, FormFeedback } from 'reactstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import { ModelPropertiesParser } from '../commons/modelPropertiesParser';



class CustomDatePicker extends DatePicker {
    constructor(props) {
        super(props);
    }

    deferFocusInput = () => {
        this.cancelFocusInput();
    }
}



const normalize = (value) => {
    if (!value) return value;
    return `${value} 00:00`;
}

const format = (value) => {
    if (!value) return value;
    return value.split(' ')[0];
}

class DatepickerComponent extends Component {
    constructor(props) {
        super(props);
        const { layoutItem, classProperty, type, validations, value, isReadOne, ...rest } = props;
        const { itemProperties: { dateConfig } } = layoutItem;
        const dateDefault = dateConfig && dateConfig.defaultDateSet ? dateConfig.defaultDateSet : null;
        const objDate = dateDefault && new moment(dateDefault);
        this.state = {
            date: objDate ? objDate : new moment()
        }
        this.handleChange = this.handleChange.bind(this);
    }
    componentWillReceiveProps(nextProps) {
        let { date } = this.state;
        const { itemClassPropertyFromTable, layoutItem: { itemProperties: { field: { classProperty }, replaceClassProperty } }, formValues, input: { onChange }, fieldType } = nextProps;
        const updatedClassProperty = nextProps.classProperty
        let classProp = replaceClassProperty ? replaceClassProperty : classProperty;
        classProp = classProp.indexOf('{') == -1 ? `{{${classProp}}}` : classProp;
        let parentModelField = '';
        let indexChild = '';
        if (updatedClassProperty.indexOf('].') !== -1) {

            parentModelField = updatedClassProperty.substring(0, updatedClassProperty.indexOf('['))
            indexChild = updatedClassProperty.substring(updatedClassProperty.indexOf('[') + 1, updatedClassProperty.indexOf(']'))
            indexChild = parseInt(indexChild)
        }
        let formValuesUpdated = formValues && parentModelField && formValues[parentModelField] ? formValues[parentModelField][indexChild] : formValues
        if (itemClassPropertyFromTable) {
            classProp = itemClassPropertyFromTable.indexOf('{') == -1 ? `{{${itemClassPropertyFromTable}}}` : itemClassPropertyFromTable;
        }
        const dateValue = formValuesUpdated && ModelPropertiesParser(classProp, formValuesUpdated);//formValues[`${classProperty}`];
        // const dateValue = formValues && ModelPropertiesParser(`{{${classProperty}}}`, formValues['multiplepaymentdetails'][0]);
        date = date === null || date === '' ? new moment() : date;
        if (date && !dateValue) {
            this.handleChange(nextProps.DefaultDATE);
        }
        if (dateValue && date.diff(dateValue) != 0 && fieldType !== 'DATE') {
            const tempDate = dateValue && moment(dateValue + ' GMT');
            const DATE = tempDate.isValid() ? tempDate : '';
            this.handleChange(DATE);
        } else if (dateValue && fieldType === 'DATE') {
            const tempDate = dateValue && moment(dateValue);
            const DATE = tempDate.isValid() ? tempDate : '';
            this.handleChange(DATE);
        }
    }

    handleChange(date) {
        const { classProperty, input: { onChange }, layoutItem: { itemProperties: { dateConfig } } } = this.props;
        const currentDate = new moment();
        let selectedDate = date;
        const minimumDate = dateConfig && dateConfig.relativeMinDate && currentDate.add(dateConfig.relativeMinDate.value, 'y');
        const maximumDate = dateConfig && dateConfig.relativeMaxDate && currentDate.add(dateConfig.relativeMaxDate.value, 'y')
        if (maximumDate && date) {
            if (date.isSameOrAfter(maximumDate)) {
                selectedDate = maximumDate;
            }
        } else if (minimumDate && date) {
            if (date.isSameOrBefore(minimumDate)) {
                selectedDate = minimumDate;
            }
        }
        this.setState({ date: selectedDate }, () => {
            selectedDate && onChange(selectedDate.format('YYYY-MM-DD HH:mm'));

        });
    }

    getDays(minDate, maxDate, daysSelection, workflowData) {
        let result = [];
        let selecteddays = workflowData && ModelPropertiesParser(daysSelection, workflowData);
        selecteddays = typeof selecteddays == 'string' && JSON.parse(selecteddays);
        var days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        let arry = [];
        selecteddays.map(day => {
            const dayNum = days.indexOf(day)
            dayNum && arry.push(dayNum)
        })
        let d = new Date(minDate);
        while (d <= new Date(maxDate)) {
            const day = arry.find(element => element == d.getDay())
            if (!day) {
                const dd = moment(d);
                result.push(dd)
            }
            d.setDate(d.getDate() + 1)
        }

        return result;
    }
    render() {
        const { date } = this.state;
        const { useLabelAsPlaceholder, label, placeholder, workflow: { currentItem: { layoutProperties } }, workflowData, meta: { touched, error }, layoutItem: { itemProperties: { field: { properties } } } } = this.props;
        const { layoutItem, formValues } = this.props;
        const { itemProperties: { dateConfig, endDateProperty, startDateProperty, daysSelected } } = layoutItem;
        const __ADDITIONAL_MODEL_DATA = workflowData && workflowData.__ADDITIONAL_MODEL_DATA ? workflowData.__ADDITIONAL_MODEL_DATA : formValues;
        let minimumDate = {}
        let maximumDate = {}
        const dateFormat = dateConfig && dateConfig.dateFormatOptions && dateConfig.dateFormatOptions.name ? dateConfig.dateFormatOptions.name : null
        const useEndDateProperty = dateConfig && dateConfig.useEndDateProperty;
        const useStartDateProperty = dateConfig && dateConfig.useStartDateProperty;
        const currentDate = new moment();
        minimumDate = dateConfig && dateConfig.relativeMinDate ? currentDate.add(dateConfig.relativeMinDate.value, 'y') : useStartDateProperty && startDateProperty && __ADDITIONAL_MODEL_DATA && ModelPropertiesParser(startDateProperty, __ADDITIONAL_MODEL_DATA);
        maximumDate = dateConfig && dateConfig.relativeMaxDate ? currentDate.add(dateConfig.relativeMaxDate.value, 'y') : useEndDateProperty && endDateProperty && __ADDITIONAL_MODEL_DATA && ModelPropertiesParser(endDateProperty, __ADDITIONAL_MODEL_DATA);
        const minDate = minimumDate;
        const maxDate = maximumDate;
        const excludedDays = minDate && maxDate && daysSelected ? this.getDays(minimumDate, maximumDate, daysSelected, __ADDITIONAL_MODEL_DATA) : [];
        const minLength = this.props.layoutItem.itemProperties && this.props.layoutItem.itemProperties.minLength ? this.props.layoutItem.itemProperties.minLength : null
        const hasError = touched && error;
        const labelStyle = layoutProperties && layoutProperties.labelStyle
        return (
            <FormGroup>
                {
                    label.show ? <Label style={labelStyle ? { textTransform: labelStyle } : {}}>{label.text}{properties.required || minLength ? <sup>*</sup> : ''}</Label> : ''
                }
                <CustomDatePicker
                    selected={date}
                    minDate={minDate ? moment(new Date(minDate)) : null}
                    maxDate={maxDate ? moment(new Date(maxDate)) : null}
                    onChange={this.handleChange}
                    disabled={this.props.disableLayoutItem}
                    dateFormat={dateFormat ? dateFormat : "MM/DD/YYYY"}
                    showYearDropdown
                    //  showMonthDropdown={true}
                    placeholderText={useLabelAsPlaceholder ? label.text : placeholder.show ? placeholder.text : 'Select Date...'}
                    className='form-control w-100'
                    disabledKeyboardNavigation={true}
                    excludeDates={excludedDays}
                />
                {hasError ? (
                    <FormFeedback className='animated fadeIn d-block'>
                        <i className='fa fa-exclamation-triangle' aria-hidden='true'></i>{' '}
                        {error}
                    </FormFeedback>
                ) : (
                    ''
                )}
            </FormGroup>
        )
    }

}

const _Date = (props) => {
    const { type, validations, value, isReadOne, ...rest } = props;
    const { layoutItem: { itemProperties: { dateConfig } } } = props;
    const dateDefault = dateConfig && dateConfig.defaultDateSet ? dateConfig.defaultDateSet : null;
    const objDate = dateDefault && new moment(dateDefault);
    return (
        <div>
            {
                isReadOne ?
                    <PrintText {...props} /> :
                    <Field
                        name={props.classProperty}
                        component={DatepickerComponent}
                        type="date"
                        validate={validations}
                        DefaultDATE={objDate}
                        value={value}
                        fieldType={type}
                        disabled={props.disableLayoutItem || (dateConfig && dateConfig.disableEditing)}
                        onBlur={() => {
                            const cEventKeys = window.cEvents && Object.keys(window.cEvents);
                            Array.isArray(cEventKeys) && cEventKeys.map(k => {
                                k.startsWith(props.classProperty) &&
                                    window.cEvents[k](props.formValues);
                            });
                        }}
                        {...rest}
                    />
            }
        </div>
    );
}

export default _Date;