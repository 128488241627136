import React from 'react';
import { connect } from 'react-redux';
import { toJS } from '../../containers/to-js';
import * as ECharts from './ExternalReports';
import { getReportDataByAction } from '../../actions/reports';

const ExternalReport = props => {
  const {
    layoutItem: {
      itemProperties: { reportConfig, label },
    },
  } = props;
  if (!reportConfig) return null;
  const { reportDefaultType } = reportConfig;
  const ReportComponent = reportDefaultType
    ? ECharts[reportDefaultType.code]
    : ECharts['PIECHART'];
  return (
    <div>
      <ReportComponent label={label} reportConfig={reportConfig} {...props} />
    </div>
  );
};

const mapDispatchToProps = dispatch => {
  return {
    getReportDataByAction: payload => {
      return dispatch(getReportDataByAction(payload));
    },
  };
};

const mapStateToProps = (state, ownProps) => {
  return {
    graphHeight: 300,
    companyDetails: state.getIn(['company', 'details']),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(toJS(ExternalReport));
