import { connect } from 'react-redux';
import DesktopWorkflowTopBarMenuItems from '../components/menu/DesktopWorkflowTopBarMenuItems';
import { getTranslate, getActiveLanguage } from 'react-localize-redux';
import { fetchNavWorkflows, endCurrentBaseWorkflow } from '../actions/workflows';
import { toJS } from './to-js';

const mapStateToProps = (state) => {
    const locale = state.get('locale');
    const companySettings = state.getIn(['company', 'details', 'data', 'companySettings']);
    return { 
        translate: getTranslate(locale),
        currentLanguage: getActiveLanguage(locale) ? getActiveLanguage(locale).code : 'en',
        user: state.get('user'),
        items: state.getIn(['navigation', 'data']),
        companySettings
      };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        fetchWorkflows: (user) => {
            dispatch(fetchNavWorkflows(user));
        },
        endCurrentBaseWorkflow: () => {
            dispatch(endCurrentBaseWorkflow());
        }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(toJS(DesktopWorkflowTopBarMenuItems));