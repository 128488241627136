import React from 'react';
import PrintText from './PrintText';
import { Field } from 'redux-form/immutable';
import NumberRangeClass from './FieldClasses/_NUMBER_RANGE_CLASS';
import { convertToNumber } from './_FORM_FIELDS';

const normalize = (values) => {
    const converted = values.map((value) => { return convertToNumber(value) });
    return converted;
}

const NumberRange = (props) => {
    const { classProperty, type, validations, value, isReadOne, ...rest } = props;
    const _ranges = Array.isArray(value) && value.length ? value : ['', ''];
    return (
        <div>
            {
                isReadOne ?
                    <PrintText {...props} /> :
                    <Field
                        name={classProperty}
                        component={NumberRangeClass}
                        validate={validations}
                        fieldType={type}
                        {...rest}
                        ranges={_ranges}
                        normalize={normalize}
                    />
            }
        </div>
    );
}

export default NumberRange;
