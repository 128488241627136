export const TypeModelDisplays = {
    CARD: {
        display: 'Card',
        value: 'CARD'
    },
    CHART:{
        display: 'Chart',
        value: 'CHART'
    },
    TABLE: {
        display: 'Table',
        value: 'TABLE'
    },
    KEYED: {
        display: 'Keyed Layout',
        value: 'KEYED'
    },
    BADGES: {
        display: 'Badges',
        value: 'BADGES'
    },
    DROPDOWN: {
        display: 'Dropdown Selector', 
        value: 'DROPDOWN_SELECTOR'
    },
    COMMASEPERATED: {
        display: 'Comma Seperated Values', 
        value: 'COMMA_SEPERATED'
    },
    ITERATOR: {
        display: 'Iterator (Subworkflow)', 
        value: 'ITERATOR'
    },
    BULLETEDLIST: {
        display: 'Bulletted List', 
        value: 'BULLETED_LIST'
    },
    LINK: {
        display: 'Link', 
        value: 'LINK'
    },
    ACCORDIAN: {
        display: 'Accodian',
        value: 'ACCORDIAN'
    },
    DECK: {
        display: 'Deck',
        value: 'DECK'
    },
    CAROUSEL: {
        display: 'Carousel',
        value: 'CAROUSEL'
    },
    COUNT: {
        display: 'Count only',
        value: 'COUNT'
    },
    CHECKBOX: {
        display: 'Check box',
        value: 'CHECKBOX'
    },
    WORKFLOWCARD: {
        display: 'Workflow Card',
        value: 'WORKFLOWCARD'
    }
}

export const BadgeOptions = {
    PRIMARY : {
        display: 'Primary',
        value: 'PRIMARY'
    },
    SECONDARY : {
        display: 'Secondary',
        value: 'SECONDARY'
    },
    INFO: {
        display: 'Informational',
        value: 'INFO'
    },
    SUCCESS: {
        display: 'Success',
        value: 'SUCCESS'
    },
    DANGER: {
        display: 'Danger',
        value: 'DANGER'
    },
    LIGHT: {
        display: 'Light',
        value: 'LIGHT'
    },
    DARK: {
        display: 'Dark',
        value: 'DARK'
    }
}