import React from 'react';
import { connect } from 'react-redux';
import { toJS } from '../../containers/to-js';
import { login } from '../../actions/user';
import * as LoginComponents from './AuthHelpers';
import {
  FormGroup,
  Input,
  Label,
  FormFeedback,
  Row,
  Col,
  Button,
} from 'reactstrap';
import FacebookLoginComp from './AuthHelpers/FacebookLogin';
import GoogleLoginComp from './AuthHelpers/GoogleLoginComp';
import LinkedInLoginComp from './AuthHelpers/LinkedInLoginComp';
import { Link } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import Transition from 'react-transition-group/Transition';

const fadeInAndOut = ({ children, position, ...props }) => (
  <Transition
    {...props}
    timeout={800}
    onEnter={node => node.classList.add('fadeIn', 'animated')}
    onExit={node => {
      node.classList.remove('fadeIn', 'animated');
      node.classList.add('fadeOut', 'animated');
    }}
  >
    {children}
  </Transition>
);
class Authentication extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      refcode: '',
    };
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.user &&
      nextProps.user.user &&
      nextProps.user.user.hasError &&
      !this.props.user.user.hasError
    ) {
      toast.error(
        nextProps.user.user.error
          ? nextProps.user.user.error.message
          : 'Authentication Failed. Username or Password not valid.'
      );
    }
  }

  render() {
    const {
      user,
      form,
      layoutItem: { itemProperties },
      authenticationCallback,
      label,
      workflow,
    } = this.props;
    const loginType =
      itemProperties && itemProperties.loginType
        ? itemProperties.loginType
        : { label: 'Email', value: 'Email' };
    const LoginHandlerComponent = LoginComponents[loginType.value];
    const hasFb =
      itemProperties &&
      itemProperties.authProvidersTypes &&
      itemProperties.authProvidersTypes.some(x => x.value === 'fb');
      const hasRefCode =
      itemProperties &&
      itemProperties.additionalOptions &&
      itemProperties.additionalOptions.some(x => x.value === 'refcode');
    const hasGoogle =
      itemProperties &&
      itemProperties.authProvidersTypes &&
      itemProperties.authProvidersTypes.some(x => x.value === 'google');
    const hasLinkedIn =
      itemProperties &&
      itemProperties.authProvidersTypes &&
      itemProperties.authProvidersTypes.some(x => x.value === 'linkedin');
      const fbLabel =
      itemProperties &&
      itemProperties.authProviderConfig &&
      itemProperties.authProviderConfig.fblabel;
      const googleLabel =
      itemProperties &&
      itemProperties.authProviderConfig &&
      itemProperties.authProviderConfig.googlelabel


    return (
      <Col style={{flexDirection:"row",display:"flex",alignItems:"center",justifyContent:"center"}}>
        {hasFb ? (
        <Row style={{width:"50%"}}>
            <FacebookLoginComp
              appId={
                itemProperties &&
                itemProperties.authConfig &&
                itemProperties.authConfig.fbAppId
              }
              requiredRole={itemProperties.requiredRole}
              user={user}
              login={this.props._login}
              authenticationCallback={authenticationCallback}
              label={fbLabel?fbLabel:null}
            />
          </Row>
        ) : null}
        {hasGoogle ? (
            <Row style={{width:"50%"}}>
            <GoogleLoginComp
              clientId={
                itemProperties &&
                itemProperties.authConfig &&
                itemProperties.authConfig.googleClientId
              }
              requiredRole={itemProperties.requiredRole}
              hasRefCode={hasRefCode}
              user={user}
              login={this.props._login}
              authenticationCallback={authenticationCallback}
              label={googleLabel?googleLabel:null}
            />
          </Row>
        ) : null}
        {/* {hasLinkedIn ? (
            <Row md="6" lg="6" sm="6" xs="6">
            <LinkedInLoginComp
              requiredRole={itemProperties.requiredRole}
              hasRefCode={hasRefCode}
              user={user}
              login={this.props._login}
              authenticationCallback={authenticationCallback}
              label={itemProperties.label}
            />
          </Row>
        ) : null} */}
        <ToastContainer
          position='bottom-right'
          type='default'
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          pauseOnHover
          transition={fadeInAndOut}
        />
      </Col>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.get('user'),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    _login: (user, successCallback) => {
      dispatch(login(user, successCallback));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(toJS(Authentication));
