import React from 'react';
import { EditorState, RichUtils, convertToRaw, convertFromRaw, Modifier, Entity, ContentState, convertFromHTML } from 'draft-js';
import Editor, { composeDecorators } from 'draft-js-plugins-editor';
import { Button } from 'reactstrap';
import { connect } from 'react-redux';
import { toJS } from '../../containers/to-js';
import createLinkifyPlugin from 'draft-js-linkify-plugin'
import createLinkPlugin from 'draft-js-link-plugin'
import 'draft-js-link-plugin/lib/plugin.css'
import 'draft-js-linkify-plugin/lib/plugin.css'
const linkifyPlugin = createLinkifyPlugin();
const linkPlugin = createLinkPlugin();

const plugins = [
  linkifyPlugin,
  linkPlugin
];

class RichhText extends React.Component {
  constructor(props) {
    super(props);
    const rawContent = props.savedData;
    this.state = {
      editorState: rawContent === null ? EditorState.createEmpty() : EditorState.createWithContent(props.isPlainText === true ? ContentState.createFromText(rawContent) : convertFromRaw(rawContent)),
      hideEditUI:props.collapsableUIStyle? true :false
    };
    this.focus = () => this.refs.editor.focus();
    this.onChange = (editorState) => {
      let newEditorState = editorState;

      const content = editorState.getCurrentContent();
      const SelectionState = editorState.getSelection();
      const key = editorState.getSelection().getFocusKey();
      const type = content.getBlockForKey(key).getType();

      if (type === 'atomic') {
        const keyAfter = content.getKeyAfter(key);
        const newSelection = SelectionState.createEmpty(keyAfter);
        newEditorState = EditorState.forceSelection(editorState, newSelection);
      }
	  this.setState({ editorState: newEditorState }, () => {
        this.save();
      });
    };
    this.handleKeyCommand = this._handleKeyCommand.bind(this);
    this.onTab = this._onTab.bind(this);
    this.toggleBlockType = this._toggleBlockType.bind(this);
    this.toggleInlineStyle = this._toggleInlineStyle.bind(this);
    this.toggleColor = (toggledColor) => this._toggleColor(toggledColor);
  }
  _handleKeyCommand(command, editorState) {
    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
      this.onChange(newState);
      //return true;
      return 'handled';
    }
    //return false;
    return 'not-handled';
  }
  _onTab(e) {
    const maxDepth = 4;
    this.onChange(RichUtils.onTab(e, this.state.editorState, maxDepth));
  }
  _toggleBlockType(blockType) {
    this.onChange(
      RichUtils.toggleBlockType(
        this.state.editorState,
        blockType
      )
    );
  }
  _toggleInlineStyle(inlineStyle) {
    this.onChange(
      RichUtils.toggleInlineStyle(
        this.state.editorState,
        inlineStyle
      )
    );
  }
  save() {
    const rawContent = convertToRaw(this.state.editorState.getCurrentContent());
    this.props.setStaticContent(rawContent);
  }
  _toggleColor(toggledColor) {
    const { editorState } = this.state;
    const selection = editorState.getSelection();
    // Let's just allow one color at a time. Turn off all active colors.
    const nextContentState = Object.keys(colorStyleMap)
      .reduce((contentState, color) => {
        return Modifier.removeInlineStyle(contentState, selection, color)
      }, editorState.getCurrentContent());
    let nextEditorState = EditorState.push(
      editorState,
      nextContentState,
      'change-inline-style'
    );
    const currentStyle = editorState.getCurrentInlineStyle();
    // Unset style override for current color.
    if (selection.isCollapsed()) {
      nextEditorState = currentStyle.reduce((state, color) => {
        return RichUtils.toggleInlineStyle(state, color);
      }, nextEditorState);
    }
    // If the color is being toggled on, apply it.
    if (!currentStyle.has(toggledColor)) {
      nextEditorState = RichUtils.toggleInlineStyle(
        nextEditorState,
        toggledColor
      );
    }
    this.onChange(nextEditorState);
  }
  onAddPropertyKey(property) {
    const { editorState } = this.state;
    const targetRange = editorState.getSelection();
    const contentState = editorState.getCurrentContent();
    const contentStateWithEntity = contentState.createEntity(
      'MODEL_PROPERTY',
      'IMMUTABLE',
      { key: property }
    );
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
    const nextContentState = Modifier.insertText(contentState, targetRange, property, null, entityKey);
    //const nextContentState = Modifier.insertText(contentState, targetRange, property);
    let nextEditorState = EditorState.push(
      editorState,
      nextContentState,
      'insert-fragment'
    );
    this.onChange(nextEditorState);
  }
  toggleHideUIBOx = ()=> {
    this.setState({hideEditUI:!this.state.hideEditUI})
  }
  render() {
    const { editorState ,hideEditUI} = this.state;
    const { collapsableUIStyle } = this.props;
    // If the user changes block type before entering any text, we can
    // either style the placeholder or hide it. Let's just hide it now.
    let className = 'RichEditor-editor';
    var contentState = editorState.getCurrentContent();
    if (!contentState.hasText()) {
      if (contentState.getBlockMap().first().getType() !== 'unstyled') {
        className += ' RichEditor-hidePlaceholder';
      }
    }
    return (
      <div className='neene-rt-editor'>
        <div className="RichEditor-root">
       {  !hideEditUI &&  <div>
          <BlockStyleControls
            editorState={editorState}
            onToggle={this.toggleBlockType}
          />
          <InlineStyleControls
            editorState={editorState}
            onToggle={this.toggleInlineStyle}
          />
          <ColorControls
            editorState={editorState}
            onToggle={this.toggleColor}
          />
          </div>}
        { collapsableUIStyle &&  <Button  style={{    position: 'absolute', top: 0, right: 40}} target='_blank' onClick={this.toggleHideUIBOx.bind(this)} color="link" size="sm">
            {hideEditUI ? "Show Font Style": "Hide Font Style"}
         </Button>}
          <div className={className} onClick={this.focus}>
            <Editor
              customStyleMap={colorStyleMap}
              blockStyleFn={getBlockStyle}
              editorState={editorState}
              handleKeyCommand={this.handleKeyCommand}
              onChange={this.onChange}
              onTab={this.onTab}
              placeholder={this.props.placeholder ? this.props.placeholder : "Please write your content here..."}
              ref="editor"
              spellCheck={true}
              plugins={plugins}
            />
          </div>
        </div>
      </div>
    );
  }
}
// Custom overrides for "code" style.
const styleMap = {
  CODE: {
    backgroundColor: 'rgba(0, 0, 0, 0.05)',
    fontFamily: '"Inconsolata", "Menlo", "Consolas", monospace',
    fontSize: 16,
    padding: 2,
  },
};
function getBlockStyle(block) {
  switch (block.getType()) {
    case 'blockquote': return 'RichEditor-blockquote';
    default: return null;
  }
}
class StyleButton extends React.Component {
  constructor() {
    super();
    this.onToggle = (e) => {
      e.preventDefault();
      this.props.onToggle(this.props.style);
    };
  }
  render() {
    let className = 'RichEditor-styleButton';
    if (this.props.active) {
      className += ' RichEditor-activeButton';
    }
    return (
      <span className={className} onMouseDown={this.onToggle}>
        {this.props.label}
      </span>
    );
  }
}

const ColorButtonStyles = {
  controls: {
    fontFamily: '\'Helvetica\', sans-serif',
    fontSize: 14,
    marginBottom: 10,
    userSelect: 'none',
  },
  styleButton: {
    color: '#999',
    cursor: 'pointer',
    marginRight: 16,
    padding: '2px 0',
  },
};

class ColorStyleButton extends React.Component {
  constructor(props) {
    super(props);
    this.onToggle = (e) => {
      e.preventDefault();
      this.props.onToggle(this.props.style);
    };
  }
  render() {
    let style;
    if (this.props.active) {
      style = { ...ColorButtonStyles.styleButton, ...colorStyleMap[this.props.style] };
    } else {
      style = ColorButtonStyles.styleButton;
    }
    return (
      <span style={style} onMouseDown={this.onToggle}>
        {this.props.label}
      </span>
    );
  }
}

const BLOCK_TYPES = [
  { label: 'H1', style: 'header-one' },
  { label: 'H2', style: 'header-two' },
  { label: 'H3', style: 'header-three' },
  { label: 'H4', style: 'header-four' },
  { label: 'H5', style: 'header-five' },
  { label: 'H6', style: 'header-six' },
  { label: 'Blockquote', style: 'blockquote' },
  { label: 'UL', style: 'unordered-list-item' },
  { label: 'OL', style: 'ordered-list-item' },
  { label: 'Code Block', style: 'code-block' }
];
var COLORS = [
  { label: 'Red', style: 'red' },
  { label: 'Orange', style: 'orange' },
  { label: 'Yellow', style: 'yellow' },
  { label: 'Green', style: 'green' },
  { label: 'Blue', style: 'blue' },
  { label: 'Indigo', style: 'indigo' },
  { label: 'Violet', style: 'violet' },
  { label: 'White', style: 'white' },
];

const colorStyleMap = {
  red: {
    color: 'rgba(255, 0, 0, 1.0)',
  },
  orange: {
    color: 'rgba(255, 127, 0, 1.0)',
  },
  yellow: {
    color: 'rgba(180, 180, 0, 1.0)',
  },
  green: {
    color: 'rgba(0, 180, 0, 1.0)',
  },
  blue: {
    color: 'rgba(0, 0, 255, 1.0)',
  },
  indigo: {
    color: 'rgba(75, 0, 130, 1.0)',
  },
  violet: {
    color: 'rgba(127, 0, 255, 1.0)',
  },
  white: {
    color: 'rgba(255, 255, 255, 1.0)',
    backgroundColor:"#d6d2d2"
  }
};

const BlockStyleControls = (props) => {
  const { editorState } = props;
  const selection = editorState.getSelection();
  const blockType = editorState
    .getCurrentContent()
    .getBlockForKey(selection.getStartKey())
    .getType();
  return (
    <div className="RichEditor-controls">
      {BLOCK_TYPES.map((type) =>
        <StyleButton
          key={type.label}
          active={type.style === blockType}
          label={type.label}
          onToggle={props.onToggle}
          style={type.style}
        />
      )}
    </div>
  );
};
var INLINE_STYLES = [
  { label: 'Bold', style: 'BOLD' },
  { label: 'Italic', style: 'ITALIC' },
  { label: 'Underline', style: 'UNDERLINE' },
  { label: 'Monospace', style: 'CODE' },
];
const InlineStyleControls = (props) => {
  var currentStyle = props.editorState.getCurrentInlineStyle();
  return (
    <div className="RichEditor-controls">
      {INLINE_STYLES.map(type =>
        <StyleButton
          key={type.label}
          active={currentStyle.has(type.style)}
          label={type.label}
          onToggle={props.onToggle}
          style={type.style}
        />
      )}
    </div>
  );
};

const ColorControls = (props) => {
  var currentStyle = props.editorState.getCurrentInlineStyle();
  return (
    <div style={ColorButtonStyles.controls}>
      {COLORS.map((type, index) =>
        <ColorStyleButton
          active={currentStyle.has(type.style)}
          label={type.label}
          onToggle={props.onToggle}
          style={type.style}
          key={index}
        />
      )}
    </div>
  );
};


export default connect(null, null)(toJS(RichhText));