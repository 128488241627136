import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { connect } from 'react-redux';
import { toJS } from '../../containers/to-js';
import { getTranslate } from 'react-localize-redux';
import { getAllReports } from '../../actions/reports';
import { saveDashboard } from '../../actions/user';
import { Label, Button } from 'reactstrap';

class ReportSelector extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedReportId: null
        }
    }
    componentDidMount() {
        const { getAllReports } = this.props;
        getAllReports();
    }
    reportSelected(report) {
        const selectedReportId = report ? report.id : null;
        this.setState({ selectedReportId });
    }
    reportAlreadAdded(id, savedReports) {
        let flag = false;
        if (!savedReports) return false;
        for (let index = 0; index < savedReports.length; index++) {
            const report = savedReports[index];
            if (report.reportId === id) {
                flag = true;
                break;
            }
        }
        return flag;
    }
    addReport() {
        const { savedDashboard: { data }, saveDashboard } = this.props;
        const { selectedReportId } = this.state;
        const payload = Object.assign({}, data);
        if (payload.layoutInformation === null) {
            payload.layoutInformation = JSON.stringify([{
                i: `reportid__${selectedReportId}`,
                x: 0,
                y: 0,
                w: 1,
                h: 2
            }]);
        }
        else {
            const _layout = JSON.parse(payload.layoutInformation);
            _layout.push({
                i: `reportid__${selectedReportId}`,
                x: 0,
                y: 0, // puts it at the bottom
                w: 1,
                h: 2
            });
            payload.layoutInformation = JSON.stringify(_layout);
        }
        if (payload.savedReports === null) {
            payload.savedReports = [{
                reportId: selectedReportId,
                preferences: ''
            }]
        }
        else {
            payload.savedReports.push({
                reportId: selectedReportId,
                preferences: ''
            });
        }
        saveDashboard(payload);
    }
    optionRenderer(report) {
        const { name, id } = report;
        return <div>
            {name}
        </div>
    }
    render() {
        const { allReports, savedDashboard } = this.props;
        const { isLoading, data, hasError } = allReports;
        const { selectedReportId } = this.state;
        const reportAlreadyAdded = selectedReportId ? this.reportAlreadAdded(selectedReportId, savedDashboard.data.savedReports) : false;
        return (
            <div className='d-flex h-100 align-items-center'>
                <div>
                    <Label className='mb-0 text-muted'>
                        <i className="fa fa-search mr-1" aria-hidden="true"></i>Search & add reports
                    </Label>
                </div>
                <div className='ml-2'>
                    <Select
                        name="report-selector"
                        isLoading={isLoading}
                        options={data}
                        labelKey='name'
                        valueKey='id'
                        className='report-seletor'
                        onChange={this.reportSelected.bind(this)}
                        value={selectedReportId}
                    />
                </div>
                {
                    selectedReportId && !reportAlreadyAdded ? <div className='ml-2'>
                        <Button color='light' onClick={this.addReport.bind(this)}>
                            <i className="fa fa-plus mr-1" aria-hidden="true"></i>Add Report
                        </Button>
                    </div> : ''
                }
                {
                    selectedReportId && reportAlreadyAdded ? <span className='ml-2 text-danger'>
                        <i className="fa fa-exclamation-triangle mr-1" aria-hidden="true"></i>Already Added!
                    </span> : ''
                }
            </div>
        );
    }
}

ReportSelector.propTypes = {

};

const mapDispatchToProps = (dispatch) => {
    return {
        getAllReports: () => {
            dispatch(getAllReports());
        },
        saveDashboard: (reportId) => {
            dispatch(saveDashboard(reportId));
        }
    }
};

const mapStateToProps = (state, ownProps) => {
    return {
        allReports: state.getIn(['reports', 'allReports']),
        savedDashboard: state.getIn(['user', 'dashboard'])
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(toJS(ReportSelector));