import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Breadcrumb, BreadcrumbItem, Button } from 'reactstrap';
import { connect } from 'react-redux';
import { toJS } from '../../containers/to-js';
import { addBreadcrumb, removeBreadcrumb } from '../../actions/breadcrumbs';
import { endWorkflow } from '../../actions/workflows';

class AppBreadcrumbs extends Component {
    componentDidMount() {
        this.pushCrumb(this.props);
    }
    componentWillReceiveProps(nextProps) {
        const { baseWorkflow } = this.props;
        if (baseWorkflow && baseWorkflow.sessionId !== nextProps.baseWorkflow.sessionId && nextProps.baseWorkflow.parentSession !== null) {
            this.pushCrumb(nextProps);
        }
    }
    hasDuplicate(breadcrumbs, sessionId) {
        let isPresent = false;
        for (let i = 0; i < breadcrumbs.length; i+= 1) {
            if (breadcrumbs[i].sessionId === sessionId) {
                isPresent = true;
                break;
            }
        }
        return isPresent;
    }
    endWorkflow(nextCrumb, index) {
        if (!nextCrumb) return;
        const { endWorkflow, removeBreadcrumb } = this.props;
        const { workflowModel, sessionId } = nextCrumb;
        endWorkflow({ workflowId: workflowModel, sessionId });
        removeBreadcrumb({ index });
    }
    pushCrumb(props) {
        if (!props.baseWorkflow) return;
        const { baseWorkflow, addBreadcrumb, breadcrumbs } = props;
        const { parentSession, currentItem: { name }, sessionId, workflowModel } = baseWorkflow;
        !this.hasDuplicate(breadcrumbs, sessionId) && addBreadcrumb({ name, sessionId, workflowModel });
    }
    render() {
        const { breadcrumbs, baseWorkflow: { sessionId, workflowModel } } = this.props;
        if (!breadcrumbs || breadcrumbs.length < 2) return null;
        return (
            <div className='app-breadcrumbs-main'>
                <Breadcrumb>
                    {
                        breadcrumbs.map((crumb, index) => {
                            const disabledCrumb = crumb.sessionId === sessionId;
                            const payload = { id: crumb.workflowModel, isBase: true, displayMode: 'DEFAULT' };
                            const endPoint = '/workflow/start';
                            return <BreadcrumbItem key={index} active={disabledCrumb}>
                                <Button className='crumb-link' disabled={disabledCrumb} color='link' onClick={this.endWorkflow.bind(this, breadcrumbs[index + 1], index + 1)}>
                                    {crumb.name}
                                </Button>
                            </BreadcrumbItem>
                        })
                    }
                </Breadcrumb>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        baseWorkflow: state.getIn(['workflow', 'baseWorkflow']),
        breadcrumbs: state.getIn(['breadcrumbs', 'items'])
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        addBreadcrumb: (payload) => {
            dispatch(addBreadcrumb(payload));
        },
        endWorkflow: (payload) => {
            dispatch(endWorkflow(payload));
        },
        removeBreadcrumb: (payload) => {
            dispatch(removeBreadcrumb(payload));
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(toJS(AppBreadcrumbs));