import React from 'react';
import PrintText from './PrintText';
import { Field } from 'redux-form/immutable';
import { FormText, Label } from 'reactstrap';
import RatingComp from 'react-rating';

class RatingCompRange extends React.Component {
  constructor(props) {
      super(props);
      this.state = {
          value: 0
      }
  }
  onSliderChanged(value) {
      const { input: { onChange }, label } = this.props;
      this.setState({ value }, () => {
          onChange(value);
      });
  }
  render() {
      const { value, label} = this.props;
      let ratingConfig  = this.props.layoutItem.itemProperties.ratingConfig;
      if(!ratingConfig) ratingConfig = this.props.ratingConfig;
      if(!ratingConfig) return <span>Please configure rating element!</span>
      const { ratingType, ratingLength, ratingStep } = ratingConfig;
      
      return (
          <div>
              {
                label.show ? <Label className='text-capitalize'>{label.text}</Label> : ''
              }
              <div className='rating-container'>
                <RatingComp
                  onChange={this.onSliderChanged.bind(this)}
                  start={0}
                  stop={parseInt(ratingLength) || 5}
                  initialRating={this.state.value}
                  fractions={ratingType.value === 'star-half' ? 2 : 1}
                  step={parseInt(ratingStep) || 1}
                  emptySymbol={`fa fa-${ratingType.value === 'star-half' ? 'star-o' : ratingType.value === 'thumbs-up' ? 'thumbs-o-up' : ratingType.value+'-o'} fa-2x ${ratingType.value}`}
                  fullSymbol={`fa fa-${ratingType.value === 'star-half' ? 'star' : ratingType.value} fa-2x ${ratingType.value}`}
                />
              </div>
          </div>
      )
  }
}

const Rating = (props) => {
    const { change, classProperty, type, validations, value, isReadOne, ...rest } = props;
    const { layoutItem: { itemProperties: { field: { properties } } } } = props;
    return (
        <div>
            {
                isReadOne ?
                    <PrintText {...props} /> :
                    <div>
                        <Field
                          name={classProperty}
                          component={RatingCompRange}
                          value={value}
                          {...rest}
                          onChange={value => change(classProperty, value)}
                          label={props.label}
                      />
                        <FormText>{properties.validationMessage}</FormText>
                    </div>
            }
        </div>
    );
}

export default Rating;