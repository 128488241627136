import React, { Component } from 'react';
import { Label } from 'reactstrap';
import { ModelPropertiesParser } from '../../commons/modelPropertiesParser';

class TypeModelBulletedList extends Component {
    render() {
        const { workflowData, modelSelector, label } = this.props;
        return (
            <div>
                <Label className='text-capitalize mb-2'>{label.text}</Label>
                <ul>
                    {
                       workflowData &&  workflowData.map((data, index) => {
                            return (
                                <li key={index}>
                                    {ModelPropertiesParser(modelSelector, data)}
                                </li>
                            )
                        })
                    }
                </ul>
            </div>
        );
    }
}

export default TypeModelBulletedList;