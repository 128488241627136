import SimpleTextCell from './SimpleTextCell';
import TypeModelCell from './TypeModelCell';
import RowSelectorCell from './RowSelectorCell';
import FileCell from './FileCell';
import UserCell from './UserCell';
import DateCell from './DateCell';
import UrlCell from './UrlCell';
import LocationCell from './LocationCell';
import RatingCell from './RatingCell';

export const TYPE = TypeModelCell;
export const FILE = FileCell;
export const SIMPLETEXTCELL = SimpleTextCell;
export const ROWSELECTORCELL = RowSelectorCell;
export const USER = UserCell;
export const DATE = DateCell;
export const URL = UrlCell;
export const LOCATION = LocationCell;
export const RATING = RatingCell;