import React, { Component } from 'react';
import LayoutItemContainer from '../../../containers/workflow/LayoutItemContainer';
import { Row, Col, Form, FormGroup, Button } from 'reactstrap';
import CreateUpdateHeader from './headers/CreateUpdateHeader';
import { build } from './helpers/form-layout/common-routines';
import Wrapper from './helpers/form-layout/Wrapper';
import { reduxForm } from 'redux-form/immutable';

class ReadOne extends Component {
    render() {
        const { workflow } = this.props;
        return (
            <div>
                <Wrapper>
                    <div className='read-one-workflow-form'>
                        {
                            build(this.props)
                        }
                    </div>
                </Wrapper>
            </div>
        );
    }
}

export default ReadOne;
