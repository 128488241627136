import React, { Component } from 'react'
import { FormGroup, Label, FormFeedback } from 'reactstrap';
export default class StaticImage extends Component {
  render() {
    const { staticImageConfig } = this.props.layoutItem.itemProperties;
    const { label} = this.props;
    if(!staticImageConfig) return null 
    const imageURL = staticImageConfig.url
    return (
      <div style={{flexDirection:"column"}}>
         {
          label.show?<Label>{label.text}</Label>:''
         }
      <div className='staticImageDiv'>
      <img className='staticImage' src={imageURL} alt='StaticImage' />
      </div>
      </div>
    )
  }
}
