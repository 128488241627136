import React, { Component } from 'react';
import { Badge, Label, Button, ButtonGroup } from 'reactstrap';
import { ModelPropertiesParser } from '../../commons/modelPropertiesParser';
import RenderIcon from '../../commons/RenderIcon'

class TypeModeCountOnly extends Component {
    render() {
        const { countConfig, workflowData, modelSelector, label, viewMode, typeModelViewMode, onChangeTypeModelViewMode, layoutItem: { itemProperties },customIcons } = this.props;

        const  { beforeIcon, textColor } = itemProperties;        
        if (!countConfig || !workflowData) return null;
        if (workflowData && Array.isArray(workflowData)) {
            const { beforeText, afterText } = countConfig;
            const displayCount = `${beforeText ? beforeText : ''} ${workflowData.length} ${afterText ? afterText : ''}`;
            return (
                <div className={textColor ? `text-${textColor.value}` : ''}>
                    <div className='d-flex justify-content-between mb-2'>
                        <div>
                            {
                            label.show && <Label className='text-capitalize mb-1'>{label.text}</Label>
                            }
                        </div>
                        <div>
                            {
                                itemProperties && itemProperties.allowAdditionalTypeModelViewMode && <ButtonGroup className="mr-4">
                                <Button onClick={onChangeTypeModelViewMode.bind(this, typeModelViewMode.value)} color='primary' outline={viewMode !== typeModelViewMode.value} size="sm">
                                    <i className="fa fa-th-list" aria-hidden="true"></i>
                                </Button>
                                <Button onClick={onChangeTypeModelViewMode.bind(this, itemProperties.additionalTypeModelViewMode.value)} color='primary' outline={viewMode !== itemProperties.additionalTypeModelViewMode.value} size="sm">
                                    <i className="fa fa-th-large" aria-hidden="true"></i>
                                </Button>
                            </ButtonGroup>
                            }
                        </div>
                    </div>
                    <div className='d-flex'>
                    { beforeIcon && <span className="mr-2"><RenderIcon config={beforeIcon}  customIcons={customIcons}/></span>}{displayCount}
                    </div>
                </div>
            )
        }
        return (
            <div className='d-flex'>

            </div>
        );
    }
}

export default TypeModeCountOnly;