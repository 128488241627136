import React, { Component } from 'react';
import PropTypes from 'prop-types';
import 'react-virtualized/styles.css';
import { Table, Column } from 'react-virtualized/dist/commonjs/Table';
import AutoSizer from 'react-virtualized/dist/commonjs/AutoSizer';
import WindowScroller from 'react-virtualized/dist/commonjs/WindowScroller';
import { Button, Label, Input } from 'reactstrap';

class AssignValueTable extends Component {
    renderCustomCell(column, data) {
        const hasCustomRender = column.type === 'TYPE';
        const cellData = data.cellData;
        if (!hasCustomRender) return cellData;
        return "Not Available";
    }
    rowSelectorChanges(data, event) {
        const checked = event.target.checked;
        const { addSourceId, removeSourceId } = this.props;
        checked ? addSourceId(data.rowData.id) : removeSourceId(data.rowData.id);   
    }
    renderSelector(data) {
        const { assignValueAddSourceId } = this.props;
        return <Label check style={{ marginTop: -10, display: 'block' }}>
            <Input onChange={this.rowSelectorChanges.bind(this, data)} type="checkbox" />
            <span className='sr-only'>Select this row</span>
        </Label>
    }
    onRowClicked({  }) {

    }
    render() {
        const { rows, columns, workflow } = this.props;
        if (!rows || !columns) return null;
        return (
            <div className='h-100 table-wrapper'>
                <WindowScroller>
                    {({ height, isScrolling, onChildScroll, scrollTop }) => (
                        <AutoSizer>
                            {({ width }) => (
                                <Table
                                    width={width}
                                    autoHeight={true}
                                    height={height}
                                    isScrolling={isScrolling}
                                    onScroll={onChildScroll}
                                    headerHeight={60}
                                    rowHeight={50}
                                    rowCount={rows.length}
                                    scrollTop={scrollTop}
                                    onRowClick={this.onRowClicked.bind(this)}
                                    rowGetter={({ index }) => {
                                        return rows[index];
                                    }}>
                                    <Column
                                        key='assign-value-selector'
                                        label=''
                                        dataKey={'id'}
                                        cellRenderer={this.renderSelector.bind(this)}
                                        width={25}>
                                    </Column>
                                    {
                                        columns.map((column, index) => {
                                            return <Column
                                                key={index}
                                                label={column.name}
                                                dataKey={column.classProperty}
                                                cellRenderer={this.renderCustomCell.bind(this, column)}
                                                width={250}
                                            />
                                        })
                                    }
                                </Table>
                            )}
                        </AutoSizer>
                    )}
                </WindowScroller>
            </div>
        );
    }
}

AssignValueTable.defaultProps = {
    rows: [],
    columns: []
}

AssignValueTable.propTypes = {
    rows: PropTypes.array.isRequired,
    columns: PropTypes.array.isRequired
};

export default AssignValueTable;