import { connect } from 'react-redux';
import ReadOne from '../../../components/workflow/operations/ReadOne';
import { getTranslate } from 'react-localize-redux';
import { toJS } from '../../to-js';
import { readOne, reset, next } from '../../../actions/workflow-item';
import { triggerAction } from '../../../actions/workflow-actions';

const mapStateToProps = (state, ownProps) => {
  return {};
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
      triggerAction(action, tid, workflow) {
          dispatch(triggerAction(action, tid, workflow));
      },
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(toJS(ReadOne));