import React, { useState, useEffect } from 'react';
import { ModelPropertiesParser } from '../../commons/modelPropertiesParser';

const CardReport = props => {
  const [filters, onChangeFilters] = useState([]);
  const [data, onChangeData] = useState([]);
  const {
    reportConfig,
    label,
    graphHeight,
    getReportDataByAction,
    workflow,
  } = props;
  useEffect(() => {
    if (
      reportConfig &&
      Array.isArray(reportConfig.filters) &&
      reportConfig.filters[0]
    ) {
      getReportDataByAction({
        workflowSessionId: workflow.sessionId,
        actionUniqueId: reportConfig.filters[0].actionGuid.value,
        filters,
        actionName: reportConfig.filters[0].actionGuid.label
      })
        .then(res => {
          try {
            const responseData = res.data.data[0];
            const modelKeys = Object.keys(responseData);
            modelKeys.map((k, i) => {
              responseData[k.replace(' ', '_')] = JSON.parse(responseData[k]);
            });
            const updatedData = Object.assign([], data);
            reportConfig.columns &&
              reportConfig.columns.map((col, colIndex) => {
                const value = ModelPropertiesParser(col.value, responseData);
                console.log(col.value);
                const entry = {
                  label: col.label,
                  value: parseInt(value),
                };
                updatedData.push(entry);
              });
            onChangeData(updatedData);
          } catch (e) {
            console.log(e, 'JSON PARSE ERROR');
          }
        })
        .catch(ex => {
          onChangeData([]);
        });
    }
  }, [filters]);
  return (
    <div>
      <p>CardReport</p>
    </div>
  )
}

export default CardReport;