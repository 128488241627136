import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import { toJS } from '../../containers/to-js';
import { connect } from 'react-redux';
import { endWorkflow } from '../../actions/workflows';
import { popBreadcrumb } from '../../actions/breadcrumbs';

class EndWorkflowButton extends Component {
    end() {
        const { endWorkflow, popBreadcrumb, baseWorkflow: { workflowModel, sessionId } } = this.props;
        popBreadcrumb();
        endWorkflow({ workflowId: workflowModel, sessionId });
    }
    render() {
        const { color, size, iconClass, className } = this.props;
        return (
            <Button onClick={this.end.bind(this)} className={className} color={color} size={size}>
                <i className={iconClass} aria-hidden="true"></i> Back
            </Button>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    const baseWorkflow = state.getIn(['workflow', 'baseWorkflow']);
    return {
        baseWorkflow
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        endWorkflow: (payload) => {
            dispatch(endWorkflow(payload));
        },
        popBreadcrumb: () => {
            dispatch(popBreadcrumb());
        }
    }
};

EndWorkflowButton.defaultProps = {
    color: 'link',
    size: 'sm',
    iconClass: 'fa fa-times',
    className: null
}

EndWorkflowButton.propTypes = {
    color: PropTypes.string,
    size: PropTypes.string,
    iconClass: PropTypes.string,
    className: PropTypes.string
};

export default connect(mapStateToProps, mapDispatchToProps)(toJS(EndWorkflowButton));