import React, { Component } from 'react';
import ActionContainer from '../../../containers/workflow/ActionContainer';
import { Button } from 'reactstrap';
import { fromJS } from 'immutable'

class SubWorkflowTrigger extends Component {
    getMatchedAction() {
        const { workflow: { currentItem: { inlineAction } }, config: { textualActionTriggerCode } } = this.props;
        let matchedAction = null;
		for (let i = 0; i < inlineAction.length; i += 1) {
            if (inlineAction[i].actionProperties.hasOwnProperty('generatedActionCode') && inlineAction[i].actionProperties.generatedActionCode === textualActionTriggerCode) {
                matchedAction = inlineAction[i];
                break;
            }
        }
        return matchedAction
    }
	
	
    render() {
        const { workflow, rows, rowIndex, data, text, wfData } = this.props;
		if (!workflow) return null;
        const action = this.getMatchedAction();
		if (!action) return null;
        let copyAction = fromJS(action);
        copyAction = copyAction.toJS();
        copyAction.actionProperties.elementText = text;
        copyAction.actionProperties.elementType = 'LINK';
		const tid = data ? data.id : rows.getObjectAt(rowIndex)['id'];
        const { actionProperties } = copyAction;
        if(actionProperties && actionProperties.actionDisabled) {
            return null;
        }
		return (
            <ActionContainer wfData={wfData} workflow={workflow} action={copyAction} tid={tid} />
        )
    }
}

export default SubWorkflowTrigger;