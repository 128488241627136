import React, { Component } from 'react';
import { Badge, Label, Button, ButtonGroup } from 'reactstrap';
import { ModelPropertiesParser } from '../../commons/modelPropertiesParser';

class TypeModelBadges extends Component {
    constructor(props){
        super(props);
        this.state = {
            showMore: true
        }
    }
    onChangeShowMore = () => {
        this.setState({showMore: !this.state.showMore})
    }
    render() {
        const { badgeType, badgeCount, workflowData, modelSelector, label, viewMode, typeModelViewMode, onChangeTypeModelViewMode, layoutItem: { itemProperties } } = this.props;
        if (!badgeType || !workflowData) return null;
        const workflowDataCount = this.state.showMore && badgeCount ? workflowData.filter((data, index) => index < badgeCount) : workflowData;
        if (workflowData && Array.isArray(workflowData)) {
            return (
                <div>
                    <div className='d-flex justify-content-between mb-2'>
                        <div>
                            {
                            label.show && <Label className='text-capitalize mb-1'>{label.text}</Label>
                            }
                        </div>
                        <div>
                            {
                                itemProperties && itemProperties.allowAdditionalTypeModelViewMode && <ButtonGroup className="mr-4">
                                <Button onClick={onChangeTypeModelViewMode.bind(this, typeModelViewMode.value)} color='primary' outline={viewMode !== typeModelViewMode.value} size="sm">
                                    <i className="fa fa-th-list" aria-hidden="true"></i>
                                </Button>
                                <Button onClick={onChangeTypeModelViewMode.bind(this, itemProperties.additionalTypeModelViewMode.value)} color='primary' outline={viewMode !== itemProperties.additionalTypeModelViewMode.value} size="sm">
                                    <i className="fa fa-th-large" aria-hidden="true"></i>
                                </Button>
                            </ButtonGroup>
                            }
                        </div>
                    </div>
                    
                    <div className='d-flex'>
                        {
                            workflowDataCount.map((data, index) => {
                                return (
                                    <Badge key={index} pill color={`${badgeType.value.toLowerCase()}`} className='mr-1'>
                                        {ModelPropertiesParser(modelSelector, data)}
                                    </Badge>
                                )
                            })
                        }
                        {
                            badgeCount && workflowData.length > badgeCount ? <Button onClick={this.onChangeShowMore} color="link" size="md">{this.state.showMore ? 'more' : 'less'}</Button> : ''
                        }
                    </div>
                </div>
            )
        }
        return (
            <div className='d-flex'>

            </div>
        );
    }
}

export default TypeModelBadges;