import React, { Component } from 'react';
import { FormGroup, Label, Input, Button } from 'reactstrap';
import * as Elements from '../elements/index';
import { operation as workflowOperation } from '../../dto/workflow';
import { ModelPropertiesParser } from '../commons/modelPropertiesParser';
import { startsWith } from 'lodash';

class LayoutItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hide: true,
      showButton: false,
      validations: [],
	  itemId: ''
    };
  }

  componentDidMount() {
    const {
      workflow: { sessionId ,sessionParams,currentItem:{operation} },
      layoutItem,
      resolveRules,
      formValues,
      classProperty,
      workflowData,
      inlineFormKey
    } = this.props;
    const itemProperties = layoutItem ? layoutItem.itemProperties : null;
    if (itemProperties && itemProperties.displayConfig) {
      if (itemProperties.displayConfig.displayWhen.value === 'basedOnRule') {
        if (
          itemProperties.connector &&
          itemProperties.connector.rules &&
          itemProperties.connector.rules.length
        ) {

          const rules = itemProperties.connector.rules;
          let cacheId = sessionId;
          rules.forEach( (x, i) => {
            if(x.key) {
            cacheId +="_" + x.key.typeModelName;
            cacheId +="_" + x.key.property;
            cacheId +="_" + x.key.staticValue;
            }
            cacheId +="_" + x.operator;
            if(x.value) {
              cacheId +="_" + x.value.staticValue;
              cacheId +="_" + x.value.typeModelName;
              cacheId +="_" + x.value.property;
            }
        });
        let chkParamRule = false
        rules.forEach( (x, i) => {
          if(x && x.sessionParamCode == "HasRequestParam") {
            let staticValue = x.value.staticValue
            chkParamRule = true
            staticValue = staticValue.split('=')
             const sessionParamKey = Object.keys(sessionParams)
             sessionParamKey.forEach((key)=>{
              if(key == staticValue[0] && sessionParams[key] == staticValue[1]){
                this.setState({ hide: false });
               
             }
             })
          }})
          if(!window.rulesCache) window.rulesCache = [];
          const self = this;
          if(window.rulesCache[cacheId] && 1==2) {
            self.setState({ hide: !window.rulesCache[cacheId].data });
          } else {
            if(!chkParamRule){
              resolveRules({
                rules: itemProperties.connector.rules,
                sessionId,
              }).then(rules => {
                if (rules.data) {
                  window.rulesCache[cacheId] = rules.data;
                  self.setState({ hide: !rules.data.data });
                }
              });
            }
          }
        } else {
          this.setState({ hide: true });
        }
      } else if (
        itemProperties.displayConfig.displayWhen.value === 'onButtonPress'
      ) {
        this.setState({ showButton: true, hide: true });
      } else if (
        itemProperties.displayConfig.displayWhen.value === 'onDataChange'
      ) {
        const field = itemProperties.displayConfig.dataField;
        let fieldValue = '';
        if(field && field.indexOf('.') == -1){
          fieldValue = formValues && formValues[`${field}`];  
          if(inlineFormKey &&  inlineFormKey.startsWith('workflow-card')){
                 fieldValue = workflowData && ModelPropertiesParser(`{{${field}}}`, workflowData)
               }
       }else {
         fieldValue= formValues && ModelPropertiesParser(`{{${field}}}`, formValues)
         if(inlineFormKey &&  inlineFormKey.startsWith('workflow-card')){
           fieldValue = workflowData && ModelPropertiesParser(`{{${field}}}`, workflowData)
         }
       }
		let values = itemProperties.displayConfig.updatedData;
		values = (values && values.indexOf(',') != -1) ? values.split(",").map((item)=>item.trim()) : values;
		if(typeof values == 'string') {
      if(values.indexOf('{{notnull}}') !== -1){
        if (typeof fieldValue !== 'undefined' && fieldValue !== null) {
          this.setState({ hide: false });
        } else {
          this.setState({ hide: true });
        }
      } else {
        if (values !== fieldValue) {
          this.setState({ hide: true });
        } else {
          this.setState({ hide: false });
        }
      }
		} else {
			const found = values && values.find(element => element == fieldValue);
			if(found) {
				this.setState({ hide: false });
			} else {
				this.setState({ hide: true });
			}
		}
        
      } else if (
        itemProperties.displayConfig.displayWhen.value === 'always'
      ) {
        this.setState({hide: false });
      }
    } else {
		this.setState({ hide: false });
	}
    const validations = this.props.getValidations(layoutItem);
    if(validations)
      this.setState({ validations, itemId: layoutItem ? layoutItem.id : ''});
  }

  onBtnPress() {
    this.setState({ hide: false });
  }

  componentWillReceiveProps(nextProps) {
    const {
      workflow: { sessionId ,currentItem:{operation} },
      layoutItem,
      resolveRules,
      formValues,
      classProperty,
      timerIndex,
      uiActions,
      workflowData,
      inlineFormKey
    } = nextProps;
    const itemProperties = layoutItem ? layoutItem.itemProperties : null;
    const timerIndexOld = this.props.timerIndex;
    if (itemProperties && itemProperties.displayConfig) {
      if (itemProperties.displayConfig.displayWhen.value === 'basedOnRule') {
        const resolveRulesFlag = itemProperties.timerConfig
          ? timerIndexOld != timerIndex
            ? true
            : false
          : true;
        if (resolveRulesFlag) {
          if (
            itemProperties.connector &&
            itemProperties.connector.rules &&
            itemProperties.connector.rules.length
          ) {
            const rules = itemProperties.connector.rules;
            let cacheId = sessionId;
            rules.forEach( (x, i) => {
              if(x.key) {
                cacheId +="_" + x.key.typeModelName;
                cacheId +="_" + x.key.property;
                cacheId +="_" + x.key.staticValue;
              }
                cacheId +="_" + x.operator;
                if(x.value) {
                  cacheId +="_" + x.value.staticValue;
                  cacheId +="_" + x.value.typeModelName;
                  cacheId +="_" + x.value.property;
                }
            });
            if(!window.rulesCache) window.rulesCache = [];
            const self = this;
            if(window.rulesCache[cacheId] && 1==2) {
              self.setState({ hide: !window.rulesCache[cacheId].data });
            } else {
              if(this.props.itemIndex !== nextProps.itemIndex) {
                resolveRules({
                  rules: itemProperties.connector.rules,
                  sessionId,
                }).then(rules => {
                  if (rules.data) {
                    window.rulesCache[cacheId] = rules.data;
                    self.setState({ hide: !rules.data.data });
                  }
                });
              }
            }
          } else {
            this.setState({ hide: true });
          }
        }
      } else if (
        itemProperties.displayConfig.displayWhen.value === 'onDataChange'
      ) {
        const field = itemProperties.displayConfig.dataField;
        let fieldValue = ''
        if(field && field.indexOf('.') == -1){
           fieldValue = formValues && formValues[`${field}`];  
           if(inlineFormKey &&  inlineFormKey.startsWith('workflow-card')){
                  fieldValue = workflowData && ModelPropertiesParser(`{{${field}}}`, workflowData)
                }
        }else {
          fieldValue= formValues && ModelPropertiesParser(`{{${field}}}`, formValues)
          if(inlineFormKey &&  inlineFormKey.startsWith('workflow-card')){
            fieldValue = workflowData && ModelPropertiesParser(`{{${field}}}`, workflowData)
          }
        }

        if(fieldValue === true ){
          fieldValue = JSON.stringify(fieldValue);
        }
        let values = itemProperties.displayConfig.updatedData;
		values = (values && values.indexOf(',') != -1) ? values.split(",").map((item)=>item.trim()) : values;
		if(typeof values == 'string') {
      if(values.indexOf('{{notnull}}') !== -1){
        if (typeof fieldValue !== 'undefined' && fieldValue !== null) {
          this.setState({ hide: false });
        } else {
          this.setState({ hide: true });
        }
      } 
			else if (fieldValue && typeof fieldValue == 'string' && values == fieldValue) {
				this.setState({ hide: false });
			} else if (fieldValue && typeof fieldValue != 'string' && fieldValue.length ) {
				const found = fieldValue && fieldValue.find(element => element == values);
				if(found) {
					this.setState({ hide: false });
				}
			} else {
				this.setState({ hide: true });
			}
		} else {
			if (fieldValue && typeof fieldValue == 'string') {
				const found = values && values.find(element => element == fieldValue);
				if(found) {
					this.setState({ hide: false });
				} else {
					this.setState({ hide: true });
				}
			} else if (fieldValue && typeof fieldValue != 'string' && fieldValue.length ) {
				var found = fieldValue.filter((item) => !values.includes(item));
				if(found) {
					this.setState({ hide: false });
				}
			} else {
				this.setState({ hide: true });
			}
		}
      }
    }

    if(this.props.itemIndex !== nextProps.itemIndex) {
      const validations = this.props.getValidations(layoutItem);
      if(validations)
        this.setState({ validations, itemId: layoutItem ? layoutItem.id : ''});
    }
  }

  render() {
    const { layoutItem, type } = this.props;
    const itemProperties = layoutItem ? layoutItem.itemProperties : null;
    const hideInWeb = itemProperties ? itemProperties.hideInWeb : null;
    const hideInMobileWebView = itemProperties ? itemProperties.hideInMobileWebView : null;
    if (hideInWeb) return null;
    if (hideInMobileWebView && window.innerWidth < 480 ) return null;
    let { classProperty, ...rest } = this.props;
    let higherZindex = false;
    if (!layoutItem) return null;
    const displayConfig = layoutItem.itemProperties.displayConfig;
    const disableBeforDataChange = displayConfig && displayConfig.disableBeforDataChange 
    let ResolvedComponent = Elements[type];
    if (ResolvedComponent === null || !ResolvedComponent) {
      ResolvedComponent = Elements.FALLBACK;
    }

    if (typeof layoutItem.itemProperties.replaceClassProperty === 'string') {
      classProperty = layoutItem.itemProperties.replaceClassProperty;
    }
    if (type === 'TYPE' || type === 'USER' || type === 'DROPDOWN') {
      higherZindex = true;
    }

    let handleAuthentication = () => {};
    if (type === 'AUTH') {
      handleAuthentication = () => {
        this.props.next();
        //this.props.fetchNavWorkflows && this.props.fetchNavWorkflows();
      };
    }
    const additionalClass = layoutItem.itemProperties.animationType
      ? 'wow ' + layoutItem.itemProperties.animationType.value
      : '';
    return (
      <div
        className={`layout-item-wrapper`}
        data-aos={
          layoutItem.itemProperties.animationType
            ? layoutItem.itemProperties.animationType.value
            : ''
        }
        data-aos-delay={
          layoutItem.itemProperties.animationDelay
            ? layoutItem.itemProperties.animationDelay.value
            : ''
        }
      >
        {( disableBeforDataChange || !this.state.hide) && (
          <ResolvedComponent
            {...rest}
            validations={this.state.validations}
            classProperty={classProperty}
            disableLayoutItem= {this.state.hide}
            authenticationCallback={handleAuthentication}
          />
        )}
        {this.state.showButton && (
          <Button
            onClick={this.onBtnPress.bind(this)}
            color={displayConfig.buttonStyle}
          >
            <i
              className={`fa ${displayConfig.cssClass} mr-1`}
              aria-hidden='true'
            ></i>
            {displayConfig.buttonName}
          </Button>
        )}
      </div>
    );
  }
}

export default LayoutItem;
