import React, { Component } from 'react';
import Chart from './charts/chart';
import DashboardHeader from './dashboard-header';
import { connect } from 'react-redux';
import { toJS } from '../../containers/to-js';
import { getTranslate } from 'react-localize-redux';
import { getReportData } from '../../actions/reports';
import { loadDashboard, saveDashboard } from '../../actions/user';
import { Row, Col, Alert } from 'reactstrap';
import { WidthProvider, Responsive } from "react-grid-layout";
import sizeMe from 'react-sizeme';

const ResponsiveReactGridLayout = WidthProvider(Responsive);
const BASE_WIDGET_HEIGHT = 200;

class Dashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            chartItems: []
        };
    }
    onLayoutChange(layout) {
        const { savedDashboard, saveDashboard } = this.props;
        const updatedPayload = Object.assign({}, savedDashboard.data);
        const strLayout = JSON.stringify(layout);
        if (updatedPayload.layoutInformation !== strLayout) {
            updatedPayload.layoutInformation = strLayout;
            saveDashboard(updatedPayload);
        }
    }
    createElement(chart) {
        const reportId = Number(chart.i.split('__')[1]);
        const { getReportData, saveDashboard, savedDashboard } = this.props;
        return (
            <div key={chart.i} data-grid={chart}>
                <Chart 
                    graphHeight={(chart.h * BASE_WIDGET_HEIGHT) - 100} 
                    reportId={reportId} 
                    getReportData={getReportData} 
                    saveDashboard={saveDashboard} 
                    savedDashboard={savedDashboard.data}
                />
            </div>
        )
    }
    componentDidMount() {
        const { loadDashboard } = this.props;
        loadDashboard();
        setTimeout(() => {
            window.dispatchEvent(new Event('resize'));
        }, 250);
    }
    componentWillReceiveProps(nextProps) {
        const { savedDashboard } = nextProps;
        if (savedDashboard && !savedDashboard.isLoading && !savedDashboard.hasError && savedDashboard.data && savedDashboard.data.layoutInformation !== null) {
            let layoutInformation = JSON.parse(savedDashboard.data.layoutInformation);
            if (layoutInformation && Array.isArray(layoutInformation) && layoutInformation.length !== this.state.chartItems.length) {
                this.setState({ chartItems: layoutInformation });
            }
        }
    }
    render() {
        const { chartItems } = this.state;
        const { savedDashboard, getReportData, size: { width } } = this.props;
        const { isLoading, hasError, data } = savedDashboard;
        if (!data) return null;
        if (isLoading) return <DashboardLoading />;
        if (hasError) return <DashboardError />;
        if (!chartItems.length) return <NoReportConfigured />;
        return (
            <div>
                <DashboardHeader />
                <div className='common-container-wrapper dashboard-main'>
                    {
                        !chartItems.length ? null : <ResponsiveReactGridLayout
                            width={width}
                            className="dashboard-rgl-container"
                            isDraggable={true}
                            isResizable={true}
                            rowHeight={BASE_WIDGET_HEIGHT}
                            breakpoints={{ lg: 1900, md: 1280, sm: 768, xs: 480 }}
                            // draggableHandle='.gridster-drag-handle'
                            onResizeStop={this.onLayoutChange.bind(this)}
                            onDragStop={this.onLayoutChange.bind(this)}
                            cols={{ lg: 6, md: 6, sm: 3, xs: 1 }}>
                            {
                                chartItems.map((chartItem, index) => {
                                    return this.createElement(chartItem)
                                })
                            }
                        </ResponsiveReactGridLayout>
                    }
                </div>
            </div>
        );
    }
}

const NoReportConfigured = (props) => {
    return <div>
        <DashboardHeader />
        <div className='common-container-wrapper'>
            <Alert color='info'><i className="fa fa-info-circle mr-1" aria-hidden="true"></i>No report has been added yet.</Alert>
        </div>
    </div>
}

const DashboardLoading = (props) => {
    return <div className='common-container-wrapper'>
        <Alert color='light'>
            <i className="fa fa-spinner fa-pulse fa-1x mr-1 fa-fw"></i>Loading your dashboard and reports...
        </Alert>
    </div>
}

const DashboardError = (props) => {
    return <div className='common-container-wrapper'>
        <Alert color='danger'>
            <i className="fa fa-exclamation-triangle mr-1"></i>Something went wrong while loading the dashboard
        </Alert>
    </div>
}

const mapDispatchToProps = (dispatch) => {
    return {
        getReportData: (payload) => {
            return dispatch(getReportData(payload));
        },
        loadDashboard: () => {
            dispatch(loadDashboard());
        },
        saveDashboard: (payload) => {
            dispatch(saveDashboard(payload));
        }
    }
};

const mapStateToProps = (state, ownProps) => {
    return {
        savedDashboard: state.getIn(['user', 'dashboard'])
    };
};

Dashboard = sizeMe()(Dashboard);

export default connect(mapStateToProps, mapDispatchToProps)(toJS(Dashboard));
