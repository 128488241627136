import React, { Component } from 'react';
import { Cell } from 'fixed-data-table-2';
import NoDataCell from '../NoDataCell';
import User from '../../../../commons/user/index';
import TextStyleResolver from '../../../TextStyleResolver/index';

class UserCell extends Component {
    render() {
        const { rows, column, columnKey, rowIndex, width } = this.props;
        const user = rows.getObjectAt(rowIndex)[columnKey];
        if (!user) {
            return <NoDataCell {...this.props}/>
        }
        if (Array.isArray(user)) {
            return <Cell width={width}>
                {
                    user.map((u, index) => {
                        return <div className='mb-1'>
                            <User key={index} avatarSize={25} user={u} displayMode='SingleLine' />
                        </div>
                    })
                }
            </Cell>
        }
        let cellAlignment;
        if(this.props.config && this.props.config.styles && this.props.config.styles) {
            const styles = this.props.config.styles
            cellAlignment = styles.alignment && styles.alignment.value;
        }
        const renderUser = <TextStyleResolver text={<User avatarSize={25} user={user} displayMode='SingleLine' />} config={column.config} {...this.props} />;
        return (
            <Cell align={cellAlignment ? cellAlignment : 'left'} width={width}>
                {renderUser}
            </Cell>
        );
    }
}

export default UserCell;