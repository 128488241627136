import {
    SAVE_ITEM_PAYLOAD,
    SAVE_ITEM_PAYLOAD_SUCCESS,
    SAVE_ITEM_PAYLOAD_ERROR,
    UPDATE_ITEM_PAYLOAD,
    UPDATE_PAYLOAD_DIRECT,
    RESET_ITEM_PAYLOAD
} from '../actions/workflow-item';
import { Map } from 'immutable';

const intitalState = Map({
    isLoading: false,
    data: null,
    payload: Map({})
});

const updatePayload = (state, payload) => {
    const currentPayload = state.get('payload');
    const newPayload = currentPayload.set(payload.key, payload.value);
    return state.merge({ payload: newPayload });
}

const save = (state = intitalState, action = {}) => {
    switch (action.type) {
        case UPDATE_ITEM_PAYLOAD: return updatePayload(state, action.payload)
        case RESET_ITEM_PAYLOAD: return intitalState;
        case UPDATE_PAYLOAD_DIRECT: return state.merge({ payload: action.payload });

        case SAVE_ITEM_PAYLOAD: return state.merge({
            isLoading: true
        });
        case SAVE_ITEM_PAYLOAD_SUCCESS: return state.merge({
            isLoading: false,
            data: action.payload
        });
        case SAVE_ITEM_PAYLOAD_ERROR: return state.merge({
            isLoading: false,
            data: {
                hasError: true,
                error: action.payload
            }
        });
        default: return state;
    }
};

export default save;