import React from 'react';
import config from '../../config';

class ImageSelector extends React.Component {
   
    render() {
        const { layoutItem, isReadOne } = this.props;
        const { itemProperties } = layoutItem;
        const { label, field: { properties }, staticContent } = itemProperties;
        const media = staticContent && staticContent.entityMap && staticContent.entityMap[0]
                    && staticContent.entityMap[0].data && staticContent.entityMap[0].data.src?
                    staticContent.entityMap[0].data.src:null;
        return <div>
            {
                media ? <img className='img-fluid' src={media} /> : ''
            }
        </div>
    }
}

export default ImageSelector;