import React from 'react';
import { Field } from 'redux-form/immutable';
import { renderSelect, genericNormalizer } from './_FORM_FIELDS';

const normalize = (setActiveLanguage, value) => {
    setActiveLanguage(value.value)
};

const Languages = props => {
  const {
    classProperty,
    type,
    validations,
    isReadOne,
    isMulti,
    customIcons,
    availableLanguages,
    setActiveLanguage,
    ...rest
  } = props;
  const {
    layoutItem: {
      itemProperties: {
        field: { properties },
      },
    },
  } = props;
  const { itemProperties } = props.layoutItem;
  const {
    label,
    field,
    placeholder
  } = itemProperties;
  const multiple = false;
  let possibleValues = availableLanguages ? availableLanguages.split(',') : [];
  const options = possibleValues.map(val => {
    return {
      value: val,
      label: val
    };
  });
  console.log(availableLanguages)
  return (
    <div>
      <Field
          name={classProperty}
          component={renderSelect}
          type='select'
          validate={validations}
          disabled={props.disableLayoutItem}
          fieldType={type}
          valueKey='value'
          labelKey='label'
          options={options}
          customIcons={customIcons}
          properties={properties}
          {...rest}
          renderSelectType='DROPDOWN'
          normalize={normalize.bind(this, setActiveLanguage)}
          multiple={multiple}
        />
    </div>
  );
};

export default Languages;
