import React, { Component } from 'react';
import Board from 'react-trello';
import { ModelPropertiesParser } from '../../commons/modelPropertiesParser';
import { analyseEvaluatedText } from '../../commons/evaluatedTextVerfier';
import TextStyleResolver from '../../elements/TextStyleResolver/index'
import NoDataAlertBox from '../../commons/NoDataAlertBox';

class KanbanBoard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            saveAwareWorkflowData: props.workflowData
        }
    }
    getMatchedAction(textualActionTriggerCode) {
        const { workflow: { currentItem: { inlineAction } } } = this.props;
        let matchedAction = null;
        for (let i = 0; i < inlineAction.length; i += 1) {
            if (inlineAction[i].actionProperties.hasOwnProperty('generatedActionCode') && inlineAction[i].actionProperties.generatedActionCode === textualActionTriggerCode) {
                matchedAction = inlineAction[i];
                break;
            }
        }
        return matchedAction
    }
    onCardClick(lanes, cardId, metadata, laneId) {
        const { workflow, triggerAction } = this.props;
        let lane = null;
        for (let index = 0; index < lanes.length; index++) {
            const element = lanes[index];
            if (element.id === laneId) {
                lane = element;
                break;
            }
        }
        if (lane) {
            let card = null;
            for (let index = 0; index < lane.cards.length; index++) {
                const element = lane.cards[index];
                if (element.id === cardId) {
                    card = element;
                    break;
                }
            }
            const config = card.configs.title;
            const tid = card.context.id;
            const action = this.getMatchedAction(config.textualActionTriggerCode);
            if (!action) return null;
            action.actionProperties.elementText = '';
            action.actionProperties.elementType = 'LINK';
            triggerAction(action, tid, workflow)
        }
    }
    handleDragEnd(workflowData, cardId, sourceLaneId, targetLaneId) {
        const { updatePayload, save, laneKey } = this.props;
        const rowId = cardId.split('_')[2];
        const sourceObject = workflowData.find((data) => {
            return data.id == rowId;
        });
        sourceObject[laneKey] = targetLaneId; // FIXED: 'status' should come from JSON config
        updatePayload(sourceObject);
        save(sourceObject);
    }
    getLaneSpecificCards(lane, workflowData, card, laneKey) {
        const cards = [];
        workflowData.map((row) => {
            if (lane === row[laneKey]) {// FIXED: 'status' should come from JSON config
                cards.push({
                    id: `CARD_${Date.now().valueOf()}_${row.id}`,
                    title: ModelPropertiesParser(typeof card.title === 'object' ? card.title.path : card.title, row),
                    label: ModelPropertiesParser(typeof card.label === 'object' ? card.label.path : card.label, row),
                    description: ModelPropertiesParser(typeof card.description === 'object' ? card.description.path : card.description, row),
                    context: row,
                    configs: {
                        title: card.title,
                        label: card.label,
                        description: card.description
                    }
                });
                // cards.push({
                //     id: `CARD_${Date.now().valueOf()}_${row.id}`,
                //     title: {config: card.title, context: row},
                //     label: {config: card.label, context: row},
                //     description: {config: card.description, context: row},
                //     workflow: this.props.workflow
                // });
            }
        });
        return cards;
    }
    componentWillReceiveProps(nextProps) {
        const { saveResponse } = nextProps;
        const { isLoading, data } = saveResponse;
        if (!isLoading && data && data.length) {
            this.setState({ saveAwareWorkflowData: data });
        }
    }
    render() {
        const boardData = {
            lanes: []
        };
        const { config, cardDragEnd, workflow, laneKey } = this.props;
        const { saveAwareWorkflowData } = this.state;
        if (!saveAwareWorkflowData || (Array.isArray(saveAwareWorkflowData) && saveAwareWorkflowData.length === 0)) {
            return <NoDataAlertBox typeModelName={workflow.currentItem.typeModel.name} />
        }
        const { laneEnumerator, card } = config;
        const lanes = laneEnumerator.properties.possibleValues.length ? laneEnumerator.properties.possibleValues : [];
        lanes.map((lane) => {
            boardData.lanes.push({
                id: `${lane}`,
                title: lane,
                label: '',
                cards: this.getLaneSpecificCards(lane, saveAwareWorkflowData, card, laneKey)
            });
        });

        return (
            <Board
                className='neene-keyed-layout'
                draggable
                data={boardData}
                onCardClick={this.onCardClick.bind(this, boardData.lanes)}
                handleDragEnd={this.handleDragEnd.bind(this, saveAwareWorkflowData)}>
                <CustomCard />
            </Board>
        );
    }
}

const CustomCard = (props) => {
    // const { title, description, label, workflow, ...rest } = props;
    // const _title = ModelPropertiesParser(title.config.path, title.context);
    // const _label = ModelPropertiesParser(label.config.path, label.context);
    // const _description = ModelPropertiesParser(description.config.path, description.context);
    return <div className='kanban-custom-card'>
        <div>
            {/* <TextStyleResolver data={title.context} config={title.config} text={_title} workflow={workflow} /> */}
        </div>
    </div>

}


export default KanbanBoard;