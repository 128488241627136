import React from 'react';
import { Field } from 'redux-form/immutable';
import { Button } from 'reactstrap';
import { renderSelect } from '../elements/_FORM_FIELDS';

class Dropdown extends React.Component {
  onSelectOption = (value) => {
    const { change, classProperty, onEnter } = this.props;
    change(classProperty, value);
    setTimeout(() => {
      onEnter();
    }, 1)
  }
  render() {
    const { classProperty, type, validations, isReadOne, isMulti, ...rest } = this.props;
    const { field: { properties }, contextual_text_color } = this.props.itemProperties;
    let { possibleValues } = properties;
    return (
      <div style={{
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
      }}>
        {
          possibleValues && possibleValues.map((option, optionIndex) => {
            return (
              <Button 
                style={{
                  margin: '12px'
                }}
                key={optionIndex}
                color={
                  contextual_text_color && contextual_text_color[option] ? contextual_text_color[option].code : 'primary'
                }
                onClick={() => this.onSelectOption(option)}
              >{option}</Button>
            )
          })
        }
      </div>
    );
  }
}

export default Dropdown;