import client from '../client';
import config from '../config';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import actionTypes from '../dto/actions';
import { toast, ToastContainer } from 'react-toastify';

export const TRIGGER_ACTION_SUBWORKFLOW = 'TRIGGER_ACTION_SUBWORKFLOW';
export const TRIGGER_ACTION_SUBWORKFLOW_SUCCESS = 'TRIGGER_ACTION_SUBWORKFLOW_SUCCESS';
export const TRIGGER_ACTION_SUBWORKFLOW_FAILURE = 'TRIGGER_ACTION_SUBWORKFLOW_FAILURE';
export const TOGGLE_SUBWORKFLOW_SIDEBAR = 'TOGGLE_SUBWORKFLOW_SIDEBAR';
export const TOGGLE_ASSIGNVALUE_MODAL = 'TOGGLE_ASSIGNVALUE_MODAL';
export const SET_TID = 'SET_TID';
export const ADD_SOURCE_ID = 'ADD_SOURCE_ID';
export const REMOVE_SOURCE_ID = 'REMOVE_SOURCE_ID';

export const ACTION_TRIGGERED = 'ACTION_TRIGGERED';
export const ACTION_TRIGGERED_SUCCESS = 'ACTION_TRIGGERED_SUCCESS';
export const ACTION_TRIGGERED_FAILURE = 'ACTION_TRIGGERED_FAILURE';
export const CLEAR_ACTION_TRIGGERTED_STATE = 'CLEAR_ACTION_TRIGGERTED_STATE';

export const PUSH_TID = 'PUSH_TID';
export const POP_TID = 'POP_TID';

export const UPDATE_READ_ALL = 'UPDATE_READ_ALL';

const processSubWorkflowAction = (action, tid, workflow, dispatch, getState, wfData, form) => {
    if(action && action.actionProperties && action.actionProperties.updateGlobalModule) {
        const globalObjectKey = action.actionProperties.globalModuleFieldName;
        const globalModel = localStorage.getItem(globalObjectKey);
        let globalData = null;
        if(globalModel) {
            const parsedData = JSON.parse(globalModel);
            if(Array.isArray(parsedData)) {
                const found = parsedData.findIndex((value) => value.id === tid);
                if(found > -1) {
                    parsedData.splice(found, 1);
                } else {
                    parsedData.push(wfData);
                }
            }
            globalData = parsedData;
            localStorage.setItem(globalObjectKey, JSON.stringify(parsedData));
        } else {
            const globalObject = [];
            globalObject.push(wfData);
            globalData = globalObject;
            localStorage.setItem(globalObjectKey, JSON.stringify(globalObject));
        }
        const gd = localStorage.getItem('globalData');
        const gdParsed = JSON.parse(gd);
        gdParsed['class'] = gdParsed['serializerClass'];
        gdParsed[globalObjectKey] = globalData;
        client().post(`${config.authAPI}/sg`, {gmid: gdParsed.typeModelId,content: gdParsed}).then(response => {
            localStorage.setItem('globalData', JSON.stringify(response.data.data));
        });
    }
    // delete action.actionProperties.elementText;
    const values = form && form.get('values')
    const triggerActionAPI = `${config.authAPI}/workflow/action/trigger/`;        
        const sdt = getState().getIn(['calendarEvents', 'subworkflowEvent', 'start']);
        const edt = getState().getIn(['calendarEvents', 'subworkflowEvent', 'end']);
        const payload = {
            actionId: action.id,
            actionType: action.actionType,
            workflowId: workflow.workflowModel,
            sessionId: workflow.sessionId,
            actionProperties: action.actionProperties,
            displayMode: action.actionProperties.displayMode,
            prop: action.actionProperties.triggerPath?action.actionProperties.triggerPath:'',
            tid,
            sdt: sdt ? sdt.getTime() : null,
            edt: edt ? edt.getTime() : null,
            wfData
        }
        // dispatch({
        //     type: ACTION_TRIGGERED,
        //     payload: { action }
        // })
        payload.displayMode !== "BACKGROUND" && dispatch({
            type: TRIGGER_ACTION_SUBWORKFLOW,
            payload: {}
        })
        dispatch(showLoading());
        client().post(triggerActionAPI, payload).then((res) => {
            const isAnonymous = getState().getIn(['user', 'isAnonymous']);
            payload.displayMode !== "BACKGROUND" && dispatch({
                type: SET_TID,
                payload: tid
            })
            payload.displayMode !== "BACKGROUND" && dispatch({
                type: TRIGGER_ACTION_SUBWORKFLOW_SUCCESS,
                payload: res.data
            });


            if(payload.displayMode === "BACKGROUND") {
                dispatch({
                    type: UPDATE_READ_ALL,
                    payload: res.data.workflowData
                })
                client().post(`${isAnonymous ? config.publicAPI : config.authAPI}/workflow/end`, {sessionId: res.data.sessionId, workflowId: res.data.workflowModel} ).then((res) => {
                    
                }).catch((error) => {
                    console.log(error);
                });
            }
            // dispatch({
            //     type: ACTION_TRIGGERED_SUCCESS,
            //     payload: {
            //         action, 
            //         response: res.data
            //     }
            // });
            dispatch(hideLoading());
        }).catch((error) => {
            dispatch({
                type: TRIGGER_ACTION_SUBWORKFLOW_FAILURE,
                payload: {
                    hasError: true,
                    error
                }
            });
            // dispatch({
            //     type: ACTION_TRIGGERED_FAILURE,
            //     payload: {
            //         action, 
            //         error
            //     }
            // });
            dispatch(hideLoading());
        });
}

// const validateForm = (workflow, values) => {
//     let error = false;
//     const containers = workflow && workflow.currentItem.layout.containers;
//     containers && containers.map((container)=>{
//         const containerItem = container.items;
//         containerItem.map((item)=>{
//             let value = '';
//             const properties = item.itemProperties;
//             const classProp = properties.replaceClassProperty ? properties.replaceClassProperty : properties.field.replaceClassProperty;
//             if(item.itemType == "MODELPROPERTIES" && properties.elementType == 'checkbox' && properties.minLength == "1") {
//                 if(classProp.indexOf('.') == -1){
//                     value = values.get(classProp);  
//                 }else {
//                     const classPropArr = classProp.split('.')
//                     value = values.get(classPropArr[0]);
//                     for(let i = 1; i< classPropArr.length; i++) {
//                         value = value && value.get(classPropArr[1]);
//                     }
//                 }
//                 if (!value || value == null)
//                 {
//                     error = true;
//                     toast.error(`"${properties.label.text}" is required !`, {
//                         position:'bottom-right',
//                         type:'default',
//                         autoClose:6000,
//                         hideProgressBar:false,
//                         newestOnTop:false,
//                         closeOnClick: true,
//                         pauseOnHover: true
//                     });
//                 }
//             }
//         })
//     })
//     return error;
// }

const processAssignValueAction = (action, tid, workflow, dispatch, getState) => {
    let typeModelName = action.actionProperties.source_model;
    if (typeModelName === 'Cost Centre') typeModelName = 'Cost_Centre'; // HACK: ISHAN REMOVE
    const payload = {
        typeModelName,
        customerName: workflow.currentItem.typeModel.customerName,
        workflowId: workflow.workflowModel
    };
    client().post(`${config.authAPI}/workflow/readall`, payload).then((res) => {
        dispatch({
            type: TOGGLE_ASSIGNVALUE_MODAL,
            payload: {
                show: true,
                action: action,
                data: res.data,
                target_ids: [tid]
            }
        });
    }).catch((error) => {
        dispatch({
            type: TOGGLE_ASSIGNVALUE_MODAL,
            payload: { show: false }
        });
    });
}

const processMutationAction = (action, tid, workflow, dispatch, getState) => {
    const triggerActionAPI = `${config.authAPI}/workflow/action/trigger/`;        
    const payload = {
        actionId: action.id,
        actionType: action.actionType,
        workflowId: workflow.workflowModel,
        sessionId: workflow.sessionId,
        actionProperties: action.actionProperties,
        displayMode: action.actionProperties.displayMode,
        mut_prop: action.actionProperties.triggerPath?action.actionProperties.triggerPath:'',
        tid
    }
    dispatch({
        type: ACTION_TRIGGERED,
        payload: { action }
    })
    dispatch(showLoading());
    client().post(triggerActionAPI, payload).then((res) => {
        dispatch({
            type: ACTION_TRIGGERED_SUCCESS,
            payload: {
                action, 
                response: res.data
            }
        });
        dispatch(hideLoading());
    }).catch((error) => {
        dispatch({
            type: ACTION_TRIGGERED_FAILURE,
            payload: {
                action, 
                error
            }
        });
        dispatch(hideLoading());
    });
}

export const triggerAction = (action, tid, workflow, wfData) => {
    return (dispatch, getState) => {
        const tids = getState().getIn(['bulkActions', 'tids']);
        const form = getState().getIn(['form', 'create-update-form-base']);
        let resolvedTids = tid;
        if (action.canActOnMultipleItems) {
            resolvedTids = tids;
        }
        switch (action.actionType) {
            case actionTypes.get('SUBWORKFLOW'): processSubWorkflowAction(action, resolvedTids, workflow, dispatch, getState, wfData, form); break;
            case actionTypes.get('ASSIGNVALUE'): processAssignValueAction(action, resolvedTids, workflow, dispatch, getState); break;
            case actionTypes.get('MUTATION'): processMutationAction(action, resolvedTids, workflow, dispatch, getState); break;
        }
    }
}

export const addSourceId = (source_id) => {
    return (dispatch) => {
        dispatch({
            type: ADD_SOURCE_ID,
            payload: source_id
        });
    }
}

export const removeSourceId = (source_id) => {
    return (dispatch) => {
        dispatch({
            type: REMOVE_SOURCE_ID,
            payload: source_id
        });
    }
}

export const pushTid = (tid) => {
    return (dispatch) => {
        dispatch({
            type: PUSH_TID,
            payload: tid
        });
    }
}

export const popTid = (tid) => {
    return (dispatch) => {
        dispatch({
            type: POP_TID,
            payload: tid
        });
    }
}


export const processAssignValueActionFinal = (workflowId) => {
    const triggerActionAPI = `${config.authAPI}/workflow/action/trigger/`;        
    return (dispatch, getState) => {
        const assignValueModal = getState().get('assignValueModal');
        const worklfow = getState().getIn(['workflow', 'loadedWorkflows', `${workflowId}`]);
        const payload = {
            actionId: assignValueModal.getIn(['action', 'id']),
            actionType: assignValueModal.getIn(['action', 'actionType']),
            workflowId: worklfow.get('workflowModel'),
            sessionId: worklfow.get('sessionId'),
            target_ids: assignValueModal.get('target_ids'),
            source_ids: assignValueModal.get('source_ids'),
        }
        client().post(triggerActionAPI, payload).then((res) => {
            dispatch(hideLoading());
        }).catch((error) => {
            dispatch(hideLoading());
        });
    }
}

export const toggleActionModal = (payload) => {
    return (dispatch) => {
        dispatch({
            type: TOGGLE_ASSIGNVALUE_MODAL,
            payload
        });
    }
}

export const clearActionTriggeredState = (payload) => {
    return (dispatch) => {
        dispatch({
            type: CLEAR_ACTION_TRIGGERTED_STATE,
            payload
        });
    }
}

export const invokeActionRules = (payload) => {
    return (dispatch) => {
        return client().post(`${config.authAPI}/workflow/action/rules`, payload);
    }
}