import React, { Component } from 'react';
import { Cell } from 'fixed-data-table-2';
import ActionContainer from '../../../../containers/workflow/ActionContainer';
import { Dropdown, ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

class ActionCell extends Component {
	constructor(props) {
        super(props);
        this.state = {
            actionsDDOpen: false
        }
    }
	toggle(key) {
        this.setState({ [key]: !this.state[key] });
    }
    componentDidMount() {
        const element = this.cell;
        const { rowHeight, colWidth } = this.props;
        const hasOverflowingChildren = element.offsetHeight > rowHeight || element.offsetWidth > colWidth;
    }

    render() {
        const { actions, rows, workflow, rowIndex, columnKey, menuTypeInlineAction,template2Table } = this.props;
        const row = rows.getObjectAt(rowIndex);
        const tid = columnKey && rows.getObjectAt(rowIndex)[columnKey];
        const wfData = rows.getObjectAt(rowIndex);
        if(template2Table){
            return <div ref={(el) => { this.cell = el }}>
         
                {
                    actions.map((action, index) => {
                        if (action.actionProperties.hasOwnProperty('systemGenerated') &&  action.actionProperties.systemGenerated === true) return null;
                        const { actionProperties } = action;
                        if(actionProperties && actionProperties.actionDisabled || actionProperties.elementType == 'MENULINK') {
                            return null;
                        }
                        return <div key={index} className='mr-1 d-inline-block'>
                            <ActionContainer wfData={wfData} key={index} workflow={workflow} action={action} tid={tid} row={row} />
                        </div>
                    })
                }
				{/* {
					menuTypeInlineAction && menuTypeInlineAction.length > 0  && <div className='ml-1 d-inline-block'>
						<Dropdown size="sm" isOpen={this.state.actionsDDOpen} toggle={this.toggle.bind(this, 'actionsDDOpen')}>
							<DropdownToggle color='link'>
								<i className="fa fa-ellipsis-h fa-lg" aria-hidden="true"></i>
							</DropdownToggle>
							<DropdownMenu right>
								{
									menuTypeInlineAction.map((action, index) => {
										if (action.actionProperties.hasOwnProperty('systemGenerated') &&  action.actionProperties.systemGenerated === true) return null;
										const { actionProperties } = action;
										if(actionProperties && actionProperties.actionDisabled) {
											return null;
										}
										return <DropdownItem >
											<ActionContainer key={index} workflow={workflow} action={action} tid={tid} row={row} />
										</DropdownItem>
									})
								}
							</DropdownMenu>
						</Dropdown>
					</div>
				} */}
         
        </div> 
        }
        return <div ref={(el) => { this.cell = el }}>
            <Cell className='actions-cell'>
                {
                    actions.map((action, index) => {
                        if (action.actionProperties.hasOwnProperty('systemGenerated') &&  action.actionProperties.systemGenerated === true) return null;
                        const { actionProperties } = action;
                        if(actionProperties && actionProperties.actionDisabled || actionProperties.elementType == 'MENULINK') {
                            return null;
                        }
                        return <div key={index} className='mr-1 d-inline-block'>
                            <ActionContainer wfData={wfData} key={index} workflow={workflow} action={action} tid={tid} row={row} />
                        </div>
                    })
                }
				{
					menuTypeInlineAction && menuTypeInlineAction.length > 0  && <div className='ml-1 d-inline-block'>
						<Dropdown size="sm" isOpen={this.state.actionsDDOpen} toggle={this.toggle.bind(this, 'actionsDDOpen')}>
							<DropdownToggle color='link'>
								<i className="fa fa-ellipsis-h fa-lg" aria-hidden="true"></i>
							</DropdownToggle>
							<DropdownMenu right>
								{
									menuTypeInlineAction.map((action, index) => {
										if (action.actionProperties.hasOwnProperty('systemGenerated') &&  action.actionProperties.systemGenerated === true) return null;
										const { actionProperties } = action;
										if(actionProperties && actionProperties.actionDisabled) {
											return null;
										}
										return <DropdownItem >
											<ActionContainer key={index} workflow={workflow} action={action} tid={tid} row={row} />
										</DropdownItem>
									})
								}
							</DropdownMenu>
						</Dropdown>
					</div>
				}
            </Cell>
        </div>
    }
}

export default ActionCell;