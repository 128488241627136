import React from 'react';
import { Button, ButtonGroup, Input } from 'reactstrap';
import RenderIcon from '../RenderIcon';

class _Pagination extends React.Component {
  render() {
    const { start, size, onChangePage, hasNextPage, onChangePageSize, totalCount,reportPaginationClass } = this.props;
    const lastPage = size * Math.floor(totalCount/size);
    return (
      <div className={reportPaginationClass?'reportPaginationClass':''}>
        {totalCount?
        <div className="pull-left pagination-wrapper mb-4" data-html2canvas-ignore="true">
          Total Count : {totalCount}
        </div>:null
        }
      <div className="pull-right pagination-wrapper mb-4" data-html2canvas-ignore="true">
        <ButtonGroup className="mr-2">
          <Button title="First Page" disabled={start <= 0} onClick={() => onChangePage(0, size)} color="primary" outline>
            &lsaquo;&lsaquo;
          </Button>
          <Button title="Previous Page" disabled={start <= 0} onClick={() => onChangePage(start - size, size)} color="primary" outline>
            &lsaquo;
          </Button>
        </ButtonGroup>
        <Input value={size} onChange={(event) => {
          onChangePageSize(parseInt(event.target.value));
        }} type="select" className="select mr-2" name="select">
          <option value={10}>10</option>
          <option value={25}>25</option>
          <option value={50}>50</option>
          <option value={100}>100</option>
		  <option value={500}>500</option>
		  <option value={1000}>1000</option>
		  <option value={5000}>5000</option>
        </Input>
        <ButtonGroup className="pagination-buttons">
          <Button title="Next Page" disabled={!hasNextPage} onClick={() => onChangePage(start + size, size)} color="primary" outline>
            &rsaquo;
          </Button>
          <Button title="Last Page" disabled={!hasNextPage} onClick={() => onChangePage(lastPage, size)} color="primary" outline>
            &rsaquo;&rsaquo;
          </Button>
        </ButtonGroup>
      </div>
      </div>
    )
  }
}

export default _Pagination;