import React from 'react';
import { Field } from 'redux-form/immutable';
import { FormGroup, Input, Label, FormFeedback, Row, Col } from 'reactstrap';

export const renderField = ({ label, useLabelAsPlaceholder, input, type, placeholder, value, min, max, step, meta: { touched, error }, onEnter }) => {
  const hasError = error;
  return <FormGroup>
    <Input 
        onKeyPress={e => { 
            if (e.key === 'Enter') {
                e.preventDefault();
                if(onEnter && !hasError) onEnter();
            }
        }}
        className="input"
        autoFocus
        min={min}
        max={max}
        step={step}
        value={value}
        placeholder={useLabelAsPlaceholder ? label.text : placeholder.show ? placeholder.text : null}
        valid={hasError ? false : null}
        type={type} { ...input } 
    />
    {
        hasError ? <FormFeedback className='animated fadeIn'>
            <i className="fa fa-exclamation-triangle" aria-hidden="true"></i> {error}
        </FormFeedback> : ''
    }
  </FormGroup>
}

class Email extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        const { classProperty, type, validations, ...rest } = this.props;
        return (
            <Field
                name={classProperty}
                component={renderField}
                type="email"
                validate={validations}
                {...rest}
            />
        );
    }
}

export default Email;