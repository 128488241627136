import React, { Component } from 'react';
import PropTypes from 'prop-types';
import MultiLine from './MultiLine';
import SingleLine from './SingleLine';
import Avatar from 'react-avatar';

const displayModes = {
    MultiLine: 'MultiLine',
    SingleLine: 'SingleLine'
};

class User extends Component {
    getComponent(displayMode) {
        let cmp = MultiLine;
        switch (displayMode) {
            case displayModes.MultiLine: cmp = MultiLine; break;
            case displayModes.SingleLine: cmp = SingleLine; break;
            default: cmp = MultiLine; break;
        }
        return cmp;
    }
    render() {
        const {
            user,
            avatarSize,
            avatarRound,
            displayMode
        } = this.props;

        if (!user) return null;
        let { firstName, lastName, email, jobTitle } = user;
        const fullName = `${firstName} ${lastName}`;
        const ModeComponent = this.getComponent(displayMode);
        return (
            <div>
                <ModeComponent fullName={fullName} email={email} {...this.props}>
                    <Avatar
                        round={avatarRound}
                        size={avatarSize}
                        name={fullName}
                        email={email}
                    />
                </ModeComponent>
            </div>
        );
    }
}

User.defaultProps = {
    user: null,
    avatarSize: 40,
    avatarRound: true,
    displayMode: displayModes.MultiLine
}

User.propTypes = {
    user: PropTypes.object.isRequired,
    avatarSize: PropTypes.number,
    avatarRound: PropTypes.bool,
    displayMode: PropTypes.string
};

export default User;