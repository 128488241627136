import React, { useRef } from 'react';
import EmailEditor from 'react-email-editor';
import { Button, Col } from 'reactstrap';

const EmailTemplate = (props) => {

  const emailEditorRef = useRef(null);

  const exportHtml = () => {
    emailEditorRef.current.editor.exportHtml((data) => {
      const { design, html } = data;
      props.change(props.classProperty, html)
    });
  };

  const onReady = () => {
    // editor is ready
    // you can load your template here;
    // const templateJson = {};
    // emailEditorRef.current.editor.loadDesign(templateJson);
  };

  return (
    <div>
      <div>

      </div>
      <EmailEditor ref={emailEditorRef} onReady={onReady} />

      <Col className="d-flex justify-content-center mt-2">
        <Button onClick={exportHtml}>Save Template</Button>
      </Col>
    </div>
  )
}

export default EmailTemplate