import registerServiceWorker from './registerServiceWorker';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import App from './App';
import rootReducer from './reducers/rootReducer';
import { composeWithDevTools } from 'redux-devtools-extension';
import { BrowserRouter } from 'react-router-dom';
import { addTranslationForLanguage } from 'react-localize-redux';
import { initialize } from 'react-localize-redux';
import locales from './locales/index';
import { Map } from 'immutable';

const initialState = Map();
const languages = ['en'];
export const store = createStore(
    rootReducer,
    initialState,
    composeWithDevTools(
        applyMiddleware(thunk)
    )
);

store.dispatch(initialize(languages, { defaultLanguage: 'en' }));
//store.dispatch(addTranslationForLanguage(locales.en, 'en'));
// store.dispatch(addTranslationForLanguage(locales.fr, 'fr'));

//registerServiceWorker();
ReactDOM.render(
    <BrowserRouter>
        <Provider store={ store }>
            <App />
        </Provider>
    </BrowserRouter>, 
document.getElementById('root'));

