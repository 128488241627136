import React, { Component } from 'react';
import { Badge, Label, ButtonGroup, Button } from 'reactstrap';
import { ModelPropertiesParser } from '../../commons/modelPropertiesParser';

class TypeModelLink extends Component {
    render() {
        const { typeModelLink, workflowData, modelSelector, label, viewMode, typeModelViewMode, onChangeTypeModelViewMode, layoutItem: { itemProperties } } = this.props;
        if (!typeModelLink || !workflowData) return null;
        if (workflowData && Array.isArray(workflowData)) {
            return (
                <div>
                    <div className='d-flex justify-content-between mb-2'>
                        <div>
                            <Label className='text-muted text-capitalize mb-0'>{label.text}</Label>
                        </div>
                        <div>
                            {
                                itemProperties && itemProperties.allowAdditionalTypeModelViewMode && <ButtonGroup className="mr-4">
                                <Button onClick={onChangeTypeModelViewMode.bind(this, typeModelViewMode.value)} color='primary' outline={viewMode !== typeModelViewMode.value} size="sm">
                                    <i className="fa fa-th-list" aria-hidden="true"></i>
                                </Button>
                                <Button onClick={onChangeTypeModelViewMode.bind(this, itemProperties.additionalTypeModelViewMode.value)} color='primary' outline={viewMode !== itemProperties.additionalTypeModelViewMode.value} size="sm">
                                    <i className="fa fa-th-large" aria-hidden="true"></i>
                                </Button>
                            </ButtonGroup>
                            }
                        </div>
                    </div>
                    <div className='d-flex justify-flex-end'>
                        {
                            workflowData.map((data, index) => {
                                return (
                                  
                                    <Badge target="_blank" key={index} pill href={ModelPropertiesParser(typeModelLink.linkUrl, data)} color="light" className='mr-1'>
                                        {ModelPropertiesParser(typeModelLink.linkDisplayText, data)}
                                    </Badge>
                                )
                            })
                        }
                    </div>
                </div>
            )
        }
        return (
            <div className='d-flex'>

            </div>
        );
    }
}

export default TypeModelLink;