import { Map } from 'immutable';
import {
    LOGIN_USER,
    LOGIN_USER_SUCCESS,
    LOGIN_USER_FAILURE,
    SET_USER,
    GET_USERS,
    GET_USERS_SUCCESS,
    GET_USERS_FAILURE,
    SET_PASSWORD,
    SET_PASSWORD_SUCCESS,
    SET_PASSWORD_FAILURE,
    SET_PASSWORD_RESET_STATE,
    RESET_PASSWORD,
    RESET_PASSWORD_FAILURE,
    RESET_PASSWORD_SUCCESS,
    RESET_PASSWORD_RESET_STATE,
    LOAD_USER_DASHBOARD,
    LOAD_USER_DASHBOARD_FAILURE,
    LOAD_USER_DASHBOARD_SUCCESS,
    SAVE_USER_DASHBOARD,
    SAVE_USER_DASHBOARD_SUCCESS,
    SAVE_USER_DASHBOARD_FAILURE,
    GET_USERS_PROFILE,
    GET_USERS_PROFILE_FAILURE,
    GET_USERS_PROFILE_SUCCESS
} from '../actions/user';

let persistedUser = localStorage.getItem('user') || null;
persistedUser = typeof persistedUser === 'string' && JSON.parse(persistedUser);
const isAnonymous = !(persistedUser !== null && typeof persistedUser === 'object');

const intitalState = Map({
    isAnonymous,
    user: persistedUser,
    signUp: Map({}),
    setPassword: Map({ isLoading: false, hasError: false, data: null }),
    resetPassword: Map({ isLoading: false, hasError: false, data: null }),
    isLoading: false,
    list: {
        isLoading: false,
        data: null,
        error: null
    },
    dashboard: Map({ isLoading: false, hasError: false, data: null }),
    saveDashboard: Map({ isLoading: false, hasError: false }),
    profile: Map({ isLoading: false, hasError: false })
});

const setPassword = (state, action) => {
    let setPassword = state.get('setPassword');
    setPassword = setPassword.set('isLoading', true);
    return state.merge({ setPassword });
}

const setPasswordSuccess = (state, action) => {
    let setPassword = state.get('setPassword');
    setPassword = setPassword.merge({ isLoading: false, hasError: false, data: action.payload });
    return state.merge({ setPassword });
}

const setPasswordFailure = (state, action) => {
    let setPassword = state.get('setPassword');
    setPassword = setPassword.merge({ isLoading: false, hasError: true, data: action.payload });
    return state.merge({ setPassword });
}

const setPasswordReset = (state, action) => {
    let setPassword = state.get('setPassword');
    setPassword = setPassword.merge({ isLoading: false, hasError: false, data: null });
    return state.merge({ setPassword });
}

const resetPassword = (state, action) => {
    let resetPassword = state.get('resetPassword');
    resetPassword = resetPassword.set('isLoading', true);
    return state.merge({ resetPassword });
}

const resetPasswordSuccess = (state, action) => {
    let resetPassword = state.get('resetPassword');
    resetPassword = resetPassword.merge({ isLoading: false, hasError: false, data: action.payload });
    return state.merge({ resetPassword });
}

const resetPasswordFailure = (state, action) => {
    let resetPassword = state.get('resetPassword');
    resetPassword = resetPassword.merge({ isLoading: false, hasError: true, data: action.payload });
    return state.merge({ resetPassword });
}

const resetPasswordState = (state, action) => {
    let resetPassword = state.get('resetPassword');
    resetPassword = resetPassword.merge({ isLoading: false, hasError: false, data: null });
    return state.merge({ resetPassword });
}



const user = (state = intitalState, action = {}) => {
    switch (action.type) {
        case LOGIN_USER: return state.merge({ isLoading: true });
        case LOGIN_USER_SUCCESS: return state.merge({ isLoading: false, user: action.payload, isAnonymous: false });
        case LOGIN_USER_FAILURE: return state.merge({ isLoading: false, user: { hasError: true, error: action.payload }, isAnonymous: true });
        case SET_USER: return state.merge({ isAnonymous: action.payload.isAnonymous, user: action.payload.user, isLoading: false });

        case GET_USERS: return state.merge({ list: { isLoading: true, data: null,  error: null} });
        case GET_USERS_SUCCESS: return state.merge({ list: { isLoading: false, data: action.payload,  error: null} });
        case GET_USERS_FAILURE: return state.merge({ list: { isLoading: false, data: null,  error: action.payload} });

        case SET_PASSWORD: return setPassword(state, action);
        case SET_PASSWORD_SUCCESS: return setPasswordSuccess(state, action);
        case SET_PASSWORD_FAILURE: return setPasswordFailure(state, action);
        case SET_PASSWORD_RESET_STATE: return setPasswordReset(state, action);

        case RESET_PASSWORD: return resetPassword(state, action);
        case RESET_PASSWORD_SUCCESS: return resetPasswordSuccess(state, action);
        case RESET_PASSWORD_FAILURE: return resetPasswordFailure(state, action);
        case RESET_PASSWORD_RESET_STATE: return resetPasswordState(state, action);

        case LOAD_USER_DASHBOARD: return state.merge({ dashboard: Map({ isLoading: true, hasError: false, data: null }) });
        case LOAD_USER_DASHBOARD_SUCCESS: return state.merge({ dashboard: Map({ isLoading: false, hasError: false, data: action.payload.data }) });
        case LOAD_USER_DASHBOARD_FAILURE: return state.merge({ dashboard: Map({ isLoading: false, hasError: true, data: action.payload }) });

        case SAVE_USER_DASHBOARD: return state.merge({ saveDashboard: Map({ isLoading: true, hasError: false, showSaveSuccess: false, showSaveError: false }) });
        case SAVE_USER_DASHBOARD_SUCCESS: return state.merge({ saveDashboard: Map({ isLoading: false, hasError: false, showSaveSuccess: true, showSaveError: false }), dashboard: Map({ isLoading: false, hasError: false, data: action.payload.data }) });
        case SAVE_USER_DASHBOARD_FAILURE: return state.merge({ saveDashboard: Map({ isLoading: false, hasError: true, showSaveSuccess: false, showSaveError: true }) });

        case GET_USERS_PROFILE: return state.merge({ profile: Map({ isLoading: true, hasError: false }) });
        case GET_USERS_PROFILE_SUCCESS: return state.merge({ profile: Map({ isLoading: false, hasError: false, data: action.payload.data }) });
        case GET_USERS_PROFILE_FAILURE: return state.merge({ profile: Map({ isLoading: false, hasError: true }) });

        default: return state;
    }
};

export default user;
