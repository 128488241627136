import React, { Component } from 'react';
import {
  FormGroup,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  InputGroup,
  InputGroupButton,
  Input,
  Label,
} from 'reactstrap';
import PropTypes from 'prop-types';
import Cascader from 'rc-cascader';
import client from '../../client';
import config from '../../config';

const NeeneCascader = props => {
  const {
    options,
    loadNestedFields,
    classPropertySelected,
    inputValue,
    addClassPropertyToUserText,
    userEditable,
  } = props;
  return (
    <Cascader
      options={options}
      loadData={loadNestedFields}
      onChange={classPropertySelected}
      changeOnSelect={true}
      transitionName='slide-up'
    >
      <InputGroup>
        <Input
          placeholder='Click to select...'
          value={inputValue}
          type='text'
          readOnly
        />
        {userEditable ? (
          <InputGroupButton>
            <Button onClick={addClassPropertyToUserText} color='light'>
              Add Property
            </Button>
          </InputGroupButton>
        ) : (
          ''
        )}
      </InputGroup>
    </Cascader>
  );
};

class ModelFieldsSelector extends Component {
  constructor(props) {
    super(props);
    const { userEditable, savedValue, fields, additionalModelMap } = props;
    this.state = {
      modal: false,
      inputValue: !userEditable && savedValue ? savedValue : '',
      selectedOptions: null,
      userText: userEditable && savedValue ? savedValue : '',
      options: this.fieldsToOptions(fields, additionalModelMap),
    };
    this.toggle = this.toggle.bind(this);
    this.loadNestedFields = this.loadNestedFields.bind(this);
    this.classPropertySelected = this.classPropertySelected.bind(this);
    this.userTextChanged = this.userTextChanged.bind(this);
    this.addClassPropertyToUserText = this.addClassPropertyToUserText.bind(
      this
    );
    this.saveUserText = this.saveUserText.bind(this);
  }
  componentWillReceiveProps(nextProps) {
    const { fields, shouldReloadFields, additionalModelMap } = nextProps;
    shouldReloadFields &&
      this.setState({
        options: this.fieldsToOptions(fields, additionalModelMap),
      });
  }

  addClassPropertyToUserText(ev) {
    ev.stopPropagation();
    let { userText, inputValue } = this.state;
    if (!inputValue || inputValue === '') return;
    userText += `{{${inputValue}}}`;
    this.setState({ userText, inputValue: '' });
  }
  toggle() {
    this.setState({ modal: !this.state.modal });
  }
  fieldsToOptions(fields, additionalModelMap) {
    const allFields = fields.map(field => {
      return {
        label: field.name,
        customTypeId: field.customTypeId,
        value: field.classProperty,
        isLeaf: field.type !== 'TYPE',
        field,
      };
    });
    if (additionalModelMap) {
      const allModels = Object.keys(additionalModelMap);
      allModels &&
        allModels.map(aModel => {
          aModel &&
            additionalModelMap[aModel].fields.map(aField => {
              allFields.push({
                label: `${aModel}.${aField.name}`,
                customTypeId: aField.customTypeId,
                value: `${aModel}.${aField.classProperty}`,
                isLeaf: aField.type !== 'TYPE',
                field: aField,
              });
            });
        });
    }
	if(allFields.length > 0 ){
        allFields.push({
			label: 'id',
			customTypeId: null,
			value: 'id',
			isLeaf: true,
			field: {}
        });
    }
    return allFields;
  }
  loadNestedFields(selectedOptions) {
    const targetOption = selectedOptions[selectedOptions.length - 1];
    targetOption.loading = true;
    const customTypeId = targetOption.customTypeId;
    client()
      .post(`${config.authAPI}/models/${customTypeId}`)
      .then(res => {
        const fields = res.data.fields;
        targetOption.loading = false;
        targetOption.children = [];
        fields.map(field => {
          targetOption.children.push({
            label: field.name,
            customTypeId: field.customTypeId,
            value: field.classProperty,
            isLeaf: field.type !== 'TYPE',
            field,
          });
        });
        this.setState({
          options: [...this.state.options],
        });
      });
  }
  classPropertySelected(value, selectedOptions) {
    const inputValue = value.map(o => o).join('.');
    const { userEditable, onSave } = this.props;
    this.setState(
      {
        inputValue,
        selectedOptions,
      },
      () => {
        !userEditable &&
          onSave(this.state.inputValue, this.state.selectedOptions);
      }
    );
  }
  userTextChanged(event) {
    this.setState({ userText: event.target.value });
  }
  saveUserText() {
    this.props.onSave(this.state.userText, this.state.selectedOptions);
    this.toggle();
  }
  render() {
    const {
      fields,
      buttonText,
      placeholderText,
      label,
      userEditable,
    } = this.props;
    const { options, inputValue } = this.state;
    if (!userEditable) {
      return (
        <div>
          <Label>{label}</Label>
          <NeeneCascader
            options={options}
            inputValue={inputValue}
            loadNestedFields={this.loadNestedFields}
            classPropertySelected={this.classPropertySelected}
            addClassPropertyToUserText={this.addClassPropertyToUserText}
            userEditable={userEditable}
          />
        </div>
      );
    }
    return (
      <div style={{ clear: 'both' }}>
        <Label>{label}</Label>
        <InputGroup>
          <Input
            readOnly
            value={this.state.userText}
            placeholder={placeholderText}
          />
          <InputGroupButton>
            <Button color='primary' onClick={this.toggle}>
              {buttonText}
            </Button>
          </InputGroupButton>
        </InputGroup>
        <Modal
          backdrop='static'
          isOpen={this.state.modal}
          toggle={this.toggle}
          className={this.props.className}
        >
          <ModalHeader toggle={this.toggle}>
            Select Model's Properties
          </ModalHeader>
          <ModalBody>
            <NeeneCascader
              options={options}
              inputValue={inputValue}
              loadNestedFields={this.loadNestedFields}
              classPropertySelected={this.classPropertySelected}
              addClassPropertyToUserText={this.addClassPropertyToUserText}
              userEditable={userEditable}
            />
            <FormGroup className='mt-3'>
              <Input
                onChange={this.userTextChanged}
                placeholder='Add keys and prepend/append your preferred text...'
                value={this.state.userText}
                style={{ padding: '20px 15px' }}
                type='text'
              />
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button color='primary' onClick={this.saveUserText}>
              Save Changes
            </Button>{' '}
            <Button color='link' onClick={this.toggle}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

ModelFieldsSelector.defaultProps = {
  buttonText: 'Add',
  fields: [],
  placeholderText: 'No properties selected...',
  label: '',
  value: '',
  userEditable: true,
  shouldReloadFields: false,
};

ModelFieldsSelector.propTypes = {
  fields: PropTypes.array,
  label: PropTypes.string,
  placeholderText: PropTypes.string,
  label: PropTypes.string,
  userEditable: PropTypes.bool,
  shouldReloadFields: PropTypes.bool,
};

export default ModelFieldsSelector;
