import { Map, List } from 'immutable';
import {
   PUSH_TID,
   POP_TID
} from '../actions/workflow-actions';

const intitalState = Map({
    tids: List([])
});

const addTid = (state, tid) => {
    let tids = state.get('tids');
    tids = tids.push(tid);
    return state.set('tids', tids);
}

const removeTid = (state, tid) => {
    let tids = state.get('tids');
    const indexToRemove = tids.indexOf(tid);
    tids = tids.delete(indexToRemove);
    return state.set('tids', tids);
}

const bulkActions = (state = intitalState, action = {}) => {
    switch (action.type) {
        case PUSH_TID: return addTid(state, action.payload);
        case POP_TID: return removeTid(state, action.payload);
        default: return state;
    }
};

export default bulkActions;
