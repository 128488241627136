import React, { Component } from 'react';
import Video from 'twilio-video';
import { Button, Card, CardBody } from 'reactstrap';

export default class VideoComponent extends Component {
  constructor(props) {
    super();

    this.state = {
      identity: null,
      roomNameErr: false,
      previewTracks: null,
      localMediaAvailable: false,
      hasJoinedRoom: false,
      activeRoom: null,
    };
  }

  joinRoom = () => {
    if (!this.props.roomName.trim()) {
      this.setState({ roomNameErr: true });
      return;
    }

    console.log("Joining room '" + this.props.roomName + "'...");
    let connectOptions = {
      name: this.props.roomName,
    };

    if (this.state.previewTracks) {
      connectOptions.tracks = this.state.previewTracks;
    }
    Video.connect(this.props.token, connectOptions).then(
      this.roomJoined,
      error => {
        alert(`Error: ${error}`);
      }
    );
  };
  attachTracks(tracks, container) {
    tracks.forEach((track)=> {
      track.attach && container.appendChild(track.attach());
    });
  }

  attachParticipantTracks(participant, container) {
    var tracks = Array.from(participant.tracks.values());
    this.attachTracks(tracks, container);
  }

  leaveRoom = () => {
    this.activeRoom && this.activeRoom.disconnect();
    this.setState({ hasJoinedRoom: false, localMediaAvailable: false });
  };

  detachTracks = tracks => {
    tracks.forEach(track => {
      track.detach && track.detach().forEach(detachedElement => {
        detachedElement.remove();
      });
    });
  };

  detachParticipantTracks = participant => {
    var tracks = Array.from(participant.tracks.values());
    this.detachTracks(tracks);
  };

  roomJoined = room => {
        this.activeRoom = room;
        window.room = room.name;

        console.log("Joined as '" + this.identity + "'");
        this.refs.buttonJoin.style.display = "none";
        this.refs.buttonLeave.style.display = "inline";

        // Attach LocalParticipant's Tracks, if not already attached.
        var localTracksPromise = this.previewTracks
        ? Promise.resolve(this.previewTracks)
        : Video.createLocalTracks();

        localTracksPromise.then(
            (tracks)=> {
                window.previewTracks = this.previewTracks = tracks;
                var previewContainer = document.getElementById("local-media");
                if (!previewContainer.querySelector("video")) {
                    this.attachTracks(tracks, previewContainer);
                }
            },
            (error)=> {
                this.log("Unable to access Camera and Microphon");
            }
        );

        // Attach the Tracks of the Room's Participants.
        room.participants.forEach((participant)=> {
            console.log("Already in Room: '" + participant.identity + "'");
            var previewContainer = document.getElementById("remote-media");
            this.attachParticipantTracks(participant, previewContainer);
        });

        // When a Participant joins the Room, log the event.
        room.on("participantConnected", (participant)=> {
            console.log("Joining: '" + Object.keys(participant.state)  + "'");
        });

        // When a Participant adds a Track, attach it to the DOM.
        room.on("trackStarted", (track, participant)=> {
            console.log(participant.identity + " added track: " + track.kind);
            var previewContainer = document.getElementById("remote-media");
            this.attachTracks([track], previewContainer);
        });

        // When a Participant removes a Track, detach it from the DOM.
        room.on("trackDisabled", (track, participant)=> {
            console.log(participant.identity + " removed track: " + track.kind);
            this.detachTracks([track]);
        });

        // When a Participant leaves the Room, detach its Tracks.
        room.on("participantDisconnected", (participant)=> {
            console.log("Participant '" + participant.identity + "' left the room");
            this.detachParticipantTracks(participant);
        });

        // Once the LocalParticipant leaves the room, detach the Tracks
        // of all Participants, including that of the LocalParticipant.
        room.on("disconnected", ()=> {
            console.log("Left");
            if (this.previewTracks) {
                this.previewTracks.forEach((track)=> {
                  track.stop();
                  track.detach().forEach(detachedElement => {
                    detachedElement.remove();
                  });
                });
            }
            // this.detachParticipantTracks(this.previewTracks);
            room.participants.forEach(this.detachParticipantTracks);
            this.activeRoom = null;
            this.refs.buttonJoin.style.display = "inline";
            this.refs.remoteMedia.style.display = 'none';
            document.getElementById("button-leave").style.display = "none";
        });
  };

  componentWillUnmount() {
    this.leaveRoom();
  }
  render() {
    let connectOptions = {
      name: this.props.roomName,
    };

    if (this.state.previewTracks) {
      connectOptions.tracks = this.state.previewTracks;
    }
    return (
      <div className="video-conference">
        <div>
          <div className='d-flex' style={{flexDirection: 'row'}}>
            <div>
              <div className="localVideoContainer" ref="localMedia" id="local-media"></div>
            </div>
            <div>
              <div className='d-flex' style={{flexDirection: 'row'}} className="remoteVideoContainer" ref="remoteMedia" id="remote-media"></div>
            </div>
          </div>
          <div>
            <br />
            <div>
            <button className="btn btn-secondary btn-block" type="button" id="button-join" ref="buttonJoin" onClick={this.joinRoom}>Join</button>
            <button className="btn btn-warning btn-block" type="button" id="button-leave" style={{display: 'none'}} ref="buttonLeave" onClick={this.leaveRoom}>Leave</button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
