import React from 'react';
import { Field } from 'redux-form/immutable';
import PrintText from './PrintText';
import { connect } from 'react-redux';
import { toJS } from '../../containers/to-js';
import { getCountries, getStates } from '../../actions/static-data';
import { renderSelect } from './_FORM_FIELDS';
import _ from 'lodash'

class Countries extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        const { getCountries, isReadOne } = this.props;
        if(!isReadOne) {
            getCountries();
        }
    }

    getCountries() {
        return this.props.countries && this.props.countries.map((country, index) => {
            return {
                value: country.name,
                label: country.name,
                countryId: country.id
            }
        });
    }

    render() {
        const { classProperty, type, validations, isReadOne, isMulti, ...rest } = this.props;
        const { layoutItem: { itemProperties: { field: { properties } } } } = this.props;
        let { possibleValues } = properties;
        const options = possibleValues.length ? [] : this.getCountries();
        
        return (
            <div>
            {
                isReadOne ?
                    <PrintText {...this.props} /> :
                    <Field
                        name={classProperty}
                        component={renderSelect}
                        type="select"
                        validate={validations}
                        fieldType={type}
                        valueKey='value'
                        labelKey='label'
                        options={options}
                        properties={properties}
                        simpleValue={false}
                        normalize={(value) => {
                            if(value) {
                                this.props.getStates(value);
                                return value.value
                            } else return value
                        }}
                        onDataChange={(v)=> {
                            const cEventKeys = window.cEvents && Object.keys(window.cEvents);
                            const fValues = this.props.formValues;
                            _.set(fValues,classProperty,v)
                            Array.isArray(cEventKeys) && cEventKeys.map(k => {
                              k.startsWith(classProperty) &&
                                window.cEvents[k](fValues);
                            });
                          }}
                        {...rest}
                    />
            }
        </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getCountries: () => {
            return dispatch(getCountries());
        },
        getStates: (payload) => {
            return dispatch(getStates(payload))
        }
    }
  };
  
  const mapStateToProps = (state, ownProps) => {
    return {
        countries: state.getIn(['staticData', 'countries']),
    };
  };


export default connect(mapStateToProps, mapDispatchToProps)(toJS(Countries));