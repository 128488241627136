import React, { Component } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import BaseWorkflow from '../workflow/BaseWorkflow';
import ModalWorkflow from '../workflow/ModalWorkflow';
import SidebarWorkflow from '../workflow/SidebarWorkflow';
import { startPublicDefaultWorkflow, startPublicLinkWorkflow } from '../../actions/workflows';
import { connect } from 'react-redux';
import { toJS } from '../../containers/to-js';

class PublicHome extends Component {
    componentDidMount() {
        const { startPublicDefaultWorkflow, match, startPublicLinkWorkflow } = this.props;
        if (match && match.path === '/public/link/:linkId' && match.params && match.params.linkId ) {
            startPublicLinkWorkflow({ linkId: match.params.linkId, displayMode: 'DEFAULT' })
        } else {
            startPublicDefaultWorkflow({ isBase: true, displayMode: 'DEFAULT' });
        }
    }
    render() {
        return <div className='h-100'>
            <BaseWorkflow isPublic={true} />
            <ModalWorkflow />
            <SidebarWorkflow />
        </div>
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        startPublicDefaultWorkflow: (payload) => {
            dispatch(startPublicDefaultWorkflow(payload));
        },
        startPublicLinkWorkflow: (payload) => {
            dispatch(startPublicLinkWorkflow(payload));
        }
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.get('user')
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(toJS(PublicHome));