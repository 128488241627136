import React from 'react';
 
class RememberMeComp extends React.Component {
    static displayName = 'RememberMe'
 
    constructor(props) {
        super(props);
        const { isCheckedProp } = this.props
        this.state = {
          isChecked: isCheckedProp,
        }
      }
 
    componentDidMount() {
        if (localStorage.checkbox && localStorage.email !== "") {
            this.setState({
                isChecked: true,
                email: localStorage.username,
                password: localStorage.password
            })
        }
    }
 
    onChangeValue = event => {
        this.setState({
            [event.target.name]: event.target.value
        })
    }
 
    onChangeCheckbox = event => {
        this.setState({
            isChecked: event.target.checked
        })
    }
 
    render() {
        const { isChecked } = this.state
        return (
            <div >
            <input type="checkbox" checked={isChecked} className="rememberme-checkbox" style={{marginRight:12}} name="lsRememberMe" onChange={this.onChangeCheckbox} />
            <label className="rememberme-label" >Remember me</label>
            </div>
        )
    }
}
 
export default RememberMeComp