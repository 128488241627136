import React, { useState, useEffect } from 'react';
import moment from 'moment';
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
  Polygon,
  DirectionsRenderer,
  InfoWindow,
} from 'react-google-maps';

const MapReport = props => {
  const { graphData, locationConfig } = props;
  if (!locationConfig) return null;
  const latlngField =
    locationConfig && locationConfig.location && locationConfig.location.value;
  const titleField =
    locationConfig && locationConfig.title && locationConfig.title.value;
  const subTitle =
    locationConfig && locationConfig.subtitle && locationConfig.subtitle.value;
  const mapPointer = locationConfig && locationConfig.pointerImage;
  return (
    <GMap
      googleMapURL='https://maps.googleapis.com/maps/api/js?key=AIzaSyBfhuMCKv2c1jH_lJcxJ1iNjg0mmHXY_zU&v=3.exp&libraries=geometry,drawing,places'
      loadingElement={<div style={{ height: `100%` }} />}
      containerElement={<div style={{ height: `600px` }} />}
      mapElement={<div style={{ height: `100%` }} />}
      latlngField={latlngField}
      titleField={titleField}
      subTitle={subTitle}
      mapPointer={mapPointer}
      graphData={graphData}
      {...props}
    />
  );
};

const getMarkers = (graphData, options) => {
  const markers = [];
  Array.isArray(graphData) &&
    graphData.map(item => {
      const loc =
        item.tableData[options.latlngField] &&
        item.tableData[options.latlngField].split(',');
      const marker = loc && {
        center: { lat: parseFloat(loc[0]), lng: parseFloat(loc[1]) },
        title: item.tableData[options.titleField],
        subTitle: item.tableData[options.subTitle],
      };
      marker && markers.push(marker);
    });
  return markers;
};

const GMap = withScriptjs(
  withGoogleMap(props => {
    const { graphData, latlngField, titleField, subTitle, mapPointer } = props;
    const markers = getMarkers(graphData, {
      latlngField,
      titleField,
      subTitle,
    });
    const c = markers.length
      ? markers[0].center
      : { lat: 19.076, lng: 72.8777 };
    const [center, setCenter] = useState(c);
    const [mapref, setMapRef] = useState(null);
    const [zoom, setZoom] = useState(15);
    const [markerIndex, setMarkerIndex] = useState(-1);

    useEffect(() => {
      const length = markers.length;
      if (length) {
        setCenter(markers[0].center);
      }
    }, [graphData]);
    let iconMarker =
      mapPointer &&
      new window.google.maps.MarkerImage(
        mapPointer,
        null /* size is determined at runtime */,
        new window.google.maps.Point(0, 0),
        null /* anchor is bottom center of the scaled image */,
        new window.google.maps.Size(zoom * 2, zoom * 2)
      );
    return (
      <div className='map-report'>
        <GoogleMap
          options={{}}
          ref={ref => setMapRef(ref)}
          defaultZoom={zoom}
          center={center}
          mapTypeId="terrain"
          onZoomChanged={()=> {setZoom(mapref.getZoom())}}
        >
          {markers.length
            ? markers.map((mark, i) => {
                let subtitle = mark.subTitle;
                if(mark.subTitle) {
                  const date = moment(mark.subTitle + ' GMT');
                  if(date.isValid()) {
                    subtitle = date.format('MMM DD YYYY hh:mm a');
                  }
                }
                return (
                  <Marker
                    animation={window.google.maps.Animation.DROP}
                    onClick={() => setMarkerIndex(i)}
                    key={mark.title}
                    icon={iconMarker}
                    position={mark.center}
                    title={mark.title}
                  >
                    {markerIndex === i ? (
                      <InfoWindow onCloseClick={() => setMarkerIndex(i)}>
                        <div>
                          {mark.title && <div className='marker-title'>{mark.title}</div>}
                          {subtitle && <div className='marker-subtitle'>{subtitle}</div>}
                        </div>
                      </InfoWindow>
                    ) : (
                      ''
                    )}
                  </Marker>
                );
              })
            : ''}
        </GoogleMap>
      </div>
    );
  })
);

export default MapReport;
