import React from 'react';
import { Row } from 'reactstrap';
import GenericColumn from './GenericColumn';

const OneColContainer = (props) => {
    
    const Layout = props.operationLayout;
    const { index, rowClassName, contStyle } = props;
    return <Row className={rowClassName} style={contStyle}>
        <GenericColumn {...props} containerIndex={index} columnIndex={0} isFirst={true} isLast={true} />
    </Row>
}

export default OneColContainer;