import client from '../client';
import config from '../config';
import { showLoading, hideLoading } from 'react-redux-loading-bar'
import { getFormValues } from 'redux-form/immutable';
import { Map } from 'immutable';

export const SAVE_ITEM_PAYLOAD = 'SAVE_ITEM_PAYLOAD';
export const SAVE_ITEM_PAYLOAD_SUCCESS = 'SAVE_ITEM_PAYLOAD_SUCCESS';
export const SAVE_ITEM_PAYLOAD_ERROR = 'SAVE_ITEM_PAYLOAD_ERROR';

export const NEXT_ITEM_REQUESTED = 'NEXT_ITEM_REQUESTED';
export const NEXT_ITEM_REQUESTED_SUCCESS = 'NEXT_ITEM_REQUESTED_SUCCESS';
export const NEXT_ITEM_REQUESTED_FAILURE = 'NEXT_ITEM_REQUESTED_FAILURE';
export const NEXT_ITEM_SAVED_SUCCESS = 'NEXT_ITEM_SAVED_SUCCESS';
export const CLEAR_NEXT_STATE = 'CLEAR_NEXT_STATE';

export const READ_ALL = 'READ_ALL';
export const READ_ALL_SUCCESS = 'READ_ALL_SUCCESS';
export const READ_ALL_ERROR = 'READ_ALL_ERROR';

export const READ_ONE = 'READ_ONE';
export const READ_ONE_SUCCESS = 'READ_ONE_SUCCESS';
export const READ_ONE_ERROR = 'READ_ONE_ERROR';

export const UPDATE_ITEM_PAYLOAD = 'UPDATE_ITEM_PAYLOAD';
export const UPDATE_PAYLOAD_DIRECT = 'UPDATE_PAYLOAD_DIRECT';
export const RESET_ITEM_PAYLOAD = 'RESET_ITEM_PAYLOAD';

export const UPDATE_WORKFLOW_DATA = 'UPDATE_WORKFLOW_DATA';
export const CHANGE_TIMER = 'CHANGE_TIMER';
export const UPDATE_WORKFLOW_CARD_DATA = 'UPDATE_WORKFLOW_CARD_DATA';

export const save = (values, info, dispatchNextOnSuccess) => {
    return (dispatch, getState) => {
        const { typeModelName, customerName, ignoreDispatch } = info;
        const payload = {
            payload: values,
            typeModelName,//: typeModel.safeName,
            customerName//: typeModel.customerName
        };
        dispatch({
            type: SAVE_ITEM_PAYLOAD,
            payload: {}
        });
        if(!payload.payload.class) {
            payload.payload.class = payload.payload.serializerClass;
        }
        if(payload.payload.class.indexOf('LinkedTreeMap') != -1) {
            payload.payload.class = payload.payload.serializerClass;
        }
        checkClass(payload.payload);
        dispatch(showLoading());
        client().post(`${config.authAPI}/workflow/save`, payload).then((res) => {
            if(!ignoreDispatch)
            {
                dispatch({
                    type: SAVE_ITEM_PAYLOAD_SUCCESS,
                    payload: res.data
                });
            }
            dispatch(hideLoading());
            // if (dispatchNextOnSuccess) {
            //     dispatch(next(workflow));
            // }
        }).catch((error) => {
            dispatch({
                type: SAVE_ITEM_PAYLOAD_ERROR,
                payload: {
                    hasError: true,
                    error
                }
            });
            dispatch(hideLoading());
        });
    }
}

const checkClass = (payload) => {
    Object.keys(payload).forEach(function(key) {
        const dict = payload[key];
        if (dict != null && typeof dict == 'object' && !dict.hasOwnProperty('class')) {  
            if(dict.length > 0) {
                dict.forEach((item) => {
                    if (item != null && typeof item == 'object' && !item.hasOwnProperty('class')) {  
                        item.class = item.serializerClass;
                        checkClass(item)
                    }
                });
            } else {
                dict.class = dict.serializerClass;
                checkClass(dict)
            }
        }
        
    });
}

const hasProperPayload = (payload) => {
    if (!payload) return false;
    const numKeys = Object.keys(payload).length;
    if (numKeys === 0) return false;
    if (numKeys === 1 && payload.hasOwnProperty('version')) {
        return false
    }
    return true
}

const prepareNextPayload = (workflow, values, state, formKey) => {
    const nextRequestPayload = {};
    nextRequestPayload.workflow = workflow;
	if (workflow.currentItem.operation == "READALL"){
		if(!nextRequestPayload.payload) {
			nextRequestPayload.payload = {};
			nextRequestPayload.payload.class = workflow.currentItem.typeModel.customClassName;
		}
	} else {
		if (!values && formKey !== null) {
			const workflowContextValues = getFormValues(formKey)(state);
			values = workflowContextValues;
		}
		if (values) {
			nextRequestPayload.payload = Map.isMap(values) ? values.toJS() : values;
			if (hasProperPayload(nextRequestPayload.payload))
				nextRequestPayload.payload.class = workflow.currentItem.typeModel.customClassName;
			else
				nextRequestPayload.payload = null;
		}
	}
	const sdt = state?state.getIn(['calendarEvents', 'subworkflowEvent', 'start']):null;
    const edt = state?state.getIn(['calendarEvents', 'subworkflowEvent', 'end']):null;
    if(!nextRequestPayload.payload) {
        nextRequestPayload.payload = {};
        nextRequestPayload.payload.class = workflow.currentItem.typeModel.customClassName;
    }
    nextRequestPayload.payload.sdt = sdt?sdt.getTime():'';
    nextRequestPayload.payload.edt = edt?edt.getTime():'';
    
    return nextRequestPayload;
}

export const next = (workflow, values, formKey = null, pristine = true) => {
    return (dispatch, getState) => {
        const state = getState();
        const payload = prepareNextPayload(workflow, values, state, formKey);
        dispatch({
            type: NEXT_ITEM_REQUESTED,
            payload: {}
        });
        dispatch(showLoading());
        
        client().post(`${config.authAPI}/workflow/next`, payload).then((res) => {
          
          if(res.data && res.data.autoProgress) {
            dispatch({
              type: NEXT_ITEM_REQUESTED_SUCCESS,
              payload: res.data
          });


          if (payload.payload !== null) {
              dispatch({
                  type: NEXT_ITEM_SAVED_SUCCESS,
                  payload: { pristine }
              });
          }
          dispatch(next(res.data));
          } else {
            dispatch({
              type: NEXT_ITEM_REQUESTED_SUCCESS,
              payload: res.data
          });


          if (payload.payload !== null) {
              dispatch({
                  type: NEXT_ITEM_SAVED_SUCCESS,
                  payload: { pristine }
              });
          }
          }
            dispatch(hideLoading());
        }).catch((error) => {
            dispatch({
                type: NEXT_ITEM_REQUESTED_FAILURE,
                payload: {
                    hasError: true,
                    displayMode: workflow.displayMode,
                    error: {
                       message: (workflow.currentItem.layoutProperties && workflow.currentItem.layoutProperties.errorMessage) ? workflow.currentItem.layoutProperties.errorMessage : "Something went wrong! Please refresh"
                    }
                }
            });
            dispatch(hideLoading());
        });
    }
}

// NOTE on FN:inlineNext : Values should not be an IMMUTABLE OBJ in INLINE since we are not creating a Redux Form for Inline Workflows
export const inlineNext = (workflow, formKeys) => {
    return (dispatch, getState) => {
        const state = getState();
        const baseWorkflowForm = getFormValues(formKeys.parent)(state);
        const inlineWorkflowFormContext = baseWorkflowForm.get(formKeys.inline);
        const payload = prepareNextPayload(workflow, inlineWorkflowFormContext);
        return client().post(`${config.authAPI}/workflow/next`, payload);
    }
}

export const readAll = (payload, containerId) => {
    return (dispatch) => {
        dispatch({
            type: READ_ALL,
            payload: { containerId: containerId, ...payload }
        });
        dispatch(showLoading());        
        const cacheKey = payload.workflow.id + '_' + payload.typeId + '_' + payload.start + '_' + payload.count + '_' + containerId;
        let showLoadingFlag = true;
        if(window.readAllCache && window.readAllCache[cacheKey]) {
            dispatch({
                type: READ_ALL_SUCCESS,
                payload: {
                    ...payload,
                    data: window.readAllCache[cacheKey].data,
                    containerId: containerId,
                    totalCount: window.readAllCache[cacheKey].count
                }
            });
            showLoadingFlag = false;
            dispatch(hideLoading());
        }

        const appender = containerId?('?cid=' + containerId): '';
        client().post(`${config.authAPI}/workflow/readall${appender}`, payload).then((res) => {
            if(!window.readAllCache) window.readAllCache = [];
            // window.readAllCache[cacheKey] = { 'data': res.data, 'count': res.headers['total-count']};
            dispatch({
                type: READ_ALL_SUCCESS,
                payload: {
                    ...payload,
                    data: res.data,
                    containerId: containerId,
                    totalCount: res.headers['total-count']
                }
            });
            if(showLoadingFlag)
                dispatch(hideLoading());
        }).catch((error) => {
            dispatch({
                type: READ_ALL_ERROR,
                payload: {
                    hasError: true,
                    containerId: containerId,
                    error
                }
            });
            if(showLoadingFlag)
                dispatch(hideLoading());
        });
    }
}

export const readAllData = (payload, containerId) => {
    return (dispatch) => {
        const appender = containerId?('?cid=' + containerId): '';
        return client().post(`${config.authAPI}/workflow/readall${appender}`, payload);
    }
}

export const findByClassProperty = (payload) => {
    return (dispatch) => {
        return client().post(`${config.authAPI}/workflow/findbyclassproperty`, payload);
    }
}

export const readOne = (payload) => {
    return (dispatch, getState) => {
        const worklfow = getState().getIn(['workflow', 'data']);
        const tid = getState().getIn(['workflow', 'tid']);
        const payload = {
            workflowId: worklfow.get('workflowModel'),
            sessionId: worklfow.get('sessionId'),
            tid
        }
        dispatch({
            type: READ_ONE,
            payload: {}
        });
        dispatch(showLoading());
        client().post(`${config.authAPI}/workflow/readone`, payload).then((res) => {
            dispatch({
                type: READ_ONE_SUCCESS,
                payload: res.data
            });
            dispatch(hideLoading());
        }).catch((error) => {
            dispatch({
                type: READ_ONE_ERROR,
                payload: {
                    hasError: true,
                    error
                }
            });
            dispatch(hideLoading());
        });
    }
}

export const reset = () => {
    return (dispatch) => {
        dispatch({
            type: RESET_ITEM_PAYLOAD,
            payload: {}
        });
    }
}

export const updateItemPayload = (payload) => {
    return (dispatch) => {
        dispatch({
            type: UPDATE_ITEM_PAYLOAD,
            payload
        });
    }
}

export const updatePayload = (payload) => {
    return (dispatch) => {
        dispatch({
            type: UPDATE_PAYLOAD_DIRECT,
            payload
        });
    }
}

export const clearNextState = () => {
    return (dispatch) => {
        dispatch({
            type: CLEAR_NEXT_STATE,
            payload: {}
        });
    }
}

export const updateWorkflowData = (workflowData, workflow) => {
    return (dispatch) => {
        dispatch({
            type: UPDATE_WORKFLOW_DATA,
            payload: {
                workflowData,
                workflow
            }
        });
    }
}

export const resolveRules = (payload) => {
    return (dispatch) => {
        return client().post(`${config.authAPI}/workflow/resolverules`, payload);
    }
}

export const getfindbyfieldname = (payload) => {
    return (dispatch) => {
        return client().post(`${config.authAPI}/findbyfieldname`, payload);
    }
}


export const imgOcrData = (payload) => {
	return (dispatch) => {
        return client().post(`${config.authAPI}/workflow/resolveimgdata`, payload);
    }
}

export const changeTimerIndex = (payload) => {
	return (dispatch) => {
        dispatch({
            type: CHANGE_TIMER,
            payload: payload
        });
    }
}

export const webhookCall = (payload) => {
	let webhookUrl = payload.url;
	webhookUrl = webhookUrl && webhookUrl.indexOf('http' == -1 ) ? encodeURI(`${config.playerApi}${webhookUrl}`) : webhookUrl;
    return (dispatch) => {
        return client().post(webhookUrl, payload.data);
    }
}

export const updateWorkflowCardData = (workflowCard, workflowCardIndex, typeModelId, containerId, workflow) => {
    return (dispatch) => {
        dispatch({
            type: UPDATE_WORKFLOW_CARD_DATA,
            payload: {
                workflowCard,
                workflowCardIndex,
                typeModelId, 
                containerId,
                workflow
            }
        });
    }
}
