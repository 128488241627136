import client from '../client';
import config from '../config';
import { showLoading, hideLoading } from 'react-redux-loading-bar';

export const GET_CALENDAR_EVENTS = 'GET_CALENDAR_EVENTS';
export const GET_CALENDAR_EVENTS_SUCCESS = 'GET_CALENDAR_EVENTS_SUCCESS';
export const GET_CALENDAR_EVENTS_FAILURE = 'GET_CALENDAR_EVENTS_FAILURE';

export const ADD_SUBWORKFLOW_EVENT = 'ADD_SUBWORKFLOW_EVENT';
export const CLEAR_SUBWORKFLOW_EVENT = 'CLEAR_SUBWORKFLOW_EVENT';

export const getCalendarEvents = (payload) => {
  return (dispatch) => {
      dispatch({
          type: GET_CALENDAR_EVENTS,
          payload: []
      });
      dispatch(showLoading());
      client().post(`${config.authAPI}/calendarevents/all`, payload).then((res) => {
          dispatch({
              type: GET_CALENDAR_EVENTS_SUCCESS,
              payload: res.data
          });
          dispatch(hideLoading());
      }).catch((error) => {
          dispatch({
              type: GET_CALENDAR_EVENTS_FAILURE,
              payload: error
          });
          dispatch(hideLoading());
      });
  };
};

export const addSubworkflowEvent = (event) => {
    return (dispatch) => {
        dispatch({
            type: ADD_SUBWORKFLOW_EVENT,
            payload: event
        });
    }
}

export const clearSubworkflowEvent = () => {
    return (dispatch) => {
        dispatch({
            type: CLEAR_SUBWORKFLOW_EVENT,
            payload: {}
        });
    }
}