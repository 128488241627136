import TextFilter from './filter-elements/TextFilter';
import DropdownFilter from './filter-elements/DropdownFilter';
import FallbackForFilter from './filter-elements/FallbackForFilter';
import TypeModelFilter from './filter-elements/TypeModelFilter';
import LocationFilter from './filter-elements/LocationFilter';
import DateFilter from './filter-elements/DateFilter';
import NumberFilter from './filter-elements/NumberFilter';
import PhoneFilter from './filter-elements/PhoneFilter';
import BitFilter from './filter-elements/BitFilter';

// import TypeModel from './TypeModel';
// import _File from './File';
// import ActionButton from './ActionButton';
// import MultiChoice from './MultiChoice';
// import MultiSelect from './MultiSelect';
// import DateTime from './DateTime';
// import Slider from './Slider';
// import DateRange from './DateRange';
// import NumberRange from './NumberRange';
// import Time from './Time';
// import _Date from './Date';
// import Bit from './Bit';
// import _Number from './Number';
// import PositiveNumber from './PositiveNumber';
// import Decimal from './Decimal';
// import _Image from './Image';
// import _Video from './Video';
// import Button from './Button';
// import Fallback from './Fallback';
// import YesNo from './YesNo';
// import UsersList from './UsersList';

export const SMALLTEXT = TextFilter;
export const MEDIUMTEXT = TextFilter;
export const LARGETEXT = TextFilter;
export const TYPE = TypeModelFilter;
export const EMAIL = TextFilter;
export const LOCATION = LocationFilter;
export const RICHHTEXT = TextFilter;
export const DROPDOWN = DropdownFilter;
export const FILE = TextFilter;
export const URL = TextFilter;
//export const ACTION = Fallback;
export const MULTICHOICE = FallbackForFilter;
export const MULTISELECT = FallbackForFilter;
export const DATETIME = DateFilter;
export const SLIDER = FallbackForFilter;
export const DATE_RANGE = FallbackForFilter;
export const TIME = FallbackForFilter;
export const DATE = DateFilter;
export const NUMBER_RANGE = FallbackForFilter;
export const NUMBER = NumberFilter;
export const POSITIVE_NUMBER = TextFilter;
export const DECIMAL = FallbackForFilter;
export const IMAGE = FallbackForFilter;
export const VIDEO = FallbackForFilter;
//export const WORKFLOWNEXTBUTTON = Fallback;
export const YESNO = FallbackForFilter;
export const USER = FallbackForFilter;
export const CONCATENATED_FIELD = TextFilter;
export const PHONE = PhoneFilter;
export const BIT = BitFilter;