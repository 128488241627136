import React from 'react';
import BigCalendar from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import ToolbarRenderer from './custom-renderers/ToolbarRenderer';
import EventRenderer from './custom-renderers/EventRenderer';
import { connect } from 'react-redux';
import { toJS } from '../../../containers/to-js';
import { getCalendarEvents, addSubworkflowEvent, clearSubworkflowEvent } from '../../../actions/calendar-event';
import { getMatchedAction } from '../../workflow/action/workflowActionsUtils';
import { triggerAction } from '../../../actions/workflow-actions';

BigCalendar.momentLocalizer(moment);
const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

class Calendar extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      events: this.getEvents(props) || []
    }
  }

  componentDidMount() {
    !this.props.calendarEvents && this.props.getCalendarEvents({email: this.props.email});
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.email !== nextProps.email) {
      this.props.getCalendarEvents({email: nextProps.email})
    }
    this.setState({ events: this.getEvents(nextProps) });
  }

  eventStyleGetter = (event, start, end, isSelected) => {
    const style = {
      backgroundColor: event.bgColor,
      minHeight: '28px'
    }
    return {
      style
    }
  }
  
  dayPropGetter = (date) => {
	const holidayCollection = this.props.holidayCollection;
	let holidayData = [];
	if (holidayCollection && holidayCollection.isLoading == false) {
		holidayData = holidayCollection.data;
	}
	let dayName = days[date.getDay()];
	const formattedDate = new moment(date).format('YYYY-MM-DD HH:mm');
	const found = holidayData && holidayData.find(element => element.date == formattedDate);
	let style = {};
	if(found){
		style = {
			backgroundColor: '#e9be1a'
		}
	}else if(dayName == 'Sunday' || dayName == 'Saturday' ){
		style = {
			backgroundColor: '#c0c2c4'
		}
	}
    return {
      style
    }
  }

  getEvents = (props) => {
    const { events, calendarEvents, holidayCollection } = props;
    const newEvents = [].concat(events);
	if(holidayCollection && holidayCollection.isLoading == false){
		const holidayData = holidayCollection.data;
		holidayData.map((event, index) => {
        const start = moment(event.date).toDate();
        const end = moment(event.date).toDate();
        const newEvent = {
            name: event.holidaydescription,
            description: event.rawTypeModelName,
            id: event.id,
            start,
            end,
			bgColor: '#e9be1a'
        }
        newEvents.push(newEvent);
      })
	}
    if (!calendarEvents) {
      return newEvents;
    } else {
      calendarEvents.map((event, index) => {
        const start = moment(event.start).toDate();
        const end = moment(event.end).toDate();
        const newEvent = {
            name: " ",
            description: event.description,
            id: event.name,
            start,
            end,
            bgColor: '#808080'
        }
        newEvents.push(newEvent);
      })
    }
    return newEvents
  }

  onAddSubworkflowEvent = (slotInfo) => {
	const { chronoEvent, dispatch, workflow: { currentItem }, workflow } = this.props;
	const { isTextualActionTriggerEnabled, textualActionTriggerCode } = chronoEvent;
    if(slotInfo.action === 'select' || slotInfo.action === 'click') {
      const events = [].concat(this.state.events);
      events.map((event, index) => {
        if(event.isTemp) {
          events.splice(index, 1);
        }
      });
      const newEvent = {
        name: " ",
        id: " ",
        start: slotInfo.start,
        end: slotInfo.end,
        bgColor: 'green',
        isTemp: true
      }
      events.push(newEvent);
      this.props.addSubworkflowEvent(newEvent);
	  if (isTextualActionTriggerEnabled) {
		const mainAction = getMatchedAction(
		  currentItem.inlineAction,
		  textualActionTriggerCode
		);
		if (mainAction) {
		  dispatch(triggerAction(mainAction, null, workflow));
		}
	  }
      this.setState({ events });
    }
  }

  componentWillUnmount() {
    this.props.clearSubworkflowEvent();
  }

  render() {
    const { view } = this.props;
    const events = this.state.events;
    return (
      <div className="calendar" style={{height: '100vh'}}>
        <BigCalendar
		  popup
          selectable
          startAccessor='start'
          endAccessor='end'
          view={view}
          onView={() => {}}
          showMultiDayTimes
          events={events || []}
          defaultDate={new Date()}
          eventPropGetter={this.eventStyleGetter}
		  dayPropGetter={this.dayPropGetter.bind(this)}
          components={{
            toolbar: ToolbarRenderer,
            event: EventRenderer
          }}
          onSelectSlot={slotInfo => this.onAddSubworkflowEvent(slotInfo)}
        />
      </div>
    )
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getCalendarEvents: (payload) => {
      dispatch(getCalendarEvents(payload))
    },
    addSubworkflowEvent: (event) => {
      dispatch(addSubworkflowEvent(event))
    },
    clearSubworkflowEvent: () => {
      dispatch(clearSubworkflowEvent());
    }
  }
};

const mapStateToProps = (state, ownProps) => {
  return {
      calendarEvents: state.getIn(['calendarEvents', 'data', 'events'])
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(toJS(Calendar));