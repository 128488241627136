import React from 'react';
import { Input } from 'reactstrap';
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from "react-google-maps";
import PrintText from './PrintText';
import DirectionRenderComponent from './DirectionRenderComponent';
import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import { toJS } from '../../containers/to-js';

class GoogleMapElement extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      bounds: null,
      center: {
        lat: 28.5355161, lng: 77.3910265
      },
      markers: [],
      refs: {},
      places: null
    }
  }
	
  onMapMounted = ref => {
    const refs = this.state.refs;
    refs.map = ref;
    this.setState({
      refs
    });
  }

  onBoundsChanged = () => {
    this.setState({
      bounds: this.state.refs.map.getBounds(),
      center: this.state.refs.map.getCenter(),
    })
  }

  render() {
    const { isReadOne, formData, classProperty, workflowData, layoutItem: { itemProperties: { placeholder, googleMapConfig } } } = this.props;
    return <div className="location">
      {
        <LocationMap 
        isMarkerShown
        googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyBfhuMCKv2c1jH_lJcxJ1iNjg0mmHXY_zU&v=3.exp&libraries=geometry,drawing,places"
        loadingElement={<div style={{ height: `100%` }} />}
        containerElement={<div style={{ height: `${googleMapConfig && googleMapConfig.screenHeight?(googleMapConfig.screenHeight + 'vh'):'400px'}` }} />}
        mapElement={<div style={{ height: `100%` }} />}
        onMapMounted={this.onMapMounted}
        onBoundsChanged={this.onBoundsChanged}
        bounds={this.state.bounds}
        center={this.state.center}
        panToBounds={() => {
          this.state.refs.map && this.state.refs.map.panToBounds(this.state.bounds);
        }}
        placeholder={placeholder}
        classProperty={classProperty}
		googleMapConfig={googleMapConfig}
		workflowData={!formData ? workflowData : formData}
      /> 
      }
      </div>
  }
}

const LocationMap = withScriptjs(withGoogleMap((props) => {
  const customMapStyle = props.googleMapConfig && props.googleMapConfig.customMapStyle
  const silverMapStyles = customMapStyle ? [{"elementType":"geometry","stylers":[{"color":"#f5f5f5"}]},{"elementType":"labels.icon","stylers":[{"visibility":"off"}]},{"elementType":"labels.text.fill","stylers":[{"color":"#616161"}]},{"elementType":"labels.text.stroke","stylers":[{"color":"#f5f5f5"}]},{"featureType":"administrative.land_parcel","elementType":"labels.text.fill","stylers":[{"color":"#bdbdbd"}]},{"featureType":"poi","elementType":"geometry","stylers":[{"color":"#eeeeee"}]},{"featureType":"poi","elementType":"labels.text.fill","stylers":[{"color":"#757575"}]},{"featureType":"poi.park","elementType":"geometry","stylers":[{"color":"#e5e5e5"}]},{"featureType":"poi.park","elementType":"labels.text.fill","stylers":[{"color":"#9e9e9e"}]},{"featureType":"road","elementType":"geometry","stylers":[{"color":"#ffffff"}]},{"featureType":"road.arterial","elementType":"labels.text.fill","stylers":[{"color":"#757575"}]},{"featureType":"road.highway","elementType":"geometry","stylers":[{"color":"#dadada"}]},{"featureType":"road.highway","elementType":"labels.text.fill","stylers":[{"color":"#616161"}]},{"featureType":"road.local","elementType":"labels.text.fill","stylers":[{"color":"#9e9e9e"}]},{"featureType":"transit.line","elementType":"geometry","stylers":[{"color":"#e5e5e5"}]},{"featureType":"transit.station","elementType":"geometry","stylers":[{"color":"#eeeeee"}]},{"featureType":"water","elementType":"geometry","stylers":[{"color":"#c9c9c9"}]},{"featureType":"water","elementType":"labels.text.fill","stylers":[{"color":"#9e9e9e"}]}]:[]
	return (
		<GoogleMap
			options={{
			  mapTypeControl: false,
        scrollwheel: false,
        styles:silverMapStyles
			}}
			ref={props.onMapMounted}
			defaultZoom={15}
			center={props.center}
			onBoundsChanged={props.onBoundsChanged}
			panToBounds={props.fitBounds}
		>
		{<DirectionRenderComponent googleMapConfig={props.googleMapConfig} workflowData={props.workflowData} />}
		</GoogleMap>
	)
  }
))

const mapStateToProps = (state, ownProps) => {
  //const { layoutItem: { itemProperties: { googleMapConfig } } } = ownProps;
  let formData = state.getIn(['form', ownProps.form, 'values'])
  return {
    formData
  }
}

export default connect(mapStateToProps)(toJS(GoogleMapElement));