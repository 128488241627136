import { connect } from 'react-redux';
import SidebarWorkflowMenuItems from '../components/menu/SidebarWorkflowMenuItems';
import { getTranslate, getActiveLanguage } from 'react-localize-redux';
import { fetchNavWorkflows, endCurrentBaseWorkflow } from '../actions/workflows';
import { getCustomIcons } from '../actions/company';
import { toJS } from './to-js';

const mapStateToProps = (state) => {
    const locale = state.get('locale');
    return { 
        translate: getTranslate(locale),
        currentLanguage: getActiveLanguage(locale) && getActiveLanguage(locale).code,
        user: state.get('user'),
        items: state.getIn(['navigation', 'data']),
        customIcons: state.getIn(['company', 'icons'])
      };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        fetchWorkflows: (user) => {
            dispatch(fetchNavWorkflows(user));
        },
        endCurrentBaseWorkflow: () => {
            dispatch(endCurrentBaseWorkflow());
        },
        getCustomIcons: payload => {
            dispatch(getCustomIcons(payload));
          },
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(toJS(SidebarWorkflowMenuItems));