import React, { Component } from 'react';
import { FormGroup, Input, Row, Label, Col, FormFeedback, InputGroup, InputGroupAddon } from 'reactstrap';
import PrintText from '../PrintText';
import { Field } from 'redux-form/immutable';

class DateRangeClass extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dates: {
                from: {
                    value: '',
                    isValid: true
                },
                to: {
                    value: '',
                    isValid: true
                }
            }
        }
    }
    dateEntered(dateKey, ev) {
        const dates = Object.assign([], this.state.dates);
        const { input: { onChange } } = this.props;
        const etVal = ev.target.value + '';
        dates[dateKey]['value'] = etVal;
        this.setState({ dates }, () => {
            onChange([this.state.dates.from.value, this.state.dates.to.value]);
        });
    }
    render() {
        const { label,
            layoutItem
        } = this.props;
        const { properties } = layoutItem.itemProperties.field;
        const { dates } = this.state;
        return (
            <FormGroup>
                {
                    label.show ? <Label>{label.text}{properties.required ? <sup>*</sup> : ''}</Label> : ''
                }
                {
                    <Row>
                        <Col sm='12' md='6'>
                            <InputGroup>
                                <InputGroupAddon>From</InputGroupAddon>
                                <Input valid={!dates.from.isValid ? false : null} type='date' onChange={this.dateEntered.bind(this, 'from')} />
                            </InputGroup>
                            {
                                !dates.from.isValid ? <InvalidDate /> : ''
                            }
                        </Col>
                        <Col sm='12' md='6'>
                            <InputGroup>
                                <InputGroupAddon>To</InputGroupAddon>
                                <Input valid={!dates.to.isValid ? false : null} type='date' onChange={this.dateEntered.bind(this, 'to')} />
                            </InputGroup>
                            {
                                !dates.to.isValid ? <InvalidDate /> : ''
                            }
                            
                        </Col>
                    </Row>
                }
            </FormGroup>
        );
    }
}

const InvalidDate = () => {
    return <p className='animated fadeIn text-danger invalid-feedback d-block'>
        <i className="fa fa-exclamation-triangle" aria-hidden="true"></i> Please enter a valid date
    </p>
}

export default DateRangeClass;