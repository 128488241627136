import React, { Component } from 'react';
import { connect } from 'react-redux';
import { toJS } from '../../../containers/to-js';
import { triggerAction } from '../../../actions/workflow-actions';
import InlineWorkflow from '../../workflow/InlineWorkflow';
import { next } from '../../../actions/workflow-item';
import { endWorkflow } from '../../../actions/workflows';
import equal from 'equals';

class CollectionIterator extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentIterationIndex: 0,
            action: null,
            triggerEnd: false
        }
    }
    getNextObject(hasEnded) {
        const { currentIterationIndex, action, triggerEnd } = this.state;
        const { triggerAction, workflow, collectionToIterate, notifyParentToIncrementIndex } = this.props;
        if (!collectionToIterate || !collectionToIterate.length) return;
        const context = collectionToIterate[currentIterationIndex];
        if (context && context.id && !triggerEnd) {
            triggerAction(action, context.id, workflow);
        }
        else if (triggerEnd || currentIterationIndex === collectionToIterate.length || currentIterationIndex > collectionToIterate.length) {
            // ITERATION OVER collectionToIterate Ended. Notify Parent Now.
            if (notifyParentToIncrementIndex) {
                notifyParentToIncrementIndex();
            } else {
                // No more referent found to notifyParentToIncrementIndex. Hence, proceed with workflowNext call
                const { workflow, triggerNext, form } = this.props;
                triggerNext(workflow, null, form);
            }
        }

    }
    incrementAndTrigger() {
        const { collectionToIterate } = this.props;
        if (!collectionToIterate || !collectionToIterate.length) return;
        if ((collectionToIterate.length - 1) === this.state.currentIterationIndex) {
            this.setState({ triggerEnd: true }, () => {
                this.getNextObject();
            });
            
        }
        else {
            this.setState({ currentIterationIndex: this.state.currentIterationIndex + 1, triggerEnd: false }, () => {
                this.getNextObject();
            });
        }
        
    }
    componentDidMount() {
        const { generatedActionCodeForIterable, actions } = this.props;
        const action = this.matchAction(actions, generatedActionCodeForIterable);
        action.actionProperties.displayMode = 'INLINE';
        this.setState({ action }, () => {
            this.getNextObject();
        });
    }
    componentWillReceiveProps(nextProps) {
        const { collectionToIterate } = nextProps;
        if (!equal(collectionToIterate, this.props.collectionToIterate)) {
            this.setState({ currentIterationIndex: 0, triggerEnd: false }, () => {
                this.getNextObject();
            });
        }
    }
    matchAction(actions, code) {
        let matchedAction = null;
        for (let i = 0; i < actions.length; i += 1) {
            if (actions[i].actionProperties.generatedActionCode === code) {
                matchedAction = actions[i];
                break;
            }
        }
        return matchedAction;
    }
    notifyParentToIncrementIndex() {
        this.incrementAndTrigger();
    }
    render() {
        const { iterator, iterationWorkflowId, notifyParentToIncrementIndex, workflow, workflowActionState, ...rest } = this.props;
        if (workflowActionState && Object.keys(workflowActionState).length && this.state.action) {
            const actionState =  workflowActionState[this.state.action.id];
            if (actionState && actionState.isLoading) {
                return <p className='text-muted'>Loading...</p>
            }
        }
        return (
            <div className='animated bounceIn'>
                <InlineWorkflow
                    notifyParentToIncrementIndex={this.notifyParentToIncrementIndex.bind(this)}
                    iterationMode={true}
                    code={iterationWorkflowId}
                    noPadding={true}
                    {...rest}
                />
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        workflowActionState: state.get('workflowActions')
    };
};


const mapDispatchToProps = (dispatch) => {
    return {
        triggerAction: (action, tid, workflow) => {
            dispatch(triggerAction(action, tid, workflow));
        },
        triggerNext: (workflow, values, form) => {
            dispatch(next(workflow, values, form));
        },
        endWorkflow: (payload) => {
            dispatch(endWorkflow(payload));
        }
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(toJS(CollectionIterator));
