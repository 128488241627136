import React from 'react';
import { convertFromRaw } from 'draft-js';
import RichhTextRenderer from '../elements/RichhTextRenderer';
import User from '../commons/user/index';

const IsJsonString = (str) => {
    try {
        
        getActualJson(str)
    } catch (e) {
        return false;
    }
    return true;
}

const isRichTextState = (obj) => {
    try {
        convertFromRaw(obj);
    }
    catch(ex) {
        return false;
    }
    return true;
}

const isUser = (obj) => {
    if (!obj) return false;
    if (Array.isArray(obj) && obj.length) {
        if (obj[0].hasOwnProperty('firstName') && obj[0].hasOwnProperty('lastName') && obj[0].hasOwnProperty('email') && obj[0].hasOwnProperty('id')) {
            return true;
        }   
    } else {
        if (obj.hasOwnProperty('firstName') && obj.hasOwnProperty('lastName') && obj.hasOwnProperty('email') && obj.hasOwnProperty('id')) {
            return true;
        }
    }
    return false;
}

const getActualJson = (str) => {
    if(typeof str === 'string' && str.includes("***|||***")) {
        const richTextContent = str.split("***|||***");
        return JSON.parse(richTextContent[0]);
    }

    return JSON.parse(str);
}

export const analyseEvaluatedText = (evaluatedText) => {
    const isJSONStr = IsJsonString(evaluatedText);
    if (isJSONStr && isRichTextState(getActualJson(evaluatedText))) {
        return <RichhTextRenderer fromSession={true} rawContent={evaluatedText} />;
    }
    /*if (isJSONStr && isUser(getActualJson(evaluatedText))) {
        const user = getActualJson(evaluatedText);
        if (Array.isArray(user)) {
            return <div className='d-flex'>
                {
                    user.map((u, index) => {
                        return <div className='mr-2'>
                            <User key={index} avatarSize={25} user={u} displayMode='SingleLine' />
                        </div>
                    })
                }
            </div>

        } else {
            return <User avatarSize={25} user={getActualJson(evaluatedText)} displayMode='SingleLine' />
        }
        
    }*/
    return evaluatedText;
}

