import { connect } from 'react-redux';
import WorkflowItem from '../../components/workflow/WorkflowItem';
import { getTranslate } from 'react-localize-redux';
import { toJS } from '../to-js';
import { next, reset, readAll, resolveRules, readAllData } from '../../actions/workflow-item';
import { setSortAndPagination } from '../../actions/workflows';
import { getCustomIcons, getStyles,getCompanyDetails } from '../../actions/company';
import { setUIFilter } from '../../actions/uiFilters';
import { getCitiesData, getStatesData } from '../../actions/static-data';

const mapStateToProps = (state, ownProps) => {
    const { workflow } = ownProps;
	const sortPagination = state.getIn(['workflow', 'sortPagination']);
	const formValues = state.getIn(['form', ownProps.form, 'values']);
    return { 
        translate: getTranslate(state.get('locale')),
        workflow,
        nextResponse: state.getIn(['workflowItem', 'data']),
        breadcrumbs: state.getIn(['breadcrumbs', 'items']),
        isLoading: state.getIn(['workflow', 'isLoading']),
        customIcons: state.getIn(['company', 'icons']),
        customStyles: state.getIn(['company', 'styleDetails']),
        sortPagination: sortPagination,
		formValues,
        uiFilters: state.getIn(['uiFilters']),
        companyDetails: state.getIn(['company', 'details'])
      };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    const { workflow } = ownProps;
    return {
        next: () => {
            dispatch(next(workflow));
        },
        readAll: () => {
            dispatch(readAll(workflow));
        },
		setSortAndPagination: (payload) => {
            dispatch(setSortAndPagination(payload));
        },  
        getCompanyDetails: () => {
            dispatch(getCompanyDetails());
        },
		resolveRules: payload => {
			return dispatch(resolveRules(payload));
        },
        getCustomIcons: payload => {
            dispatch(getCustomIcons(payload));
        },
        getCustomStyles: ()  => {
            dispatch(getStyles());
        },
        readAllData: (payload) => {
            return dispatch(readAllData(payload));
        },
        setUIFilter: (payload) => {
            dispatch(setUIFilter(payload));
        },
        getCitiesData: (payload) =>{
            return dispatch(getCitiesData(payload));
        },
        getStatesData: (payload) =>{
            return dispatch(getStatesData(payload));
        }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(toJS(WorkflowItem));