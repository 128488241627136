import React from 'react';
import { Alert } from 'reactstrap';

const NoDataAlertBox = (props) => {
    const { typeModelName, noDataText,noDataTextHeading} = props;
    return <Alert color='light' className='no-data-alert'>
        <div className='d-flex justify-content-center'>
            <div>
                <h5><i className="fa fa-exclamation-triangle mr-1" aria-hidden="true"></i> {noDataTextHeading? noDataTextHeading:`No ${typeModelName}`}!</h5>
                <p>{noDataText ? noDataText : `We weren't able to find any entries for ${typeModelName.toLowerCase()}`}</p>
            </div>
        </div>
    </Alert>
}

export default NoDataAlertBox;