import React from 'react';
import { Input, Button, Label, Row, Col, FormFeedback } from 'reactstrap';
import { connect } from 'react-redux';
import { toJS } from '../../containers/to-js';
import { ModelPropertiesParser } from '../commons/modelPropertiesParser';
import Select from 'react-select';
import config from '../../config';
import { getBreakpoint } from '../workflow/operations/helpers/form-layout/common-routines';
import { Map, fromJS } from 'immutable';
import moment from 'moment';

class OCRElement extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			image:null,
			imageData: null,
			currentScale: 1,
			currentPan: 0,
			showOcrData: false,
			imageUrl: null,
			fieldList: [],
			selectedItem: null,
			mousePos: {x: 0, y:0},
			loading: false,
			init: false,
			rows: [],
			columns: [],
			rowIndex: null,
			targetWidth: null,
			nextTargetWidth: null,
			currentMousePos: {},
			mouseDownMousePos: {},
			finishRect: {},
			originalWidth:400,
			selectedField: {name: '', value:''},
			mouseDown: false,
			selectedRow: {index: '', rowItem: ''},
			selectedCol: {header: '', classProp: ''},
			hasError: false,
			error: ''
		}
		this.container = null;
		this.canvasA = null;
	}

	renderCanvas() {
		if(!this.state.image || !this.canvasA) return null;
		const context = this.canvasA.getContext('2d');
		const r = this.container.getBoundingClientRect();
		this.canvasA.height = (window.innerHeight -  r.top - 50) * this.state.currentScale;
		//if(this.canvasA.width < this.canvasA.height) this.canvasA.width = this.canvasA.height;
		//else this.canvasA.width = this.container.offsetWidth;
		//this.canvasA.width = this.canvasA.height;
		this.canvasA.width = (this.container.offsetWidth * this.state.currentScale);
		const img = this.state.image;
		const canvas = this.canvasA;
		var hRatio = canvas.width / img.width    ;
		var vRatio =  canvas.height / img.height  ;
		var ratio  = Math.min ( hRatio, vRatio );
		if(ratio < 0.15 && !(this.state.init)) {
			let newScale = this.state.currentScale;
			let loopBreaker = 0;
			while(ratio < 0.15) {
				newScale += 0.25;
				loopBreaker++;
				if(loopBreaker > 10) break;
				let tmp = (window.innerHeight -  r.top - 50) * newScale;
				hRatio = tmp  / img.width    ;
				vRatio =  tmp / img.height  ;
				ratio  = Math.min ( hRatio, vRatio );
			}
			this.setState({currentScale: newScale, init: true});
			return;
		}
		var centerShift_x = ( canvas.width - img.width*ratio ) / 2;
		var centerShift_y = ( canvas.height - img.height*ratio ) / 2;  
		context.drawImage(img, 0,0, img.width, img.height,
						   centerShift_x,centerShift_y,img.width*ratio, img.height*ratio); 


		if(this.state.showOcrData)
			this.renderText(this.state.imageData);
		if(this.state.currentMousePos.x && this.state.currentMousePos.y) {
			const x = this.state.currentMousePos.x;
			const y = this.state.currentMousePos.y;
			context.strokeWidth = 1;
			context.moveTo(x, 0);
			context.lineTo(x, this.canvasA.height);
			context.moveTo(0,  y);
			context.lineTo(this.canvasA.width,  y);
			// Line color
			context.strokeStyle = 'green';
			context.stroke();

			if(this.state.mouseDown) {
				context.strokeWidth = 1;
				// Line color
				context.fillStyle = '#22222277';
				context.rect(Math.min(this.state.mouseDownMousePos.x, x),Math.min(this.state.mouseDownMousePos.y, y),
				Math.abs(this.state.mouseDownMousePos.x-x), Math.abs(this.state.mouseDownMousePos.y-y));
				//context.stroke();
				context.fill();
			} else if(this.state.finishRect.x && this.state.finishRect.y) {
				let x1 = Math.min(this.state.finishRect.x, this.state.mouseDownMousePos.x);
				let x2 = Math.max(this.state.finishRect.x, this.state.mouseDownMousePos.x);
				let y1 = Math.min(this.state.finishRect.y, this.state.mouseDownMousePos.y);
				let y2 = Math.max(this.state.finishRect.y, this.state.mouseDownMousePos.y);
				const mouseEndX = this.state.finishRect.x;
				const mouseEndY = this.state.finishRect.y;
				x1 = (x1 - centerShift_x) / ratio;
				x2 = (x2 - centerShift_x) / ratio;
				y1 = (y1 - centerShift_y) / ratio;
				y2 = (y2 - centerShift_y) / ratio;
				this.setState({finishRect: {}}, () => {
					let text ='';
					this.state.imageData && this.state.imageData.translated && 
					this.state.imageData.translated.forEach(function(item, index) {
						if ((x1 <= item.x1 && x2 >= item.x2 &&
							y1 <= item.y1 && y2 >= item.y2) || (item.x1 <= x1 && item.x2 >= x2 &&
								item.y1 <= y1 && item.y2 >= y2) ) {
								text = text + item.value+' ';
						}
					});
					let yTop = mouseEndY + r.top - this.container.scrollTop;
					if(yTop + 250 > window.innerHeight )
						yTop = yTop - (yTop + 250 - window.innerHeight);
					this.setState({selectedItem: text.trim(), mousePos: {x: mouseEndX + r.left - this.container.scrollLeft, y: yTop}});					
				});
			}
		}
	}
  
	componentDidMount() {
		const self = this;
		const { workflowData, container, container: {items}, layoutItem: { itemProperties: { ocrConfig } }, getImgOcrData } = this.props;
		let {fieldList, imageData, imageUrl } = this.state;
		this.canvasA.width = this.container.offsetWidth;
		this.container.style.maxHeight = (window.innerHeight -  this.container.getBoundingClientRect().top - 50) + 'px';		
		this.container.style.minHeight = (window.innerHeight -  this.container.getBoundingClientRect().top - 50) + 'px';		
		const column = container && container.properties && container.properties.columns[1];
		let bkPoint = getBreakpoint(this.props);
		let grid = column && column.layouts[bkPoint];
		const imgSource = ocrConfig && ocrConfig.imgSource ? ocrConfig.imgSource : null;
		const imgData = ocrConfig && ocrConfig.imgData ? ocrConfig.imgData : null;
		let imgUrl = imgSource && workflowData && ModelPropertiesParser(`{{${imgSource}}}`, workflowData);
		//imgUrl = 'https://h-assets.s3.eu-west-1.amazonaws.com/test-public-data/HOADON_0100107317_759.jpg';
		if(imgUrl)
			imgUrl = imgUrl.startsWith('http') ? imgUrl : config.assetsBasePath + imgUrl;
		const imgDataObj = imgData && workflowData && ModelPropertiesParser(`{{${imgData}}}`, workflowData);
		//this.canvasA.addEventListener('click', this.showFormFields, false);
		this.canvasA.addEventListener('mousedown', this.handleMouseDown, false);
		this.canvasA.addEventListener('mouseup', this.handleMouseUp, false);
		this.canvasA.addEventListener('mousemove', this.handleMouseMove, false);
		this.canvasA.addEventListener('mouseout', this.handleMouseOut, false);
		document.onkeydown = function(evt) {
			evt = evt || window.event;
			if (evt.keyCode == 27) {
				evt.preventDefault();
				evt.stopPropagation();
				self.closeList();
			}
		};
		if(imgUrl)
		{
			const image = new Image();
			image.onload = () => {
				this.setState({image: image});
			};
			image.src = imgUrl;
			imageUrl = imgUrl
		}
		if(imgDataObj) {
			imageData = imgDataObj;
		}
		else if(!imgDataObj && imgUrl) {
			self.setState({loading: true});
			getImgOcrData({
				filePath: imgUrl
			}).then(resp => {
				if(resp.data) {
					self.setState({ imageData: resp.data.data, loading: false });
				}
			});
		}
		items && items.forEach(function(item, index) {
			if (item.itemType === 'MODELPROPERTIES' && !item.itemProperties.hideInWeb) {
				if(grid[index-1])
				{
					if(fieldList[grid[index-1].y]) {
						fieldList.splice(grid[index-1].y+1, 0, item.itemProperties.field);
					} else 
						fieldList[grid[index-1].y] = item.itemProperties.field;
				}
			}
		});
		this.setState({ imageData, imageUrl, fieldList, originalWidth: this.canvasA.width});
	}
	
	onBoxMove =(event) => {
		document.onmousemove = this.elementDrag;
		document.onmouseup = this.closeDragElement;
	}
	
	elementDrag = (event) => {
		this.setState({mousePos: {x: event.clientX, y: event.clientY}});
	}
	
	closeDragElement = (event) => {
		document.onmouseup = null;
		document.onmousemove = null;
	}
	
	handleMouseDown =(event) => {
		if(this.state.selectedItem && this.state.selectedItem.length) return;
		const r = this.canvasA.getBoundingClientRect();
		this.setState({mouseDown: true, mouseDownMousePos: {x:(event.clientX - r.left),y:(event.clientY - r.top)}})
	}
	
	handleMouseUp =(event) => {
		if(this.state.selectedItem && this.state.selectedItem.length) return;
		const r = this.canvasA.getBoundingClientRect();
		this.setState({ mouseDown: false, finishRect: {x:(event.clientX - r.left), y: (event.clientY - r.top)}});
	}

	handleMouseOut = (event) => {
		if(this.state.selectedItem && this.state.selectedItem.length) return;		
		this.setState({ currentMousePos: {}, mouseDownMousePos: {}}, () => this.renderCanvas());
	}
	
	handleMouseMove =(event) => {
		if(this.state.selectedItem && this.state.selectedItem.length) return;
		const r = this.canvasA.getBoundingClientRect();
		this.setState({currentMousePos: {x: (event.clientX - r.left), y: (event.clientY - r.top )}}, () => this.renderCanvas());
	}
	
	renderImage = (imgUrl) => {
		if(imgUrl)
		{
			const image = new Image();
			image.onload = () => {
				this.fitImageOn(this.canvasA, image);
			};
			image.src = imgUrl;
		}
	}
	
	renderText = (textData) => {
		const { mousePosStart, mousePosEnd, selectedItem} = this.state;
		if(!textData) return;
		const context = this.canvasA.getContext('2d');
		context.fillStyle = "#000000";

		// const imageObj = this.state.image;
		const canvas = this.canvasA;
		const img = this.state.image;
		var hRatio = canvas.width  / img.width    ;
		var vRatio =  canvas.height / img.height  ;
		var ratio  = Math.min ( hRatio, vRatio );
		var centerShift_x = ( canvas.width - img.width*ratio ) / 2;
		var centerShift_y = ( canvas.height - img.height*ratio ) / 2;  
				
		textData.translated.map((item, index) => {
			const fontSize = Math.round((item.y2-item.y1)*ratio);
			context.font = ((fontSize>50?48:fontSize<5?5:fontSize)) + "px 'Segoe UI'";
			context.textBaseline = "top";
			context.fillStyle = "#eeeeee99";
			context.fillRect(centerShift_x + ((item.x1) * ratio), centerShift_y + ((item.y1))* ratio, (item.x2-item.x1)*ratio, (item.y2-item.y1)*ratio);
			context.fillStyle = "#000000";
			context.fillText(item.value, centerShift_x + ((item.x1) * ratio), centerShift_y +((item.y1) * ratio));
		})
	}
	
	showFormFields =(event) => {
		const self = this;
		const { mouseDown, selectedItem } = self.state;
		if(mouseDown && selectedItem) {
			self.setState({mousedown: false});
			return;
		}
		
		self.setState({selectedItem: null, columns:[], rows: [], rowIndex: null, mouseDown: false, selectedCol: {header: '', classProp: ''}, 
		selectedField:{name: '', value:''}, selectedRow: {index: '', rowItem: ''}});
		const r = this.canvasA.getBoundingClientRect();
		const scalex = (this.canvasA.width/self.state.image.width)*self.state.currentScale
		const scaley = (this.canvasA.height/this.state.image.height)*this.state.currentScale
		const mousePos = {
			x: ((event.clientX - r.left) + self.state.currentPan) / scalex,
			y: (event.clientY - r.top - this.canvasA.offsetTop)
		};
		self.state.imageData && self.state.imageData.translated.forEach(function(item) {
			if (mousePos.x >= item.x1 && mousePos.x <= item.x2 &&
				mousePos.y >= item.y1 && mousePos.y <= item.y2) {
					self.setState({selectedItem: item.trim(), mousePos: {x: event.clientX, y: event.clientY}});
			} 
		});
	}
	
	onFieldSelect = (value, event) => { 
		const { container: {items}, formData } = this.props;
		const targetWidth = event && event.target.offsetWidth;
		if(value)
		{
			if(value.type === 'TYPE') {
				items && items.map((item, index) => {
					if (item.itemType === 'MODELPROPERTIES') {
						if(item.itemProperties.field.classProperty === value.classProperty) {
							const columns = item.itemProperties.tableConfig.config.columns;
							const rows = formData[item.itemProperties.field.classProperty];
							this.setState({columns, rows, targetWidth, selectedField: item, mouseDown: false, hasError: false});
						}
					}
				});
			} else {
				const item = this.state.selectedItem;
				const itemValue = item.value ? item.value : item;
				this.props.change(value.classProperty, itemValue);
				this.setState({selectedItem: null, mouseDown: false, hasError: false});
			}
		}
	}
	
	onRowSelect = (row, event) => {
		const nextTargetWidth = event ? event.target.offsetWidth : '';
		if(event) {
			this.setState({rowIndex: row, nextTargetWidth});
		} else {
			this.setState({selectedRow: row});
		}
		
	}
	
	onColumnSelect = (column, event) => {
		if(event)
		{
			const item = this.state.selectedItem;
			const fieldClassProp = this.state.selectedField.itemProperties.field.classProperty;
			let parentClassProp = `${fieldClassProp}[${this.state.rowIndex}]`;
			const classProp = `${parentClassProp}.${column.field.classProperty}`;
			this.props.change(classProp, item.value);
			this.setState({selectedItem: null, columns:[], rows: [], rowIndex: null});
		}
		else {
			this.setState({ selectedCol: column});
		}
	}
	
	showOcrData =() => {
			const showOcrData = this.state.showOcrData;
		// if(!showOcrData) {
		// 	this.renderText(this.state.imageData);
		// } else {
		// 	const context = this.canvasA.getContext('2d');
		// 	context.clearRect(0, 0, this.canvasA.width, this.canvasA.height);
		// 	this.fitImageOn(this.canvasA, this.state.image);
		// 	//this.renderImage(this.state.imageUrl);
		// }
		this.setState({showOcrData: !showOcrData}, () => this.renderCanvas());
	}
	
	itemSelect = (value) => {
		const { container: {items}, formData } = this.props;
		if(value)
		{
			if(value.type === 'TYPE') {
				items && items.map((item, index) => {
					if (item.itemType === 'MODELPROPERTIES') {
						if(item.itemProperties.field.classProperty === value.classProperty) {
							const columns = item.itemProperties.tableConfig.config.columns;
							const rows = formData[item.itemProperties.field.classProperty];
							let rowObj = [], colObj = [];
							rows.map((row, rowIndex) => {
								rowObj.push({index: rowIndex, rowItem: `${item.itemProperties.label.text} - Row ${rowIndex+1}` })
							});
							columns.map((col, rowIndex) => {
								colObj.push({header: col.config.header, classProp: col.field.classProperty, type: col.field.type})
							})
							this.setState({columns: colObj, rows: rowObj, selectedField: value, mouseDown: false});
						}
					}
				});
			} else {
				this.setState({selectedField: value, columns:[], rows: [], selectedRow: {index: '', rowItem: ''}});
			}
		}
	}
	
	setFieldValue = () => {
		let { hasError, error, selectedCol } = this.state;
		hasError = false;
		const item = this.state.selectedItem;
		let classProp = '';
		let itemValue = item.value ? item.value : item;
		if(this.state.selectedField.type === 'TYPE') {
			const fieldClassProp = this.state.selectedField.classProperty;
			let parentClassProp = `${fieldClassProp}[${this.state.selectedRow.index}]`;
			classProp = `${parentClassProp}.${this.state.selectedCol.classProp}`;
			if(selectedCol.type === 'NUMBER' || selectedCol.type === 'POSITIVE_NUMBER') {
				if (isNaN(itemValue))
				{
					hasError = true;
					error = 'Not a valid number';
				}
			}
			if(selectedCol.type === 'DECIMAL') {
				const regex = /^\d*\.?\d*$/;
				if(!itemValue.match(regex))
				{
					hasError = true;
					error = 'Invalid value';
					
				}
			}
		} else {
			classProp = this.state.selectedField.classProperty;
			let elem = document.querySelector(`[for=${this.state.selectedField.classProperty}]`);
			if(!elem) elem = document.querySelector(`[name=${this.state.selectedField.classProperty}]`);
			elem && elem.scrollIntoView({behavior: 'smooth'});
			if(this.state.selectedField.type === 'NUMBER' || this.state.selectedField.type === 'DECIMAL' || 
			this.state.selectedField.type === 'POSITIVE_NUMBER' || this.state.selectedField.type === 'CALCULATED_NUMBER') {
				itemValue = itemValue.replace(',','');
			}
			if(this.state.selectedField.type === 'NUMBER' || this.state.selectedField.type === 'POSITIVE_NUMBER') {
				if (isNaN(itemValue))
				{
					hasError = true;
					error = 'Not a valid number';
				}
			}
			if(this.state.selectedField.type === 'DECIMAL') {
				const regex = /^\d*\.?\d*$/;
				if(!itemValue.match(regex))
				{
					hasError = true;
					error = 'Invalid value';
				}
			}
			if(this.state.selectedField.type === 'DATE') {
				const dateValue = moment(itemValue + ' GMT');;
				if(!dateValue.isValid())
				{
					hasError = true;
					error = 'Invalid Date';
				}
			}
		}
		if(hasError) {
			this.setState({hasError, error});
		} else {
			this.props.change(classProp, itemValue.trim());
			this.setState({selectedItem: null, hasError: false, error: '', selectedField:{name: '', value:''}, 
			selectedRow: {index: '', rowItem: ''}, selectedCol: {header:'', classProp:'', type: ''}, mouseDown: false});
		}
	}
	
	closeList = () => {
		this.setState({selectedItem: null, selectedField: {name: '', value:''}, columns:[], rows: [], selectedRow: {index: '', rowItem: ''}, 
		selectedCol: {header:'', classProp:'', type: ''}, mouseDown: false, hasError: false, error: false});
	}
	
	valueChange = (e) => {
		this.setState({selectedItem: e.target.value})
	}
	
	addRow = () => {
		const existingRows = this.state.rows;
		const {workflow: {currentItem: {typeModel}}} = this.props;
		const customTypeClass = this.state.selectedField.customTypeClass;
		const customClassName = typeModel.customClassName;
		const customerName = typeModel.customerName;
		let modelVersion = customClassName.replace('com.uttara.neene.proxyto.' + customerName + '_','');
		modelVersion = modelVersion.substring(0, modelVersion.indexOf('.'));
		const iModelVerision = parseInt(modelVersion.substring(1));
		let className = customTypeClass;
		for(let i = 0; i < iModelVerision; i++) {
			if(className.indexOf('v' + i + '.') != -1)
				className = className.replace('v' + i, modelVersion).replace('v' + i, modelVersion);
		}
		let rowObj = [];
		const formRows = this.props.formValues ? this.props.formValues[this.state.selectedField.name] : [];
		let formRowsTemp = fromJS(formRows);
		Object.assign(rowObj, existingRows);
		Array.isArray(rowObj) && rowObj.push({index: existingRows.length, rowItem: `${this.state.selectedField.name} - Row ${existingRows.length+1}` })
		Array.isArray(existingRows) && existingRows.push({});
		formRowsTemp = formRowsTemp.push(new Map({class: className}));
		this.setState({ rows: rowObj }, () => {
			this.props.change(this.state.selectedField.name, formRowsTemp);
		})
	}
	
	render() {
		const { isReadOne, formData, classProperty, workflowData, layoutItem: { itemProperties: { placeholder, ocrConfig } } } = this.props;
		const { fieldList, selectedItem, mousePos, loading, selectedField, mouseClick, mouseDrag, selectedOption, selectedRow, selectedCol, hasError, error} = this.state;
		this.renderCanvas();
		return <div className="h-100">
		<div className="ocr h-100" ref={el => (this.container = el)} style = {{
							overflow: 'scroll',
							background: '#eee',
							padding: "10px"
				}}>
			<canvas id="ocrCanvas" ref={canvasA => {
				this.canvasA = canvasA;
			}} style={{display:"block", margin:"auto"}}>
			</canvas>
		</div>
			{
				selectedItem != null && selectedItem.value && <div style={{position: 'fixed', top: `${mousePos.y}px`, border:'1px solid #ccc', maxHeight: '300px', overflowY: 'auto',
				left: `${mousePos.x}px`, transition: 'width 2s, height 4s', transitionDelay: '2s', background: '#fff',  zIndex: 9999}}>
					<ol style={{listStyle:'none', padding: 0, margin: 0}}>
					{
						fieldList.map((item, index) => {
							return <li key={index} style={{borderBottom:'1px solid #ccc', padding: '5px', cursor: 'pointer'}} 
							onClick={ this.onFieldSelect.bind(this, item)}>{item.name}</li>
						})
					}
					</ol>
				</div>
			}
			{
					selectedItem != null && selectedItem.value && this.state.rows && this.state.rows.length > 0 && <div style={{position: 'fixed', top: `${mousePos.y}px`, border:'1px solid #ccc', maxHeight: '300px', overflowY: 'auto',
					left: `${mousePos.x+this.state.targetWidth}px`, background: '#fff',  zIndex: 9999}}><ol style={{listStyle:'none', padding: 0, margin: 0}}>
					{
						this.state.rows.map((row, index) => {
							return <li key={index} style={{borderBottom:'1px solid #ccc', padding: '5px', cursor: 'pointer'}} 
							onClick={ this.onRowSelect.bind(this, index)}>{`${selectedField.itemProperties.label.text} - Row ${index+1}`}</li>
						})
					}
					</ol></div>
			}
			{
					this.state.rowIndex != null && <div style={{position: 'fixed', top: `${mousePos.y}px`, border:'1px solid #ccc', maxHeight: '300px', overflowY: 'auto',
					left: `${mousePos.x+this.state.targetWidth+this.state.nextTargetWidth}px`, background: '#fff',  zIndex: 9999}}>
					<ol style={{listStyle:'none', padding: 0, margin: 0}}>
					{
						this.state.columns && this.state.columns.map((column, index) => {
							return <li key={index} style={{borderBottom:'1px solid #ccc', padding: '5px', cursor: 'pointer'}} 
							onClick={ this.onColumnSelect.bind(this, column)}>{column.config.header}</li>
						})
					}
					</ol></div>
			}
			{
				selectedItem != null && <div style={{position: 'fixed', top: `${mousePos.y}px`, border:'2px solid #666', boxShadow: '4px 4px #aaa', minWidth:'350px', height: 'auto',
				left: `${mousePos.x}px`, transition: 'width 2s, height 4s', transitionDelay: '2s', background: '#fff', zIndex: 9999, padding: 10}}>
					<div style={{cursor: 'move'}} className="mb-2 d-flex justify-content-start" onMouseDown ={this.onBoxMove} ><i className="fa fa-arrows" aria-hidden="true"></i></div>
					<div><Input type="textarea"  name="itemvalue" value={selectedItem} onChange={this.valueChange}/>
					{
						hasError && <FormFeedback className='animated fadeIn'><i className="fa fa-exclamation-triangle" aria-hidden="true"></i> {error} </FormFeedback>
					}
					</div>
					<div>
					{
						selectedItem != null && <Select autoFocus className='mt-2' style={{width: '100%'}} onChange={this.itemSelect} isSearchable={true} openOnFocus={true}
							value={selectedField} options={fieldList} valueKey='name' labelKey='name' />
					}
					{
						this.state.rows && this.state.rows.length > 0 && <Row><Col md='10'><Select className='mt-2' style={{width: '100%'}} onChange={this.onRowSelect} isSearchable={true} openOnFocus={true}
							value={selectedRow} options={this.state.rows} valueKey='index' labelKey='rowItem' /></Col>
							<Col md='2'><Button className='mt-2' size='sm' onClick={this.addRow.bind(this)}><i className="fa fa-plus" aria-hidden="true"></i></Button></Col></Row>
					}
					{
						this.state.columns && this.state.columns.length > 0 && <Select className='mt-2' style={{width: '100%'}} onChange={this.onColumnSelect.bind(this)} isSearchable={true} openOnFocus={true}
							value={selectedCol} options={this.state.columns} valueKey='classProp' labelKey='header' />
					}
					</div>
					<div className="mt-2 d-flex justify-content-end">
						<Button onClick={this.setFieldValue.bind(this)} className='btn-info mr-1' > Save</Button>
						<Button onClick={this.closeList} className='btn-light'> Cancel</Button>
					</div>
				</div>
			}
		<div className="ml-2" >
				<Label>
					<Input onChange={this.showOcrData.bind(this)} type="checkbox" checked={this.state.showOcrData} />{' '}
					Show OCR Data
				</Label>
				{
					loading?<div style={{textAlign: 'center'}}>Loading OCR data...</div>:null
				}
				<div className="pull-right">
				<Button style={{margin:'2px'}} onClick={() => this.setState({currentScale: this.state.currentScale + 0.25})}>+</Button>
				<Button style={{margin:'2px'}} onClick={() => this.setState({currentScale: this.state.currentScale - 0.25})}>-</Button>
				</div>
				</div>
			</div>		
	}
}

const mapStateToProps = (state, ownProps) => {
  let formData = state.getIn(['form', ownProps.form, 'values'])
  
  return {
    formData
  }
}

export default connect(mapStateToProps, {})(toJS(OCRElement));