import React, { Component } from 'react';
import AssignValueTable from './AssignValueTable';
import AssignValueModal from './AssignValueModal';

class AssignValue extends Component {  
    render() {
        return (
            <AssignValueModal {...this.props} />
        );
    }
}

export default AssignValue;