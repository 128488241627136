import client from '../client';
import config from '../config';
import { showLoading, hideLoading } from 'react-redux-loading-bar';

export const FETCH_ALL_REPORTS = 'FETCH_ALL_REPORTS';
export const FETCH_ALL_REPORTS_SUCCESS = 'FETCH_ALL_REPORTS_SUCCESS';
export const FETCH_ALL_REPORTS_FAILURE = 'FETCH_ALL_REPORTS_FAILURE';

export const FETCH_REPORT_DATA = 'FETCH_REPORT_DATA';
export const FETCH_REPORT_DATA_SUCCESS = 'FETCH_REPORT_DATA_SUCCESS';
export const FETCH_REPORT_DATA_FAILURE = 'FETCH_REPORT_DATA_FAILURE';

export const getAllReports = () => {
  return dispatch => {
    dispatch({
      type: FETCH_ALL_REPORTS,
      payload: {},
    });
    dispatch(showLoading());
    client()
      .post(`${config.authAPI}/reports/all`)
      .then(res => {
        dispatch({
          type: FETCH_ALL_REPORTS_SUCCESS,
          payload: res.data,
        });
        dispatch(hideLoading());
      })
      .catch(error => {
        dispatch({
          type: FETCH_ALL_REPORTS_FAILURE,
          payload: {
            hasError: true,
            error,
          },
        });
        dispatch(hideLoading());
      });
  };
};

export const getReportData = payload => {
  return dispatch => {
    return client().post(`${config.authAPI}/reports/data`, payload);
  };
};

export const getReportDataByName = payload => {
  let endPoint = `${config.authAPI}/reports/databyname`;
  return dispatch => {
    return client().post(endPoint, payload);
  };
};

export const getReportDataByAction = payload => {
  let endPoint = `${config.authAPI}/reports/databyaction`;
  return dispatch => {
    return client().post(endPoint, payload);
  };
};
