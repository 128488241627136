import React, { Component } from 'react';
import { Nav, NavItem, NavLink, DropdownToggle, Dropdown, DropdownMenu, DropdownItem, UncontrolledDropdown } from 'reactstrap';
import { NavLink as RRNavLink } from 'react-router-dom';
import PropTypes from 'prop-types';

class WorkflowsNav extends Component {
    componentDidMount() {
        this.props.fetchWorkflows(this.props.user);
    }
    render() {
        const { router } = this.context;
        const { items, endCurrentBaseWorkflow, resolveIsActive, isDesktop, baseWorkflowRoute, renderTop } = this.props;
        if (!items) return null;

        const listStyle = isDesktop ? {
            width: `${100 / items.length}%`
        } : {};
        return (
            <ul className="worklow-menu-items">
                {
                    items.map((item, index) => {
                        if(item.hideInMenu) return null;
                        const hasChildren = item.childMenus && item.childMenus.length > 0;
                        const routerBase = router.route.match.path;
                        const parentPath = `${baseWorkflowRoute}/${item.friendlyUrl.toLowerCase()}`;
                        const { title } = item;
                        return hasChildren ? <li className="worklow-menu-item parent" key={index} style={listStyle}>
                                <a className="worklow-menu-item-link">
                                    {title}
                                </a>
                                <ul className="workflow-menu-parent-items">
                                    {
                                        item.childMenus.map((child, childIndex) => {
                                            if (child.hideInMenu) return null;
                                            const path = `${baseWorkflowRoute}/${child.friendlyUrl.toLowerCase()}`;
                                            const childPath = `${path}`;
                                            return <li className="worklow-menu-item child" key={childIndex}>
                                                <a href={childPath} className="worklow-menu-item-link">
                                                    {child.title}
                                                    <span className="right-icon-link">
                                                        <i className='fa fa-arrow-circle-o-right'></i>
                                                    </span>
                                                </a>
                                            </li>
                                        })
                                    }
                                </ul>
                            </li> : <li className="worklow-menu-item parent" key={index} style={listStyle}>
                                        <a href={parentPath} className="worklow-menu-item-link">
                                            {title}
                                            <span className="right-icon-link">
                                                <i className='fa fa-arrow-circle-o-right'></i>
                                            </span>
                                        </a>
                                    </li>
                    })
                }
            </ul>
        );
    }
}

const RenderClickableParent = (props) => {
    const { index, parentPath, title, endCurrentBaseWorkflow, resolveIsActive, item } = props;
    if (item.hideInMenu) return null;
    if (!item.workflowCode) return null;
    return (
        <NavItem key={index}>
            <RRNavLink isActive={resolveIsActive} className='nav-link' onClick={endCurrentBaseWorkflow} to={parentPath}>
                <span>
                    {title}
                </span>
            </RRNavLink>
        </NavItem>
    )
}

const isLinkActive = (parentPath) => {
    return window.location.pathname.indexOf(parentPath) > -1;
}


const RenderParentDropdown = (props) => {
    const { index, parentPath, title, item, routerBase, endCurrentBaseWorkflow, resolveIsActive } = props;
    return (
        <NavItem key={index}>
            <UncontrolledDropdown>
                <DropdownToggle nav className={isLinkActive(parentPath) ? 'active' : ''}>
                    <span>
                        {title} <i style={{ position: 'relative', top: '-1px' }} className="fa fa-chevron-down ml-1" aria-hidden="true"></i>
                    </span>
                </DropdownToggle>
                <DropdownMenu>
                    {
                        item.childMenus.map((child, childIndex) => {
                            if (child.hideInMenu) return null;
                            const path = `${routerBase}/${child.friendlyUrl.toLowerCase()}`;
                            const childPath = `${path}`;
                            return <DropdownItem isActive={resolveIsActive} onClick={endCurrentBaseWorkflow} tag={RRNavLink} key={childIndex} to={childPath} key={childIndex}>
                                {child.title}
                            </DropdownItem>
                        })
                    }
                </DropdownMenu>
            </UncontrolledDropdown>
        </NavItem>
    )

}

WorkflowsNav.contextTypes = {
    router: PropTypes.object
}

export default WorkflowsNav;