import React, { Component } from 'react';
import PropTypes from 'prop-types';
import CardLayout from '../CardLayout';
import { Label, Button, ButtonGroup } from 'reactstrap';
import ActionContainer from '../../../containers/workflow/ActionContainer';
import DataListStore from '../../workflow/operations/filters/DataListStore';
import DataListWrapper from '../../workflow/operations/filters/DataListWrapper';
import FilterWrapperHOC from '../../workflow/operations/filters/FilterWrapperHOC';

const findActionByCode = (actions, code) => {
    let matchedAction = null;
    for (let i = 0; i < actions.length; i += 1) {
        if (actions[i].actionProperties.generatedActionCode && actions[i].actionProperties.generatedActionCode === code) {
            matchedAction = actions[i]
        }
    }
    return matchedAction;
}

class TypeModelDeck extends Component {
    constructor(props) {
        super(props);
        this._dataList = new DataListStore(props.cards);
        this.state = {
            filteredDataList: this._dataList
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (this.state.filteredDataList.getSize() === nextState.filteredDataList.getSize()) {
            return false;
        }
        return true;
    }
    
    getCompatibleInlineActions(actions, itemProperties) {
        const { allowDeletion, allowDeletionActionId } = itemProperties;
        if (!allowDeletion) return [];
        const action = findActionByCode(actions, allowDeletionActionId);
        return action ? [action] : [];
    }
    render() {
        const { workflow, cards, label, inlineActions, viewMode, typeModelViewMode, onChangeTypeModelViewMode, layoutItem: { itemProperties } } = this.props;
        const { filteredDataList } = this.state;
        return (
            <div>
                <div className='d-flex justify-content-between mb-2'>
                    <div>
                        <Label className='text-muted text-capitalize mb-0'>{label.text}</Label>
                    </div>
                    <div>
                        {
                            itemProperties && itemProperties.allowAdditionalTypeModelViewMode && <ButtonGroup className="mr-4">
                            <Button onClick={onChangeTypeModelViewMode.bind(this, typeModelViewMode.value)} color='primary' outline={viewMode !== typeModelViewMode.value} size="sm">
                                <i className="fa fa-th-list" aria-hidden="true"></i>
                            </Button>
                            <Button onClick={onChangeTypeModelViewMode.bind(this, itemProperties.additionalTypeModelViewMode.value)} color='primary' outline={viewMode !== itemProperties.additionalTypeModelViewMode.value} size="sm">
                                <i className="fa fa-th-large" aria-hidden="true"></i>
                            </Button>
                        </ButtonGroup>
                        }
                        <TypeModelCreateAction workflow={workflow} itemProperties={itemProperties} actions={workflow.currentItem.inlineAction} />
                    </div>
                </div>
                <CardLayout 
                    cards={filteredDataList}
                    workflowId={workflow.workflowModel}
                    inlineActions={inlineActions}
                    cardConfig={itemProperties.cardConfig ? itemProperties.cardConfig.config : null}
                    workflow={workflow}
					editActionId={itemProperties.allowEditing ? itemProperties.generatedActionCodeForAllowEditing : null}
                />

            </div>
        );
    }
}

const TypeModelCreateAction = (props) => {
    const { actions, itemProperties, workflow } = props;
    const { allowAddition, generatedActionCodeForAllowAddition } = itemProperties;
    if (!allowAddition) return null;
    const action = findActionByCode(actions, generatedActionCodeForAllowAddition);
    if (!action) return null;
    return (
        <ActionContainer workflow={workflow} action={action} />
    )
}

TypeModelDeck.defaultProps = {
    cards: []
}

TypeModelDeck.propTypes = {
    workflow: PropTypes.object,
    cards: PropTypes.array,
    label: PropTypes.object
};

export default TypeModelDeck;
