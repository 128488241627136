import React, { Component } from 'react';
import { Input } from 'reactstrap';
import { withScriptjs, withGoogleMap, GoogleMap, Marker, Size, Point,InfoWindow } from "react-google-maps";
import { ModelPropertiesParser } from '../../commons/modelPropertiesParser';
import { SearchBox } from "react-google-maps/lib/components/places/SearchBox";
import { Field } from 'redux-form';
import * as CardTemplates from '../../templates/card/index';


class MapView extends Component {
    constructor(props) {
        super(props);
        this.state = {
          bounds: null,
          center: {
            lat: 12.92, lng: 77.546
          },
          defaultCenter: {
            lat: 12.92, lng: 77.546
          },
          markers: [],
          refs: {},
          places: null,
          showMarkerIndex: -1
        }
        this.getGeoLocation();
      }

    getGeoLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                position => {
                    this.setState({center: { lat: position.coords.latitude,
                        lng: position.coords.longitude }});
                }
            )
        } else {
            console.log('Navigator geo location not found');
        }
    }

    onMapMounted = ref => {
        const refs = this.state.refs;
        refs.map = ref;
        this.setState({
          refs
        });
      }
    
      onBoundsChanged = () => {
          if(window.mapsBoundChange) {
              clearTimeout(window.mapsBoundChange);
          }
          window.mapsBoundChange = setTimeout(() => {
            const bounds = this.state.refs.map.getBounds();
            const center = this.state.refs.map.getCenter();
            const distance = this.getDistanceFromLatLonInKm(bounds.getNorthEast().lat(), bounds.getNorthEast().lng(), 
                            center.lat(), center.lng() );

            if(!this.previousBounds) {
                this.previousBounds = bounds;
                return;
            } 
            this.previousBounds = bounds;
            const filterValue = [];
            filterValue.push(`${center.lat()}`);
            filterValue.push(`${center.lng()}`);
            filterValue.push(`${Math.round(distance)}`);
            const filterConfig = {
                filter: {
                    targetClassProperty: this.props.config.laneEnumerator.classProperty,
                    leafType: this.props.config.laneEnumerator.type
                },
                filterIndex: 0
            };
            this.props.onFilterChange(filterConfig, filterValue);
            this.props.onFiltersApplied();
        }, 500);

      }

      onSearchBoxMounted = ref => {
        const refs = this.state.refs;
        refs.searchBox = ref;
        this.setState({
          refs
        });
      }
      onPlacesChanged = () => {
        const places = this.state.refs.searchBox.getPlaces();
        const center = {
          lat: places[0].geometry.location.lat(),
          lng: places[0].geometry.location.lng()
        }
        this.setState({
          center,
          places
        });
      }

      onMarkerClick = (index) => {
          this.setState({ showMarkerIndex: index});
      }

      onMarkerClose = (index) => {
        this.setState({showMarkerIndex: -1});
    }


    componentWillReceiveProps(nextProps) {
         const {  value, classProperty, workflowData, laneKey, locationConfig, config: {card} } = nextProps;
      const newValue = ModelPropertiesParser('{{'+laneKey+'}}', workflowData && workflowData[0]);
      if(newValue  ){
        let newValues = newValue.split(",")
        let lat = newValues[0];
        let long = newValues[1];
        lat = parseFloat(lat)
        long = parseFloat(long)
        if(this.state.defaultCenter.lat == this.state.center.lat){
          this.setState({center: { lat: lat,
            lng: long }});
        }
      }
    }

    getMarkers(workflowData, laneKey, cardConfig) {
        const markerData = (workflowData || []).map((data, index) => {
            let pos = data[laneKey];
            if(pos) {
                pos = pos.split(',');
                return {center: {lat: parseFloat(pos[0]), lng: parseFloat(pos[1])}, id: data.id, title: getCardData(cardConfig.title, data), data };
            }
            return {center: {lat: 0, long: 0}, id: data.id, title: getCardData(cardConfig.title, data), data };
        });
        return markerData;
    }

    getDistanceFromLatLonInKm(lat1, lon1, lat2, lon2) {
        var R = 6371; // Radius of the earth in km
        var dLat = this.deg2rad(lat2-lat1);  // deg2rad below
        var dLon = this.deg2rad(lon2-lon1); 
        var a = 
          Math.sin(dLat/2) * Math.sin(dLat/2) +
          Math.cos(this.deg2rad(lat1)) * Math.cos(this.deg2rad(lat2)) * 
          Math.sin(dLon/2) * Math.sin(dLon/2)
          ; 
        var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a)); 
        var d = R * c; // Distance in km
        return d;
      }
      
      deg2rad(deg) {
        return deg * (Math.PI/180)
      }

    render() {
        const {  value, classProperty, workflowData, laneKey, locationConfig, config: {card} } = this.props;
        const markers = this.getMarkers(workflowData, laneKey, card);
        let tempCardConfig = card;
        tempCardConfig.config = {hasRating: false, cardTitle: card.title, cardSubtitle: card.label};
        return <div style={{ height: `${window.innerHeight - 70}px`, 
            width: '100%', 
            display: 'flex', 
            flexFlow: 'row nowrap', 
            justifyContent: 'center', 
            padding: 0 }} className="location">
            <LocationMap 
            googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyBfhuMCKv2c1jH_lJcxJ1iNjg0mmHXY_zU&v=3.exp&libraries=geometry,drawing,places"
            loadingElement={<div style={{ height: `100%` }} />}
            containerElement={<div style={{ width: `100%`,marginLeft:`0` }} />}
            mapElement={<div style={{ height: `100%`, width: `100%` }} />}
            onMapMounted={this.onMapMounted}
            onBoundsChanged={this.onBoundsChanged}
            onSearchBoxMounted={this.onSearchBoxMounted}
            onPlacesChanged={this.onPlacesChanged}                
            center={this.state.center}
            panToBounds={() => {
              this.state.refs.map && this.state.refs.map.panToBounds(this.state.bounds);
            }}
            markers = {markers}
            classProperty={classProperty}
            value={value}
            markerIcon={locationConfig && locationConfig.markerIcon}
            onMarkerClick={this.onMarkerClick}
            onMarkerClose={this.onMarkerClose}
            cardConfig={tempCardConfig}
            showMarkerIndex={this.state.showMarkerIndex}
            inlineActions={this.props.workflow.currentItem.inlineAction}
            workflow={this.props.workflow}
          />
          
          </div>
      }
}

const LocationMap = withScriptjs(withGoogleMap((props) => {
    const markerIcon = {
        url: props.markerIcon,
        scaledSize: new window.google.maps.Size(50, 50),
        origin: new window.google.maps.Point(0,0), // origin
        anchor: new window.google.maps.Point(0, 0) // anchor
    }
    const config = props.cardConfig;
    const SimpleCard = CardTemplates.SIMPLECARD;
    const ImageCard = CardTemplates.IMAGECARD;
    return (
        <GoogleMap
          options={{
            mapTypeControl: false,
            scrollwheel: false
          }}
          ref={props.onMapMounted}
          defaultZoom={15}
          center={props.center}
          onBoundsChanged={props.onBoundsChanged}
          panToBounds={props.fitBounds}
        >
        <SearchBox
          ref={props.onSearchBoxMounted}
          bounds={props.bounds}
          controlPosition={1}
          onPlacesChanged={props.onPlacesChanged}
          options={{
            enableEventPropagation: false
          }}
        >
          <Field
              name={props.classProperty}
              component={renderInput}
              type="text"
              value={props.value}
              placeholder={props.placeholder}
          />
        </SearchBox>
        {props.markers.map((marker, i) => <Marker animation={window.google.maps.Animation.DROP} key={marker.id} 
            icon={markerIcon} position={marker.center} title={marker.title} onClick={() => props.onMarkerClick(i)} >
        {props.showMarkerIndex === i && (
          <InfoWindow onCloseClick={() => props.onMarkerClose(i)}>
            {
              config.image && config.image.path?
              <ImageCard
                image={getCardData(config.image, marker.data)}
                title={getCardData(config.title, marker.data)}
                subtitle={getCardData(config.label, marker.data)}
                description={getCardData(config.description, marker.data)}
                label={getCardData(config.cardLabel, marker.data)}
                data={marker.data}
                cardConfig={props.cardConfig}
                imageCardStyle={{
                  width: '320px'
                }}
                {...props}
              />:
              <SimpleCard
                title={getCardData(config.title, marker.data)}
                subtitle={getCardData(config.label, marker.data)}
                description={getCardData(config.description, marker.data)}
                label={getCardData(config.cardLabel, marker.data)}
                data={marker.data}
                cardConfig={props.cardConfig}
                {...props}
            />
            }
          </InfoWindow>
        )}
                    </Marker>)}
      </GoogleMap>
      )
    }
  ))

  const renderInput = (props) => <Input
  type="text"
  style={{
    width: '40%',
    marginLeft: '5%',
    marginTop: '40px'
  }}
  placeholder={'Please enter the place name'}
/>;

const getCardData = (key, data) => {
    let _key = null;
    if (typeof key === 'string') {
        _key = key;
    }
    if (typeof key === 'object' && key.hasOwnProperty('path')) {
        _key = key.path;
    }
    if (_key !== null) {
        return ModelPropertiesParser(_key, data);
    }
    return 'Not Available';
}

  export default MapView;