import React, { Component } from 'react';
import {
  FormGroup,
  Input,
  Label,
  FormFeedback,
  Row,
  Col,
  Button,
} from 'reactstrap';
import client from '../../../client';
import config from '../../../config';
import GoogleLogin from 'react-google-login';

class GoogleLoginComp extends Component {
  constructor(props) {
    super(props);
  }
  responseGoogle = response => {
    console.log(response);
    let token = response.accessToken;
    let user = response.profileObj;
    if (token) {
      const payload = {
        token,
        requiredRole: this.props.requiredRole,
      };
      client()
        .post(`${config.publicAPI}/googleLogin`, payload)
        .then(res => {
          const { authenticationCallback } = this.props;
          authenticationCallback();
        })
        .catch(error => {});
    }
  };
  render() {
    const { clientId,label } = this.props;
    return (
      <GoogleLogin
        clientId={
          clientId
            ? clientId
            : '996886828755-qq0pfj5307tat8ftd2c5sdc7d047atts.apps.googleusercontent.com'
        }
        //buttonText="LOG IN WITH GOOGLE"
        onSuccess={this.responseGoogle}
        onFailure={this.responseGoogle}
        render={renderProps => (
          <button
            onClick={renderProps.onClick}
            className='google-button-class w-100'
          >
            <i className='fa fa-google-plus'></i> {label?label:"LOGIN WITH GOOGLE"}
          </button>
        )}
      />
    );
  }
}

export default GoogleLoginComp;
