import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Table, Column, Cell } from 'fixed-data-table-2';
import { UncontrolledTooltip } from 'reactstrap';
import ActionCell from './helpers/ActionCell';
import * as CellTypes from './helpers/cells/index';
import { WIDTHS } from './helpers/TypeColumnWidths';
import { Alert } from 'reactstrap';
import Measure from 'react-measure';
import NoDataAlertBox from '../../commons/NoDataAlertBox';
import FileDownload from '../FileDownload'
import {
    FileProtectOutlined,
    LoadingOutlined,
    SettingFilled,
    SmileOutlined,
    SyncOutlined,
} from '@ant-design/icons';
import { Space, Table as Table2, Tag, Image, Tooltip, Statistic } from 'antd';
import { ModelPropertiesParser } from '../../commons/modelPropertiesParser';
import DynamicColumnsTable from './TableV2/DynamicTable';
import { calculateColumnsWidth } from './TableV2/DynamicHelper';
import config2 from '../../../config';
import { analyseEvaluatedText } from '../../commons/evaluatedTextVerfier';

class VirtualizedTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            maxHeight: 800,
            dimensions: {
                width: -1,
                height: -1
            }
        }
    }
    componentDidMount() {
        this.setState({ maxHeight: window.innerHeight - 180 });
    }

    componentWillReceiveProps(nextProps) {
        // console.log('nextProps')
    }
    checkColumnsFit(availWidth, columns) {
        let calcWidth = 0;
        columns.map((col) => {
            const { field: { type } } = col;
            const typeWidth = WIDTHS[type] || { width: 100, flexGrow: 0 };
            calcWidth = calcWidth + typeWidth.width;
        });
        return calcWidth < availWidth;
    }

    checkInlineNonSubworkflowAction = (actions) => {
        let hasInlineNonSubWorkflowAction = 0;
        actions.map((action) => {
            hasInlineNonSubWorkflowAction = action.actionProperties.workflow ? action.code === "DeleteActionProcessor" ? hasInlineNonSubWorkflowAction + 1 : hasInlineNonSubWorkflowAction : hasInlineNonSubWorkflowAction + 1;
            if (!hasInlineNonSubWorkflowAction && action.actionProperties.actionName.startsWith("ALLOW_DELETION_GENERATED")) {
                hasInlineNonSubWorkflowAction = hasInlineNonSubWorkflowAction + 1;
            }
        });
        return hasInlineNonSubWorkflowAction;
    }

    checkInlineActions = (actions) => {
        let hasInlineAction = 0;
        actions.map((action) => {
            hasInlineAction = action.actionProperties.systemGenerated ? hasInlineAction : hasInlineAction + 1;
        });
        return hasInlineAction;
    }

    sortByColumn = (classProperty) => {
        this.props.sortByClassProperty && this.props.sortByClassProperty(classProperty);
    }

    render() {
        const { rows, selectedTemplate, columns, inlineActions, workflow, canActOnMultipleItems, typeModelName, tableStyleConfig, sortByClassProperty, dateformat, menuTypeInlineAction,
            sorting, workflow: { currentItem: { allowDownloadAsPdf, duplicateRowsInOneToMany, operation, name, pageHeaderConfig } }, isReadOneTable, noDataText, noDataTextHeading } = this.props;


        const { allDataDownload, allData, workflow: { currentItem: { layoutProperties } } } = this.props
        const { width } = this.state.dimensions;
        if (!rows || !columns) return null;
        if (rows.getSize() === 0) {
            return <NoDataAlertBox typeModelName={typeModelName || workflow.currentItem.typeModel.name} noDataText={noDataText} noDataTextHeading={noDataTextHeading} />
        }
        const windowHeight = window.innerHeight > 300 ? window.innerHeight : 300;
        const { maxHeight } = this.state;
        const rowHeight = 50;
        const actionColumnWidth = layoutProperties && layoutProperties.actionColumnWidth ? parseInt(layoutProperties.actionColumnWidth) : 200;
        const tableWidth = width;
        const hasInlineNonSubWorkflowAction = this.checkInlineNonSubworkflowAction(inlineActions);
        const hasInlineActions = this.checkInlineActions(inlineActions);
        const availableContentColumnsWidth = hasInlineActions ? (tableWidth - actionColumnWidth) : tableWidth;
        const numColumns = columns.length;
        const hasExtraSpace = this.checkColumnsFit(availableContentColumnsWidth, columns);
        const RowSelectorCell = CellTypes.ROWSELECTORCELL;
        const fileName = pageHeaderConfig && pageHeaderConfig.isConfigured ? pageHeaderConfig.modelSelector : name;
        // const tableHeight = this.state.dimensions.top ? windowHeight - this.state.dimensions.top - 60 : rowHeight * rows.getSize() + 60;
        const tableHeight = rowHeight * rows.getSize() + 60;
        let allRows = {};
        allRows['_rows'] = allData;


        // TEMPLATE 2 COnfigs
        
        const bordered = tableStyleConfig && tableStyleConfig.tableBorder
        const title = tableStyleConfig && tableStyleConfig.tableHeading
        const footer = tableStyleConfig && tableStyleConfig.tableFooter
        const pagination = tableStyleConfig && tableStyleConfig.tableCustomPageSize ? parseInt(tableStyleConfig.tableCustomPageSize ): null
        const scroll = tableStyleConfig && tableStyleConfig.tableHeaderFixHeight ? parseInt(tableStyleConfig.tableHeaderFixHeight ): null
        const size = tableStyleConfig && tableStyleConfig.tableSize ? tableStyleConfig.tableSize.value : null 
        const topPostion = tableStyleConfig && tableStyleConfig.tablePaginationPosition ? tableStyleConfig.tablePaginationPosition.value : null
 

        let columnsAntd = columns;
        columnsAntd && columnsAntd.map((column, columnIndex) => {
            const collection = column.field.collection;

            let renderCell = (text) => text ? analyseEvaluatedText(text) : <span>Not Available</span>
            const { field: { type }, config } = column;
            const columnKey = column.field.classProperty
            if (type === 'FILE') {
                renderCell = (text) => text ? <a href={(text && !text.startsWith('http')) ? (config2.assetsBasePath + text) : text} target="_blank" >
                    <Tooltip title={'Download'} placement="rightTop">
                        <FileProtectOutlined style={{ fontSize: 30 }} />
                    </Tooltip>
                </a> : <span>Not Available</span>
            }
            if (columnKey.startsWith("$$SELF$$")) {


                if (columnKey.startsWith("$$SELF$$") && config && config.componentShowAs && config.componentShowAs.value === 'number') {
                    const columnNumberConfig = config.columnNumberConfig;
                    renderCell = (text) => text ? <Statistic title={null} value={text} /> : <span>Not Available</span>
                    if (columnNumberConfig) {
                        const afterIcon = columnNumberConfig.afterIcon;
                        const suffixIcon = `fa ${afterIcon} ml-1`
                        const beforeIcon = columnNumberConfig.beforeIcon;
                        const prefixIcon = `fa ${beforeIcon} mr-1`
                        const formatter = columnNumberConfig.formatter;
                        const precision = columnNumberConfig.precision;
                        const suffix = columnNumberConfig.suffix;
                        const valueStyle = columnNumberConfig.valueStyle;
                        const fontSize = valueStyle && valueStyle.fontSize;
                        const color = valueStyle && valueStyle.color;

                        renderCell = (text) => text ? <Statistic
                            title={null}
                            value={text}
                            valueStyle={valueStyle ? { fontSize: parseInt(fontSize), color: color ? color : "#000" } : {}}
                            precision={precision}
                            prefix={<i className={prefixIcon} aria-hidden="true"></i>}
                            suffix={<i className={suffixIcon} aria-hidden="true"></i>}
                        /> : <span>Not Available</span>
                    }


                }
            }
            if (type === 'IMAGE') {
                const columnImageConfig = config.columnImageConfig;
                renderCell = (text) => text ? <a href={(text && !text.startsWith('http')) ? (config2.assetsBasePath + text) : text} target="_blank" >
                    <img src={`${config2.assetsBasePath}${text}`} height={50} width={50} alt="TableImage" />
                </a> : <span>Not Available</span>
                if (columnImageConfig) {
                    const height = columnImageConfig.height;
                    const width = columnImageConfig.width;
                    const borderRadius = columnImageConfig.borderRadius;
                    renderCell = (text) => text ? <a href={(text && !text.startsWith('http')) ? (config2.assetsBasePath + text) : text} target="_blank" >
                        <img src={`${config2.assetsBasePath}${text}`} height={height ? parseInt(height) : 50} width={width ? parseInt(width) : 50} style={borderRadius ? { borderRadius: parseInt(borderRadius) } : {}} alt="TableImage" />
                    </a> : <span>Not Available</span>
                }

            }

            if (type === 'DROPDOWN' || (type === 'TYPE' && !columnKey.startsWith("$$SELF$$") && !collection)) {
                let color = config && config.columnDropdownConfig && config.columnDropdownConfig.color ? config.columnDropdownConfig.color : 'volcano'
                let icon = config && config.columnDropdownConfig && config.columnDropdownConfig.beforeIcon ? config.columnDropdownConfig.beforeIcon : null
                const prefixIcon = `fa ${icon} mr-1`
                renderCell = (text) => text ? <Tag icon={<i className={prefixIcon} aria-hidden="true"></i>} color={color}>
                    {text.toUpperCase()}
                </Tag> : <span>Not Available</span>
            }

            if ((type === 'TYPE' && !columnKey.startsWith("$$SELF$$") && collection)) {
                // let color = config && config.columnDropdownConfig &&  config.columnDropdownConfig.color? config.columnDropdownConfig.color :'volcano'
                let colors = ['magenta',
                    'orange',
                    'gold',
                    'lime',
                    'green',
                    'cyan',
                    'blue',
                    'geekblue',
                    'purple']
                let icon = config && config.columnDropdownConfig && config.columnDropdownConfig.beforeIcon ? config.columnDropdownConfig.beforeIcon : null
                const prefixIcon = `fa ${icon} mr-1`

                renderCell = (text) => (text ? text.map((value, index) => {
                    return <Tag key={index} icon={<i className={prefixIcon} aria-hidden="true"></i>} color={colors[index]}>
                        {value && value.toUpperCase()}
                    </Tag>
                }) : <span>Not Available</span>)
            }



            let columnModelSelector = column.config.modelSelector
            columnModelSelector && columnModelSelector.replace('{{', '');
            columnModelSelector && columnModelSelector.replace('}}', '');
            let fixedColumnAlignment = config.fixedColumnAlignment ? config.fixedColumnAlignment : null;


            columnsAntd[columnIndex] = {
                title: column.config.header,
                dataIndex: column.field.type === 'TYPE' ? column.field.classProperty.startsWith("$$SELF$$") ? column.field.classProperty : column.field.classProperty + '_type' : column.field.classProperty,
                modelSelector: column.config.modelSelector,
                key: column.field.name,
                render: renderCell,
                fixed: fixedColumnAlignment,

                ...column
            }
        })


        let dataNew = rows['_rows'];


        dataNew && dataNew.map((row, rowIndex) => {
            dataNew[rowIndex] = {
                key: rowIndex,
                ...row
            }
        })

        columnsAntd && columnsAntd.map((column, columnIndex) => {
            const columnKey = column.field.classProperty;
            const fieldType = column.field.type;
            const collection = column.field.collection;



            if (fieldType === 'TYPE' && columnKey.startsWith("$$SELF$$")) {
                dataNew && dataNew.map((row, rowIndex) => {
                    let typeModeldata = dataNew[rowIndex];
                    let text = ModelPropertiesParser(
                        column.config.modelSelector,
                        typeModeldata
                    );
                    let obj = {}
                    obj[columnKey] = text;
                    dataNew[rowIndex] = {
                        ...obj,
                        ...row
                    }
                })
            } else if (fieldType === 'TYPE' && !collection) {

                let newDatKey = column.field.classProperty + '_type'

                dataNew && dataNew.map((row, rowIndex) => {
                    let text = ModelPropertiesParser(
                        column.config.modelSelector,
                        rows.getObjectAt(rowIndex)[columnKey]
                    );
                    let obj = {}
                    const analysedText = analyseEvaluatedText(text);
                    obj[newDatKey] = text;
                    dataNew[rowIndex] = {
                        ...obj,
                        ...row
                    }
                })
            } else if (fieldType === 'TYPE' && collection) {

                let newDatKey = column.field.classProperty + '_type'


                dataNew && dataNew.map((row, rowIndex) => {
                    const models = rows.getObjectAt(rowIndex)[columnKey];
                    let data = []
                    models && models.map((model, index) => {
                        let text = ModelPropertiesParser(
                            column.config.modelSelector,
                            model
                        );
                        data.push(text)
                    });
                    let obj = {}
                    obj[newDatKey] = data;
                    dataNew[rowIndex] = {
                        ...obj,
                        ...row
                    }
                })
            }

        })


        const onChange = (pagination, filters, sorter, extra) => {
            console.log('params', pagination, filters, sorter, extra);
        };


        let columnsNew = columnsAntd

        const maxWidthPerCell = 600;
        const tableV2Height = 500;
        const rowKey = "id";

        // This helper function helps to calculate the width for each column
        // based on all table cells - column cell and source cell
        const dataTable = calculateColumnsWidth(columnsNew, dataNew, maxWidthPerCell);


        if (selectedTemplate === 'Template2') {
            return (
                <Measure
                    bounds
                    onResize={(contentRect) => {
                        this.setState({ dimensions: contentRect.bounds })
                    }}
                >
                    {({ measureRef }) =>
                        <div ref={measureRef} className='mb-2'>

                            {(allowDownloadAsPdf && operation == 'READALL') || isReadOneTable ? <div className='d-flex justify-content-end mb-3'>
                                <FileDownload rows={allDataDownload && allData.length > 0 ? allRows : rows} columns={columns} name={fileName} dateformat={dateformat} duplicateRowsForOneToMany={workflow.currentItem.layoutProperties.duplicateRowsInOneToMany} /></div> : null}
                            {/* <DynamicColumnsTable
                            columns={dataTable.columns}
                            height={tableHeight}
                            rowKey={rowKey}
                            source={dataTable.source}
                            width={dataTable.tableWidth}
                            actions={inlineActions}
                            rows={rows}
                            columnKey='id'
                            workflow={workflow}
                            rowHeight={rowHeight}
                            colWidth={actionColumnWidth}
                            menuTypeInlineAction={menuTypeInlineAction}
                        /> */}
   
                            <Table2 size={size ? size : 'large'} pagination={pagination?{ pageSize: pagination,position: [topPostion? topPostion:'bottomRight'] }:{}} scroll={scroll? { x: true, y: scroll }:{x:true}} bordered={bordered} title={() => title ? title : null} footer={() => footer? footer : null}  columns={columnsNew} dataSource={dataNew} onChange={onChange} />

                        </div>
                    }
                </Measure>
            )
        }

        return (
            <Measure
                bounds
                onResize={(contentRect) => {
                    this.setState({ dimensions: contentRect.bounds })
                }}
            >
                {({ measureRef }) =>
                    <div ref={measureRef} className='mb-2'>
                        {(allowDownloadAsPdf && operation == 'READALL') || isReadOneTable ? <div className='d-flex justify-content-end mb-3'>
                            <FileDownload rows={allDataDownload && allData.length > 0 ? allRows : rows} columns={columns} name={fileName} dateformat={dateformat} duplicateRowsForOneToMany={workflow.currentItem.layoutProperties.duplicateRowsInOneToMany} /></div> : null}
                        <Table
                            rowHeight={rowHeight}
                            rowsCount={rows.getSize()}
                            headerHeight={50}
                            width={tableWidth}
                            className='neene-table'
                            maxHeight={tableHeight}>
                            {
                                canActOnMultipleItems ? <Column
                                    key={`item_selector_column`}
                                    columnKey='id'
                                    cell={
                                        <RowSelectorCell rows={rows} />
                                    }
                                    width={50}
                                /> : null
                            }
                            {
                                columns.map((column, index) => {
                                    const { field: { type }, config } = column;
                                    const columnWidthCustom = config && config.styles && config.styles.columnWidth ? parseInt(config.styles.columnWidth) : null
                                    const hideColumn = config && config.styles && config.styles.hideColumn ? config.styles.hideColumn : null
                                    let CellByType = CellTypes.SIMPLETEXTCELL;
                                    const WidthByType = WIDTHS[type];
                                    const TypeWidth = columnWidthCustom ? { width: columnWidthCustom, flexGrow: 0 } : WidthByType ? WidthByType : { width: 100, flexGrow: 0 };
                                    if (type === 'TYPE') {
                                        CellByType = CellTypes.TYPE;
                                    }
                                    if (type === 'FILE') {
                                        CellByType = CellTypes.FILE;
                                    }
                                    if (type === 'USER') {
                                        CellByType = CellTypes.USER;
                                    }
                                    if (type === 'DATE' || type === 'DATETIME' || type === 'TIME') {
                                        CellByType = CellTypes.DATE;
                                    }
                                    if (type === 'URL') {
                                        CellByType = CellTypes.URL;
                                    }
                                    if (type === 'LOCATION') {
                                        CellByType = CellTypes.LOCATION;
                                    }
                                    if (type === 'RATING') {
                                        CellByType = CellTypes.RATING;
                                    }
                                    let flexGrow = TypeWidth.flexGrow;
                                    if (hasExtraSpace) {
                                        flexGrow = flexGrow === 0 ? 1 : flexGrow + 1;
                                    }
                                    if (hideColumn) return null;
                                    return <Column
                                        key={`data_column_${index}`}
                                        cellClassName={`cell_class_${index}`}
                                        className={`column_class_${index}`}
                                        header={<Cell
                                            onClick={() => { this.sortByColumn(column.field.classProperty) }}
                                            className='neene-table-header'>
                                            {column.config.header}
                                            {
                                                column.config.sortable && (
                                                    <span className="table-sort-icon">
                                                        <i className={`fa ${column.field.classProperty === sorting.classProperty ? sorting.order === 'ASCENDING' ? 'fa-sort-amount-desc active' : 'fa-sort-amount-asc active' : 'fa-sort'}`}></i>
                                                    </span>
                                                )
                                            }
                                        </Cell>
                                        }
                                        columnKey={column.field.classProperty}
                                        cell={<CellByType workflow={workflow} column={column} rows={rows} config={config} actions={inlineActions} dateformat={dateformat} />}
                                        width={TypeWidth.width}
                                        flexGrow={flexGrow}
                                    />
                                })
                            }
                            {
                                inlineActions.length ? <Column
                                    key={`action_column`}
                                    columnKey='id'
                                    header={<Cell className='neene-table-header'>Actions</Cell>}
                                    cell={<ActionCell
                                        actions={inlineActions}
                                        rows={rows}
                                        workflow={workflow}
                                        rowHeight={rowHeight}
                                        colWidth={actionColumnWidth}
                                        menuTypeInlineAction={menuTypeInlineAction}
                                    />}
                                    width={actionColumnWidth}
                                    fixedRight={true}
                                    flexGrow={1}
                                /> : null
                            }
                        </Table>
                    </div>
                }
            </Measure>
        )
    }
}

VirtualizedTable.defaultProps = {
    rows: null,
    columns: [],
    typeModelName: null
}

VirtualizedTable.propTypes = {
    rows: PropTypes.object.isRequired,
    columns: PropTypes.array.isRequired,
    typeModelName: PropTypes.string
};

export default VirtualizedTable;