import React, { Component } from 'react';
import { Badge, Button } from 'reactstrap';
import { connect } from 'react-redux';
import { toJS } from '../../../containers/to-js';
import { triggerAction } from '../../../actions/workflow-actions';
import TextStyleResolver from '../../elements/TextStyleResolver/index';

const SingleActionMinimalCard = (props) => {
    const { title, subtitle, label, triggerAction, cardConfig } = props;
    return <div className='single-action-minimal-card'>
        <div className='d-flex justify-content-between'>
            <div>
                <div className='mb-1'>
                    <TextStyleResolver config={cardConfig.config.cardTitle} text={title} {...props} />
                </div>
                <div className='card-subtitle text-muted'>
                    <TextStyleResolver config={cardConfig.config.cardSubtitle} text={subtitle} {...props} />
                </div>
            </div>
            <div className='card-label'>
                <Badge color='warning'>{label}</Badge>
            </div>
        </div>
    </div>
}

const mapDispatchToProps = (dispatch) => {
    return {
        triggerAction: (action, tid, workflow) => {
            dispatch(triggerAction(action, tid, workflow));
        }
    }
}

export default connect(null, mapDispatchToProps)(SingleActionMinimalCard);