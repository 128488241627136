import React from 'react';
import { FormGroup, Input, Label, FormFeedback, Row, Col, Button } from 'reactstrap';
import RememberMeComp from '../../AuthHelpers/RememberMeComp';
import { Link } from 'react-router-dom';
class Template3 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      isChecked: false,
      isShowPassword:false
    }
  }

  onLogin = () => {
    const { email, password, isChecked } = this.state;
    const { authenticationCallback, itemProperties, sessionId } = this.props;
  
    const payload = {
      email,
      password
    };
    if (itemProperties && itemProperties.authenticateUsingPlugins) {
      payload['authenticateUsingPlugins'] = true;
      payload['sessionId'] = sessionId;
    }
    this.props.setError(false)
    this.props.login(payload, authenticationCallback);
    if (isChecked && email !== "") {
      localStorage.username = email
      localStorage.password = password
      localStorage.checkbox = isChecked
    }
  }

  togglePasswordVisiblity =()=> {
    this.setState({ isShowPassword: !this.state.isShowPassword });
}
  render() {
    const { label,hasForgotPassword,authTemplate3SideImageUrlComplete,authTemplateTopFormText,companySettings,logoUrlComplete,authTemplateSideImageUrlComplete,authTemplateBackgroundColor,authTemplateFontColor } = this.props;
    const { itemProperties } = this.props;
    console.log(companySettings,"companySettings")
    const rememberMe =
      itemProperties &&
      itemProperties.authConfig &&
      itemProperties.authConfig.rememberMe
    const showPassword =
    itemProperties &&
    itemProperties.authConfig &&
    itemProperties.authConfig.showPassword

    return (
      <div className='authTemplate3'  style={{backgroundColor:authTemplateBackgroundColor? authTemplateBackgroundColor:'#fff',backgroundImage:authTemplateBackgroundColor? authTemplateBackgroundColor:'#fff',color:authTemplateFontColor?authTemplateFontColor:"#000"}}>
          <div className='template3Main'>
                <div className='rightLoginComponentTemplate3'>
                    {true && <div className='template3loginToContiue'><h3 style={{fontWeight:'bold'}} > {authTemplateTopFormText ?authTemplateTopFormText:'LOGIN'} </h3 ></div>}
                <FormGroup className={'formTemplate3'} >
          <Label for={'email'}>Email</Label>
          <Input
            value={this.state.email}
            onChange={(event) => this.setState({ email: event.target.value })}
            onKeyDown={(event) => {
              if (event.key === "Enter") {
                event.preventDefault();
                event.stopPropagation();
                this.onLogin();
              }
            }}
            placeholder={'Enter Email'}
            type={'text'}
          />
        </FormGroup>
        <FormGroup className={'formTemplate3'}>
            <div className='passwordTemplate3'>
          <Label for={'password'}>Password</Label>
         { hasForgotPassword ?  <Link className='forgotPasswordTemplate3' to='/app/forgotpassword'>Forgot password?</Link>:<div></div>}
            </div>
          <Input
            value={this.state.password}
            onChange={(event) => this.setState({ password: event.target.value })}
            onKeyDown={(event) => {
              if (event.key === "Enter") {
                event.preventDefault();
                event.stopPropagation();
                this.onLogin();
              }
            }}
            placeholder={'Enter password'}
            type={this.state.isShowPassword?'text':'password'}
          />
        </FormGroup>
         {showPassword &&   <div className='showPasswordTemplate3'>
            <input type="checkbox" checked={this.state.isShowPassword} className="showPassword-checkbox" style={{marginRight:12}} name="lsRememberMe" onChange={this.togglePasswordVisiblity} />
            <label className="showPassword-label" >Show Password</label>
          </div>}
        <div className="template3LoginButton">
          <Button
            size="sm"
            className="pr-2 pl-2"
            color="primary"
            onClick={this.onLogin}
          >
            <i className="fa fa-lock pr-2" aria-hidden="true"></i>
            {
              label.show ? label.text : 'Login'
            }
          </Button>
        </div>
                </div>
            </div>
        {authTemplateSideImageUrlComplete && <div className='authTemplate3Logo'>
            <img src={authTemplateSideImageUrlComplete}  alt="Image" />
            </div>}
            
       
   
        <Row>
        </Row>
  
      </div>
    )
  }
}
export default Template3;