import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Table from '../Table/Tablev1';
import { Label, Button, ButtonGroup, Input, Row, Col } from 'reactstrap';
import ActionContainer from '../../../containers/workflow/ActionContainer';
import DataListStore from '../../workflow/operations/filters/DataListStore';
import DataListWrapper from '../../workflow/operations/filters/DataListWrapper';
import FilterWrapperHOC from '../../workflow/operations/filters/FilterWrapperHOC';
import NoDataAlertBox from '../../commons/NoDataAlertBox';
import { Field, FieldArray } from 'redux-form/immutable';
import { renderField } from '../_FORM_FIELDS';
import { Map } from 'immutable';
import * as Elements from '../index';
import { ModelPropertiesParser } from '../../commons/modelPropertiesParser';
import JSONPath from 'jsonpath';


String.prototype.capitalize = function () { return this.charAt(0).toUpperCase() + this.slice(1); }

const renderTableFields = ({ rows, label, columns, fields, getValidations, validations, meta: { error }, classProperty, ...rest }) => {

	const { layoutItem: { itemProperties: { field: { customTypeClass }, buttonLabel } }, workflow: { currentItem: { typeModel: { customClassName, customerName } } } } = rest;
	let modelVersion = customClassName.replace('com.uttara.neene.proxyto.' + customerName + '_', '');
	modelVersion = modelVersion.substring(0, modelVersion.indexOf('.'));
	const { layoutItem: { itemProperties } } = rest
	const hideAddDeleteButton = itemProperties && itemProperties.dontAllowAdditionDeletion ? itemProperties.dontAllowAdditionDeletion : null;
	const iModelVerision = parseInt(modelVersion.substring(1));
	let className = customTypeClass;
	for (let i = 0; i < iModelVerision; i++) {
		if (className.indexOf('v' + i + '.') != -1)
			className = className.replace('v' + i + '.', modelVersion + '.').replace(customerName.capitalize() + '_v' + i, customerName.capitalize() + '_' + modelVersion);
	}
	if (fields.length == 0) {
		fields.push(new Map({ class: className }));
	}
	return <div className='mt-2'>
		{
			fields.map((member, index) => {
				return <Row key={index}>
					{
						columns.map((item, colIndex) => {
							return item.field.type != 'LARGETEXT' && <Col key={colIndex}>
								<TableLayoutItem
									member={member}
									memberData={rows[index]}
									item={item}
									rows={rows}
									parentClassProperty={classProperty}
									parentIndex={index}
									columns={columns}
									getValidations={getValidations}
									{...rest}
								/></Col>
						})
					}
					{!hideAddDeleteButton && <Button onClick={() => fields.remove(index)} size='sm' color='link'>
						<i className="fa fa-trash text-danger" aria-hidden="true"></i>
					</Button>}
				</Row>
			})
		}
		{!hideAddDeleteButton && <div className="d-flex justify-content-end mt-4">
			<Button color='primary' size="sm" onClick={() => fields.push(new Map({ class: className }))}>
				<i className="fa fa-plus" aria-hidden="true"></i>{` ${buttonLabel? buttonLabel: label.text}`}
			</Button>
		</div>}
	</div>
}

class TypeModelCreateUpdateTable extends Component {
	constructor(props) {
		super(props);
		this.state = {
			validationArr: []
		};
	}
	componentDidMount() {

	}
	componentWillReceiveProps(nextProps) {
	}

	render() {
		const { workflow, rows, columns, label, viewMode, typeModelViewMode, onChangeTypeModelViewMode,
			workflow: { currentItem: { typeModel: { name } } }, layoutItem: { itemProperties }, noDataText } = this.props;
		return (
			<div className="type-model-table">
				<div className='d-flex justify-content-between mb-2 border-botoom'>
					<div>
						<Label className='text-muted text-capitalize mb-0' style={{ marginLeft: 20 }}>{label.text}</Label>
					</div>
				</div>
				{
					<div>
						<div className="p-2" style={{ border: '1px solid #ccc', borderRadius: 5 }}>
							<ColumnHeader columns={columns} />
							<FieldArray label={label} rows={rows} columns={columns} name={this.props.classProperty} validations={this.props.validations}
								component={renderTableFields} {...this.props} />
						</div>
					</div>
				}
			</div>
		);
	}
}

const ColumnHeader = (props) => {
	const { columns } = props;
	return (
		<Row className='w-100'>
			{
				columns && columns.length && columns.map((item, index) => {
					return item.field.type != 'LARGETEXT' && <Col key={index}><b className='text-capitalize'>{item.config.header}</b></Col>
				})
			}
		</Row>
	)
}

class TableLayoutItem extends Component {
	constructor(props) {
		super(props);
		this.state = {
			validations: [],
			itemId: ''
		};
	}
	componentDidMount() {
		const item = this.props.item;
		const validations = this.props.getValidations({
			itemType: 'MODELPROPERTIES',
			itemProperties: {
				field: item.field,
				minLength: item.config.minLength,
				regexPattern: item.config.regexPattern,
				regexErrorMessage: item.config.regexErrorMessage,
				maxLength: item.config.maxLength,
			}
		});
		this.setState({ validations, itemId: item.field.id });
	}

	render() {
		const { item, member, layoutItem, rows, columns, parentClassProperty, parentIndex, ...rest } = this.props;
		let ResolvedComponent = Elements[item.field.type];
		const { hasMember, memberClassProperty, memberIndex } = this.props.workflow;
		if (item.config.renderReadOnly) {
			if (!this.props.workflowData) return null;
			let textToRender = '';
			if (typeof member === 'string') {
				textToRender = ModelPropertiesParser(item.config.modelSelector, this.props.memberData[item.field.classProperty]);
			} else {

				const dataToRender = JSONPath.value(this.props.workflowData, `$.${member}.${item.field.classProperty}`);
				textToRender = ModelPropertiesParser(item.config.modelSelector, dataToRender);

			}
			//const textToRender = ModelPropertiesParser(item.config.modelSelector, member[item.field.classProperty]);
			return <Label>{textToRender}</Label>
		}
		return (
			<ResolvedComponent
				classProperty={`${parentClassProperty}[${parentIndex}].${item.field.classProperty}`}
				itemClassPropertyFromTable={item.field.classProperty}
				label=''
				placeholder=''
				ratingConfig={item.config.ratingConfig}
				layoutItem={{
					itemProperties: {
						modelSelector: item.config.modelSelector ? item.config.modelSelector : '',
						label: { show: false },
						placeholder: { show: false },
						elementType: item.field.type,
						field: item.field,
						ratingConfig: item.config.ratingConfig,
						replaceClassProperty: `${parentClassProperty}[${parentIndex}].${item.field.classProperty}`
					},
					...item
				}}
				type={item.field.type}
				validations={item.field.id == this.state.itemId ? this.state.validations : []}
				dontShowDROPBOX={true}
				rows={rows}
				columns={columns}
				disableState={item && item.config.disableState}
				{...rest}
			/>
		);
	}
}

TypeModelCreateUpdateTable.defaultProps = {
	rows: [],
	columns: []
}

TypeModelCreateUpdateTable.propTypes = {
	workflow: PropTypes.object,
	rows: PropTypes.array,
	columns: PropTypes.array,
	label: PropTypes.object
};

export default TypeModelCreateUpdateTable;