import React, { Component } from 'react';
import { FormGroup, Input, Row, Label, Col, FormFeedback, InputGroup, InputGroupAddon } from 'reactstrap';
import PrintText from '../PrintText';
import { Field } from 'redux-form/immutable';
import time from '../../workflow/validations/time';
import date from '../../workflow/validations/date';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';

class DateTimeClass extends Component {
    constructor(props) {
        super(props);
        this.state = {
            date: null
        }
        this.handleChange = this.handleChange.bind(this);
    }
    componentDidMount() {
        if(this.props.defaultValue) {
            const date = this.props.defaultValue;
            const { input: { onChange } } = this.props;
            const year = date.toDate().getFullYear();
            const month = date.toDate().getMonth();
            const day = date.toDate().getDate();
            const hour = date.toDate().getHours();
            const min = date.toDate().getMinutes();
            const value = `${year}-${(month > 9 ? '' : '0') + (month + 1)}-${(day > 9 ? '' : '0') + day} ${(hour > 9 ? '' : '0') + hour}:${(min > 9 ? '' : '0') + min}`;
            onChange(value);
        }
    }
    handleChange(date) {
        const { input: { onChange }} = this.props;
        let updatedTime = date.clone();
        const offSet = date.utcOffset();
        updatedTime = updatedTime.subtract(offSet, 'minutes');
        const year = updatedTime.toDate().getFullYear();
        const month = updatedTime.toDate().getMonth();
        const day = updatedTime.toDate().getDate();
        const hour = updatedTime.toDate().getHours();
        const min = updatedTime.toDate().getMinutes();
        const value = `${year}-${(month > 9 ? '' : '0') + (month + 1)}-${(day > 9 ? '' : '0') + day} ${(hour > 9 ? '' : '0') + hour}:${(min > 9 ? '' : '0') + min}`;
        this.setState({ date }, () => { onChange(value) });
    }
	handleDateSelect(date) {
        const { input: { onChange }, layoutItem } = this.props;
		const { itemProperties: { dateTimeConfig } } = layoutItem;
        let updatedTime = date.clone();
        const offSet = date.utcOffset();
        updatedTime = updatedTime.subtract(offSet, 'minutes');
		const year = updatedTime.toDate().getFullYear();
        const month = updatedTime.toDate().getMonth();
        const day = updatedTime.toDate().getDate();
        let hour = updatedTime.toDate().getHours();
        let min = updatedTime.toDate().getMinutes();
		if (dateTimeConfig && dateTimeConfig.defaultTimeSet) {
			const valueArray = dateTimeConfig.defaultTimeSet.split(':')
            hour = parseInt(valueArray[0])
            min = parseInt(valueArray[1])
		}
		// date = new Date(updatedTime.toDate().setHours(hour));
		// date = moment(updatedTime.toDate().setMinutes(min));
        const value = `${year}-${(month > 9 ? '' : '0') + (month + 1)}-${(day > 9 ? '' : '0') + day} ${(hour > 9 ? '' : '0') + hour}:${(min > 9 ? '' : '0') + min}`;
        this.setState({ date }, () => { onChange(value) });
    }
    render() {
        const { label,
            layoutItem,
            useLabelAsPlaceholder,
            placeholder
        } = this.props;
        const { properties } = layoutItem.itemProperties.field;
        const { date } = this.state;
        return (
            <FormGroup>
                {
                    label.show ? <Label>{label.text}{properties.required ? <sup>*</sup> : ''}</Label> : ''
                }
                <DatePicker
                    selected={date || this.props.defaultValue}
                    onChange={this.handleChange}
					onSelect={this.handleDateSelect.bind(this)} 
                    showTimeSelect
                    timeFormat="HH:mm"
                    timeIntervals={15}
                    dateFormat="LLL"
                    timeCaption="time"
                    className='form-control'
                    placeholderText={useLabelAsPlaceholder ? label.text : placeholder.show ?placeholder.text:'Select Date...'}
                />
            </FormGroup>
        )
    }
}

export default DateTimeClass;




// const isTime = (value) => {
//     const pattr = new RegExp(/^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$/);
//     return pattr.test(value);
// }

// class DateTimeClass extends Component {
//     constructor(props) {
//         super(props);
//         this.state = {
//             values: {
//                 date: {
//                     value: '',
//                     isValid: true,
//                     error: ''
//                 },
//                 time: {
//                     value: '',
//                     isValid: true,
//                     error: ''
//                 }
//             }
//         }
//     }
//     validate(key, val) {
//         let v = null;
//         if (key === 'date') v = date;
//         if (key === 'time') v = time;
//         if (v) {
//             return v(val);
//         }
//     }
//     valueEntered(key, ev) {
//         const values = Object.assign([], this.state.values);
//         const { input: { onChange } } = this.props;
//         const etVal = ev.target.value + '';
//         const validityCheck = this.validate(key, etVal);

//         values[key]['isValid'] =  !validityCheck ? true : false;
//         values[key]['error'] =  validityCheck;
//         values[key]['value'] = etVal;
//         this.setState({ values }, () => {
//             onChange(`${this.state.values.date.value} ${this.state.values.time.value}`);
//         });
//     }
//     render() {
//         const { label,
//             layoutItem
//         } = this.props;
//         const { properties } = layoutItem.itemProperties.field;
//         const { values } = this.state;
//         return (
//             <FormGroup>
//                 {
//                     label.show ? <Label>{label.text}{properties.required ? <sup>*</sup> : ''}</Label> : ''
//                 }
//                 {
//                     <Row>
//                         <Col sm='12' md='6'>
//                             <InputGroup>
//                                 <InputGroupAddon>Date</InputGroupAddon>
//                                 <Input valid={!values.date.isValid ? false : null} type='date' onChange={this.valueEntered.bind(this, 'date')} />
//                             </InputGroup>
//                             {
//                                 !values.date.isValid ? <InvalidDate error={values.date.error} /> : ''
//                             }
//                         </Col>
//                         <Col sm='12' md='6'>
//                             <InputGroup>
//                                 <InputGroupAddon>Time</InputGroupAddon>
//                                 <Input valid={!values.time.isValid ? false : null} type='time' onChange={this.valueEntered.bind(this, 'time')} />
//                             </InputGroup>
//                             {
//                                 !values.time.isValid ? <InvalidDate error={values.time.error} /> : ''
//                             }

//                         </Col>
//                     </Row>
//                 }
//             </FormGroup>
//         );
//     }
// }

// const InvalidDate = (props) => {
//     return <p className='animated fadeIn text-danger invalid-feedback d-block'>
//         <i className="fa fa-exclamation-triangle" aria-hidden="true"></i> {props.error}
//     </p>
// }

// export default DateTimeClass;