import React from 'react';
import { FormGroup, Input, Label, FormFeedback, Row, Col, Button } from 'reactstrap';
import RememberMeComp from '../../AuthHelpers/RememberMeComp';
import { Link } from 'react-router-dom';
class Template1 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      isChecked: false,
      isShowPassword:false
    }
  }

  onLogin = () => {
    const { email, password, isChecked } = this.state;
    const { authenticationCallback, itemProperties, sessionId } = this.props;
    const payload = {
      email,
      password
    };
    if (itemProperties && itemProperties.authenticateUsingPlugins) {
      payload['authenticateUsingPlugins'] = true;
      payload['sessionId'] = sessionId;
    }
    this.props.setError(false)
    this.props.login(payload, authenticationCallback);
    if (isChecked && email !== "") {
      localStorage.username = email
      localStorage.password = password
      localStorage.checkbox = isChecked
    }
  }

  togglePasswordVisiblity =()=> {
    this.setState({ isShowPassword: !this.state.isShowPassword });
}
  render() {
    const { label,hasForgotPassword,showForgotPasswordAboveLoginButton } = this.props;
    const { itemProperties } = this.props;
    const rememberMe =
      itemProperties &&
      itemProperties.authConfig &&
      itemProperties.authConfig.rememberMe
    const showPassword =
    itemProperties &&
    itemProperties.authConfig &&
    itemProperties.authConfig.showPassword
    return (
      <div>
        <FormGroup>
          <Label for={'email'}>Email</Label>
          <Input
            value={this.state.email}
            onChange={(event) => this.setState({ email: event.target.value })}
            onKeyDown={(event) => {
              if (event.key === "Enter") {
                event.preventDefault();
                event.stopPropagation();
                this.onLogin();
              }
            }}
            placeholder={'Enter Email'}
            type={'text'}
          />
        </FormGroup>
        <FormGroup>
          <Label for={'password'}>Password</Label>
          <Input
            value={this.state.password}
            onChange={(event) => this.setState({ password: event.target.value })}
            onKeyDown={(event) => {
              if (event.key === "Enter") {
                event.preventDefault();
                event.stopPropagation();
                this.onLogin();
              }
            }}
            placeholder={'Enter password'}
            type={this.state.isShowPassword?'text':'password'}
          />
        </FormGroup>
        <Row>
       { showPassword &&  <Col>
          <div style={{padding:2}}>
            <input type="checkbox" checked={this.state.isShowPassword} className="showPassword-checkbox" style={{marginRight:12}} name="lsRememberMe" onChange={this.togglePasswordVisiblity} />
            <label className="showPassword-label" >Show Password</label>
          </div>
          </Col>}
          <Col>
         {rememberMe ? (
          <div className="remember-me-login">
            <RememberMeComp isCheckedProp={this.state.isChecked} />
          </div>
        ) : null   }
        </Col>
        </Row>
        {hasForgotPassword && showForgotPasswordAboveLoginButton? (
          <div className='emailAuthFOrgotPassword'>
            <Link to='/app/forgotpassword'>Forgot password?</Link>
          </div>
        ) : null}
        <div className="d-flex justify-content-end">
          <Button
            size="sm"
            className="pr-2 pl-2"
            color="primary"
            onClick={this.onLogin}
          >
            <i className="fa fa-lock pr-2" aria-hidden="true"></i>
            {
              label.show ? label.text : 'Login'
            }
          </Button>
        </div>
      </div>
    )
  }
}
export default Template1;