import React from 'react';
import {Cell, PieChart, Pie, ResponsiveContainer, Sector} from 'recharts';

const renderActiveShapeSmall = (props) => {
  const RADIAN = Math.PI / 180;
  const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle,
    fill, payload, percent, value } = props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 10) * cos;
  const sy = cy + (outerRadius + 10) * sin;
  const mx = cx + (outerRadius + 30) * cos;
  const my = cy + (outerRadius + 30) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 22;
  const ey = my;
  const textAnchor = cos >= 0 ? 'start' : 'end';

  return (
    <g>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={fill}
      />
      <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none"/>
      <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none"/>
      <text x={ex + (cos >= 0 ? 1 : -1) * 12} fontSize={12} y={ey} textAnchor={textAnchor} fill={fill}>{payload.name}</text>
      <text x={ex + (cos >= 0 ? 1 : -1) * 12} fontSize={12} y={ey} dy={18} textAnchor={textAnchor} fill="#999">
        {`${(percent * 100).toFixed(2)}%`}
      </text>
    </g>
  );
};

const renderActiveShape = (props) => {
  const RADIAN = Math.PI / 180;
  const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle,
    fill, payload, percent, value } = props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 10) * cos;
  const sy = cy + (outerRadius + 10) * sin;
  const mx = cx + (outerRadius + 30) * cos;
  const my = cy + (outerRadius + 30) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 22;
  const ey = my;
  const textAnchor = cos >= 0 ? 'start' : 'end';

  return (
    <g>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={fill}
      />
      <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none"/>
      <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none"/>
      <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill={fill}>{payload.name}</text>
      <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} fill="#999">
        {`${(percent * 100).toFixed(2)}%`}
      </text>
    </g>
  );
};


const renderActiveShapeVerySmall = (props) => {
  const RADIAN = Math.PI / 180;
  const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle,
    fill, payload, percent, value } = props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 10) * cos;
  const sy = cy + (outerRadius + 10) * sin;
  const mx = cx + (outerRadius + 30) * cos;
  const my = cy + (outerRadius + 30) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 22;
  const ey = my;
  const textAnchor = cos >= 0 ? 'start' : 'end';

  return (
    <g>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={fill}
      />
      <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none"/>
      <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none"/>
      <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} fontSize={8} textAnchor={textAnchor} fill={fill}>{payload.name}</text>
      <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} fontSize={8} dy={18} textAnchor={textAnchor} fill="#999">
        {`${(percent * 100).toFixed(2)}%`}
      </text>
    </g>
  );
};

export default class PIECHART extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeIndex: 0
    }
  }

  onPieEnter = (data, index) => {
    this.setState({
      activeIndex: index,
    });
  }

  render() {
    const { graphData, colors, legendName, xLabel, yLabel, graphHeight,reportPrimaryColor } = this.props;
    const COLORS = colors;
    let data = [];
    graphData && graphData.map((item, index) => {
      const newItem = {
        name: item.xProperties,
        value: item.yProperties
      }
      data.push(newItem);
    });
    

    if(graphHeight < 301 && graphHeight > 200 ){
      return (
        <div style={{width: '100%'}} className='reportMainContainer'>
          <ResponsiveContainer height={graphHeight/2 +30} width={'99%'}>
          <PieChart width={graphHeight/2}>
            <Pie 
              dataKey="value"
              innerRadius={10}
              outerRadius={10} data={data}
              fill="#8884d8"
              onMouseEnter={this.onPieEnter}
              activeShape={renderActiveShapeVerySmall}
              paddingAngle={25} minAngle={25}
              activeIndex={this.state.activeIndex}
              label={renderActiveShapeVerySmall}
            >
              {
                data.map((entry, index) => <Cell key={index} fill={ reportPrimaryColor && index===0 ? reportPrimaryColor: COLORS[index % COLORS.length]}/>)
              }
            </Pie>
          </PieChart>
          </ResponsiveContainer>
        </div>
      );
     }

 if(graphHeight < 301 ){
  return (
    <div style={{width: '100%'}} className='reportMainContainer'>
      <ResponsiveContainer height={graphHeight/2 +30} width={'99%'}>
      <PieChart width={graphHeight/2}>
        <Pie 
          dataKey="value"
          innerRadius={20}
          outerRadius={20} data={data}
          fill="#8884d8"
          onMouseEnter={this.onPieEnter}
          activeShape={renderActiveShapeSmall}
          paddingAngle={20} minAngle={20}
          activeIndex={this.state.activeIndex}
          label={renderActiveShapeSmall}
        >
          {
            data.map((entry, index) => <Cell key={index} fill={ reportPrimaryColor && index===0 ? reportPrimaryColor: COLORS[index % COLORS.length]}/>)
          }
        </Pie>
      </PieChart>
      </ResponsiveContainer>
    </div>
  );
 }


 if(graphHeight >301 && graphHeight <400 ){
  return (
    <div style={{width: '100%'}} className='reportMainContainer'>
      <ResponsiveContainer height={graphHeight} width={'99%'}>
      <PieChart width={graphHeight}>
        <Pie 
          dataKey="value"
          innerRadius={55}
          outerRadius={70} data={data}
          fill="#8884d8"
          onMouseEnter={this.onPieEnter}
          activeShape={renderActiveShapeSmall}
          activeIndex={this.state.activeIndex}
          label={renderActiveShapeSmall}
        >
          {
            data.map((entry, index) => <Cell key={index} fill={ reportPrimaryColor && index===0 ? reportPrimaryColor: COLORS[index % COLORS.length]}/>)
          }
        </Pie>
      </PieChart>
      </ResponsiveContainer>
    </div>
  );
        }


    return (
      <div style={{width: '100%'}} className='reportMainContainer'>
        <ResponsiveContainer height={graphHeight}>
        <PieChart>
          <Pie 
            dataKey="value"
            innerRadius={75}
            outerRadius={100} data={data}
            fill="#8884d8"
            onMouseEnter={this.onPieEnter}
            activeShape={renderActiveShape}
            activeIndex={this.state.activeIndex}
            label={renderActiveShape}
          >
            {
              data.map((entry, index) => <Cell key={index} fill={ reportPrimaryColor && index===0 ? reportPrimaryColor: COLORS[index % COLORS.length]}/>)
            }
          </Pie>
        </PieChart>
        </ResponsiveContainer>
      </div>
    );
  }
}