import React from 'react';
import { toJS } from '../../containers/to-js';
import { connect } from 'react-redux';
import { endWorkflow } from '../../actions/workflows';
import { Redirect } from 'react-router-dom';

class _Redirect extends React.Component {
  componentDidMount() {
    const { endWorkflow, baseWorkflow: { workflowModel, sessionId } } = this.props;
    const { layoutItem: { itemProperties: { redirectConfig } } } = this.props;
    if(redirectConfig && redirectConfig.linkField) {
      //dont do anything
    } else if(redirectConfig && typeof redirectConfig.link === 'string' && redirectConfig.link.startsWith('http')) {
      endWorkflow({ workflowId: workflowModel, sessionId });
    }
  }

  componentWillReceiveProps(nextProps) {
    const { endWorkflow, baseWorkflow: { workflowModel, sessionId } } = nextProps;
    const { layoutItem: { itemProperties: { redirectConfig } }, urlTORedirect } = nextProps;
    if(redirectConfig && typeof urlTORedirect === 'string' && urlTORedirect.startsWith('http')) {
      window.location.href = urlTORedirect;
    }
  }

  render() {
    const { layoutItem: { itemProperties: { redirectConfig } }, urlTORedirect } = this.props;
    if(redirectConfig && redirectConfig.linkField && typeof urlTORedirect === 'string' && !urlTORedirect.startsWith('http')) {
      return <Redirect to={`/app/${urlTORedirect}`} />
    } else if(redirectConfig && typeof redirectConfig.link === 'string' && !redirectConfig.link.startsWith('http')) {
      return <Redirect to={`/app/${redirectConfig.link}`} />
    }
    return (
      <div>Waiting to Redirect</div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const { layoutItem: { itemProperties: { redirectConfig } } } = ownProps;
  const baseWorkflow = state.getIn(['workflow', 'baseWorkflow']);
  return {
    baseWorkflow,
    urlTORedirect: redirectConfig && redirectConfig.linkField && state.getIn(['form', ownProps.form, 'values', ...getAbsoluteField(redirectConfig.linkField)])
  };
};

const getAbsoluteField = (fieldKey) => {
  return fieldKey ? fieldKey.split(".") : fieldKey;
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    endWorkflow: (payload) => {
      dispatch(endWorkflow(payload));
    },
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(toJS(_Redirect));